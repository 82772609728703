import React from "react";
import { errorMessageChecker } from "../../../../_helpers/methods";
// import { dynamicValidator } from "../../../../_helpers/dynamicValidator";
import InputField from "../../../InputField";

function Text({
  register,
  elementId,
  language,
  className,
  placeholder,
  maxlength,
  value,
  setValue,
  errors = {},
  properties = {},
  validations,
  subType = 'text',
  isDisabled
}) {
  return (
    <div>
      <InputField
        type={subType}
        language={language}
        className={`${className} input ${properties?.isRequired ? "textbox_req" : ""
          } ${errorMessageChecker(errors, elementId) ? "is-invalid" : ""} `}
        register={register}
        elementId={elementId}
        placeholder={placeholder}
        maxLength={maxlength}
        value={value}
        setValue={setValue}
        properties={properties}
        validation={validations}
        isDisabled={isDisabled}
      />
    </div>
  );
}

export default Text;
