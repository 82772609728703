/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaEdit, FaEye, FaPrint } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { ElderlyServices } from "../../api/services/ElderlyServices";
import DefaultColumnFilter, {
  StyledFilterInput,
} from "../../components/Table/Filters/DefaultColumnFilter";
import { useFilters, usePagination, useRowSelect, useTable } from "react-table";
import ReactTableRender from "../../components/Table/ReactTableRender";
import { debounce } from "../../_helpers/methods";
import { GenderData } from "../../_datas/bloodGroup";
import InputField from "../../components/InputField";
import { useSelector } from "react-redux";
import ElderlyUploader from "../../components/ExcelUploader/Elderly/ElderlyUploader";
import { checkPermission } from "../../_helpers/roles/check-roles";

const OldAge = () => {
  const history = useHistory();
  const { pageNum } = useParams();
  const inputRef = useRef(null);
  const user = useSelector((state) => state.users?.user);

  const role = user.role_en;

  const [pageNo, setPageNo] = useState(pageNum);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [queryFilter, setQueryFilter] = useState({});

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    let wardFilter = {};
    if (role !== "admin") {
      wardFilter = {
        ward: user.ward,
      };
    }

    const results = await ElderlyServices.getElderlyProfiles({
      page: pageNo ?? 1,
      ...queryFilter,
      ...wardFilter,
    });

    setTotalCount(results.count);
    setResults(results.results);
    setLoading(false);
  }, [pageNo, queryFilter, role, user.ward]);

  const ManualFilter = useCallback(({ column }) => {
    const handleOnChange = debounce((value) => {
      setQueryFilter((prev) => ({ ...prev, [column.id]: value }));
    }, 800);

    if (column?.filterType === "Select") {
      let options = column.filterOptions || {};
      return (
        <select
          style={{ width: "100%" }}
          onChange={(e) => {
            handleOnChange(e.target.value || undefined);
          }}
        >
          {Object.keys(options).map((key) => {
            return (
              <option key={key} value={key}>
                {options[key]}
              </option>
            );
          })}
        </select>
      );
    } else {
      if (column?.FilterLanguage === "nepali") {
        return (
          <InputField
            placeholder="खोज्नुहोस ..."
            getInputData={handleOnChange}
            className="filer-input"
          />
        );
      } else {
        return (
          <StyledFilterInput
            ref={inputRef}
            placeholder="खोज्नुहोस ..."
            onChange={(e) => {
              handleOnChange(e.target.value);
            }}
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    history.push(`/elderly/list/${pageNo}`);
    try {
      fetchProfiles();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [fetchProfiles, history, pageNo]);

  const columns = useMemo(
    () => [
      {
        Header: "क्र. स.",
        accessor: "serial_number",
        disableFilters: true,
        width: "80px",
      },
      //   {
      //     Header: "फोटो",
      //     accessor: "photo",
      //     disableFilters: true,
      //     width: "120px",
      //   },
      {
        Header: "पुरा नाम",
        accessor: "fullname",
        width: "200px",
        Filter: ManualFilter,
        FilterLanguage: "nepali",
      },
      {
        Header: "लिङ्ग",
        accessor: "gender",
        Filter: ManualFilter,
        filterType: "Select",
        filterOptions: GenderData,
        // Filter: ManualFilterByPhone,
        width: "100px",
      },
      //   {
      //     Header: "कार्ड नं",
      //     accessor: "cardNumber",
      //     disableFilters: true,
      //     width: "100px",
      //   },
      // {
      // 	Header: 'जन्म मिति (B.S.)',
      // 	accessor: 'dob_bs',
      // 	disableFilters: true,
      // 	width: '150px'
      // },
      {
        Header: "नागरिता न.",
        accessor: "citizenshipNo",
        disableFilters: true,
        width: "120px",
      },
      {
        Header: "जारी मिति (B.S.)",
        accessor: "citizenshipIssuedDate",
        disableFilters: true,
        width: "150px",
      },
      {
        Header: "कार्यहरु",
        accessor: "actions",
        width: "100px",
        disableFilters: true,
      },
    ],
    [ManualFilter]
  );
  const data = useMemo(() => {
    if (!results) return [];
    let count = 1;

    return results.map((result) => {
      const actions = (
        <span className="d-flex align-items-center">
          <FaEye
            className="mr-3 text-info pointer"
            onClick={() => history.push(`/preview/elderly/${result.id}`)}
          />
          <FaEdit
            className="mr-3 text-primary pointer"
            onClick={() => history.push(`/elderly/edit/${result.id}`)}
          />

          <FaPrint
            className="text-primary pointer"
            onClick={() => history.push(`/elderly/pratilipi_form/${result.id}`)}
          />
        </span>
      );

      const photoContainer = (
        <span>
          {result.photo ? (
            <img
              src={result.photo}
              alt={result.name_en}
              css={css`
                min-width: 0px;
                width: 100px;
                margin-right: 15px;
              `}
            />
          ) : (
            <span>No Photo</span>
          )}
        </span>
      );
      return {
        serial_number: (pageNo - 1) * 10 + count++,
        fullname: (
          <div className="d-flex justify-content-start align-items-center">
            {photoContainer}
            <div>
              <p className="font-weight-bold">{result.name_np}</p>
              <p>{result.cardNo}</p>
            </div>
          </div>
        ),
        dob_bs: result.dateOfBirth_np,
        // cardNumber: result.cardNo,
        gender: result.gender,
        citizenshipNo: result.citizenshipNo,
        citizenshipIssuedDate: result.citizenshipIssuedDate,
        actions: actions,
      };
    });
  }, [history, pageNo, results]);

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    pageCount,
    previousPage,
  } = useTable(
    {
      columns,
      data: data,
      manualPagination: true,
      pageCount: pageNo,
      defaultCanFilter: false,
      defaultColumn,
    },
    useFilters,
    usePagination,
    useRowSelect
  );

  const pageCountArray = useMemo(
    () =>
      Array.from({ length: Math.ceil(totalCount / pageSize) }, (v, i) => i + 1),
    [totalCount, pageSize]
  );

  const nextPage = (page) => {
    setPageNo(page);
  };
  const gotoPage = (page) => {
    setPageNo(page);
  };

  return (
    <div>
      <div aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" aria-current="page">
            Home
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            जेष्ठ नागरिकको विवरणहरु
          </li>
        </ol>
      </div>
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <h2>जेष्ठ नागरिकको विवरणहरु</h2>

        <div className="d-flex">
          {
            checkPermission(role, 'upload:excelList') &&
            <ElderlyUploader />
          }

          <Button
            variant="success"
            onClick={() => history.push("/elderly/new")}
            className="ml-2"
          >
            नयाँ फारम
          </Button>
        </div>
      </div>
      <Row>
        <Col>
          <ReactTableRender
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            totalCount={totalCount}
            gotoPage={gotoPage}
            previousPage={previousPage}
            pageCount={pageCount}
            pageCountArray={pageCountArray}
            pageNum={pageNo}
            showGoto={true}
            nextPage={nextPage}
            loading={loading}
            setPageSize={setPageSize}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OldAge;
