import { all_rules } from "../../_datas";

export const handleConvertNepali = (font = "preeti", text) => {
  let output = "";
  const myFont = all_rules[font];
  if (!myFont) {
    throw Error("font not included in module");
  }

  for (let w = 0; w < text.length; w++) {
    let letter = text[w];
    output += myFont["char-map"][letter] || letter;
  }
  for (let r = 0; r < myFont["post-rules"].length; r++) {
    output = output.replace(
      new RegExp(myFont["post-rules"][r][0], "g"),
      myFont["post-rules"][r][1]
    );
  }
  return output;
};
