/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { connect, useSelector } from "react-redux";
import {
  fetchFiscalYear,
  createFiscalYear,
  editFiscalYear,
  deleteFiscalYear,
} from "../../_redux/slices/fiscalyear";
import { FaCheckCircle, FaPlusCircle, FaTrash } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import BackButton from "../../_elements/BackButton";
import { VscLoading } from "react-icons/vsc";
import PaginationComp from "../../components/Pagination";
import FormInput from "../../components/Form/Elements/_new/FormInput";
import { useDispatch } from "react-redux";
import { handleDecrementFiscalYearStats, handleIncrementFiscalYearStats } from "../../_redux/slices/appStats";

const FiscalYear = ({
  fetchFiscalYear,
  fiscalyear,
  totalFiscalYear,
  createFiscalYear,
  editFiscalYear,
  deleteFiscalYear,
}) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchFiscalYear(page);
  }, [fetchFiscalYear, page]);

  const { register, handleSubmit, setValue, formState: { errors }, } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const handleFiscalYearForm = ({ incrementFiscalYearStats }) => {
    setShowAdd(false);
    if (incrementFiscalYearStats) {
      dispatch(handleIncrementFiscalYearStats())
    }
  };

  const onSubmit = (data) => {
    createFiscalYear(data, handleFiscalYearForm);
  };

  const onActive = (item) => {
    let activatedItem = {
      id: item.id,
      title: item.title,
      active: true
    };
    editFiscalYear(activatedItem);
    setShow(null);
  };

  const decrementFiscalYearStats = () => {
    dispatch(handleDecrementFiscalYearStats());
  }

  const [show, setShow] = useState(null);
  const handleDeleteFiscalYear = (fiscalYearId) => {
    deleteFiscalYear(fiscalYearId, decrementFiscalYearStats);
    setShow(null);
  };
  const [showAdd, setShowAdd] = useState(false);
  const loading = useSelector((state) => state.fiscal.loading);

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <BackButton />
        </Col>
      </Row>
      {showAdd && (
        <Card>
          <Card.Header className="card-header-custom d-block">
            <h4>नयाँ आर्थिक वर्ष</h4>
          </Card.Header>
          <Card.Body className="p-0 my-4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="m-2">
                <Col md={4}>
                  <FormInput
                    label="वर्ष"
                    id="title"
                    language="english"
                    register={register}
                    setValue={setValue}
                    type="Text"
                    placeholder="YYYY/YY"
                    errors={errors}
                    validations={{
                      isRequired: true
                    }}
                  />
                </Col>
                <Col md={12}>
                  <FormInput
                    id="active"
                    register={register}
                    setValue={setValue}
                    type="Checkbox"
                    errors={errors}
                    properties={{
                      text: "चालु आर्थिक वर्ष?"
                    }}
                  />
                </Col>
                <Col md={12} className="mt-4">
                  <Button variant="success" type="submit">
                    सुरक्षित
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      )}

      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>आर्थिक वर्ष</h2>
              <Button
                variant="primary"
                onClick={() => {
                  setShowAdd(!showAdd);
                  setValue("title", "");
                }}
              >
                <FaPlusCircle />
                &nbsp; नयाँ आर्थिक वर्ष
              </Button>
            </Card.Header>

            <Card.Body className="p-0 mt-4">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr
                      css={css`
                        th {
                          text-align: center;
                        }
                      `}
                    >
                      <th>आर्थिक वर्ष</th>
                      <th>परिचय पत्र</th>
                      <th className="text-center">कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td></td>
                        <td className="text-right">
                          <VscLoading className="spin" fontSize="48" />
                        </td>
                        <td></td>
                      </tr>
                    ) : fiscalyear.length > 0 ? (
                      fiscalyear.map((item, index) => (
                        <tr
                          css={css`
                            td {
                              text-align: center;
                            }
                          `}
                          key={item.id}
                        >
                          <td className="d-flex justify-content-center align-items-center">
                            {item.title}
                            <FaCheckCircle
                              className={
                                item.active === true
                                  ? `text-success ml-1`
                                  : `ml-1`
                              }
                            />
                          </td>
                          <td className="text-center">0</td>
                          <td className="text-center">
                            {!item.active && (
                              <Fragment>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    setShow({ type: "activate", item: item })
                                  }
                                >
                                  <AiFillCheckCircle size={12} /> Activate
                                </Button>
                                <Button
                                  variant="danger"
                                  className="ml-2"
                                  onClick={() =>
                                    setShow({ type: "delete", item: item })
                                  }
                                >
                                  <FaTrash size={12} /> हटाउनु
                                </Button>
                              </Fragment>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr
                        css={css`
                          th {
                            text-align: center;
                          }
                        `}
                      >
                        <td colSpan={4} className="text-center">
                          <h5>आर्थिक वर्ष पाईएन</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Card.Footer>
              {totalFiscalYear > 10 && (
                <PaginationComp
                  total={totalFiscalYear}
                  page={page}
                  setPage={setPage}
                />
              )}
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Modal show={Boolean(show)} onHide={() => setShow(null)} centered>
        <Modal.Header>
          <h3>आर्थिक वर्ष</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="h5 font-weight-lighter">
            {show?.type === "activate"
              ? "के तपाइँ यो आर्थिक वर्ष सक्रिय गर्न चाहानुहुन्छ?"
              : "के तपाइँ यो आर्थिक वर्ष हटाउन चाहानुहुन्छ?"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setShow(null);
            }}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              if (show?.type === "activate") {
                onActive(show?.item);
              } else {
                handleDeleteFiscalYear(show?.item.id);
              }
            }}
          >
            सुनिश्चित गर्नुहोस्
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  fiscalyear: state.fiscal.fiscalyear,
  totalFiscalYear: state.fiscal.totalFiscalYear,
});

const mapDispatchToProps = {
  fetchFiscalYear,
  createFiscalYear,
  editFiscalYear,
  deleteFiscalYear,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiscalYear);
