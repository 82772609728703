import { fetchWrapper } from "../../_helpers/fetchWrapper";

const createElderlyProfile = async (body, options = {}) => {
    const { isPublic } = options;
    let url = `/elderly`;
    if (isPublic) {
        url += '/public';
    }
    const response = await fetchWrapper.post(url, body);
    return response.data;
}
const updateElderlyProfile = async (body, id) => {
    const response = await fetchWrapper.patch(`/elderly/${id}`, body);
    return response.data;
}

const getElderlyProfiles = async ({ page, fullname, gender, ward, isPublic }) => {
    let filters = '';
    if (fullname) {
        filters += `&name_np=${fullname}`
    }
    if (gender) {
        filters += `&gender=${gender}`
    }
    if (ward) {
        filters += `&ward=${ward}`
    }
    if (isPublic) {
        filters += `&public=${isPublic}`
    }
    const response = await fetchWrapper.get(`/elderly/?page=${page}${filters}`);
    return response.data;
}

const getFingerprints = async (id) => {
    const response = await fetchWrapper.get(`/fingerPrint?applicant=${id}`);
    return response.data;
}

const getElderlyProfileById = async (id, issued = true) => {
    const response = await fetchWrapper.get(`/elderly/${id}${issued ? '' : '?issued=false'}`);
    return response.data;
}

const deleteElderlyProfile = async (id) => {
    await fetchWrapper.delete(`/elderly/${id}`);
}

const getElderlyPratilipis = async ({ page, fullname, gender, ward, isPublic }) => {
    let filters = '';
    if (fullname) {
        filters += `&name_np=${fullname}`
    }
    if (gender) {
        filters += `&gender=${gender}`
    }
    if (ward) {
        filters += `&ward=${ward}`
    }
    if (isPublic) {
        filters += `&public=${isPublic}`
    }
    const response = await fetchWrapper.get(`/elderly/copy/?page=${page}${filters}`);
    return response.data;
}

const createElderlyProfileCopy = async (body, options = {}) => {
    let url = `/elderly/copy/`;
    const response = await fetchWrapper.post(url, body);
    return response.data;
}

const uploadElderlyProfile = async (body, options = {}) => {
    let url = `/elderly/excel/`;
    const response = await fetchWrapper.post(url, body, true);
    return response.data;
}

const getElderlyPratilipiById = async (id) => {
    let url = `/elderly/copy/${id}`;
    const response = await fetchWrapper.get(url);
    return response.data;
}

export const ElderlyServices = {
    createElderlyProfile,
    updateElderlyProfile,
    getElderlyProfiles,
    getElderlyProfileById,
    deleteElderlyProfile,
    getElderlyPratilipis,
    createElderlyProfileCopy,
    uploadElderlyProfile,
    getElderlyPratilipiById,
    getFingerprints
}
