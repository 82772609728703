import styled from "@emotion/styled";
import { Form } from "react-bootstrap";

export const StyledFormRow = styled(Form.Row)`
  margin-bottom: 20px;
`;

export const StyledLabel = styled.legend`
  width: fit-content;
  padding: 0 10px;
`;


export const StyledRadioInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  .form-check {
    margin-right: 20px;
  }
`;

export const StyledFormInput = styled.div`
  label{
    color: #2f2f2f;
    margin-bottom: 0.1rem;
  }
`;

