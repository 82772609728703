import React, { useRef } from "react";
import InputField from "../components/InputField";

// styles
import { StyledResizableInput } from "./ResizableInputStyles";

export const ResizableInput = ({
  type,
  fields = {},
  element = {},
  elementId,
  language,
  maxlength,
  className,
  placeholder,
  register,
  isRequired
}) => {
  const widthMachineRef = useRef();
  return (
    <StyledResizableInput>
      <span className="width-machine" aria-hidden="true" ref={widthMachineRef}>
        {fields[element.id]?.placeholder || placeholder}
      </span>
      <InputField
        type={type}
        language={language}
        className={`input ${className}`}
        register={register}
        elementId={elementId}
        placeholder={placeholder}
        {...fields[element.id]}
        maxLength={maxlength}
        isRequired={isRequired}
        onKeyUp={(e) => {
          widthMachineRef.current.innerHTML = e.target.value;
          if (!widthMachineRef.current.innerHTML) {
            widthMachineRef.current.innerHTML =
              fields[element.id]?.placeholder || placeholder;
          }
        }}
      />
    </StyledResizableInput>
  );
};
