import React,{Fragment} from "react";
import { Card, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import { FaEye, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const Users = () => {
  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h3>नागरिक प्रयोगकर्ता प्रमाणित गर्नुहोस्</h3>
              <div className="card-header-form">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control custom-search"
                      placeholder="Search"
                    />
                    <div className="input-group-btn">
                      <button className="btn btn-primary input-search-icon">
                        <FaSearch />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example"
                className="mb-1"
              >
                <Tab eventKey="all" title="सबै प्रयोगकर्ता">
                  <Table striped>
                    <tr>
                      <th>क्र.स.</th>
                      <th>नागरिकता नं</th>
                      <th>नागरिकता जारी जील्ला</th>
                      <th>निवेदकको नाम</th>
                      <th>निवेदकको फोन</th>
                      <th>अस्थायी प्रदेश</th>
                      <th>स्थाई प्रदेश</th>
                      <th>प्रमाणित</th>
                      <th>कार्य</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>912192</td>
                      <td>Makwanpur</td>
                      <td>Anish Lamsal</td>
                      <td>98273838393</td>
                      <td>Province 1</td>
                      <td>Bagamati Province</td>
                      <td>
                        <kbd>छ</kbd>
                      </td>
                      <td>
                        <Link to="">
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>912192</td>
                      <td>Makwanpur</td>
                      <td>Anish Lamsal</td>
                      <td>98273838393</td>
                      <td>Province 1</td>
                      <td>Bagamati Province</td>
                      <td>
                        <kbd>छ</kbd>
                      </td>
                      <td>
                        <Link to="">
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                  </Table>
                </Tab>
                <Tab eventKey="verified" title="प्रमाणित प्रयोगकर्ता">
                  <Table striped>
                    <tr>
                      <th>क्र.स.</th>
                      <th>नागरिकता नं</th>
                      <th>नागरिकता जारी जील्ला</th>
                      <th>निवेदकको नाम</th>
                      <th>निवेदकको फोन</th>
                      <th>अस्थायी प्रदेश</th>
                      <th>स्थाई प्रदेश</th>
                      <th>प्रमाणित</th>
                      <th>कार्य</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>912192</td>
                      <td>Makwanpur</td>
                      <td>Anish Lamsal</td>
                      <td>98273838393</td>
                      <td>Province 1</td>
                      <td>Bagamati Province</td>
                      <td>
                        <kbd>छ</kbd>
                      </td>
                      <td>
                        <Link to="">
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>912192</td>
                      <td>Makwanpur</td>
                      <td>Anish Lamsal</td>
                      <td>98273838393</td>
                      <td>Province 1</td>
                      <td>Bagamati Province</td>
                      <td>
                        <kbd>छ</kbd>
                      </td>
                      <td>
                        <Link to="">
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                  </Table>
                </Tab>
                <Tab eventKey="unverified" title="अप्रमाणित प्रयोगकर्ता">
                  <Table striped>
                    <tr>
                      <th>क्र.स.</th>
                      <th>नागरिकता नं</th>
                      <th>नागरिकता जारी जील्ला</th>
                      <th>निवेदकको नाम</th>
                      <th>निवेदकको फोन</th>
                      <th>अस्थायी प्रदेश</th>
                      <th>स्थाई प्रदेश</th>
                      <th>प्रमाणित</th>
                      <th>कार्य</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>912192</td>
                      <td>Makwanpur</td>
                      <td>Anish Lamsal</td>
                      <td>98273838393</td>
                      <td>Province 1</td>
                      <td>Bagamati Province</td>
                      <td>
                        <kbd>छ</kbd>
                      </td>
                      <td>
                        <Link to="">
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>912192</td>
                      <td>Makwanpur</td>
                      <td>Anish Lamsal</td>
                      <td>98273838393</td>
                      <td>Province 1</td>
                      <td>Bagamati Province</td>
                      <td>
                        <kbd>छ</kbd>
                      </td>
                      <td>
                        <Link to="">
                          <FaEye />
                        </Link>
                      </td>
                    </tr>
                  </Table>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Users;
