/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { PreviewHeader, PreviewMainContainer } from '../styles/StyledPreview';
import DisabledParichayaPatra from './DisabledParichayaPatra';
import DisabledPreviewDetail from './DisabledPreviewDetail';

function DisabledPreview({ userProfile, nagarpalika, fingerPrints }) {
    const DetailRef = useRef();
    const CitizenCopyRef = useRef();
    const BirthCopyRef = useRef();

    const handleDetailPrint = useReactToPrint({
        content: () => {
            return DetailRef.current;
        },
        onAfterPrint: () => {
            try {
                //Need to detect if the print is cancelled, if cancelled do not increment count
            } catch (err) { }
        },
    });
    const handleBirthCopyPrint = useReactToPrint({
        content: () => {
            return BirthCopyRef.current;
        },
        onAfterPrint: () => {
            try {
                //Need to detect if the print is cancelled, if cancelled do not increment count
            } catch (err) { }
        },
    });
    const handleCitizenCopyPrint = useReactToPrint({
        content: () => {
            return CitizenCopyRef.current;
        },
        onAfterPrint: () => {
            try {
                //Need to detect if the print is cancelled, if cancelled do not increment count
            } catch (err) { }
        },
    });

    return (
        <PreviewMainContainer>
            <div className="preview-card" ref={DetailRef}>
                <PreviewHeader>
                    <h3>
                        {userProfile.name_np}
                        को व्यक्तिगत विवरण
                    </h3>

                    <div>
                        <FaPrint className='pointer no-print' onClick={handleDetailPrint} />
                    </div>
                </PreviewHeader>

                <DisabledPreviewDetail userProfile={userProfile} fingerPrints={fingerPrints} />
            </div>

            <div className="preview-card">
                <PreviewHeader>
                    <h3>
                        अपाङ्गता परिचयपत्र
                    </h3>

                    {/* <div>
                        <FaPrint />
                    </div> */}
                </PreviewHeader>

                <DisabledParichayaPatra userProfile={userProfile} />
            </div>

            <div className="preview-card">
                <PreviewHeader>
                    <h3>
                        कागजातहरू
                    </h3>
                </PreviewHeader>

                <Container fluid>

                    {
                        userProfile?.identityDetails?.birthCertificateCopy || userProfile?.identityDetails?.citizenshipCopy ?
                            <Row>
                                {
                                    userProfile?.identityDetails?.citizenshipCopy &&
                                    <Col css={css`
                                    display: flex;
                                    justify-content: center;
                                    padding: 20px 0;
                                `}>
                                        <div className='d-flex justify-content-center flex-column align-items-center'>
                                            <div className='w-100 d-flex justify-content-end px-5 mb-2'>
                                                <FaPrint className='pointer' onClick={handleCitizenCopyPrint} />
                                            </div>
                                            <img ref={CitizenCopyRef} css={css`width: 80%;`} src={userProfile?.identityDetails?.citizenshipCopy} alt={`${userProfile?.name_en} citizenship copy`} />
                                        </div>
                                    </Col>
                                }
                                {
                                    userProfile?.identityDetails?.birthCertificateCopy &&
                                    <Col
                                        css={css`
                                        display: flex;
                                        justify-content: center;
                                        padding: 20px 0;
                                `}>
                                        <div className='d-flex justify-content-center flex-column align-items-center'>
                                            <div className='w-100 d-flex justify-content-end px-5 mb-2'>
                                                <FaPrint className='pointer' onClick={handleBirthCopyPrint} />
                                            </div>
                                            <img ref={BirthCopyRef} css={css`width: 80%;`} src={userProfile?.identityDetails?.birthCertificateCopy} alt={`${userProfile?.name_en} birthCertificate`} />
                                        </div>
                                    </Col>
                                }
                            </Row>
                            :
                            <Row>
                                <d className="p-5 text-center">
                                    माफ गर्नुहोस्‌, तपाईँले सम्बन्धित कुनै पनि कागजातहरू अपलोड गर्नुभएको छैन। कृपया आवश्यक कागजातहरू अपलोड गर्नुहोस्‌।
                                </d>
                            </Row>
                    }
                </Container>


            </div>
        </PreviewMainContainer>
    )
}

export default DisabledPreview