import { useRef } from "react";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import { PreviewHeader, PreviewMainContainer } from "../styles/StyledPreview";
import ElderlyParichayaPatra from "./ElderlyParichayaPatra";
import ElderlyPreviewDetail from "./ElderlyPreviewDetail";

/**
 * 
 */
export default function ElderlyPreview({ userProfile,fingerPrints }) {
    const DetailRef = useRef();

    const handleDetailPrint = useReactToPrint({
        content: () => {
            return DetailRef.current;
        },
        onAfterPrint: () => {
            try {
                //Need to detect if the print is cancelled, if cancelled do not increment count
            } catch (err) { }
        },
    });
    return (
        <PreviewMainContainer>
            <div className="preview-card" ref={DetailRef}>
                <PreviewHeader>
                    <h3>
                        {userProfile.name_np}
                        को व्यक्तिगत विवरण
                    </h3>

                    <div>
                        <FaPrint className='pointer no-print' onClick={handleDetailPrint} />
                    </div>
                </PreviewHeader>

                <ElderlyPreviewDetail userProfile={userProfile}  fingerPrints={fingerPrints}/>
            </div>
            <div className="preview-card">
                <PreviewHeader>
                    <h3>
                        जेस्ठ नागरिक परिचयपत्र
                    </h3>

                    {/* <div>
                        <FaPrint />
                    </div> */}
                </PreviewHeader>

                <ElderlyParichayaPatra userProfile={userProfile} />
            </div>
        </PreviewMainContainer>

    )
}