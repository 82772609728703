/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Dropdown } from 'react-bootstrap';
// import { FaBars } from "react-icons/fa";
import { connect, useSelector } from 'react-redux';
import { logoutUser } from '../_redux/slices/users';
import { languageSelector } from '../_redux/slices/languages';
import { nepaliNumberConverter } from '../_helpers/methods';
import { useState, Fragment } from 'react';
import ChangePasswordModal from './ChangePasswordModal';
import { Link } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';

const Header = ({ logoutUser }) => {
	const user = useSelector((state) => state.users.user);
	// console.log(user, "user");

	const {
		language,
		//  fontType
	} = useSelector(languageSelector);
	const activeFiscalYear = useSelector((state) => state.fiscal.activeFiscalYear);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const handleShowChangePasswordModal = () => {
		setShowChangePasswordModal(true);
	};
	const handleCloseChangePasswordModal = () => {
		setShowChangePasswordModal(false);
	};

	return (
		<div className="header">
			<div className="header-inner">
				<div className="header-left d-flex align-items-center">
					{/* <FaBars size="18" color="#fff" className="mr-4" /> */}
					<h4 className="text-white">
						{activeFiscalYear && (
							<span>
								{language === 'nepali' ? (
									<Fragment>आ.व. : {nepaliNumberConverter(activeFiscalYear?.title)}</Fragment>
								) : (
									<Fragment>आ.व. : {activeFiscalYear?.title}</Fragment>
								)}
							</span>
						)}{' '}
					</h4>
				</div>
				<div className="header-right">
					{/* <FaBell size="18" color="#fff" className="mr-3" /> */}
					{/* <FaCog size="18" color="#fff" className="mr-3" /> */}
					<img src="/assets/images/avatar.png" className="header-avatar" alt="" />

					{user && (
						<div
							css={css`
								display: flex;
								flex-direction: column;
								.role {
									font-size: 12px;
								}
							`}
						>
							<p>
								{language === 'nepali'
									? user?.fullname_np ||
									user?.profile?.fullname ||
									(user?.username?.split('@') && user?.username?.split('@')[0])
									: user?.fullname_en ||
									user?.profile?.fullname ||
									(user?.username?.split('@') && user?.username?.split('@')[0])}
							</p>
							<p className="role">
								{language === 'nepali' ? user?.profile?.post_np || user?.role_en : user?.profile?.post_en || user?.role_en}
							</p>
						</div>
					)}

					<Dropdown>
						<Dropdown.Toggle
							variant="primary"
							id="dropdown-basic"
							css={css`
								&.dropdown-toggle {
									color: white !important;
								}
							`}
						></Dropdown.Toggle>

						<Dropdown.Menu
							css={css`
								transform: translate(-155px, 40px) !important;
							`}
						>
							<Link
								css={css`
									display: block;
									width: 100%;
									padding: 0.25rem 1.5rem;
									clear: both;
									font-weight: 400;
									color: #212529;
									text-align: inherit;
									white-space: nowrap;
									background-color: transparent;
									border: 0;
									:hover {
										color: #16181b;
										text-decoration: none;
										background-color: #f8f9fa;
									}
								`}
								to="/settings/profile"
							>
								{language === 'nepali' ? 'मेरो प्रोफाइल' : 'My Profile'}
							</Link>
							<Dropdown.Item
								onClick={() => {
									handleShowChangePasswordModal();
								}}
							>
								{language === 'nepali' ? 'पासवर्ड परिवर्तन गर्नुहोस्' : 'Change password'}
							</Dropdown.Item>
							<Dropdown.Item onClick={() => logoutUser()}>{language === 'nepali' ? 'लगआउट' : 'Logout'}</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

					<LanguageSelector />
				</div>
				{showChangePasswordModal && (
					<ChangePasswordModal
						show={showChangePasswordModal}
						handleCloseChangePasswordModal={handleCloseChangePasswordModal}
						showChangePasswordModal={showChangePasswordModal}
					/>
				)}
			</div>
		</div>
	);
};

export default connect(null, { logoutUser })(Header);
