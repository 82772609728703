import React, { Fragment } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

const Unverified = () => {
  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h3>सबै अप्रमाणित परिचय पत्र</h3>
              <div className="card-header-form">
                <form>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control custom-search"
                      placeholder="Search"
                    />
                    <div className="input-group-btn">
                      <button className="btn btn-primary input-search-icon">
                        <FaSearch />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <tr>
                    <th>फोटो</th>
                    <th>निवेदकको नाम</th>
                    <th>निवेदकको नागरिकता</th>
                    <th>निवेदकको फोन</th>
                    <th>आर्थिक वर्ष</th>
                    <th>वडा कार्यालय</th>
                    <th>सिर्जना गरिएको</th>
                    <th>कार्य</th>
                  </tr>
                  <tr>
                    <td>
                      <img
                        alt="avatar"
                        src="../assets/images/avatar.png"
                        class="rounded-circle"
                        width="35"
                      />
                    </td>
                    <td>Anish Lamsal</td>
                    <td>91819</td>
                    <td>98273838393</td>
                    <td>२०७६/७७</td>
                    <td>वडा नं. २</td>
                    <td>२०७७-८-८</td>
                    <td>
                      <div class="dropdown">
                        <Button className="btn btn-primary">More</Button>

                        <div class="dropdown-menu dropdown-menu-left actions-dropdown">
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-eye"></i> पूर्वावलोकन
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="fa fa-print"></i> प्रिन्ट
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-edit"></i> सम्पादन
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-copy"></i> नक्कल
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-file-alt"></i> Documents
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-trash"></i> हटाउनु
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        alt="avatar"
                        src="../assets/images/avatar.png"
                        class="rounded-circle"
                        width="35"
                      />
                    </td>
                    <td>Anish Lamsal</td>
                    <td>91819</td>
                    <td>98273838393</td>
                    <td>२०७६/७७</td>
                    <td>वडा नं. २</td>
                    <td>२०७७-८-८</td>
                    <td>
                      <div class="dropdown">
                        <Button className="btn btn-primary">More</Button>

                        <div class="dropdown-menu dropdown-menu-left actions-dropdown">
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-eye"></i> पूर्वावलोकन
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="fa fa-print"></i> प्रिन्ट
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-edit"></i> सम्पादन
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-copy"></i> नक्कल
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-file-alt"></i> Documents
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-trash"></i> हटाउनु
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        alt="avatar"
                        src="../assets/images/avatar.png"
                        class="rounded-circle"
                        width="35"
                      />
                    </td>
                    <td>Anish Lamsal</td>
                    <td>91819</td>
                    <td>98273838393</td>
                    <td>२०७६/७७</td>
                    <td>वडा नं. २</td>
                    <td>२०७७-८-८</td>
                    <td>
                      <div class="dropdown">
                        <Button className="btn btn-primary">More</Button>

                        <div class="dropdown-menu dropdown-menu-left actions-dropdown">
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-eye"></i> पूर्वावलोकन
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="fa fa-print"></i> प्रिन्ट
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-edit"></i> सम्पादन
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-copy"></i> नक्कल
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-file-alt"></i> Documents
                          </Button>
                          <Button class="dropdown-item has-icon">
                            <i class="far fa-trash"></i> हटाउनु
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Unverified;
