import React, { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaPrint, FaSave, FaTimesCircle } from "react-icons/fa";

const TemplateOld = () => {
  const [tab, setTab] = useState("template");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Fragment>
      <div className="row mt-2">
        <div className="col-4">
          <div className="card mb-3 custom-card">
            <div className="card-header">
              <h4 className="text-primary">सामान्य जानकारी</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label for="first_name">निवेदकको नाम</label>
                    <input
                      id="first_name"
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder=""
                      autofocus
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label for="first_name">नागरिकता नम्बर</label>
                    <input
                      id="first_name"
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder=""
                      autofocus
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label for="first_name">नागरिकता जारी जिल्ला</label>
                    <input
                      id="first_name"
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder=""
                      autofocus
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label for="first_name">निवेदकको फोन</label>
                    <input
                      id="first_name"
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder=""
                      autofocus
                    />
                  </div>
                </div>
              </div>
              <h6 className="text-primary mb-2">स्थायी ठेगाना</h6>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label for="province">प्रदेश</label>
                    <select type="text" className="form-control">
                      <option>Province no. 1</option>
                      <option>Province no. 2</option>
                      <option>Bagamati</option>
                      <option>Gandaki</option>
                      <option>Lumbini</option>
                      <option>Karnali</option>
                      <option>Sudurpaschim</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label for="first_name">जिल्ला</label>
                    <select type="text" className="form-control">
                      <option>Kathmandu Metropolitan</option>
                      <option>Lalitpur Sub-Metropolitan</option>
                      <option>Bhaktapur Municipality</option>
                      <option>Makawanpur Sub-Metropolitan</option>
                      <option>Chitwan Sub-Metropolitan</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label for="first_name">स्थानीय तह</label>
                    <input
                      id="first_name"
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder=""
                      autofocus
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card custom-card">
            <div className="card-header">
              <h4 className="text-primary">कागजातहरु</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="gallery-img">
                    <img
                      className="gallery-item"
                      src="https://demo.getstisla.com/assets/img/news/img03.jpg"
                      data-title="Image 11"
                      alt=""
                    />
                    <FaTimesCircle className="deleteIcon" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label for="documents">कागजात अपलोड गर्नुहोस्</label>
                    <input
                      id=""
                      type="file"
                      className="form-control"
                      name="documents"
                      placeholder=""
                      autofocus
                      multiple
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-7">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="nav nav-pills flex" id="myTab3" role="tablist" mb-3>
              <li className="nav-item mr-2">
                <Button
                  className={
                    tab === "template"
                      ? `btn btn-primary`
                      : `btn btn-outline-primary`
                  }
                  data-toggle="tab"
                  onClick={() => setTab("template")}
                >
                  Template
                </Button>
              </li>
              <li className="nav-item">
                <Button
                  className={
                    tab === "application"
                      ? `btn btn-primary`
                      : `btn btn-outline-primary`
                  }
                  onClick={() => setTab("application")}
                >
                  Application
                </Button>
              </li>
            </ul>
            <div className="d-flex">
              <Button className="btn btn-success mr-2">
                <FaSave /> Save
              </Button>
              <Button className="btn btn-info" onClick={handleShow}>
                <FaPrint /> Print
              </Button>
            </div>
          </div>
          {tab === "template" ? (
            <div className="zoom" id="templateZoom">
              <page className="page p-3 mt-3">
                <div className="template-header d-flex justify-content-between">
                  <img
                    src="../assets/images/nep_logo.png"
                    className="brand-logo"
                    alt=""
                  />

                  <div className="text-center">
                    <h2>घोडाघोडी नगरपालिका</h2>
                    <p>१ नं वडा कार्यालय</p>
                    <p>सुखड, कैलाली</p>
                    <p>सुदुरपश्चिम प्रदेश, नेपाल</p>
                  </div>
                  <div></div>
                </div>
                <h1>Sifarish</h1>
              </page>
            </div>
          ) : (
            <div className="zoom" id="templateZoom">
              <page className="page p-3 mt-3">
                <div className="template-header d-flex justify-content-between">
                  <img
                    src="../assets/images/nep_logo.png"
                    className="brand-logo"
                    alt=""
                  />

                  <div className="text-center">
                    <h2>घोडाघोडी नगरपालिका</h2>
                    <p>१ नं वडा कार्यालय</p>
                    <p>सुखड, कैलाली</p>
                    <p>सुदुरपश्चिम प्रदेश, नेपाल</p>
                  </div>
                  <div></div>
                </div>
                <h1>Application</h1>
              </page>
            </div>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default TemplateOld;
