import { Fragment } from "react";
import styled from "styled-components";
import { VscLoading } from "react-icons/vsc";
import { Pagination, Table } from "react-bootstrap";
import {
  AiFillCaretDown,
  AiFillCaretUp,
  AiOutlineDatabase,
} from "react-icons/ai";
import UpDownArrow from "../../assets/images/updown.svg";
import { nepaliNumberConverter } from "../../_helpers/methods";

const ShowCount = ({ local, totalCount = 0, page, loading }) => {
  if (loading) return null;
  if (!totalCount) return null;
  return (
    <span className="text-secondary">
      {totalCount && (
        <>
          {local === "ne" ? (
            <span>
              {nepaliNumberConverter(totalCount)} मध्ये{" "}
              {nepaliNumberConverter(page.length)} देखाउँदै
            </span>
          ) : (
            <span>
              Showing {page.length} of ~ {totalCount} items
            </span>
          )}
        </>
      )}
    </span>
  );
};
function ReactTableRender({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
  totalCount,
  gotoPage,
  previousPage,
  pageCount,
  pageCountArray,
  pageNum,
  showGoto,
  nextPage,
  loading,
  visibleColumns,
  children,
  pagination = true,
  maxContent,
  emptyTableMessage,
  noFilters,
}) {
  const local = "nepali";
  return (
    <StyledTable className="position-relative overflow-auto pagination-wrapper">
      <div className="card overflow-hidden card-table event-wrapper">
        <div className="table-responsive">
          <Table
            {...getTableProps()}
            className={`table-thead-bordered table-hover ${
              maxContent ? "maxContent" : ""
            }`}
          >
            <thead className="thead-light">
              {headerGroups.map((headerGroup) => (
                // eslint-disable-next-line react/jsx-key
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // eslint-disable-next-line react/jsx-key
                    <th
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          width: column.width,
                        },
                        class: column.className,
                      })}
                    >
                      {column.render("Header")}
                      {column.canSort ? (
                        <span
                          {...column.getHeaderProps(
                            column?.getSortByToggleProps
                              ? column.getSortByToggleProps()
                              : {}
                          )}
                        >
                          {column.isSorted ? (
                            <span className="ms-1">
                              {column.isSortedDesc ? (
                                <AiFillCaretDown color={"#555"} />
                              ) : (
                                <AiFillCaretUp color={"#555"} />
                              )}{" "}
                            </span>
                          ) : (
                            <span className="ms-1">
                              <img
                                src={UpDownArrow}
                                width="16"
                                style={{ padding: "0 3px" }}
                                alt="down"
                              />
                            </span>
                          )}
                        </span>
                      ) : null}
                      {noFilters ? null : (
                        <div style={{ height: "30px" }}>
                          {column.canFilter ? column.render("Filter") : null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <tr>
                  <td colSpan="10000" className="text-center">
                    <VscLoading
                      fontSize={50}
                      className="spin my-5"
                      color={"#2078f9"}
                    />
                  </td>
                </tr>
              ) : (
                (page.length > 0 &&
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <tr {...row.getRowProps()}>
                        {row.cells.length > 0 ? (
                          row.cells.map((cell) => {
                            return (
                              // eslint-disable-next-line react/jsx-key
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    minWidth: cell.column.minWidth,
                                    width: cell.column.width,
                                  },
                                })}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })
                        ) : (
                          <td>No data</td>
                        )}
                        {row.isExpanded ? (
                          <span>
                            <td colSpan={visibleColumns.length}>{children}</td>
                          </span>
                        ) : null}
                      </tr>
                    );
                  })) || (
                  <tr>
                    <td colSpan="10000" className="text-center">
                      <span className="vh-100 my-5">
                        <span className="d-block mb-2">
                          <AiOutlineDatabase size={48} />
                        </span>
                        <span className="lead">
                          {emptyTableMessage || <div>No results found</div>}
                        </span>
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
        {pagination && (
          <div className="d-flex align-items-center justify-content-between mx-4 mb-4">
            <ShowCount
              loading={loading}
              totalCount={totalCount}
              local={local}
              page={page}
            />
            <PaginationComp
              gotoPage={gotoPage}
              previousPage={previousPage}
              pageCount={pageCount}
              pageCountArray={pageCountArray}
              pageNum={pageNum}
              nextPage={nextPage}
              showGoto={showGoto}
            />
          </div>
        )}
      </div>
    </StyledTable>
  );
}

const StyledTable = styled.div`
  table {
    th {
      padding: 10px 10px 5px;
    }
    td {
      /* padding: 25px; */
      border-bottom: 1px solid #eaeaea;
      vertical-align: baseline;
    }
  }
`;

export default ReactTableRender;

const PaginationComp = ({
  gotoPage,
  previousPage,
  pageCount,
  pageCountArray,
  pageNum,
  nextPage,
  showGoto,
}) => {
  return (
    <StyledPagination>
      <Pagination>
        <Pagination.First
          onClick={() => gotoPage(1)}
          disabled={+pageNum === 1}
        />

        <Pagination.Prev
          onClick={() => gotoPage(pageNum - 1)}
          disabled={+pageNum === 1}
        />

        {pageNum < 5 ? (
          pageCountArray
            .filter((count, index) => index < 5)
            .map((count) => (
              <Fragment key={count}>
                <Pagination.Item
                  active={count === pageNum}
                  key={count}
                  onClick={() => gotoPage(count)}
                >
                  {count}
                </Pagination.Item>
              </Fragment>
            ))
        ) : (
          <>
            <Pagination.Item>...</Pagination.Item>
            <Pagination.Item onClick={() => gotoPage(pageNum - 2)}>
              {pageNum - 2}
            </Pagination.Item>
            <Pagination.Item onClick={() => gotoPage(pageNum - 1)}>
              {pageNum - 1}
            </Pagination.Item>

            <Pagination.Item active>{pageNum}</Pagination.Item>

            {pageCountArray.includes(pageNum + 1) && (
              <Pagination.Item onClick={() => gotoPage(pageNum + 1)}>
                {pageNum + 1}
              </Pagination.Item>
            )}
            {pageCountArray.includes(pageNum + 2) && (
              <>
                <Pagination.Item onClick={() => gotoPage(pageNum + 2)}>
                  {pageNum + 2}
                </Pagination.Item>
                <Pagination.Item>...</Pagination.Item>
              </>
            )}
          </>
        )}
        <Pagination.Next
          onClick={() => nextPage(pageNum + 1)}
          disabled={+pageNum === pageCountArray.at(-1)}
        />
        <Pagination.Last
          onClick={() => gotoPage(pageCountArray.at(-1))}
          disabled={+pageNum === pageCountArray.at(-1)}
        />
      </Pagination>

      {showGoto ? (
        <div className="pagination-input_div text-muted">
          <span className="mx-2">
            Go to :{" "}
            <input
              className="pagination-input"
              type="number"
              defaultValue={pageNum}
              style={{
                maxWidth: "45px",
                textAlign: "center",
              }}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) : 0;
                gotoPage(page);
              }}
            />
          </span>
          <span> of {pageCountArray.length} page</span>
        </div>
      ) : null}
    </StyledPagination>
  );
};

const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pagination {
    margin: 0;
  }
  .pagination-input_div {
    margin-left: 0.8rem;
  }
  .pagination-input {
    width: 25%;
    border: 1px solid #c7c7c7;
    text-align: center;
    padding: 0.2rem;
    border-radius: 5px;
  }
`;
