import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
	FaBuilding,
	FaCalendar,
	// FaEnvelope,
	// FaFileUpload,
	// FaMapMarkedAlt,
	// FaSitemap,
	// FaUserAlt,
	FaUserTie,
} from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkPermission } from '../../_helpers/roles/check-roles';
import { englishToNepaliNumber as np } from 'nepali-number';
import { languageSelector } from '../../_redux/slices/languages';

const Settings = () => {
	const user = useSelector((state) => state.users.user);
	const { language } = useSelector(languageSelector);
	const { wardStats, sakhaStats, fiscalYearStats, administratorStats } = useSelector(state => state.appStats);

	const role = user?.role || user?.role_en;

	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<Card>
						<Card.Header className="card-header-custom d-block">
							<h2 className="text-center">{language === 'english' ? 'Settings' : 'सेटिङ्स'}</h2>
						</Card.Header>
					</Card>
				</Col>

				<Col md={3}>
					<div className="icon-container text-center">
						<Link to="/settings/prashasak/list/1">
							<span className="settings-badge position-absolute">{np(administratorStats?.count || 0)}</span>
							<div className="icon">
								<FaUserTie size={48} />
								<h6 className="card-text mt-3">{language === 'english' ? 'Administrator' : 'प्रशासक'}</h6>
							</div>
						</Link>
					</div>
				</Col>
				{checkPermission(role, 'view:arthikBarsa') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/fiscalyear">
								<span className="settings-badge position-absolute">{np(fiscalYearStats?.count || 0)}</span>
								<div className="icon">
									<FaCalendar size={48} />
									<h6 className="card-text mt-3">{language === 'english' ? 'Fiscal year' : 'आर्थिक वर्ष'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null}

				<Fragment>
					{checkPermission(role, 'view:wodaKaralayaHaru') ? (
						<Col md={3}>
							<div className="icon-container text-center">
								<Link to="/settings/allusers/1">
									<span className="settings-badge position-absolute">{np(wardStats?.count || 0)}</span>
									<div className="icon">
										<FaBuilding size={48} />
										<h6 className="card-text mt-3">{language === 'english' ? 'Ward offices' : 'वडा कार्यालयहरु'}</h6>
									</div>
								</Link>
							</div>
						</Col>
					) : null}

					{checkPermission(role, 'view:wodaKaralayaHaru') ? (
						<Col md={3}>
							<div className="icon-container text-center">
								<Link to="/settings/all-sakhas/1">
									<span className="settings-badge position-absolute">{np(sakhaStats?.count || 0)}</span>
									<div className="icon">
										<FaBuilding size={48} />
										<h6 className="card-text mt-3">{'शाखा कार्यालयहरु'}</h6>
									</div>
								</Link>
							</div>
						</Col>
					) : null}


					{/* {role === 'ward_admin' ? (
						<Col md={3}>
							<div className="icon-container text-center">
								<Link to={`/settings/warduser/${user?.profile?.ward}`}>
									<span className="settings-badge position-absolute">{np(stats?.ward || 0)}</span>
									<div className="icon">
										<FaBuilding size={48} />
										<h6 className="card-text mt-3">
											{language === 'english' ? 'My ward office' : 'मेरो वडा कार्यालय'}
										</h6>
									</div>
								</Link>
							</div>
						</Col>
					) : null} */}
				</Fragment>

				{/* {checkPermission(role, 'view:sifarisKoPrakar') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/sifarishtype">
								<span className="settings-badge position-absolute">{np(stats?.template_cat || 0)}</span>
								<div className="icon">
									<FaSitemap size={48} />
									<h6 className="card-text mt-3">{language === 'english' ? 'Sifarish types' : 'परिचय पत्रको प्रकार'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null} */}

				{/* {checkPermission(role, 'view:kagajatBiwaran') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/documentstype/1">
								<span className="settings-badge position-absolute">{np(stats?.application || 0)}</span>
								<div className="icon">
									<FaFileUpload size={48} />
									<h6 className="card-text mt-3">{language === 'english' ? 'Documents detail' : 'कागजात विवरण'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null} */}

				{/* {checkPermission(role, "view:sawikKoPalika") ? (
          <Col md={3}>
            <div className="icon-container text-center">
              <Link to="/settings/formermunicipal">
                <span className="settings-badge position-absolute">
                  {np(stats?.sabik_palika || "चाडै आउदै छ")}
                </span>
                <div className="icon">
                  <FaMapMarkedAlt size={48} />
                  <h6 className="card-text mt-3">साविकको पालिका</h6>
                </div>
              </Link>
            </div>
          </Col>
        ) : null} */}

				{/* App related, Include after app ready
        <Col md={3}>
          <div className="icon-container text-center">
            <Link to="/settings">
              <span className="settings-badge position-absolute">0</span>
              <div className="icon">
                <FaEnvelope size={48} />
                <h6 className="card-text mt-3">सबै सूचना</h6>
              </div>
            </Link>
          </div>
        </Col> */}

				{/* {checkPermission(role, 'view:nayaAawedakDarta') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/applicants">
								<span className="settings-badge position-absolute">{np(stats?.applicant || 0)}</span>
								<div className="icon">
									<FaUserAlt size={48} />
									<h6 className="card-text mt-3">{language === 'english' ? 'New applicants' : 'नयाँ आवेदक दर्ता'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null} */}

				{/* {checkPermission(role, 'view:mayorTab') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/mayor">
								
								<div className="icon">
									<FaUserTie size={48} />
									<h6 className="card-text mt-3">{language === 'english' ? 'Mayors' : 'मेयर'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null} */}
				{/* {checkPermission(role, 'view:designTemplate') ? (
					<Col md={3}>
						<div className="icon-container text-center">
							<Link to="/settings/designtemplate">
								<span className="settings-badge position-absolute">{np(stats?.design_template || 0)}</span>
								<div className="icon">
									<RiFileEditFill size={48} />
									<h6 className="card-text mt-3">{language === 'english' ? 'Design Template' : 'Design Template'}</h6>
								</div>
							</Link>
						</div>
					</Col>
				) : null} */}
			</Row>
		</Fragment>
	);
};

export default Settings;
