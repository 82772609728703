export const GENDERS = {
    'पुरुष': "Male",
    'महिला': "Female",
    'अन्य': "Other",
}

export const WARD_ROLES = {
    null: "---भूमिका छान्नुहोस्---",
    "ward_user": "वडा सचिब",
    "ward_admin": "वडा अध्यक्ष",
}


export const DashboardStatsObject = {
    disabled: {
        title: 'अपाङ्गता परिचय पत्र',
        path: '/disabled/list/1',
    },
    elderly: {
        title: 'जेष्ठ नागरिक परिचय पत्र',
        path: '/elderly/list/1',
    },
}