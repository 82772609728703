import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  component: Component,
  accessGrantedRoles,
  ...rest
}) => {
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const user = useSelector((state) => state.users.user);
  const role = user?.role || user?.role_en;

  return (
    <Route
      {...rest}
      render={(props) => {
        const localStorageUser = window.localStorage.getItem("sv_user");
        if (!isLoggedIn || !localStorageUser) {
          return <Redirect to="/login" />;
        }
        if (user) {
          if (accessGrantedRoles.includes(role))
            return <Component {...props} />;
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

// const FirstConnectedComponent = ContainerCreater(PrivateRoute);
// const SecondConnectedComponent = ContainerCreater(AuthRoute);

const FirstConnectedComponent = PrivateRoute;
// const SecondConnectedComponent = ContainerCreater(AuthRoute);

export {
  FirstConnectedComponent as PrivateRoute,
  // , SecondConnectedComponent as AuthRoute
};
