import NepaliDate from "nepali-date-converter";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Col, FormControl, Row, } from "react-bootstrap";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { fetchWrapper } from "../_helpers/fetchWrapper";
import { fetchWards } from "../_redux/slices/ward";
import { fetchFiscalYear } from "../_redux/slices/fiscalyear";
import {
  englishToNepaliNumber as np,
} from "nepali-number";
import { BsFillPeopleFill, BsDownload } from "react-icons/bs";
import { RiStackFill } from "react-icons/ri";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import moment from "moment";
import { useTable } from "react-table";
import { nepaliNumberConverter } from "../_helpers/methods";
import styled from "styled-components";
import { VscLoading } from "react-icons/vsc";

const Box = ({ title, icon = null, number }) => {
  return (
    <div className="dashboard-stats-box">
      <div className="dashboard-stats-box-content">
        <h5>{title}</h5>
      </div>
      <h5 className="dashboard-number">
        <kbd className="red-shade">{number}</kbd>
      </h5>
      <span>
        <div className="dashboard-stats-box-icon">{icon}</div>
      </span>
    </div>
  );
};

function Report({ fetchWards, fetchFiscalYear }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const wards = useSelector((state) => state.wards.wards);
  const user = useSelector(state => state.users.user);
  const fiscalYear = useSelector((state) => state.fiscal.fiscalyear);
  const activeFiscalYear = useSelector(
    (state) => state.fiscal.activeFiscalYear
  );
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [filter, setFilter] = useState({
    fy: activeFiscalYear?.id,
    ward: null,
    from: { ad: null, bs: null },
    to: { ad: null, bs: null },
  });
  const [toDateError, setToDateError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [totalApplicants, setTotalApplicants] = useState(0);

  const getReport = useCallback(
    async () => {
      try {
        const queryParams = new URLSearchParams();
        if (filter.fy) queryParams.append("fiscal", filter.fy);
        if (filter.from?.ad) queryParams.append("startDate", filter.from.ad);
        if (filter.to?.ad) queryParams.append("endDate", filter.to.ad);
        if (filter.ward) queryParams.append("ward", filter.ward);

        const uri = `/report/progress/?${queryParams.toString()}`;
        setIsLoading(true);
        const res = await fetchWrapper.get(uri);
        let reportCount = res.data.disabled.counts;
        let reportTotalCounts = res.data.disabled.totalCounts;
        let tempTableCountData = [];
        reportCount.forEach(kindDetail => {
          let tempKindDetail = { ...kindDetail };
          let tempKindData = { ...tempKindDetail.data };
          tempKindData.totals = reportTotalCounts[kindDetail.kind];

          tempKindDetail.data = tempKindData;
          tempTableCountData.push(tempKindDetail)
        });
        setTotalApplicants(res.data.applicants);
        setTableData(tempTableCountData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [filter.from.ad, filter.fy, filter.to.ad, filter.ward]
  );

  useEffect(() => {
    fetchWards();
    fetchFiscalYear();
  }, [fetchFiscalYear, fetchWards]);

  useEffect(() => {
    getReport();
  }, [getReport]);

  useEffect(() => {
    if (user) {
      if (user.role_en === "ward_admin") {
        setFilter({
          ...filter,
          ward: user.ward
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleDownloadFile = async (type = "url", isGenderwise) => {
    setDownloadLoading(true);
    let uri = '';
    if (isGenderwise) {
      uri = `/report/gender-wise?type=${type}`;
    }
    else {
      uri = `/report/progress?type=${type}`;
    }
    if (filter.fy) {
      uri += `&fiscal=${filter.fy}`;
    }
    if (filter.ward) {
      uri += `&ward=${filter.ward}`;
    }
    if (filter.from?.ad) {
      uri += `&startDate=${filter.from.ad}`
    }
    if (filter.to?.ad) {
      uri += `&endDate=${filter.to.ad}`
    }

    const res = await fetchWrapper.get(uri);
    const a = document.createElement("a");
    a.setAttribute("target", "_blank");
    let url = res.data?.url ?? "";
    a.href = url.replace('http', 'https');
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownloadLoading(false);
  };

  return (
    <Fragment>
      <Row className="justify-content-between">
        {
          user.role_en === "ward_admin" ?
            null
            :
            <Col md={3}>
              <div className="d-flex flex-column">
                <label htmlFor="from" className="w-100 mr-2 mb-0">
                  वडा कार्यालय:{" "}
                </label>
                <FormControl
                  as={"select"}
                  onChange={(e) => setFilter({ ...filter, ward: e.target.value })}
                >
                  <option value="1">वडा कार्यालय छान्नुहोस्</option>
                  {wards.map((w) => (
                    <option value={w.id} key={w.id}>
                      {w.ward_np || w.ward_en} न. वडा{" "}
                    </option>
                  ))}
                </FormControl>
              </div>
            </Col>
        }


        <Col md={3}>
          <div className="d-flex flex-column">
            <label htmlFor="from" className="w-100 mr-2 mb-0">
              आर्थिक वर्ष:{" "}
            </label>
            <FormControl
              as={"select"}
              onChange={(e) => setFilter({ ...filter, fy: e.target.value })}
            >
              <option value="">आर्थिक वर्ष छान्नुहोस्</option>
              {fiscalYear.map((fy) => (
                <option
                  value={fy.id}
                  key={fy.id}
                  selected={activeFiscalYear?.id === fy.id}
                >
                  {fy.title}
                </option>
              ))}
            </FormControl>
          </div>
        </Col>

        <Col md={3}>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column w-100">
                <label htmlFor="from" className="w-100 mr-2 mb-0">
                  From:{" "}
                </label>
                <NepaliDatePicker
                  inputClassName="form-control"
                  className=""
                  value={filter.from.bs}
                  onChange={(value) => {
                    if (value && value !== filter.from.bs) {
                      try {
                        let { year, month, date } = new NepaliDate(value).getAD();
                        let today = moment().format("YYYY-MM-DD");
                        let selectedAD = `${year}-${("0" + (month + 1)).split(
                          -2
                        )}-${date}`;
                        if (
                          moment(selectedAD).isAfter(today, "day") &&
                          filter.from.bs
                        ) {
                          setFromDateError(
                            "चयन गरिएको मिति आजको मिति भन्दा ठूलो हुनु हुँदैन"
                          );
                        } else {
                          setFromDateError(false);
                          setFilter({
                            ...filter,
                            from: {
                              ad: `${year}-${("0" + (month + 1)).split(
                                -2
                              )}-${date}`,
                              bs: value,
                            },
                          });
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }}
                  options={{ calenderLocale: "ne", valueLocale: "en" }}
                />
              </div>
            </div>
            <span className="p-2 text-danger">{fromDateError}</span>
          </div>
        </Col>
        <Col md={3}>
          <div className="d-flex flex-column">
            <div className="ml-4 d-flex align-items-center justify-content-start">
              <div className="d-flex flex-column w-100">
                <label className="w-100 mr-2 mb-0">To: </label>
                <NepaliDatePicker
                  inputClassName="form-control"
                  className=""
                  value={filter.to.bs}
                  onChange={(value) => {
                    if (value && value !== filter.to.bs) {
                      try {
                        let { year, month, date } = new NepaliDate(value).getAD();
                        let today = moment().format("YYYY-MM-DD");
                        let selectedAD = `${year}-${("0" + (month + 1)).split(
                          -2
                        )}-${date}`;

                        if (moment(selectedAD).isAfter(today, "day")) {
                          setToDateError(
                            "चयन गरिएको मिति आजको मिति भन्दा ठूलो हुनु हुँदैन"
                          );
                        } else if (filter?.from?.ad) {
                          if (
                            moment(selectedAD).isBefore(filter?.from?.ad, "day")
                          ) {
                            setToDateError(
                              'चयन गरिएको मिति "From" मिति भन्दा सानो हुनु हुँदैन'
                            );
                          } else {
                            setToDateError(false);
                            setFilter({
                              ...filter,
                              to: {
                                ad: `${year}-${("0" + (month + 1)).split(
                                  -2
                                )}-${date}`,
                                bs: value,
                              },
                            });
                          }
                        } else {
                          setToDateError(false);
                          setFilter({
                            ...filter,
                            to: {
                              ad: `${year}-${("0" + (month + 1)).split(
                                -2
                              )}-${date}`,
                              bs: value,
                            },
                          });
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }}
                  options={{ calenderLocale: "ne", valueLocale: "en" }}
                />
              </div>
            </div>
            <span className="p-2 text-danger">{toDateError}</span>
          </div>
        </Col>
      </Row>

      <Row className="mt-5 mb-3">
        <div className="col-3">
          <Box
            title="निवेदकहरु"
            number={np(totalApplicants)}
            icon={<BsFillPeopleFill />}
          />
        </div>
        <div className="col-3">
          <Box
            title="परिचय पत्रका ढाँचा"
            number={np(2)}
            icon={<RiStackFill />}
          />
        </div>
        {/* <div className="col-3">
          <Link to="/papers/verified/1">
            <Box
              title="जारी परिचय पत्र"
              number={np(0)}
              icon={<GiPapers />}
            />
          </Link>
        </div> */}
      </Row>


      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="text-primary">जारी भएका अपाङ्गताको परिचय पत्र</h4>

        <div>

          <Button
            variant="outline-primary"
            className="ml-2"
            onClick={() => handleDownloadFile('url', true)}
            disabled={downloadLoading}
          >
            <BsDownload className="mr-2" />
            &nbsp;Gender wise overall report download
          </Button>

          <Button
            variant="outline-primary"
            className="ml-2"
            onClick={() => handleDownloadFile()}
            disabled={downloadLoading}
          >
            <BsDownload className="mr-2" />
            &nbsp;Download
          </Button>
        </div>
      </div>

      <CountTable tableData={tableData} isLoading={isLoading} />

    </Fragment >
  );
}

export default connect(null, { fetchWards, fetchFiscalYear })(Report);


const CountTable = ({ tableData, isLoading }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'क्रम संख्या',
        accessor: 'sn'
      },
      {
        Header: 'अपाङ्गताको प्रकार',
        accessor: 'disablilityKind'
      },
      {
        Header: 'वर्ग',
        columns: [
          {
            Header: 'क वर्ग पूर्ण अशक्त अपाङ्गता',
            className: "a-class",
            columns: [
              {
                Header: 'पुरुष',
                accessor: 'पूर्ण अशक्त अपाङ्ग_पुरुष',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'महिला',
                accessor: 'पूर्ण अशक्त अपाङ्ग_महिला',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'अन्य',
                accessor: 'पूर्ण अशक्त अपाङ्ग_अन्य',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'जम्मा',
                accessor: 'पूर्ण अशक्त अपाङ्ग_total',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
            ]
          },
          {
            Header: 'ख वर्ग आति अशक्त अपाङ्गता',
            className: "b-class",
            columns: [
              {
                Header: 'पुरुष',
                accessor: 'अति अशक्त अपाङ्ग_पुरुष',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'महिला',
                accessor: 'अति अशक्त अपाङ्ग_महिला',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'अन्य',
                accessor: 'अति अशक्त अपाङ्ग_अन्य',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'जम्मा',
                accessor: 'अति अशक्त अपाङ्ग_total',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
            ]
          },
          {
            Header: 'ग वर्ग मध्यम अपाङ्गता',
            className: "c-class",
            columns: [
              {
                Header: 'पुरुष',
                accessor: 'मध्यम अपाङ्गता _पुरुष',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'महिला',
                accessor: 'मध्यम अपाङ्गता _महिला',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'अन्य',
                accessor: 'मध्यम अपाङ्गता _अन्य',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'जम्मा',
                accessor: 'मध्यम अपाङ्गता _total',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
            ]
          },
          {
            Header: 'घ वर्ग सामान्य अपाङ्गता',
            className: "d-class",
            columns: [
              {
                Header: 'पुरुष',
                accessor: 'सामान्य अपाङ्गता_पुरुष',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'महिला',
                accessor: 'सामान्य अपाङ्गता_महिला',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'अन्य',
                accessor: 'सामान्य अपाङ्गता_अन्य',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'जम्मा',
                accessor: 'सामान्य अपाङ्गता_total',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
            ]
          },
          {
            Header: 'कुल जम्मा',
            columns: [
              {
                Header: 'पुरुष',
                accessor: 'totals_पुरुष',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'महिला',
                accessor: 'totals_महिला',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'अन्य',
                accessor: 'totals_अन्य',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
              {
                Header: 'जम्मा',
                accessor: 'totals_total',
                Cell: ({ column, row }) => {
                  const value = row.original[column.id];
                  return <span>
                    {value ? nepaliNumberConverter(value)
                      : "-"}
                  </span>
                }
              },
            ]
          },

        ]
      },
    ],
    []
  );

  const data = React.useMemo(() => {
    if (!tableData) return [];
    let count = 1;

    return tableData.map(report => {
      let obj = {};
      Object.keys(report.data).forEach(kind => {
        Object.entries(report.data[kind]).forEach(([subKind, value]) => {
          obj[`${kind}_${subKind}`] = value;
        });
      });

      return {
        sn: nepaliNumberConverter(count++),
        disablilityKind: report.kind,
        ...obj,
      }
    })

  }, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
  );

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              return (
                <th {...column.getHeaderProps()} className={column.className ?? ""}>{column.render('Header')}</th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {
          isLoading ?
            <tr>
              <td colSpan={100}><VscLoading className="spin" size="48" /></td>
            </tr>
            :
            !rows.length ?

              <tr>
                <td colSpan={100}>
                  No data available
                </td>
              </tr>
              :
              rows.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })
        }
      </tbody>
    </StyledTable>
  )
}


const StyledTable = styled.table`
  width: 100%;
  background: white;
  th,
  td {
    border: 1px solid #333;
    text-align: center;
    padding: 8px;
  }
  tr:hover{
    background: #f6f6f6;
  }

  .a-class {
    background: #FCD0CE;
    color: #36201F;
  }
  .b-class {
    background: #B9BFFC;
    color: #445CC1;
  }
  .c-class {
    background: #FEFBEB;
    color: #E89E52;
  }
  .d-class {
    background: #EEEEEE;
    color: #333;
  }
`;