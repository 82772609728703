import React from "react";
import { errorMessageChecker } from "../../../../_helpers/methods";
import InputField from "../../../InputField";

function Number({
  language,
  className,
  placeholder,
  register,
  errors = {},
  elementId,
  value,
  properties = {},
  setValue,
  isPublic,
  entity, maxLength, validations,
}) {
  if (isPublic && entity === "private") {
    return null;
  }
  return (
    <div>
      <InputField
        type="number"
        language={language}
        className={`${className} input ${properties?.isRequired ? "textbox_req" : ""
          } ${errorMessageChecker(errors, elementId) ? "is-invalid" : ""} `}
        register={register}
        errors={errors}
        elementId={elementId}
        placeHolder={placeholder}
        maxLength={maxLength}
        value={value}
        properties={properties}
        validation={validations}
        setValue={setValue}
      />
    </div>
  );
}

export default Number;
