/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect } from "react";
import { FaPlusSquare } from "react-icons/fa";
import { AiFillMinusSquare } from "react-icons/ai";

import { useFieldArray } from "react-hook-form";
import InputField from "../../../InputField";
import { nepaliNumber } from "../../../../_helpers/methods";

const Table = ({
  data,
  language,
  register,
  control,
  setValue,
  value = [],
  category,
  entity,
  isPublic,
}) => {
  const headings = data.data.headings;
  const headingLength = headings.length;

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: data.id,
  });

  useEffect(() => {
    if (value.length === 0) {
      if (fields.length < (Number(data.data.minRow) || 2)) {
        append({});
      }
    } else {
      if (fields.length < value.length) {
        append({});
      }
    }
  }, [append, data.data.minRow, fields, value]);

  if (isPublic && entity === "private") {
    return null;
  }
  return (
    <div className="w-100 my-2">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            {headings.map((heading, idx) => (
              <th key={idx}>{heading.name}</th>
            ))}
            <th
              css={css`
                width: 75px;
              `}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map(({ id, ...rest }, index) => (
            <tr key={id}>
              {Array.from({ length: headingLength }).map((_, fieldIndex) => {
                let elementId = `${
                  data.id
                }[${index}].${fieldIndex}___${headings[fieldIndex].name
                  .split(".")
                  .join("####")}`;
                let elementNoHashParseId = `${data.id}[${index}].${fieldIndex}___${headings[fieldIndex].name}`;
                let tableDataValue =
                  value[index] &&
                  (value[index][
                    `${fieldIndex}___${headings[fieldIndex].name
                      .split(".")
                      .join("####")}`
                  ] ||
                    value[index][
                      `${fieldIndex}___${headings[fieldIndex].name}`
                    ]);
                return (
                  <td key={fieldIndex} className="p-2 m-0">
                    {headings[fieldIndex].type !== "select" ? (
                      fieldIndex === 0 ? (
                        <InputField
                          className="table-input"
                          type={headings[fieldIndex].type}
                          language={language}
                          register={register}
                          elementId={elementId}
                          readOnly
                          value={
                            category === "English Format"
                              ? index + 1
                              : nepaliNumber(index + 1)
                          }
                          setValue={setValue}
                        />
                      ) : (
                        <InputField
                          className="table-input"
                          type={headings[fieldIndex].type}
                          language={language}
                          register={register}
                          elementId={elementId}
                          value={tableDataValue}
                          setValue={setValue}
                        />
                      )
                    ) : (
                      <select {...register(elementNoHashParseId)}>
                        {headings[fieldIndex].options.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                            selected={tableDataValue === option.value}
                          >
                            {option.value}
                          </option>
                        ))}
                      </select>
                    )}
                  </td>
                );
              })}
              {index + 1 > (Number(data.data.minRow) || 2) ? (
                <td
                  onClick={() => {
                    swap(index, fields.length - 1);
                    remove(fields.length - 1);
                  }}
                  css={css`
                    cursor: pointer;
                    width: 75px;
                    text-align: center !important;
                    svg {
                      :hover {
                        color: #fc1a66 !important;
                      }
                    }
                  `}
                >
                  <AiFillMinusSquare color="#e91e63" size="28" />
                </td>
              ) : (
                <td></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {Number(data.data.maxRow) > fields.length && (
        <div
          className="d-flex justify-content-end"
          onClick={() => append({})}
          css={css`
            cursor: pointer;
          `}
        >
          <FaPlusSquare color="blue" size="24" />
        </div>
      )}
    </div>
  );
};

export default Table;
