import { fetchWrapper } from "../../_helpers/fetchWrapper";

const createPrashasakProfile = async (body) => {
    const response = await fetchWrapper.post(`/adminstrator`, body);
    return response.data;
}
const editPrashasakProfile = async (body, id) => {
    const response = await fetchWrapper.patch(`/adminstrator/${id}`, body);
    return response.data;
}

const getProfiles = async ({ page = 1, fullname_np, fullname_en }) => {
    let filters = '';
    if (fullname_np) {
        filters += `&fullname_np=${fullname_np}`
    }
    if (fullname_en) {
        filters += `&fullname_en=${fullname_en}`
    }
    
    const response = await fetchWrapper.get(`/adminstrator?page=${page}${filters}`);
    return response.data;
}

const getProfile = async ({ id }) => {
    const response = await fetchWrapper.get(`/adminstrator/${id}`);
    return response.data;
}

const getActivePrashasak = async () => {
    const response = await fetchWrapper.get(`/adminstrator/active`);
    return response.data;
}

const deletePrashasak = async (id) => {
    await fetchWrapper.delete(`/adminstrator/${id}`);
}

const activatePrashasak = async (item) => {
    const response = await fetchWrapper.patch(`/adminstrator/active`, {
        id: item.id
    });
    return response.data;
}



export const PrashasakServices = {
    createPrashasakProfile, editPrashasakProfile,
    getProfiles,
    getProfile,
    getActivePrashasak,
    activatePrashasak,
    deletePrashasak
}
