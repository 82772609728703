/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

//
import { VscLoading } from "react-icons/vsc";

//
import { DisabledServices } from "../../api/services/DisabledServices";
import { ElderlyServices } from "../../api/services/ElderlyServices";
import DisabledPreview from "./Disabled/DisabledPreview";
import ElderlyPreview from "./Elderly/ElderlyPreview";

//

/**
 *
 */
export default function Preview() {
  const { category, id } = useParams();
  const disabledCategory = useMemo(() => ["disabled", "disabledProfile"], []);

  const [userProfile, setUserProfile] = useState(null);
  const [fingerPrintData, setFingerPrintData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const hasFingerprint =
    process.env.REACT_APP_HAS_FINGERPRINT_FEATURE === "true";

  useEffect(() => {
    const handleFetchUserProfile = async () => {
      try {
        let result = [];
        if (category === "elderly") {
          result = await ElderlyServices.getElderlyProfileById(id);
        } else if (disabledCategory.includes(category)) {
          result = await DisabledServices.getDisabledProfileById(id);
        }

        if (hasFingerprint) {
          let fp_response = await ElderlyServices.getFingerprints(id);
          let fingerprint = fp_response.results[0];

          if (fingerprint) {
            setFingerPrintData(fingerprint.fingerPrint);
          }
        }

        setUserProfile(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    handleFetchUserProfile();
  }, [category, disabledCategory, id]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        css={css`
          min-height: 60vh;
        `}
      >
        <VscLoading className="spin" size={"48"} />
      </div>
    );
  }
  if (!userProfile) {
    return <div>loading...</div>;
  }
  return (
    <div>
      <div aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" aria-current="page">
            Home
          </li>
          <li class="breadcrumb-item" aria-current="page">
            परिचय पत्र
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {userProfile?.name_np}
          </li>
        </ol>
      </div>

      {category === "elderly" ? (
        <ElderlyPreview
          userProfile={userProfile}
          fingerPrints={fingerPrintData}
        />
      ) : (
        <DisabledPreview
          userProfile={userProfile}
          fingerPrints={fingerPrintData}
        />
      )}
    </div>
  );
}
