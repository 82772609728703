import React from 'react';
import { englishToNepaliNumber as np } from 'nepali-number';
import { Link } from 'react-router-dom';
import { DashboardStatsObject } from '../_datas/contants';

function StatCard({item, title, icon }) {
	const TitleObject = DashboardStatsObject[title];
	return (
		<div className="col p-2">
			<Link to={TitleObject?.path || null}>
				<div className="dashboard-stats-box">
					<div className="main-stats">
						<div className="dashboard-stats-box-content">
							<h5>{TitleObject?.title}</h5>
						</div>
						<h5 className="dashboard-number">
							<kbd className="red-shade">{item?.count ? np(item?.count) : '--'}</kbd>
						</h5>
						<span>
							<div className="dashboard-stats-box-icon">{icon}</div>
						</span>
					</div>
					<div className="stats-category">
						{
							item?.genders?.map(genderData =>
								<div key={genderData?._id}>
									<div>{genderData?._id}</div>
									<kbd className="red-shade">{genderData?.total ? np(genderData?.total) : '--'}</kbd>
								</div>  )
						}
					</div>
				</div>
			</Link>
		</div>
	);
}

export default StatCard;
