/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import {
  fetchDocumentType,
  deleteDocumentType,
} from "../../../_redux/slices/documents";
import { connect, useSelector } from "react-redux";
import AddDocumentType from "./AddDocumentType";
import BackButton from "../../../_elements/BackButton";
import { VscLoading } from "react-icons/vsc";
import PaginationComp from "../../../components/Pagination";
import { useHistory, useParams } from "react-router-dom";

const DocumentsType = ({
  fetchDocumentType,
  documentsType,
  count,
  deleteDocumentType,
}) => {
  const history = useHistory();
  const { pageNum } = useParams();

  const loading = useSelector((state) => state.documents.loading);

  const [showAdd, setShowAdd] = useState(false);
  const [documentDetail, setDocumentDetail] = useState(null);
  const [page, setPage] = useState(pageNum);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  useEffect(() => {
    fetchDocumentType(page);
  }, [fetchDocumentType, page]);

  const handleEdit = (document) => {
    let tempDocu = { ...document };
    tempDocu.eventType = "edit";
    setDocumentDetail(tempDocu);
    setShowAdd(true);
  };
  const [show, setShow] = useState(false);

  const handleDeleteDocument = async () => {
    await deleteDocumentType(show);
    fetchDocumentType(page);
    setShow(false);
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <BackButton />
        </Col>
      </Row>
      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <AddDocumentType
              showAdd={showAdd}
              setShowAdd={setShowAdd}
              documentDetail={documentDetail}
              setDocumentDetail={setDocumentDetail}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>कागजात विवरण</h2>
              <Button
                variant="primary"
                onClick={() => {
                  setShowAdd(true);
                  setDocumentDetail(null);
                }}
              >
                <FaPlusCircle />
                &nbsp; नयाँ कागजात विवरण
              </Button>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>शीर्षक</th>
                      <th>प्रकार</th>
                      <th>परिचय पत्र</th>
                      <th className="text-center">कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <VscLoading className="spin" />
                        </td>
                        <td></td>
                      </tr>
                    ) : documentsType.length > 0 ? (
                      documentsType.map((item, index) => (
                        <tr>
                          <td>{item.title}</td>
                          <td>{item.type}</td>
                          <td></td>
                          <td className="text-center">
                            <Button
                              variant="info"
                              className="mr-1"
                              onClick={() => handleEdit(item)}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => setShow(item.id)}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <h3 className="color-primary">कागजात विवरण पाईएन</h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <PaginationComp page={page} total={count} setPage={handleSetPage} />

      <Modal show={Boolean(show)} onHide={() => setShow(false)} centered>
        <Modal.Header>
          <h3>कागजात विवरण</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="h5 font-weight-lighter">
            {" "}
            के तपाइँ यो कागजात विवरण हटाउन चाहानुहुन्छ?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleDeleteDocument}>
            सुनिश्चित गर्नुहोस्
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  documentsType: state.documents.documentsType,
  count: state.documents.count
});

const mapDispatchToProps = {
  fetchDocumentType,
  deleteDocumentType,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsType);
