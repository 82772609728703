import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { languageSelector } from "../_redux/slices/languages";

function BackButton() {
  const history = useHistory();
  const { language } = useSelector(languageSelector);
  return (
    <div className="mb-3">
      <Button variant="outline-primary" onClick={() => history.goBack()}>
        {language === "english" ? "Back" : "पछाडी जानुहोस"}
      </Button>
    </div>
  );
}

export default BackButton;
