import Checkbox from "./Checkbox";
import Select from "./Select";
import Number from "./Number";
import Text from "./Text";
import DatePicker from "../../../../_elements/DatePicker";
import RadioOptions from "./RadioOptions";
import { StyledFormInput } from "../../../../styles/StyledComponents";
import SelectProvince from "../../../../_elements/SelectProvince";
import SelectDistrict from "../../../../_elements/SelectDistrict";
import SelectPalika from "../../../../_elements/SelectPalika";
import {
  errorMessageChecker,
  formValidationCreator,
} from "../../../../_helpers/methods";
import List from "./List";
import { useEffect, useRef } from "react";
import Password from "./Password";
import FileUpload from "./FileUpload";

const FormInput = ({
  id,
  type,
  subType,
  label,
  defaultValue,
  language,
  placeholder,
  options,
  className,
  maxLength,
  clearErrors,
  onChange,
  properties,
  isDisabled,
  register,

  errors = {},

  setValue,

  ADBSmode = "BS",
  validations,
}) => {
  let element = null;
  let formValidations = {};
  const isRequired = validations?.isRequired;
  if (validations) {
    formValidations = formValidationCreator(validations, label);
  }

  useEffect(() => {
    if (setValue && defaultValue) {
      setValue(id, defaultValue);
      if (clearErrors) {
        clearErrors(id);
      }
    }
  }, [defaultValue, id, setValue, clearErrors]);

  switch (type) {
    case "Text":
      element = (
        <Text
          register={register}
          elementId={id}
          errors={errors}
          language={language}
          className={`form-control ${className ?? ""}`}
          placeholder={placeholder}
          value={defaultValue}
          setValue={setValue}
          subType={subType}
          validations={formValidations}
          disabled={isDisabled}
        />
      );
      break;

    case "Password":
      element = (
        <Password
          register={register}
          elementId={id}
          errors={errors}
          className={`form-control ${className ?? ""}`}
          placeholder={placeholder}
          value={defaultValue}
          setValue={setValue}
          validations={formValidations}
          disabled={isDisabled}
        />
      );
      break;
    case "Date":
      element = (
        <DatePicker
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          errors={errors}
          elementId={id}
          value={defaultValue}
          mode={ADBSmode}
          onChange={onChange}
          disabled={isDisabled}
          validations={formValidations}
        />
      );
      break;

    case "Select":
      element = (
        <Select
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          errors={errors}
          elementId={id}
          options={options}
          value={defaultValue}
          disabled={isDisabled}
          validations={formValidations}
        />
      );
      break;

    case "Number":
      element = (
        <Number
          register={register}
          errors={errors}
          elementId={id}
          language={language}
          className={`form-control ${className ?? ""}`}
          placeholder={placeholder}
          value={defaultValue}
          validations={formValidations}
          setValue={setValue}
          maxLength={maxLength}
          disabled={isDisabled}
        />
      );
      break;

    case "Checkbox":
      element = (
        <Checkbox
          text={properties.text}
          register={register}
          elementId={id}
          value={defaultValue}
          errors={errors}
          disabled={isDisabled}
          validation={formValidations}
        />
      );
      break;

    case "Radio":
      element = (
        <RadioOptions
          register={register}
          elementId={id}
          defaultValue={defaultValue}
          setValue={setValue}
          errors={errors}
          options={options}
          validation={formValidations}
          disabled={isDisabled}
        />
      );
      break;

    case "List":
      element = (
        <List
          register={register}
          elementId={id}
          defaultValue={defaultValue}
          setValue={setValue}
          disabled={isDisabled}
          language={language}
        />
      );
      break;
    case "Province":
      element = (
        <SelectProvince
          elementId={id}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          defaultValue={defaultValue}
          onChange={onChange}
          validations={formValidations}
          disabled={isDisabled}
          errors={errors}
        />
      );
      break;

    case "District":
      element = (
        <SelectDistrict
          elementId={id}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          selectedProvince={properties?.province}
          defaultValue={defaultValue}
          onChange={onChange}
          validations={formValidations}
          disabled={isDisabled}
          errors={errors}
        />
      );
      break;

    case "Palika":
      element = (
        <SelectPalika
          elementId={id}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          selectedProvince={properties?.province}
          selectedDistrict={properties?.district}
          defaultValue={defaultValue}
          onChange={onChange}
          validations={formValidations}
          disabled={isDisabled}
          errors={errors}
        />
      );
      break;

    case "File":
      element = (
        <FileUpload
          elementId={id}
          register={register}
          setValue={setValue}
          clearErrors={clearErrors}
          onChange={onChange}
          validations={formValidations}
          disabled={isDisabled}
          value={defaultValue}
          errors={errors}
          subType={subType}
        />
      );
      break;
    default:
      element = null;
  }

  return (
    <StyledFormInput className="d-flex flex-column py-2">
      {label && (
        <div>
          <label htmlFor={id}>
            <strong>
              {label}
              {isRequired && <span className="text-danger ml-1">*</span>} :{" "}
            </strong>
          </label>
        </div>
      )}

      {element}

      <ErrorMessage errors={errors} id={id} />
    </StyledFormInput>
  );
};

export default FormInput;

const ErrorMessage = ({ errors, id }) => {
  let errorMessage = errorMessageChecker(errors, id);
  const ErrorRef = useRef();

  useEffect(() => {
    if (errorMessage) {
      ErrorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });

  if (!errorMessage) return null;

  return (
    <div className="text-danger mt-2" ref={ErrorRef}>
      {errorMessage}
    </div>
  );
};
