/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { patchWardUser } from "../../../_redux/slices/ward";
import { nepaliNumberConverter } from "../../../_helpers/methods";
import { useEffect } from "react";
import { toast } from "react-toastify";
import FormInput from "../../../components/Form/Elements/_new/FormInput";
import { useState } from "react";
import { WARD_ROLES } from "../../../_datas/contants";
import { WardServices } from "../../../api/services/WardServices";
import { VscLoading } from "react-icons/vsc";

const AddWardUser = ({
  showAdd,
  setShowAdd,
  userDetail,
  setUserDetail,
  id,
  asyncFetchWardDetail,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue("fullname_np", userDetail?.fullname_np);
  }, [setValue, userDetail?.fullname_np]);

  useEffect(() => {
    setValue("fullname_en", userDetail?.fullname_en);
  }, [setValue, userDetail?.fullname_en]);

  useEffect(() => {
    setValue("password", userDetail?.password);
  }, [setValue, userDetail?.password]);

  useEffect(() => {
    setValue("phone_en", userDetail?.phone_en);
  }, [setValue, userDetail?.phone_en]);

  useEffect(() => {
    setValue("email", userDetail?.email);
  }, [setValue, userDetail?.email]);

  useEffect(() => {
    setValue("role_en", userDetail?.role_en);
  }, [setValue, userDetail?.role_en]);

  useEffect(() => {
    setValue("designation_np", userDetail?.designation_np);
  }, [setValue, userDetail?.designation_np]);

  useEffect(() => {
    setValue("designation_en", userDetail?.designation_en);
  }, [setValue, userDetail?.designation_en]);

  const hideUserForm = () => {
    setShowAdd(false);
  };
  const onSubmit = async (data) => {
    try {
      if (data.phone_en.length !== 10) {
        return toast.error("Phone number is invalid");
      }
      setIsLoading(true);
      data.phone_np = nepaliNumberConverter(data.phone_en);
      data.ward = id;
      data.username = data.email;
      try {
        if (userDetail) {
          delete data.ward;
          await WardServices.updateWardUser(data, userDetail.id);
        } else {
          await WardServices.createWardUser(data);
        }
        hideUserForm();
        asyncFetchWardDetail();
      }
      catch (error) {
        toast.error(error.message);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete={"off"}>
      <Card>
        <Card.Header className="card-header-custom">
          <h3>
            {userDetail?.eventType === "edit"
              ? "प्रयोगकर्ता सम्पादन गर्नुहोस्"
              : " नयाँ प्रयोगकर्ता"}
          </h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4} className="mb-3">
              <FormInput
                label="पुरा नाम (नेपालीमा)"
                id="fullname_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                placeholder={"पूरा नाम भर्नुहोस्"}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={userDetail && userDetail.fullname_np}
              />
            </Col>
            <Col md={4} className="mb-3">
              <FormInput
                label="पुरा नाम (अंग्रेजीमा)"
                id="fullname_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                language="english"
                type="Text"
                placeholder={"Enter full name"}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={userDetail && userDetail.fullname_en}
              />
            </Col>
            <Col md={4} className="mb-3">
              <FormInput
                label="Phone no."
                id="phone_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                placeholder={"Enter phone number"}
                errors={errors}
                defaultValue={userDetail && userDetail.fullname_en}
                language="english"
                type="Number"
                maxLength="10"
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <FormInput
                label="ईमेल"
                id="email"
                register={register}
                language="english"
                type="Text"
                setValue={setValue}
                isDisabled={isLoading}
                errors={errors}
                placeholder={"abc@xyz.com"}
                defaultValue={userDetail && userDetail.email}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
            <Col md={4} className="mb-3">
              <FormInput
                label="Password"
                id="password"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Password"
                errors={errors}
                validations={{
                  isRequired: Boolean(userDetail?.eventType !== "edit"),
                  minLength: {
                    value: 8,
                    message: "Password Should be atleast 8 characters", // JS only: <p>error message</p> TS only support string
                  },
                }}
                placeholder="Enter Password"
                defaultValue={userDetail && userDetail.password}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} className="mb-3">
              <FormInput
                label="पद"
                id="designation_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                placeholder={"पद भर्नुहोस्"}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={userDetail && userDetail.designation_np}
              />
            </Col>
            <Col md={4} className="mb-3">
              <FormInput
                label="Designation"
                id="designation_en"
                register={register}
                language="english"
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                placeholder={"Enter designation"}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={userDetail && userDetail.designation_en}
              />
            </Col>
            <Col md={4} className="mb-3">
              <FormInput
                label="भूमिका"
                id="role_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Select"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                clearErrors={clearErrors}
                options={WARD_ROLES}
                defaultValue={userDetail && userDetail.role_en}
              />
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => {
              setShowAdd(!showAdd);
              setUserDetail(null);
            }}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button type="submit" variant="success" disable={isLoading}>
            {isLoading ? <VscLoading className="spin" /> : "पेश गर्नुहोस्"}
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  patchWardUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWardUser);
