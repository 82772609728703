import React, { useState } from 'react';
import { useEffect } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PrashasakServices } from '../../../api/services/PrashasakServices';
import FormInput from '../../../components/Form/Elements/_new/FormInput';
import { StyledFormRow } from '../../../styles/StyledComponents';
import BackButton from '../../../_elements/BackButton';
import { nepaliNumberConverter } from '../../../_helpers/methods';
import { handleIncrementAdministratorStats } from '../../../_redux/slices/appStats';
import { handlesetActivePrashasak } from '../../../_redux/slices/prashasak';

const NewPrashasak = () => {
	const history = useHistory();
	const { id } = useParams();
	const dispatch = useDispatch();

	const [apiError, setApiError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [prashasak, setPrashasak] = useState(null);

	const { register, setValue, handleSubmit, formState: { errors } } = useForm({});

	useEffect(() => {
		if (id) {
			async function getProfile() {
				const data = await PrashasakServices.getProfile({ id });
				setPrashasak(data);
			}

			getProfile();
		}
	}, [id]);

	useEffect(() => {
		if (prashasak) {
			setValue('fullname_np', prashasak.fullname_np)
			setValue('fullname_en', prashasak.fullname_en)
			setValue('phone_en', prashasak.phone_en)
			setValue('designation_en', prashasak.designation_en)
			setValue('designation_np', prashasak.designation_np)
			setValue('active', prashasak.active)
			setValue('redSignature', prashasak.redSignature)
			setValue('blackSignature', prashasak.blackSignature)
			setValue('stamp', prashasak.stamp)
		}
	}, [prashasak, setValue]);

	const onSubmit = async (data) => {
		try {
			let body = {
				...data,
				phone_np: nepaliNumberConverter(data.phone_en)
			}
			setIsLoading(true);
			if (id) {
				delete body.active;
				await PrashasakServices.editPrashasakProfile(body, id);
			}
			else {
				const data = await PrashasakServices.createPrashasakProfile(body);
				dispatch(handleIncrementAdministratorStats());
				if (body.active) {
					dispatch(handlesetActivePrashasak(data));
				}
			}
			history.push(`/settings/prashasak/list/1`)
			setApiError(null);
			setIsLoading(false);
		}
		catch (error) {
			setApiError(error?.message);
			setIsLoading(false);
		}
	}

	return (
		<div>
			<div aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item" aria-current="page">
						Home
					</li>
					<li class="breadcrumb-item" aria-current="page">
						फारमहरु
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						प्रशासकहरु
					</li>
				</ol>
			</div>

			<div className="d-flex align-items-center">
				<BackButton />
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				<h2 className="text-center mb-2">
					{
						id ? "" : "नयाँ"}
					प्रसाशक बनाउनुहोस</h2>
				<div class="alert alert-primary" role="alert">
					नयाँ प्रसाशक बनाउनुहोस
				</div>
				<fieldset className="p-2 border border-2">
					<StyledFormRow>
						<Col md={6} lg={4}>
							<FormInput
								label="पुरा नाम नेपालीमा"
								id="fullname_np"
								register={register}
								defaultValue={prashasak?.fullname_np}
								setValue={setValue}
								type="Text"
								errors={errors}
								isDisabled={isLoading}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
						<Col md={6} lg={4} className="mb-4">
							<FormInput
								label="Full name in English"
								id="fullname_en"
								language="english"
								register={register}
								setValue={setValue}
								defaultValue={prashasak?.fullname_en}
								type="Text"
								errors={errors}
								isDisabled={isLoading}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
						<Col md={6} lg={4}>
							<FormInput
								label="Phone number"
								id="phone_en"
								register={register}
								setValue={setValue}
								language='english'
								type="Number"
								defaultValue={prashasak?.phone_en}
								maxLength="10"
								isDisabled={isLoading}
								errors={errors}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
						<Col md={6} lg={4}>
							<FormInput
								label="पद नेपालीमा"
								id="designation_np"
								register={register}
								setValue={setValue}
								type="Text"
								defaultValue={prashasak?.designation_np}
								errors={errors}
								isDisabled={isLoading}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
						<Col md={6} lg={4} className="mb-4">
							<FormInput
								label="Designation"
								id="designation_en"
								language="english"
								register={register}
								setValue={setValue}
								defaultValue={prashasak?.designation_en}
								type="Text"
								errors={errors}
								isDisabled={isLoading}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
					</StyledFormRow>

					{!id &&
						<StyledFormRow>
							<Col md={6} lg={4}>
								<FormInput
									label="सक्रिय प्रशासकहरु"
									id="active"
									register={register}
									setValue={setValue}
									type="Checkbox"
									isDisabled={isLoading}
									defaultValue={prashasak?.active}
									errors={errors}
									properties={{
										text: "हो"
									}}
								/>
							</Col>
						</StyledFormRow>
					}


					<StyledFormRow>
						<Col md={6} lg={4}>
							<FormInput
								label="रातो हस्ताक्षर"
								id="redSignature"
								register={register}
								setValue={setValue}
								defaultValue={prashasak?.redSignature}
								type="File"
								isDisabled={isLoading}
								errors={errors}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
						<Col md={6} lg={4}>
							<FormInput
								label="कालो हस्ताक्षर"
								id="blackSignature"
								register={register}
								setValue={setValue}
								type="File"
								isDisabled={isLoading}
								errors={errors}
								defaultValue={prashasak?.blackSignature}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
						<Col md={6} lg={4}>
							<FormInput
								label="छाप"
								id="stamp"
								register={register}
								setValue={setValue}
								type="File"
								defaultValue={prashasak?.stamp}
								isDisabled={isLoading}
								errors={errors}
								validations={{
									isRequired: true
								}}
							/>
						</Col>
					</StyledFormRow>
				</fieldset>

				{
					apiError &&
					<Row>
						<Alert variant='danger'>
							{apiError}
						</Alert>
					</Row>
				}
				<div className="d-flex justify-content-end py-4">
					<Button variant="success" type="submit">पेश गर्नुहोस</Button>
				</div>
			</form>
		</div>
	);
};

export default NewPrashasak;
