/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import { BsCheckCircle } from "react-icons/bs";
import { VscLoading } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { RiUserReceived2Fill } from "react-icons/ri";
import { fetchSakhaById, fetchSakhaUsers, removeSakhaUser } from "../../../../_redux/slices/sakha";
import { languageSelector } from "../../../../_redux/slices/languages";
import BackButton from "../../../../_elements/BackButton";
import AddSakhaUser from "./AddSakhaUser";

const SakhaUser = ({ match }) => {
    const dispatch = useDispatch();

    const sakhaUsers = useSelector((state) => state.sakhas.sakhaUsers);

    const [showModal, setShowModal] = useState(null);
    const [userDetail, setUserDetail] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { language } = useSelector(languageSelector);
    const [sakha, setSakha] = useState({});

    const asyncFetchSakhaDetail = useCallback(async () => {
        setIsLoading(true);
        dispatch(fetchSakhaUsers(match.params.id));
        const response = await fetchSakhaById(match.params.id);
        setSakha(response);
        setIsLoading(false);
    }, [dispatch, match.params.id]);

    useEffect(() => {
        asyncFetchSakhaDetail();
    }, [asyncFetchSakhaDetail]);

    function editUser(item) {
        let tempItem = {
            ...item,
            eventType: "edit",
        };
        setUserDetail(tempItem);
        setShowAdd(!showAdd);
    }

    return (
        <Fragment>
            <BackButton />
            {showAdd && (
                <Row className="mt-2">
                    <Col md={12}>
                        <AddSakhaUser
                            showAdd={showAdd}
                            setShowAdd={setShowAdd}
                            userDetail={userDetail}
                            setUserDetail={setUserDetail}
                            id={match.params.id}
                            asyncFetchSakhaDetail={asyncFetchSakhaDetail}
                        />
                    </Col>
                </Row>
            )}
            <Row className="mt-2">
                <Col md={12}>
                    <Card className="px-1">
                        <Card.Header className="card-header-custom">
                            <h2>{sakha.sakha_np || sakha.sakha_en}</h2>
                            <Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
                                <FaPlusCircle />
                                &nbsp; नयाँ प्रयोगकर्ता
                            </Button>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="table-responsive">
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th>नाम</th>
                                            <th>ईमेल</th>
                                            <th>फोन</th>
                                            <th>पद</th>
                                            <th className="text-center">कार्यहरू</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td />
                                                <td />
                                                <td
                                                    colSpan="5"
                                                    css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                          `}
                                                >
                                                    <VscLoading className="spin" fontSize={48} />
                                                </td>
                                                <td />
                                                <td />
                                            </tr>
                                        ) : (
                                            sakhaUsers?.map((sakhaUser) => (
                                                <tr
                                                    key={sakhaUser.id}
                                                    className={`${sakhaUser.is_active ? "kawa_active" : ""
                                                        }`}
                                                >
                                                    <td>
                                                        {sakhaUser.is_active ? (
                                                            <span className="kawa_indicator">
                                                                <RiUserReceived2Fill color="#6171e4" />
                                                                {language === "english"
                                                                    ? "Act. Chairman"
                                                                    : "का.वा"}
                                                            </span>
                                                        ) : null}
                                                        {sakhaUser.fullname_np || sakhaUser.fullname_en}
                                                    </td>
                                                    <td>{sakhaUser.email}</td>
                                                    <td>{sakhaUser.phone_np || sakhaUser.phone_en}</td>
                                                    <td>
                                                        {sakhaUser.designation_np || sakhaUser.designation_en}
                                                    </td>
                                                    <td className="text-center">
                                                        <Button
                                                            variant="info"
                                                            className="mr-1"
                                                            onClick={() => editUser(sakhaUser)}
                                                        >
                                                            <FaEdit />
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            className="mr-1"
                                                            onClick={() =>
                                                                setShowModal({
                                                                    type: "delete",
                                                                    body: {
                                                                        profile_id: sakhaUser.id,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <FaTrash />
                                                        </Button>

                                                        {sakhaUser.is_active ? (
                                                            <Button
                                                                variant="secondary"
                                                                onClick={() => {
                                                                    setShowModal({
                                                                        type: "kawa",
                                                                        body: {
                                                                            profile_id: sakhaUser.id,
                                                                            status: "inactive",
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <BsCheckCircle className="mr-1" />
                                                                {language === "english"
                                                                    ? "Deactivate Act. Chairman"
                                                                    : "का.वा निष्क्रिय पार्नुहोस्"}
                                                            </Button>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal
                show={Boolean(showModal)}
                onHide={() => setShowModal(null)}
                centered
            >
                <Modal.Header>
                    <h3>{showModal?.type === "kawa" ? "का.वा अपडेट" : "User delete"}</h3>
                </Modal.Header>
                <Modal.Body>
                    <p className="h5 font-weight-lighter">
                        {showModal?.type === "kawa"
                            ? "के तपाइँ यो का.वा अपडेट गर्न चाहानुहुन्छ?"
                            : "के तपाइँ यो user हटाउन चाहानुहुन्छ?"}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn btn-secondary"
                        onClick={() => {
                            setShowModal(null);
                        }}
                    >
                        रद्द गर्नुहोस्
                    </Button>
                    <Button
                        className="btn btn-danger"
                        onClick={() => {
                            console.log(showModal, "showModal");
                            if (showModal?.type === "delete") {
                                dispatch(removeSakhaUser(showModal.body.profile_id));
                                setShowModal(null);
                            } else if (showModal?.type === "kawa") {
                                setShowModal(null);
                            }
                        }}
                    >
                        सुनिश्चित गर्नुहोस्
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SakhaUser;
