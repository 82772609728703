import styled from "@emotion/styled";

export const StyledResizableInput = styled.span`
  position: relative;
  display: inline-block;
  .input {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    top: 0;
    left: 0;
    font-weight: 800;
    font-size: inherit;
    /* border: 1px solid #ccc; */
    border: none;
    text-align: center;
  }
  .input:focus {
    outline: 1px solid #000;
  }
  .width-machine {
    padding: 0 0.6rem;
    opacity: 0;
    font-weight: 800;
    font-size: inherit;
    font-family: "Helvetica", Arial, Lucida Grande, sans-serif;
  }
`;
