import React from "react";
import { memo } from "react";
import styled from "@emotion/styled";

export const LoaderStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  place-items: center;
  place-content: center;
  display: flex;
  z-index: 1;
  background: rgba(255, 255, 255, 0.7);
  img: {
    pointer-events: none;
    max-width: 100%;
    width: 150px;
  }
`;

const Loader = () => {
  return (
    <LoaderStyles>
      <img src="/assets/images/ball-triangle.svg" alt="loader" />
    </LoaderStyles>
  );
};

export default memo(Loader);
