import React, { Fragment, useEffect, useState } from "react";
import { PALIKADATA } from "../_datas";
import { errorMessageChecker } from "../_helpers/methods";
import { setSelectValue } from "../_redux/slices/formTemplates";

function SelectDistrict({ elementId, register, setValue, defaultValue, onChange, clearErrors,
  selectedProvince, validations = {}, disabled, errors }) {
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    if (selectedProvince) {
      try {
        const keys = Object.keys(PALIKADATA[selectedProvince]);
        setDistricts(keys);
      } catch { }
    }
  }, [selectedProvince]);
  return (
    <Fragment>
      <select
        className={`form-control
                        ${errorMessageChecker(errors, elementId) ? "is-invalid" : ""} 
                        `}
        id={elementId}
        value={defaultValue}
        onChange={(e) => {
          let value = e.target.value;
          setSelectValue(value);
          if (value) {
            clearErrors(elementId);
          }
          if (setValue) {
            setValue(elementId, e.target.value);
          }
          if (onChange) {
            onChange(value)
          }
          register(elementId).onChange(e);
        }}
        disabled={disabled}
      >
        <option value="">कृपया जिल्ला छान्नुहोस</option>
        {districts.map((district) => (
          <option key={district} value={district}>{district}</option>
        ))}
      </select>
      <input
        {...register(elementId, validations)}
        hidden readOnly />
    </Fragment>
  );
}

export default SelectDistrict;
