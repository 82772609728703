import React from "react";
import { PALIKADATA } from "../_datas";

function District({
  register,
  elementId,
  province,
  district,
  handleDistrictChange, disabled
}) {
  return (
    <div>
      <label>District</label>
      <select
        value={district}
        {...register(elementId)}
        onChange={(e) => {
          handleDistrictChange(e.target.value);
          register(elementId).onChange(e);
        }}
        disabled={disabled}
      >
        <option key={1} value={""}>
          select district
        </option>
        {Object.keys(PALIKADATA)
          .filter((provinceKey) => provinceKey === province)
          .map((selectedProvinceKey) => {
            return Object.keys(PALIKADATA[selectedProvinceKey]).map(
              (district) => (
                <option key={district} value={district}>
                  {district}
                </option>
              )
            );
          })}
      </select>
    </div>
  );
}

export default District;
