import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import { connect, useSelector } from "react-redux";
import BackButton from "../../_elements/BackButton";
import { useForm } from "react-hook-form";
import {
  createSifarishType,
  fetchSifarishTypes,
  deleteSifarishType,
  editSifarishType,
} from "../../_redux/slices/sifarishTypes";
import { VscLoading } from "react-icons/vsc";
import InputField from "../../components/InputField";
import { InputFieldStyleOverwrite } from "./Ward/AddWard";
import { languageSelector } from "../../_redux/slices/languages";

const SifarishType = ({
  fetchSifarishTypes,
  createSifarishType,
  editSifarishType,
  deleteSifarishType,
}) => {
  const sifarishTypes = useSelector(
    (state) => state.sifarishTypes.sifarishTypes
  );
  const loading = useSelector((state) => state.sifarishTypes.loading);
  const { language } = useSelector(languageSelector);
  useEffect(() => {
    fetchSifarishTypes();
  }, [fetchSifarishTypes]);

  const [showAdd, setShowAdd] = useState(false);
  const [editType, setEditType] = useState(false);
  const [show, setShow] = useState(false);
  const { register, handleSubmit, setValue } = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  useEffect(() => {
    if (showAdd?.title) {
      setValue("title", showAdd?.title);
    }
  }, [setValue, showAdd?.title]);

  const onSubmit = (data) => {
    if (editType) {
      data.id = showAdd.id;
      editSifarishType(data, setShowAdd);
      setEditType(false);
    } else {
      createSifarishType(data, setShowAdd);
    }
  };

  const handleDeleteSifarishType = async () => {
    await deleteSifarishType(show.id);
    setShow(null);
  };
  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <BackButton />
        </Col>
      </Row>

      {showAdd && (
        <Card>
          <Card.Header className="card-header-custom d-block">
            <h3>
              {editType
                ? "परिचय पत्रको प्रकार सम्पादन गर्नुहोस्"
                : " नयाँ परिचय पत्रको प्रकार"}
            </h3>
          </Card.Header>
          <Card.Body className="p-0 mt-4">
            <Row className="mt-2">
              <Col md={12}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <div className="d-flex align-items-center">
                      <label className="mb-0">
                        {language === "english" ? "Title" : "शीर्षक"}
                      </label>
                      <InputField
                        css={InputFieldStyleOverwrite}
                        type="text"
                        className={`textbox_req`}
                        language={language}
                        classNameName={`input`}
                        elementId={"title"}
                        placeholder={
                          language === "english"
                            ? "Enter Sifaris type title"
                            : "परिचय पत्रको प्रकारको शीर्षक"
                        }
                        maxLength={50}
                        register={register}
                        isRequired={true}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                  <div className="p-3 d-flex justify-content-end">
                    <Button
                      className="mr-2"
                      variant="primary"
                      onClick={() => {
                        setShowAdd(false);
                        setValue("title", "");
                        setEditType(true);
                      }}
                    >
                      रद्द गर्नुहोस्
                    </Button>
                    {showAdd?.eventType === "edit" ? (
                      <Button variant="success" type="submit">
                        अपडेट गर्नुहोस्
                      </Button>
                    ) : (
                      <Button variant="success" type="submit">
                        सुरक्षित गर्नुहोस्
                      </Button>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>परिचय पत्रको प्रकार</h2>
              <Button
                variant="primary"
                onClick={() => {
                  setShowAdd(true);
                  setEditType(false);
                  setValue("title", "");
                }}
              >
                <FaPlusCircle />
                &nbsp; नयाँ परिचय पत्रको प्रकार
              </Button>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>शीर्षक</th>
                      <th>छनौट</th>
                      <th className="text-center">कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td></td>
                        <td className="text-center">
                          <VscLoading className="spin" fontSize="48" />
                        </td>
                        <td></td>
                      </tr>
                    ) : sifarishTypes.length > 0 ? (
                      sifarishTypes.map((tempCategory) => (
                        <tr key={tempCategory.id}>
                          <td width="10%">{tempCategory.title}</td>
                          <td width="70%">
                            {tempCategory.templates &&
                              tempCategory.templates.map((temp) => (
                                <span className="mr-1">{temp.title},</span>
                              ))}
                          </td>
                          <td className="text-center" width="20%">
                            <Button
                              variant="info"
                              className="mr-1"
                              onClick={() => {
                                setEditType(true);
                                setShowAdd(tempCategory);
                              }}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              variant="danger"
                              onClick={() => setShow(tempCategory)}
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          <h3 className="color-primary">
                            परिचय पत्र को प्रकार पाईएन
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={Boolean(show)} onHide={() => setShow(null)} centered>
        <Modal.Header>परिचय पत्रको प्रकार</Modal.Header>
        <Modal.Body>
          <p className="h5 font-weight-lighter">
            के तपाइँ यो परिचय पत्रको प्रकार हटाउन चाहानुहुन्छ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={() => setShow(null)}>
            रद्द गर्नुहोस्
          </Button>
          <Button className="btn btn-danger" onClick={handleDeleteSifarishType}>
            सुनिश्चित गर्नुहोस्
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapDispatchToProps = {
  fetchSifarishTypes,
  createSifarishType,
  editSifarishType,
  deleteSifarishType,
};

export default connect(null, mapDispatchToProps)(SifarishType);
