/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

const FingerPrint = ({ data }) => {
  return (
    <div
      css={css`
        width: 230px;
        height: max-content;
        border: 1px solid black;
      `}
    >
      <p
        css={css`
          margin-bottom: 0;
          width: 100%;
          border-bottom: 1px solid black;
        `}
      >
        Finger Print
      </p>
      <div className="d-flex">
        <p
          className="mb-0 w-50"
          css={css`
            border-bottom: 1px solid black;
            border-right: 1px solid black;
          `}
        >
          Left
        </p>
        <p
          className="mb-0 w-50"
          css={css`
            border-bottom: 1px solid black;
          `}
        >
          Right
        </p>
      </div>
      <div
        className="d-flex"
        css={css`
          height: 120px;
        `}
      >
        <p
          className="mb-0 w-50"
          css={css`
            border-right: 1px solid black;
          `}
        ></p>
        <p className="mb-0 w-50"></p>
      </div>
    </div>
  );
};

export default FingerPrint;
