import React, { Fragment } from "react";
import { PALIKADATA } from "../_datas";
import { errorMessageChecker } from "../_helpers/methods";

function SelectProvince({
  elementId,
  register,
  setValue,
  defaultValue,
  clearErrors,
  onChange,
  validations,
  disabled,
  errors,
}) {
  return (
    <Fragment>
      <select
        className={`form-control
                        ${
                          errorMessageChecker(errors, elementId)
                            ? "is-invalid"
                            : ""
                        } 
                        `}
        value={defaultValue}
        onChange={(e) => {
          let value = e.target.value;
          if (value) {
            clearErrors(elementId);
          }
          if (setValue) {
            setValue(elementId, value);
          }
          if (onChange) {
            onChange(value);
          }
          register(elementId).onChange(e);
        }}
        disabled={disabled}
      >
        <option value="">कृपया प्रदेश छान्नुहोस</option>
        {Object.keys(PALIKADATA).map((province) => (
          <option key={province} value={province}>
            {province}
          </option>
        ))}
      </select>
      <input {...register(elementId, validations)} hidden readOnly />
    </Fragment>
  );
}

export default SelectProvince;
