import React from "react";
import { Form } from "react-bootstrap";

const Step2 = () => {
  return (
    <Form className="wizard-content">
      <div className="zoom" id="templateZoom">
        <page className="p-5 a4">
          <div className="template-header d-flex justify-content-between">
            <img
              src="../assets/images/nep_logo.png"
              className="brand-logo"
              alt=""
            />
            <div className="text-center">
              <h2>घोडाघोडी नगरपालिका</h2>
              <p>१ नं वडा कार्यालय</p>
              <p>सुखड, कैलाली</p>
              <p>सुदुरपश्चिम प्रदेश, नेपाल</p>
            </div>
            <div></div>
          </div>
          <div className="mt-5 py-5" contenteditable="true">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eveniet
            commodi facilis, consequuntur assumenda, vitae quaerat repellat est
            numquam illo error repellendus! Et repellat hic, perspiciatis aut
            totam error ea neque, omnis eos doloribus enim? Molestias
            consectetur provident earum! Repellat dignissimos nulla ea illum
            quas vel in nam maxime ducimus eligendi similique porro, fugiat,
            incidunt quasi omnis! Recusandae dolores placeat necessitatibus
            nobis dolorem culpa exercitationem ea minima excepturi unde corrupti
            mollitia maxime rem molestiae nostrum aut eaque a ipsum magni,
            consequuntur ipsam velit blanditiis? Dignissimos a nesciunt commodi
            at obcaecati, quasi odio deleniti officiis corrupti asperiores
            repellat neque sunt doloribus blanditiis ad autem, recusandae
            consequatur pariatur ullam quaerat. Beatae exercitationem eius,
            architecto laborum molestias harum officiis, animi commodi
            molestiae, ea veritatis.
          </div>
        </page>
        {/* <div className="zoomBtn text-center mb-2">
          <i
            className="fa fa-search-plus mr-2 pointer"
            onclick="templateZoom.style.zoom='100%'"
          >
            100%
          </i>
          <i
            className="fa fa-search-minus pointer"
            onclick="templateZoom.style.zoom='50%'"
          >
            50%
          </i>
        </div> */}
      </div>
    </Form>
  );
};

export default Step2;
