import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Form, Row, Col, Button, Alert, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VscLoading } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ElderlyServices } from "../../api/services/ElderlyServices";
import { ADToBS } from "../../components/Form/Elements/DatePicker/AdToBsConverter/AdBsConverter";
import FormInput from "../../components/Form/Elements/_new/FormInput";
import { StyledFormRow, StyledLabel } from "../../styles/StyledComponents";
import { BloodGroup } from "../../_datas/bloodGroup";
import Camera from "../../_elements/Camera";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { fetchWards } from "../../_redux/slices/ward";

function OldAgeForm() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWards());
  }, [dispatch]);
  const history = useHistory();
  const photoRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [palika, setPalika] = useState("");

  const [defaultValues, setDefaultValues] = useState({});
  const [ward, setWard] = useState(null);
  const wards = useSelector((state) => state.wards.wards);

  const [formDateStates, setFormDateStates] = useState({
    birthAD: null,
    birthBS: null,
  });

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    try {
      if (!data.photo) {
        photoRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return setApiError("फोटो अनिवार्य छ!");
      }
      setIsLoading(true);
      let body = {
        ...data,
        fingerprint: {
          type: data.fingerprintType || "none",
          left: "",
          right: "",
        },
        hasDisease: data.hasDisease === "true" ? true : false,
        onMedication: data.onMedication === "true" ? true : false,
        ward: ward,
      };
      delete body.fingerprintType;
      if (body.photo === null) {
        delete body.photo;
      }
      if (body.cardNo) {
        body.cardNo = body.cardNo.toString();
      }
      await ElderlyServices.createElderlyProfile(body, { isPublic: true });

      toast.success("Succesfully Applied!");
      history.goBack();
      setApiError(null);
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      setApiError(error?.message);
      setIsLoading(false);
    }
  };

  const hasDisease = watch("hasDisease");
  const onMedication = watch("onMedication");
  const fingerprintType = watch("fingerprintType");

  const handleSelectProvince = (provinceVal) => {
    setProvince(provinceVal);
  };

  const handleSelectDistrict = (districtVal) => {
    setDistrict(districtVal);
  };

  const handleSelectPalika = (palikaVal) => {
    setPalika(palikaVal);
  };

  // palikas handling
  useEffect(() => {
    setDistrict("");
  }, [province]);

  useEffect(() => {
    setPalika("");
  }, [district]);

  const handleSetDateAD = (id, value, valueAd) => {
    if (valueAd) {
      setFormDateStates((prev) => ({
        ...prev,
        [id]: valueAd.replaceAll("/", "-"),
      }));
    }
  };

  const handleSetDateBS = (id, value) => {
    const date = ADToBS(value.split("-").join("/"));

    setFormDateStates((prev) => ({
      ...prev,
      [id]: date,
    }));
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    try {
      setIsLoading(true);
      formData.append("file", file);
      const response = await fetchWrapper.post("/file", formData, true);
      const result = response.data;
      setValue("photo", result);
      setIsLoading(false);
      setDefaultValues((prev) => ({ ...prev, photo: result }));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className="d-flex flex-column">
          <label htmlFor="from" className="w-100 mr-2 mb-0">
            वडा कार्यालय:{" "}
          </label>
          <FormControl as={"select"} onChange={(e) => setWard(e.target.value)}>
            <option value="1">वडा कार्यालय छान्नुहोस्</option>
            {wards.map((w) => (
              <option value={w.id} key={w.id}>
                {w.ward_np || w.ward_en} न. वडा{" "}
              </option>
            ))}
          </FormControl>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-center">जेस्ठ नागरिक विवरण</h2>

        <fieldset className="mt-3 p-3 border border-2" ref={photoRef}>
          <FormInput
            label="# फोटो"
            id="photo"
            register={register}
            setValue={setValue}
            isDisabled={isLoading}
            clearErrors={clearErrors}
            type="File"
            errors={errors}
            defaultValue={defaultValues.photo}
          />
          <Camera
            handleFileUpload={handleFileUpload}
            defaultValue={defaultValues.photo}
          />
        </fieldset>
        {/* <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel># छाप</StyledLabel>
          <div className="d-flex">
            <FormInput
              id="fingerprintType"
              register={register}
              setValue={setValue}
              isDisabled={isLoading}
              clearErrors={clearErrors}
              type="Radio"
              errors={errors}
              options={[
                {
                  id: "fingerprintType-हातको औंला",
                  label: "हातको औंला",
                  value: "hand",
                },
                {
                  id: "fingerprintType-खुट्टाको औंला",
                  label: "खुट्टाको औंला",
                  value: "leg",
                },
                {
                  id: "fingerprintType-दुवै नभएको",
                  label: "दुवै नभएको",
                  value: "none",
                },
              ]}
              defaultValue={defaultValues.fingerprint?.type}
            />
          </div>
          {fingerprintType !== "none" && (
            <div className="d-flex mt-2">
              <Form.Group>
                <Form.Label htmlFor="left">
                  <Button>बायाँ</Button>
                </Form.Label>
                <Form.Control type="file" id="left" hidden />
              </Form.Group>
              <Form.Group className="ml-3">
                <Form.Label htmlFor="left">
                  <Button>दायाँ</Button>
                </Form.Label>
                <Form.Control type="file" id="left" hidden />
              </Form.Group>
            </div>
          )}
        </fieldset> */}

        <fieldset className="mt-3 p-3 border border-2">
          <StyledLabel>१. व्यक्तिगत विवरण</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="पुरा नाम नेपालीमा"
                id="name_np"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.name_np}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="Full name in English"
                id="name_en"
                language="english"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.name_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="जन्म मिति (बि.स.)"
                id="dateOfBirth_np"
                register={register}
                setValue={setValue}
                type="Date"
                errors={errors}
                clearErrors={clearErrors}
                isDisabled={isLoading}
                validations={{
                  isRequired: true,
                }}
                defaultValue={formDateStates.birthBS}
                onChange={(value, valueAd) =>
                  handleSetDateAD("birthAD", value, valueAd)
                }
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="जन्म मिति (AD)"
                id="dateOfBirth_en"
                register={register}
                setValue={setValue}
                type="Date"
                errors={errors}
                clearErrors={clearErrors}
                isDisabled={isLoading}
                validations={{
                  isRequired: true,
                }}
                ADBSmode="AD"
                defaultValue={formDateStates.birthAD}
                onChange={(value) => handleSetDateBS("birthBS", value)}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="कार्ड न."
                id="cardNo"
                register={register}
                setValue={setValue}
                type="Number"
                language="english"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.cardNo}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="लिङ्ग"
                id="gender"
                register={register}
                setValue={setValue}
                type="Select"
                isDisabled={isLoading}
                errors={errors}
                clearErrors={clearErrors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.gender}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="रक्त समूह"
                id="bloodGroup"
                register={register}
                setValue={setValue}
                type="Select"
                isDisabled={isLoading}
                clearErrors={clearErrors}
                errors={errors}
                options={BloodGroup}
                defaultValue={defaultValues.bloodGroup}
              />
            </Col>
          </StyledFormRow>

          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="नागरिता न."
                id="citizenshipNo"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.citizenshipNo}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="जारी मिति (B.S.)"
                id="citizenshipIssuedDate"
                register={register}
                setValue={setValue}
                type="Date"
                errors={errors}
                clearErrors={clearErrors}
                isDisabled={isLoading}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.citizenshipIssuedDate}
              />
            </Col>
          </StyledFormRow>

          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="पति/पत्नीको नाम (In English)"
                id="spouse_en"
                language="english"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.spouse_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="पति/पत्नीको नाम ( नेपालीमा )"
                id="spouse_np"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.spouse_np}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="Father Name"
                id="fatherName_en"
                language="english"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.fatherName_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="बुवाको नाम"
                id="fatherName_np"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.fatherName_np}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="Mother Name"
                id="motherName_en"
                language="english"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.motherName_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="आमाको नाम"
                id="motherName_np"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.motherName_np}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2">
          <StyledLabel>१. बिस्तृत विवरण</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="प्रदेश"
                id="address.province"
                register={register}
                type="Province"
                defaultValue={province}
                setValue={setValue}
                onChange={handleSelectProvince}
                isDisabled={isLoading}
                errors={errors}
                clearErrors={clearErrors}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="जिल्ला"
                id="address.district"
                register={register}
                type="District"
                defaultValue={district}
                setValue={setValue}
                onChange={handleSelectDistrict}
                properties={{
                  province: province,
                }}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="स्थानीय तह"
                id="address.local"
                register={register}
                type="Palika"
                setValue={setValue}
                defaultValue={palika}
                onChange={handleSelectPalika}
                clearErrors={clearErrors}
                properties={{
                  province: province,
                  district: district,
                }}
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="वडा न."
                id="address.ward"
                register={register}
                type="Number"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                setValue={setValue}
                defaultValue={defaultValues.address?.ward}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="टोल को नाम"
                id="address.tole"
                register={register}
                setValue={setValue}
                type="Text"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.address?.tole}
              />
            </Col>
          </StyledFormRow>
        </fieldset>

        <fieldset className="mt-3 p-3 border border-2">
          <StyledLabel>३. संरक्षक को विवरण</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="पुरा नाम"
                id="guardian.name_np"
                register={register}
                setValue={setValue}
                type="Text"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.guardian?.name_np}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="संरक्षकको नाम(In English)"
                id="guardian.name_en"
                language="english"
                register={register}
                setValue={setValue}
                type="Text"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.guardian?.name_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="ठेगाना"
                id="guardian.address"
                register={register}
                setValue={setValue}
                type="Text"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.guardian?.address}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2">
          <StyledLabel>४.सम्पर्क व्यक्ति को विवरण</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="सम्पर्क गर्नुपर्नेको पुरा नाम"
                id="contactPerson.name_np"
                register={register}
                setValue={setValue}
                type="Text"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.contactPerson?.name_np}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="सम्पर्क गर्नुपर्नेको पुरा नाम (In English)"
                id="contactPerson.name_en"
                register={register}
                setValue={setValue}
                language="english"
                type="Text"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.contactPerson?.name_en}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="सम्पर्क न."
                id="contactPerson.phone"
                register={register}
                setValue={setValue}
                language="english"
                type="Number"
                maxLength="10"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.contactPerson?.phone}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="ठेगाना"
                id="contactPerson.address"
                register={register}
                setValue={setValue}
                type="Text"
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.contactPerson?.address}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2">
          <StyledLabel>५. कुनै प्रकारको रोग छ वा छैन ?</StyledLabel>
          <StyledFormRow className="mb-2">
            <Col sm="12" className="d-flex">
              <FormInput
                id="hasDisease"
                register={register}
                setValue={setValue}
                type="Radio"
                errors={errors}
                options={[
                  {
                    id: "छ",
                    label: "छ",
                    value: "true",
                  },
                  {
                    id: "छैन",
                    label: "छैन",
                    value: "false",
                  },
                ]}
                isDisabled={isLoading}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.hasDisease}
              />
            </Col>
            {hasDisease === "true" && (
              <Col sm="6">
                <FormInput
                  label="रोगको नाम"
                  id="disease"
                  language="english"
                  register={register}
                  setValue={setValue}
                  type="Text"
                  errors={errors}
                  isDisabled={isLoading}
                  validations={{
                    isRequired: true,
                  }}
                  defaultValue={defaultValues.disease}
                />
              </Col>
            )}
          </StyledFormRow>
        </fieldset>

        <fieldset className="mt-3 p-3 border border-2">
          <StyledLabel>६. हेरचाह केन्द्रमा बसेकोभए सो को विवरण</StyledLabel>
          <StyledFormRow>
            <Col lg={6}>
              <FormInput
                label="हेरचाह केन्द्रको विवरण नेपालीमा"
                id="careHomeDetail_np"
                language="nepali"
                register={register}
                setValue={setValue}
                type="Text"
                subType="textarea"
                errors={errors}
                defaultValue={defaultValues.careHomeDetail_np}
                isDisabled={isLoading}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                label="हेरचाह केन्द्रको विवरण (In English)"
                id="careHomeDetail_en"
                language="english"
                register={register}
                setValue={setValue}
                type="Text"
                subType="textarea"
                errors={errors}
                isDisabled={isLoading}
                defaultValue={defaultValues.careHomeDetail_en}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2">
          <StyledLabel>
            ७. कुनै प्रकार को औषधि सेवन गरिएको छ वा छैन?
          </StyledLabel>
          <StyledFormRow className="mb-2">
            <Col sm="12" className="d-flex">
              <FormInput
                id="onMedication"
                register={register}
                setValue={setValue}
                type="Radio"
                errors={errors}
                options={[
                  {
                    id: "onMedication-true",
                    label: "छ",
                    value: "true",
                  },
                  {
                    id: "onMedication-false",
                    label: "छैन",
                    value: "false",
                  },
                ]}
                defaultValue={defaultValues.onMedication}
              />
            </Col>
            {onMedication === "true" && (
              <Col sm="6">
                <FormInput
                  label="औषधिको नाम"
                  id="medication"
                  language="english"
                  register={register}
                  setValue={setValue}
                  type="Text"
                  errors={errors}
                  isDisabled={isLoading}
                  validations={{
                    isRequired: true,
                  }}
                  defaultValue={defaultValues.medication}
                />
              </Col>
            )}
          </StyledFormRow>
        </fieldset>

        {apiError && (
          <Row>
            <Alert variant="danger">{apiError}</Alert>
          </Row>
        )}

        <Row className="mt-4">
          <Col lg={6}>
            <Button variant="success" type="submit" disabled={isLoading}>
              {isLoading ? <VscLoading className="spin" /> : "पेश गर्नुहोस"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default OldAgeForm;
