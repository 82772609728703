import React, { Fragment } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const Support = () => {
	return (
		<Fragment>
			<Row>
				<Col md={6} className="mt-5">
					<h3 className="main-title mb-3">Welcome to our Support page</h3>
					<p className="text-muted">
						यहाँलाई Idea Breed द्वारा निर्मित परिचय पत्र ब्यबस्थापन प्रणालीको सपोर्ट पगेमा हार्दिक स्वागत छ। कृपया परिचय पत्र ब्यबस्थापन प्रणाली बारे बुझ्नको लागि तल दिएको फोन नं. वा इमेलमा मा सम्पर्क गर्नुहोस्।
					</p>
					<div className="mt-5">
						<Row>
							<Col md={6}>
								<Card>
									<Card.Body className="d-flex align-items-center">
										<FaPhone className="text-primary mr-3" />
										<p className="text-primary">9808424754</p>
									</Card.Body>
								</Card>
							</Col>
							<Col md={6}>
								<Card>
									<Card.Body className="d-flex align-items-center">
										<FaEnvelope className="text-danger mr-3" />
										<p className="text-danger">support@ideabreed.net</p>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</div>
				</Col>
				<Col md={6}>
					<img src="./assets/images/support.png" className="support-img" alt="support" />
				</Col>
			</Row>
		</Fragment>
	);
};

export default Support;
