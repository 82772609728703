import React, { useState } from "react";
import { useEffect } from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import { dynamicValidator } from "../../../../_helpers/dynamicValidator";
import InputField from "../../../InputField";
import SearchByNagritaModal from "./Modals/SearchByNagritaModal/SearchByNagritaModal";

function Name({
  language,
  register,
  errors = {},
  data,
  elementId,
  value,
  properties = {},
  setValue,
  popper = true,
  isPublic,
  entity,
}) {
  const [showModal, setShowModal] = useState(false);
  const [nameValue, setNameValue] = useState(value);
  const fNameValidation = dynamicValidator(properties?.fName, language);
  const mNameValidation = dynamicValidator(properties?.mName, language);
  const lNamevalidation = dynamicValidator(properties?.lName, language);

  useEffect(() => {
    setNameValue(value);
  }, [value]);

  if (isPublic && entity === "private") {
    return null;
  }

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleHideModal = () => {
    setShowModal(false);
  };

  return (
    <li className="vital-add-info vital-name py-2" key={nameValue}>
      <label htmlFor="">
        {data.label ? (
          data.label
        ) : (
          <React.Fragment>
            {language === "english" ? "नाम (अंग्रेजीमा)" : "नाम (नेपालीमा)"}
          </React.Fragment>
        )}
        <input id="memberId" name="memberId" type="hidden" value="" />
        <input id="memberDefCd" name="memberDefCd" type="hidden" value="" />
      </label>

      <InputField
        type="text"
        className={` ${properties?.fName?.isRequired ? "textbox_req" : ""} ${
          errors[elementId]?.firstName ? "is-invalid" : ""
        } `}
        language={language}
        classNameName={`input`}
        elementId={`${elementId}.firstName`}
        placeholder={language === "english" ? "First Name" : "अगाडिको नाम"}
        maxLength={50}
        register={register}
        isRequired={properties?.fName?.isRequired}
        value={nameValue?.firstName}
        style={{ width: "125px" }}
        properties={properties}
        validation={fNameValidation}
        setValue={setValue}
      />

      <InputField
        language={language}
        className={`${properties?.mName?.isRequired ? "textbox_req" : ""} ${
          errors[elementId]?.middleName ? "is-invalid" : ""
        } `}
        onkeypress="return setUnicode(event,this);"
        placeholder={language === "english" ? "Middle Name" : "बिचको नाम"}
        style={{ width: "100px" }}
        type="text"
        elementId={`${elementId}.middleName`}
        value={nameValue?.middleName}
        register={register}
        setValue={setValue}
        properties={properties}
        validation={mNameValidation}
        isRequired={properties?.mName?.isRequired}
      />

      <InputField
        language={language}
        className={`jumper ${
          properties?.lName?.isRequired ? "textbox_req" : ""
        } ${errors[elementId]?.lastName ? "is-invalid" : ""} `}
        jumpto="firstNameEng"
        isRequired={properties?.lName?.isRequired}
        placeholder={language === "english" ? "Last Name" : "थर"}
        style={{ width: "125px" }}
        type="text"
        elementId={`${elementId}.lastName`}
        value={nameValue?.lastName}
        register={register}
        setValue={setValue}
        properties={properties}
        validation={lNamevalidation}
      />

      {popper && (
        <span>
          <FaChevronCircleRight fill="#999999" onClick={handleShowModal} />
        </span>
      )}

      {showModal && (
        <SearchByNagritaModal
          show={showModal}
          handleClose={handleHideModal}
          setFormValue={setValue}
          language={language}
          nameElementId={elementId}
          setNameValue={setNameValue}
        />
      )}
    </li>
  );
}

export default Name;
