import React from "react";
import { Button, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { logValidationSchema } from "../../_helpers/schema-validation";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(logValidationSchema),
  });
  const onSubmit = (data) => {
    // SignUp api
  };

  return (
    <section className="section">
      <Container>
        <Row className="mt-5">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="SignUp-brand">
              <img
                src="/assets/images/nep_logo.png"
                alt="logo"
                width="100"
                className="shadow-light rounded-circle"
              />
            </div>
            <div className="card card-primary">
              <div className="card-header">
                <h4>लगइन</h4>
              </div>
              <div className="card-body">
                <form
                  method="POST"
                  action="#"
                  className="needs-validation"
                  novalidate=""
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-group">
                    <label for="email">ईमेल</label>
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      name="email"
                      tabindex="1"
                      required
                      autofocus
                      {...register("email")}
                    />
                    <div className="invalid-feedback">
                      कृपया तपाईंको ईमेल भर्नुहोस्
                    </div>
                    <p className="text-small text-danger">
                      {errors.email?.message}
                    </p>
                  </div>

                  <div className="form-group">
                    <div className="d-block">
                      <label for="password" className="control-label">
                        पासवर्ड
                      </label>
                      <div className="float-right">
                        <a href="forgot-password.html" className="text-small">
                          पासवर्ड भुल्नु भयो?
                        </a>
                      </div>
                    </div>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      name="password"
                      tabindex="2"
                      required
                      {...register("password")}
                    />
                    <div className="invalid-feedback">
                      कृपया तपाईको पासवर्ड भर्नुहोस्
                    </div>
                    <p className="text-small text-danger">
                      {errors.password?.message}
                    </p>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="remember"
                        className="custom-control-input"
                        tabindex="3"
                        id="remember-me"
                      />
                      <label
                        className="custom-control-label text-muted"
                        for="remember-me"
                      >
                        मलाई सम्झनुहोस्
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <Button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                    >
                      लगइन
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="simple-footer">
              Copyright &copy; {new Date().getFullYear()} Ideabreed IT Solution
              Pvt. Ltd.
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default SignUp;
