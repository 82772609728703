/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { DisabilityTypesObject } from '../../../_datas/bloodGroup';
import { nepaliNumberConverter } from '../../../_helpers/methods';
import { Item, PreviewDetailContainer } from "../styles/StyledPreview";

import { FINGER_PRINT_DATA } from '../../../constant';

/**
 * 
 */
export default function DisabledPreviewDetail({ userProfile, fingerPrints }) {
    const disablityTypeObj = DisabilityTypesObject[userProfile.disabilityType.trim()];
    const hasFather = userProfile.cardDetails.isFatherGurdian;
    const hasMother = userProfile.cardDetails.isMotherGurdain;

    return (
        <PreviewDetailContainer>
            {/* 1st row */}
            <Item className="left"
                label="अपाङ्गता परिचयपत्र नं"
                value={userProfile.cardDetails.cardNo}
            />
            <Item className="center" label="परिचय पत्रको प्रकार"
                value={`${disablityTypeObj?.code_np} वर्ग`}
            />
            <Item className="photo">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div css={css`
                        width: 130px;
                        height: 150px;
                        border: 1px solid black;
                        margin-bottom: 8px;
                        border-radius: 14px;
                        overflow: hidden;
                    `}>

                        <img src={userProfile?.photo} alt={userProfile.name_en} css={css`
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    `} />
                    </div>
                </div>
            </Item>
            {/* End of 1st Row */}

            {/* 2nd Row */}
            <Item className="left"
                label="नाम, थर"
                value={userProfile.name_np}
            />
            <Item className="center"
                label="जन्म मिति"
                value={nepaliNumberConverter(userProfile.dateOfBirth_np)}
            />
            {/* End of 2nd Row */}

            {/* 3rd Row */}
            <Item className="left"
                label="लिङ्ग"
                value={userProfile.gender}
            />
            <Item className="center"
                label="रक्त समूह"
                value={userProfile.bloodGroup}
            />
            {/* End of 3rd Row */}

            {/* 4th Row */}
            <Item className="left"
                label="ठेगाना(स्थाई)"
                value={userProfile.parmanentAddress?.local}
            />
            <Item className="center"
                label="ठेगाना(अस्थाई)"
                value={userProfile.temporaryAddress?.local}
            />
            {/* End of 4th Row */}

            {/* 5th Row */}
            <Item className="left"
                label="नागरिकता नं"
                value={userProfile?.identityDetails?.citizenshipNo}
            />
            <Item className="center"
                label="नागरिकता पाएको मिति"
                value={userProfile?.identityDetails?.citizenshipIssuedDate_np}
            />
            <Item className="right"
                label="जातीयता"
                value={userProfile.ethinicity}
            />
            {/* End of 5th Row */}

            {/* 6th Row */}
            <Item className="left"
                label={`${hasFather ? "बाबु" : hasMother ? "आमा" : "संरक्षक"}को नाम थर`}
                value={hasFather ? userProfile.cardDetails?.fatherName_np : hasMother ? userProfile.cardDetails?.motherName_np : userProfile.guardian?.name_np}
            />
            <Item className="center"
                label="सम्पर्क नं."
                value={userProfile?.guardian?.phone}
            />

            {
                !hasFather && !hasMother ?
                    <Item className="right"
                        label="अपाङ्गता भएको व्यक्तिसंग नाता"
                        value={userProfile?.guardian?.relation}
                    />
                    :
                    <Item className="right" isEmpty={true} />
            }
            {/* End of 6th Row */}

            {/* 7th Row */}
            <Item className="left"
                label={`पछिल्लो सैक्षिक योग्यता`}
                value={userProfile.educationQualification}
            />
            <Item className="center"
                label="कुनै तालिम प्राप्त गरेको भए मुख्य तालिमको नाम"
                value={userProfile.mainQualification}
            />
            <Item className="right"
                label="पेशा"
                value={userProfile.occupation}
            />
            {/* End of 7th Row */}

            {/* 8th Row */}
            <Item className="left"
                label={`सम्पर्क नं.`}
                value={userProfile.parmanentAddress?.phone || userProfile.temporaryAddress?.phone}
            />
            <Item className="center"
                label="जन्म दर्ता नं."
                value={userProfile.identityDetails?.birthCertificate}
            />
            <Item className="right"
                label="जन्म दर्ता पाएको मिति"
                value={nepaliNumberConverter(userProfile.identityDetails?.birthCertificateIssuedDate_bs)}
            />
            {/* End of 8th Row */}

            <Item className="title left-right" title="अपाङ्गताको किसिम" />

            {/* 9th Row */}
            <Item className="left"
                label={`क) अपाङ्गताको प्रकृतिको आधारमा`}
                value={userProfile.organDisabilityType}
            />
            <Item className="center"
                label="ख) अपाङ्गताको गम्भीरताको आधारमा"
                value={userProfile.disabilityType}
            />
            <Item className="right"
                label={`अपाङ्गताको कारण`}
                value={userProfile.reasonOfDisability}
            />
            {/* End of 9th Row */}

            {/* 10th Row */}
            <Item className="left"
                label="सहयोग सामाग्री प्रयोग गर्नुपर्ने आबश्यकता"
                value={userProfile.useHelpDevice ? "भएको" : "नभएको"}
            />
            <Item className={'center-right'} isEmpty={!userProfile.helpMaterial?.required} label={'सामाग्री विवरण'} value={userProfile.helpMaterial?.details} />
            {/* End of 10th Row */}

            {/* 11th Row */}
            <Item className="left-right"
                label="अन्य व्यक्तिको सहयोग लिनु पर्ने भए त्यस्तो सहयोग लिनु पर्ने काम"
                value={userProfile.needHelpWith?.join(', ')}
            />
            {/* End of 11th Row */}

            {/* 12th Row */}
            <Item className={'left-right'} label='अन्य व्यक्तिको सहयोग बिना गर्न सक्ने दैनिक कार्य' value={userProfile.doesnotNeedHelpWith?.join(', ')} />
            {/* End of 12th Row */}

            {/* 13th Row */}
            <Item className={'left'} label='साहायक सामाग्री प्रयोग गर्ने' value={userProfile.useHelpDevice ? 'गरेको' : 'नगरेको'} />

            <Item className={'center-right'} label='साहायक सामाग्रीको नाम' value={userProfile.helpDeviceName} isEmpty={!userProfile.useHelpDevice} />
            {/* End of 13th Row */}


            {/* 14th Row */}
            {
                process.env.REACT_APP_HAS_FINGERPRINT_FEATURE &&
                <Item className={'left-right'}>
                    {
                        fingerPrints ?
                            <div className="d-flex justify-content-around align-items-center">
                                {
                                    fingerPrints?.map(finger => {
                                        return (
                                            <div className='text-center' key={finger.fingerNo}>
                                                <div
                                                    css={css`
                                width: 130px;
                                height: 150px;
                                border: 1px solid black;
                                margin-bottom: 8px;
                                border-radius: 14px;
                                overflow: hidden;
                                text-align: center;
                            `}
                                                >
                                                    {finger.image ?
                                                        <img src={finger.image} alt="finger" css={css`width: 120px;height:120px; object-fit: contain;`} />
                                                        :
                                                        <p className="mt-4">Not found</p>
                                                    }
                                                </div>
                                                <p>
                                                    {FINGER_PRINT_DATA[finger.fingerNo]}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='text-left'>
                                <h5 className='font-weight-bold'>
                                    औंलाको छाप उपलब्ध छैन।
                                </h5>
                            </div>
                    }
                </Item>
            }
            {/* End of 14th Row */}

            {/* 15th Row */}
            <Item className={'left-center'} label='प्रमाणित गर्नेको पुरा नाम' value={userProfile.cardDetails.issuedBy_np} />

            <Item className={'right'} label='प्रमाणित गर्नेको पद' value={userProfile.cardDetails.issuedDesignation_np} />
            {/* End of 15th Row */}
        </PreviewDetailContainer>
    )
}