import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const AddFormalMunicipal = ({ showAdd, setShowAdd }) => {
  return (
    <Form>
      <Card>
        <Card.Header className="card-header-custom">
          <h3>नयाँ आवश्यक कागजात</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">शीर्षक</Form.Label>
                <Form.Control type="text" placeholder="Enter title" />
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">वडाको संख्या</Form.Label>
                <Form.Control type="email" placeholder="Enter type" />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button
            variant="secondary"
            className="mr-2"
            onClick={() => setShowAdd(!showAdd)}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button
            type="submit"
            variant="success"
            onClick={() => setShowAdd(!showAdd)}
          >
            पेश गर्नुहोस्
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default AddFormalMunicipal;
