"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.getSeparatedDateFromMilliseconds =
  exports.getSeparatedDateFromBsDate =
  exports.getDateFromMilliseconds =
  exports.getDateMilliseconds =
  exports.replaceWithNepaliDigit =
    void 0;

var _AdBsConverter = require("../AdToBsConverter/AdBsConverter");

var replaceWithNepaliDigit = function replaceWithNepaliDigit(number) {
  var numbers = {
    0: "०",
    1: "१",
    2: "२",
    3: "३",
    4: "४",
    5: "५",
    6: "६",
    7: "७",
    8: "८",
    9: "९",
  };
  var arrNumNepali = number
    .toString()
    .split("")
    .map(function (_char) {
      if (isNaN(parseInt(_char))) {
        return _char;
      }

      return numbers[Number(_char)];
    });
  return arrNumNepali.join("");
};

exports.replaceWithNepaliDigit = replaceWithNepaliDigit;

var getDateMilliseconds = function getDateMilliseconds(date, dateType) {
  dateType = dateType || "BS";

  if (dateType === "BS") {
    date = (0, _AdBsConverter.BSToAD)(date);
  }

  return new Date(date).getTime();
};

exports.getDateMilliseconds = getDateMilliseconds;

var getDateFromMilliseconds = function getDateFromMilliseconds(
  dateInMills,
  dateType,
  showTime
) {
  showTime = showTime || false;
  dateType = dateType || "BS"; // initialize new Date object

  var dateObject = new Date(dateInMills); // year as 4 digits (YYYY)

  var year = dateObject.getFullYear(); // month as 2 digits (MM)

  var month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // date as 2 digits (DD)

  var date = ("0" + dateObject.getDate()).slice(-2);
  var yyMmDd = "".concat(year, "/").concat(month, "/").concat(date);

  if (dateType !== "AD") {
    yyMmDd = (0, _AdBsConverter.ADToBS)(yyMmDd);
  }

  return "".concat(yyMmDd);
};

exports.getDateFromMilliseconds = getDateFromMilliseconds;

var getSeparatedDateFromBsDate = function getSeparatedDateFromBsDate(BsDate) {
  var splittedDate = ["/", "/", "/"];

  if (BsDate) {
    const TempBsDate = BsDate.replaceAll("-", "/");
    splittedDate = TempBsDate.split("/");
  }

  return {
    day: splittedDate[2],
    month: splittedDate[1],
    year: splittedDate[0],
  };
};

exports.getSeparatedDateFromBsDate = getSeparatedDateFromBsDate;

var getSeparatedDateFromMilliseconds =
  function getSeparatedDateFromMilliseconds(dateInMills) {
    var splittedDate = ["/", "/", "/"];

    if (dateInMills) {
      splittedDate = getDateFromMilliseconds(dateInMills).split("/");
    }

    return {
      day: splittedDate[2],
      month: splittedDate[1],
      year: splittedDate[0],
    };
  };

exports.getSeparatedDateFromMilliseconds = getSeparatedDateFromMilliseconds;
