/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
// eslint-disable-next-line
import React, { Fragment, useEffect, useState, useRef } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { IoMdCloseCircle } from "react-icons/io";
import { AiOutlineFile } from "react-icons/ai";

import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { fetchWrapper } from "../_helpers/fetchWrapper";
import { VscLoading } from "react-icons/vsc";
import { toast } from "react-toastify";
import PreviewModel from "../components/Form/PreviewModel";
import { generatePreview } from "../_helpers/methods";
import Elements from "../components/Form/Elements";
import { languageSelector } from "../_redux/slices/languages";
import { incrementPaperCountHandler } from "../_redux/slices/paper";
import BackButton from "../_elements/BackButton";
import { FaTimes } from "react-icons/fa";
import InputFields from "../components/Form/Inputs";
import InputField from "../components/InputField";

const CitizenTemplateSingle = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [previewFormData, setPreviewFormData] = useState();
  const [showApproveToast, setApproveToast] = useState(false);
  const printRef = useRef();
  const { language } = useSelector(languageSelector);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current;
    },
    onAfterPrint: () => {
      history.push("/verify/1");
      try {
        //Need to detect if the print is cancelled, if cancelled do not increment count
        dispatch(incrementPaperCountHandler(id));
      } catch (err) {}
    },
  });
  const [remarks, setRemarks] = useState("");
  const [showReject, setShowReject] = useState(false);
  const [paper, setPaper] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getPaper = async () => {
      try {
        setIsLoading(true);
        let response = await fetchWrapper.get(`/paper/${id}/`);
        setPaper(response.data);
        let templateRes = await fetchWrapper.get(
          `/template/${response.data.template}/`
        );
        setData(templateRes.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getPaper();
  }, [id]);

  // const [approved, setApproved] = useState(false);

  const handlePaperStatus = async (status, remarks) => {
    try {
      let body = {
        paper_id: id,
        paper_status: status,
      };
      if (remarks) {
        body.remarks = remarks;
      }
      const response = await fetchWrapper.post(`/approve-reject-paper/`, body);
      setPaper(response.data);
      // toast.success('Paper status updated successfully!');

      if (status === "Approved") {
        // handlePreview();
        toast.success("आवेदन सम्पादन पृष्ठमा प्रवेश गरियो |");
        // setApproved(true);
        history.push(`/paper-single/${id}/citizen`);
      } else {
        toast.success("Paper status updated successfully!");
        history.push("/verify/1");
      }
    } catch (err) {
      toast.error("Something went wrong, Please try again letter");
    }
  };

  const valueParser = (val, id) => {
    let value = "";
    if (typeof val === "object") {
      if (id.startsWith("date")) {
        return `${val.year}/${val.month}/${val.day}`;
      }
      if (id.startsWith("fullname")) {
        return `${val.firstName} ${val.middleName} ${val.lastName}`;
      } else {
        Object.keys(val).forEach((key, index) => {
          let curVal = val[key];
          curVal += " ";
          value += curVal;
        });
        return value;
      }
    }
    return val;
  };

  const handleClose = () => {
    setShowPreview(false);
    // setApproved(false);
  };

  const handlePreview = () => {
    setPreviewFormData(paper.form_values);
    if (data?.preview) {
      const tempPreviewData = generatePreview(data, paper.form_values, user);
      setPreviewData(tempPreviewData);
    }
    setShowPreview(true);
  };

  const tableRenderer = (formData) => {
    if (formData?.length > 0) {
      let tableData =
        '<div class="content-container"><table class="table table-bordered" id="table_resize" border="0" cellpadding = "0" cellspacing="0"><tr>';
      const headings = Object.keys(formData[0]);
      const headingData = headings
        .sort()
        .map((heading, headingIndex) =>
          headingIndex === 0
            ? `<th key=${headingIndex} style="width:60px;">${heading
                .split("___")[1]
                .split("####")
                .join(".")
                .split("&&&&")
                .join("'")}</th>`
            : `<th key=${headingIndex}>${heading
                .split("___")[1]
                .split("####")
                .join(".")
                .split("&&&&")
                .join("'")}</th>`
        )
        .join("");
      tableData += headingData;
      const rows = formData.map(
        (rowData) =>
          `<tr>${headings
            .map((heading, headingIndex) =>
              headingIndex === 0
                ? `<td key=${headingIndex} style="max-width:25px;width:25px;">${rowData[heading]}</td>`
                : `<td key=${headingIndex}>${rowData[heading]}</td>`
            )
            .join("")}</tr>`
      );
      tableData += `</tr>${rows.join("")}</table></div>`;
      return tableData;
    }
    return null;
  };

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  async function download(e) {
    e.preventDefault();
    const a = document.createElement("a");
    a.href = await toDataURL(e.target.href);
    a.download = "document" + id;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Fragment>
      {isLoading ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80vh;
          `}
        >
          <VscLoading className="spin" fontSize="48" />
        </div>
      ) : (
        <Fragment>
          <Row>
            <Col md={5} className="d-flex align-items-center">
              <BackButton />
            </Col>
            <Col className="d-flex align-items-start justify-content-end">
              {paper?.paper_status === "Approved" ? (
                <>
                  <Button
                    variant="primary"
                    onClick={() => {
                      handlePreview();
                      setShowPreview(true);
                    }}
                  >
                    Preview and Print
                  </Button>
                  {showPreview && (
                    <PreviewModel
                      handleClose={handleClose}
                      show={showPreview}
                      innerRef={printRef}
                      paper={
                        <div className="page">
                          <div className="content">
                            <Elements
                              language={language}
                              elements={data.values}
                            />
                          </div>
                        </div>
                      }
                      paperExtra={paper?.extra}
                      previewFormData={previewFormData}
                      previewData={previewData}
                      documents={paper?.documents}
                      handlePrint={handlePrint}
                      handleTemplateSave={() => {
                        return true;
                      }}
                      config={data.config}
                      meta={data.meta}
                      isEnglish={data.category_title === "English Format"}
                      isCitizenTemplate={true}
                      paperId={id}
                      isApproved={true}
                      issueId={paper?.issue_id}
                    />
                  )}
                </>
              ) : (
                <Row>
                  {paper?.paper_status === "Rejected" ? (
                    <div className="badge badge-danger px-4 py-2">Rejected</div>
                  ) : (
                    <Col>
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="danger"
                          className="mr-2"
                          onClick={() => setShowReject(true)}
                        >
                          Reject
                        </Button>

                        <Button
                          variant="info"
                          className="mr-1"
                          onClick={() => setApproveToast(true)}
                        >
                          स्वीकृत गर्नुहोस
                        </Button>

                        {showApproveToast && (
                          <Modal
                            show={showApproveToast}
                            onHide={() => setApproveToast(false)}
                            centered
                          >
                            <Modal.Header>
                              <div className="d-flex justify-content-end w-100">
                                <FaTimes
                                  className="pointer"
                                  onClick={() => setApproveToast(false)}
                                />
                              </div>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="my-4">
                                <h2 className="text-center">
                                  आवेदन स्वीकृत गर्न चाहनुहुन्छ ?
                                </h2>
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <div className="d-flex align-items-center justify-content-end w-100">
                                <Button
                                  variant="secondary"
                                  onClick={() => setApproveToast(false)}
                                >
                                  पछाडी जानुहोस
                                </Button>
                                <Button
                                  className="ml-1"
                                  variant="info"
                                  onClick={() => handlePaperStatus("Approved")}
                                >
                                  हुन्छ
                                </Button>
                              </div>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </div>
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={6}>
              <Card>
                <Card.Header className="card-header-custom">
                  <h2>{paper?.extra?.paper_title}</h2>
                  {/* <Button onClick={handlePreview}>Preview</Button> */}
                </Card.Header>
                <Card.Body>
                  <div className="scroll">
                    {paper &&
                      (data?.values ? (
                        <Fragment>
                          {data.values.map((formGroup) => {
                            return (
                              <div key={formGroup.title} className="my-4">
                                {formGroup.title && (
                                  <h4 className="mb-1">{formGroup.title}</h4>
                                )}
                                <ListGroup className="w-100">
                                  {formGroup.row.map((elements) => {
                                    return elements.map((element) => {
                                      let id = element.id;
                                      if (id === "fullname_np") {
                                        id = "नाम (नेपालीमा)";
                                      }
                                      if (id === "fullname_en") {
                                        id = "नाम (अंग्रेजीमा)";
                                      }
                                      if (id === "table") {
                                        return (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: tableRenderer(
                                                paper.form_values[id]
                                              ),
                                            }}
                                          ></span>
                                        );
                                      }
                                      return (
                                        <ListGroup.Item>
                                          {element.label || id} :{" "}
                                          {valueParser(
                                            paper.form_values[element.id],
                                            element.id
                                          )}
                                        </ListGroup.Item>
                                      );
                                    });
                                  })}
                                </ListGroup>
                              </div>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <div>Corrupted</div>
                      ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Header className="card-header-custom">
                  <h2>{language === "nepali" ? "कागजात" : "Documents"}</h2>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    {paper?.documents?.length > 0 ? (
                      paper?.documents?.map((document) => {
                        return (
                          <div
                            key={document.id}
                            className="img-wrapper d-flex flex-column align-items-center"
                            css={css`
                              padding: 0.2rem;
                              border: 2px solid #dfdfdf;
                              border-radius: 5px;
                              cursor: pointer;
                              box-shadow: 2px 2px 5px 0px #b1b1b1;
                              transition: box-shadow 100ms;
                              :hover {
                                box-shadow: 0px 0px 0px 0px #b1b1b1;
                              }
                            `}
                            onClick={() => setShow(document.file)}
                          >
                            <div
                              css={css`
                                width: 100%;
                                height: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: #eee;
                              `}
                            >
                              <AiOutlineFile fontSize="28" />
                            </div>
                            <p
                              className="text-muted"
                              css={css`
                                font-size: 1rem !important;
                              `}
                            >
                              {document.title}
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-faded">No documents </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
              {show && (
                <Card>
                  <Card.Body>
                    <div className="d-flex justify-content-between">
                      <a
                        className="btn btn-secondary"
                        onClick={(e) => download(e)}
                        href={show}
                        download
                      >
                        Download
                      </a>
                      <Button variant="secondary" onClick={() => setShow(null)}>
                        <IoMdCloseCircle fontSize="24" />
                      </Button>
                    </div>
                    <div key={show}>
                      <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={[{ uri: show }]}
                      />
                    </div>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>

          <Modal show={showReject} onHide={() => setShowReject(false)}>
            <Modal.Header closeButton>
              <Modal.Title>परिचय पत्र अस्विकृत गर्न चाहनुहुन्छ ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label className="w-100">
                    परिचय पत्र अस्विकृत गर्नुको विवरण लेख्नुहोस
                  </Form.Label>
                  <InputField
                    type="textarea"
                    value={remarks}
                    language={language}
                    getInputData={(data) => {
                      setRemarks(data);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowReject(false)}>
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  handlePaperStatus("Rejected", remarks);
                  setShowReject(false);
                }}
              >
                Send
              </Button>
            </Modal.Footer>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default connect(null, {})(CitizenTemplateSingle);
