/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Elements from "./index";

function Partition({ data, language }) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-areas: ". . .";
        grid-gap: 10px;
      `}
    >
      {data.parts.map((part) => (
        <Elements language={language} elements={[part]} />
      ))}
    </div>
  );
}

export default Partition;
