/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Link } from "react-router-dom";
import { Row, Container } from "react-bootstrap";

import CitizensHeader from "./CitizensHeader";
import { useSelector } from "react-redux";
import { languageSelector } from "../../_redux/slices/languages";
import InputField from "../../components/InputField";

//icons
import { AiOutlineSearch } from "react-icons/ai";
import TemplateCard from "../../components/TemplateCard/TemplateCard";

const Box = ({ title, icon = null, number }) => {
  return (
    <div className="dashboard-stats-box">
      <div className="dashboard-stats-box-content">
        <h5>{title}</h5>
      </div>
      <h5 className="dashboard-number">
        <kbd className="red-shade">{number}</kbd>
      </h5>
      <span>
        <div className="dashboard-stats-box-icon">{icon}</div>
      </span>
    </div>
  );
};

const CitizensHome = () => {
  const { language } = useSelector(languageSelector);

  return (
    <div className="main-wrapper">
      <div className="content-wrapper">
        <CitizensHeader />
        <Container fluid>
          <div className="main-content">
            <div>
              <Link to="/login" className="btn btn-primary">
                {language === "english" ? "Back" : "पछाडी जानुहोस"}
              </Link>
            </div>
            <div
              css={css`
                padding-top: 1rem;
                text-align: right;
                .search_input-div {
                  position: relative;
                  .search-icon {
                    position: absolute;
                    font-size: 26px;
                    color: rgb(102, 119, 239);
                    top: 4px;
                    right: 8px;
                  }
                }
                .search_input {
                  padding: 0.5rem;
                  width: 20rem;
                  border-radius: 15px;
                }
              `}
            >
              <div className="search_input-div">
                <InputField
                  language={language}
                  className={`search_input`}
                  placeHolder="परिचय पत्रको नाम"
                  getInputData={(value) => {
                    console.log(value);
                  }}
                />
                <AiOutlineSearch className="search-icon" />
              </div>
            </div>
            <div>
              <h5 className="main-title mt-3">परिचय पत्र</h5>

              <Row className="mt-3">
                <TemplateCard
                  templateUrl="/citizen-template/"
                  title="अपाङ्गता परिचय पत्र"
                  id={'disabled'}
                  idx={0}
                />
                <TemplateCard
                  templateUrl="/citizen-template/"
                  title="जेष्ठ नागरिक परिचय पत्र"
                  id={'elderly'}
                  idx={1}
                />
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
export { Box };
export default CitizensHome;
