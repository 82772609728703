/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import {
	FaAddressCard,
	FaEdit,
	FaPlusCircle,
	FaTrashAlt,
} from 'react-icons/fa';
import { Link, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { VscLoading } from 'react-icons/vsc';
import PaginationComp from '../../../components/Pagination';
import { fetchSakhas } from '../../../_redux/slices/sakha';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import SakhaForm from './Sakha/SakhaForm';
import BackButton from '../../../_elements/BackButton';

const Sakhas = ({ fetchSakhas, sakhas, count }) => {
	const [showAdd, setShowAdd] = useState(false);
	const [deleteSakha, setDeleteSakha] = useState(false);
	const { pageNum } = useParams();
	const [page, setPage] = useState(pageNum);
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	const handleSetPage = (page) => {
		setPage(page);
		history.push(`${page}`);
	};
	useEffect(() => {
		const asyncFetchSakha = async () => {
			setIsLoading(true);
			await fetchSakhas(page);
			setIsLoading(false);
		};
		asyncFetchSakha();
	}, [fetchSakhas, page]);

	const handelSakhaDelete = async (sakhaId) => {
		let confirmation = window.confirm(
			'Are you sure you want to delete this Sakha ?'
		);
		if (confirmation) {
			setDeleteSakha(sakhaId);
			await fetchWrapper.delete(`/sakha/${sakhaId}/`);
			fetchSakhas(page);
			setDeleteSakha(false);
		}
	};
	
	return (
		<Fragment>
			{showAdd && (
				<Row className="mt-2">
					<Col md={12}>
						<SakhaForm showAdd={showAdd} setShowAdd={setShowAdd} />
					</Col>
				</Row>
			)}
			<Row className="mt-2">
				<Col>
					<BackButton />
				</Col>
				<Col md={12}>
					<Card
						css={css`
							border-radius: 8px;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
						`}
					>
						<Card.Header className="card-header-custom">
							<h2>शाखा कार्यालयहरु</h2>
							<Link
								to="/settings/sakha/form"
								className="btn btn-primary"
								variant="primary"
							>
								<FaPlusCircle />
								&nbsp; नयाँ शाखा कार्यालय
							</Link>
						</Card.Header>
					</Card>
				</Col>
			</Row>
			<Row
				md={12}
				css={css`
					min-height: 400px;
				`}
			>
				{isLoading ? (
					<div
						css={css`
							display: flex;
							justify-content: center;
							align-items: center;
							width: 100%;
						`}
					>
						<VscLoading className="spin" fontSize={48} />
					</div>
				) : (
					<Fragment>
						{sakhas.map((sakha) => (
							<Col md={3}>
								<div className="icon-container text-center">
									{deleteSakha === sakha.id && (
										<div
											css={css`
												display: flex;
												align-items: center;
												justify-content: center;
												width: 100%;
												height: 100%;
												position: absolute;
												top: 0;
												left: 0;
												background: rgba(255, 255, 255, 0.5);
											`}
										>
											<VscLoading className="spin" />
										</div>
									)}
									{/* <span className="settings-badge position-absolute">1</span> */}
									<div className="d-flex justify-content-between align-items-center">
										<div className="icon">
											<Link to={`/settings/sakhausers/${sakha.id}`}>
												<FaAddressCard size={48} />
												<h6 className="card-text">
													{sakha.sakha_np || sakha.sakha_en}
												</h6>
											</Link>
										</div>
										<div>
											<p>{sakha.phone_np || sakha.phone_en}</p>
											<Fragment>
												<Link to={`/settings/sakha/update/${sakha.id}`}>
													<FaEdit className="text-info mr-2" />
												</Link>
												<FaTrashAlt
													className="text-danger"
													css={css`
															cursor: pointer;
														`}
													onClick={() => handelSakhaDelete(sakha.id)}
												/>
											</Fragment>
										</div>
									</div>
								</div>
							</Col>
						))}
					</Fragment>
				)}
			</Row>
			<PaginationComp page={page} total={count} setPage={handleSetPage} />
		</Fragment>
	);
};
const mapStateToProps = (state) => ({
	sakhas: state.sakhas.sakhas,
	count: state.sakhas.count,
});

const mapDispatchToProps = {
	fetchSakhas,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sakhas);
