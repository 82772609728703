import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { setFormTemplate } from "../../_redux/slices/formTemplates";
import { clearForm } from "../../_redux/slices/paper";
import CitizensHeader from "./CitizensHeader";
import BackButton from "../../_elements/BackButton";
import DisabledForm from "../Disabled/DisabledForm";
import OldAgeForm from "../OldAge/OldAgeForm";

const CitizensTemplate = ({ setFormTemplate, clearForm }) => {
  const { id } = useParams();
  let form = null;

  if (id === "disabled") {
    form = <DisabledForm />
  }
  else if (id === "elderly") {
    form = <OldAgeForm />
  }

  useEffect(() => {
    clearForm();
  }, [clearForm]);
  return (
    <div>
      <CitizensHeader />
      <div className="main-content">
        <BackButton />

        <div>
          <div className="py-4">
            {form}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { setFormTemplate, clearForm })(CitizensTemplate);
