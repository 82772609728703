import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

export const initialState = {
  dashboardStats: {},
  administratorStats: {},
  wardStats: {},
  sakhaStats: {},
  fiscalYearStats: {},
};

// A slice
const appStatsSlice = createSlice({
  name: "appStats",
  initialState,
  reducers: {
    setAppStats: (state, action) => {
      state.dashboardStats = {
        disabled: action.payload.disabled,
        elderly: action.payload.elderly,
      };
      state.wardStats = action.payload.ward;
      state.sakhaStats = action.payload.sakha;
      state.fiscalYearStats = action.payload.fiscalYear;
      state.administratorStats = action.payload.administrator;
    },

    incrementAdministratorStats: (state, action) => {
      let tempStat = state.administratorStats;
      tempStat.count++;
      state.administratorStats = tempStat;
    },
    decrementAdministratorStats: (state, action) => {
      let tempStat = state.administratorStats;
      tempStat.count--;
      state.administratorStats = tempStat;
    },

    incrementWardStats: (state, action) => {
      let tempStat = state.wardStats;
      tempStat.count++;
      state.wardStats = tempStat;
    },
    decrementWardStats: (state, action) => {
      let tempStat = state.wardStats;
      tempStat.count--;
      state.wardStats = tempStat;
    },

    incrementFiscalYearStats: (state, action) => {
      let tempStat = state.fiscalYearStats;
      tempStat.count++;
      state.fiscalYearStats = tempStat;
    },
    decrementFiscalYearStats: (state, action) => {
      let tempStat = state.fiscalYearStats;
      tempStat.count--;
      state.fiscalYearStats = tempStat;
    },
  },
});

// Actions generated from the slice
const {
  setAppStats,
  incrementAdministratorStats,
  incrementFiscalYearStats,
  incrementWardStats,
  decrementAdministratorStats,
  decrementFiscalYearStats,
  decrementWardStats,
} = appStatsSlice.actions;

// export The reducer
const appStatsReducer = appStatsSlice.reducer;

export default appStatsReducer;

// Actions
export const fetchAppStats = (filter) => async (dispatch) => {
  try {
    let response = await fetchWrapper.get(`/stats/${filter ?? ""}`);
    dispatch(setAppStats(response.data));
  } catch (error) {
    console.log(error, "error");
  }
};

export const handleIncrementAdministratorStats = () => (dispatch) => {
  dispatch(incrementAdministratorStats());
};
export const handleDecrementAdministratorStats = () => (dispatch) => {
  dispatch(decrementAdministratorStats());
};

export const handleIncrementWardStats = () => (dispatch) => {
  dispatch(incrementWardStats());
};
export const handleDecrementWardStats = () => (dispatch) => {
  dispatch(decrementWardStats());
};

export const handleIncrementFiscalYearStats = () => (dispatch) => {
  dispatch(incrementFiscalYearStats());
};
export const handleDecrementFiscalYearStats = () => (dispatch) => {
  dispatch(decrementFiscalYearStats());
};
