import { fetchWrapper } from "../../_helpers/fetchWrapper";

const createDisabledProfile = async (body, options = {}) => {
    const { isPublic } = options;

    let url = `/disabledProfile`;
    if (isPublic) {
        url += '/public';
    }
    const response = await fetchWrapper.post(url, body);
    return response.data;
}

const updateDisabledProfile = async (body, id) => {
    const response = await fetchWrapper.patch(`/disabledProfile/${id}`, body);
    return response.data;
}


const getDisabledProfiles = async ({ page, fullname, gender, jatiye, disabilityType, ward, isPublic }) => {
    let filters = ``;
    if (isPublic) {
        filters += `&public=true`
    }
    if (fullname) {
        filters += `&name_np=${fullname}`
    }
    if (gender) {
        filters += `&gender=${gender}`
    }
    if (jatiye) {
        filters += `&ethinicity=${jatiye}`
    }
    if (disabilityType) {
        filters += `&disabilityType=${disabilityType}`
    }
    if (ward) {
        filters += `&ward=${ward}`
    }
    const response = await fetchWrapper.get(`/disabledProfile/?page=${page}${filters}`);
    return response.data;
}

const getDisabledProfileById = async (id) => {
    const response = await fetchWrapper.get(`/disabledProfile/${id}`);
    return response.data;
}

const deleteDisabledProfile = async (id) => {
    await fetchWrapper.delete(`/disabledProfile/${id}`);
}

const getDisabledPratilipis = async ({ page, fullname, gender, jatiye, disabilityType, ward, isPublic }) => {
    let filters = ``;
    if (isPublic) {
        filters += `&public=true`
    }
    if (fullname) {
        filters += `&name_np=${fullname}`
    }
    if (gender) {
        filters += `&gender=${gender}`
    }
    if (jatiye) {
        filters += `&ethinicity=${jatiye}`
    }
    if (disabilityType) {
        filters += `&disabilityType=${disabilityType}`
    }
    if (ward) {
        filters += `&ward=${ward}`
    }
    const response = await fetchWrapper.get(`/disabledProfile/copy/?page=${page}${filters}`);
    return response.data;
}

const createDisabledProfileCopy = async (body, options = {}) => {
    let url = `/disabledProfile/copy/`;
    const response = await fetchWrapper.post(url, body);
    return response.data;
}

const uploadDisabledProfile = async (body, options = {}) => {
    let url = `/disabledProfile/excel/`;
    const response = await fetchWrapper.post(url, body, true);
    return response.data;
}

const getDisabledPratilipiById = async (id) => {
    let url = `/disabledProfile/copy/${id}`;
    const response = await fetchWrapper.get(url);
    return response.data;
}
export const DisabledServices = {
    createDisabledProfile,
    updateDisabledProfile,
    getDisabledProfiles,
    getDisabledProfileById,
    deleteDisabledProfile,
    getDisabledPratilipis,
    createDisabledProfileCopy,
    uploadDisabledProfile,
    getDisabledPratilipiById
}
