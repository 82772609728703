import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { PALIKADATA } from "../_datas";
import { errorMessageChecker } from "../_helpers/methods";

function SelectPalika({ selectedProvince, selectedDistrict, defaultValue, elementId, register, setValue, clearErrors, onChange, errors, validations, disabled }) {
  const [palikas, setPalikas] = useState([]);

  useEffect(() => {
    if (selectedProvince && selectedDistrict) {
      try {
        const palikasArray = PALIKADATA[selectedProvince][selectedDistrict];
        const keys = palikasArray?.map(pali => pali['स्थानीय तहको नाम'])
        setPalikas(keys || []);
      } catch { }
    }
  }, [selectedProvince, selectedDistrict,]);

  return (
    <Fragment>
      <select
        className={`form-control
                        ${errorMessageChecker(errors, elementId) ? "is-invalid" : ""} 
                        `}
        id={elementId}
        value={defaultValue}
        onChange={(e) => {
          let value = e.target.value;
          if (value) {
            clearErrors(elementId);
          }
          if (setValue) {
            setValue(elementId, value);
          }
          if (onChange) {
            onChange(value)
          }
          register(elementId).onChange(e);
        }}
        disabled={disabled}
      >
        <option value="">कृपया पालिका छान्नुहोस</option>

        {palikas.map((palika) => (
          <option key={palika} value={palika}>
            {palika}
          </option>
        ))}
      </select>
      <input
        {...register(elementId, validations)}
        hidden readOnly />
    </Fragment>
  );
}

export default connect(null, {})(SelectPalika);
