const rules = {
  admin: {
    static: [
      "view:settings",
      "view:arthikBarsa",
      "view:sifarisKoPrakar",
      "view:kagajatBiwaran",
      "view:sawikKoPalika",
      "view:nayaAawedakDarta",
      "view:wodaKaralayaHaru",
      "view:mayorTab",
      "view:reports",
      "view:templates",
      "view:jariVayekoSifaris",
      "view:sifarisAnurodPranali",
      "view:sifarishVerification",
      "view:nagarpalikaDetails",
      "view:support",
      "view:faq",
      "upload:excelList",
    ],
  },
  ito_admin: {
    static: [
      "view:settings",
      "view:arthikBarsa",
      "view:sifarisKoPrakar",
      "view:kagajatBiwaran",
      "view:sawikKoPalika",
      "view:wodaKaralayaHaru",
      "view:nayaAawedakDarta",
      "view:nagarpalikaDetails",
      "view:sifarishVerification",
      "view:mayorTab",
      "view:reports",
      "view:templates",
      "view:jariVayekoSifaris",
      "view:sifarisAnurodPranali",
      "view:designTemplate",
      "view:support",
      "view:faq",
    ],
  },
  ward_admin: {
    static: [
      "view:sawikKoPalika",
      "view:nayaAawedakDarta",
      "view:reports",
      "view:templates",
      "view:jariVayekoSifaris",
      "view:sifarisAnurodPranali",

      "view:support",
      "view:faq",
    ],
  },
  ward_user: {
    static: [
      "view:templates",
      "view:jariVayekoSifaris",
      "view:sifarisAnurodPranali",
      "view:support",
      "view:faq",
    ],
  },
  sakha_user: {
    static: [
      "view:templates",
      "view:jariVayekoSifaris",
      "view:sifarisAnurodPranali",
      "view:support",
      "view:faq",
    ],
  },
  ward_paper_issuer: {
    static: [
      "view:templates",
      "view:jariVayekoSifaris",
      "view:sifarisAnurodPranali",
      "view:support",
      "view:faq",
    ],
  },
  mayor: {
    static: ["view:reports"],
  },
  upa_mayor: {
    static: ["view:reports"],
  },
};

export default rules;
