import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = (props) => {
  const [showMenu, setShowMenu] = useState(true);

  return (
    <div className="main-wrapper">
      <div className="d-flex">
        <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} />
        <div className="right-content">
          <Header />
          <div className="content-wrapper">
            <div className="main-content">{props.children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
