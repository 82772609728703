import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

export const initialState = {
  loading: false,
  hasErrors: false,
  templatesCategory: [],
};

// A slice
const templateCategorySlice = createSlice({
  name: "templatesCategory",
  initialState,
  reducers: {
    getTemplatesCategory: (state, action) => {
      state.templatesCategory = action.payload.data.results;
    },
  },
});

// Actions generated from the slice
const { getTemplatesCategory } = templateCategorySlice.actions;

// export user selector to get the slice in any component
export const templatesCategorySelector = (state) => state.templatesCategory;

// export The reducer
const templateCategoryReducer = templateCategorySlice.reducer;

export default templateCategoryReducer;

// Actions
export const fetchTempCategory = () => async (dispatch) => {
  try {
    const category = await fetchWrapper.get("/template/category/");
    dispatch(getTemplatesCategory(category));
  } catch (error) {}
};
