const formKeyMapper = {
  //api values key: form key
  first_name_np: "fullname_np.firstName",
  middle_name_np: "fullname_np.middleName",
  last_name_np: "fullname_np.lastName",

  first_name_en: "fullname_en.firstName",
  middle_name_en: "fullname_en.middleName",
  last_name_en: "fullname_en.lastName",
  
  citizenship_issue_district: "text3",
  
  citizenship_number: "citizenship_no",
};

export default function getFormInputKey(key) {
  return formKeyMapper[key] || key;
}
