import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage, setUiError } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  sakhas: [],
  count: 0,
  sakhaUsers: [],
};

// A slice
const sakhaSlice = createSlice({
  name: "sakhas",
  initialState,
  reducers: {
    getSakhas: (state, action) => {
      state.sakhas = action.payload.data.results;
      state.count = action.payload.data.count;
    },
    getSakhaUsers: (state, action) => {
      state.sakhaUsers = action.payload;
    },
    createSakhaUser: (state, action) => {
      state.sakhaUsers.users.push(action.payload.data.users[0]);
    },
    editSakhaUser: (state, action) => {
      const editedUser = [...state.sakhaUsers];

      const editedIndex = editedUser.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        editedUser[editedIndex] = action.payload;
      }
      state.sakhaUsers = editedUser;
    },
    activeSakhaUser: (state, action) => {
      const tempUser = [...state.sakhaUsers];
      const editedIndex = tempUser.findIndex(
        (user) => user.id === action.payload.profile_id
      );
      if (editedIndex !== -1) {
        if (action.payload.status === "active") {
          tempUser[editedIndex].is_active = true;
        } else {
          tempUser[editedIndex].is_active = false;
        }
      }
      state.sakhaUsers = tempUser;
    },
    deleteSakhaUser: (state, action) => {
      const tempUsers = [...state.sakhaUsers];
      const index = tempUsers.findIndex((user) => user.id === action.payload);
      tempUsers.splice(index, 1);
      state.sakhaUsers = tempUsers;
    },
  },
});

// Actions generated from the slice
const {
  getSakhas,
  getSakhaUsers,
  // createSakhaUser,
  activeSakhaUser,
  editSakhaUser,
  deleteSakhaUser,
} = sakhaSlice.actions;

// export user selector to get the slice in any component
export const sakhaSelector = (state) => state.sakhas;

// export The reducer
const sakhaReducer = sakhaSlice.reducer;

export default sakhaReducer;

// Actions
export const fetchSakhas =
  (page = 1) =>
    async (dispatch) => {
      try {
        const sakhas = await fetchWrapper.get(`/sakha/?page=${page}`);
        console.log(sakhas, "sakhas");
        dispatch(getSakhas(sakhas));
      } catch (error) {
        console.log(error, "error");
      }
    };

export const fetchSakhaUsers = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(`/sakha/${id}/users`);
    dispatch(getSakhaUsers(response.data));
  } catch (error) { }
};

export const fetchSakhaById = async (id) => {
  try {
    const response = await fetchWrapper.get(`/sakha/${id}`);
    return response.data;
  } catch (error) { }
};

export const patchSakhaUser =
  (data, id, setShowAdd, showAdd) => async (dispatch) => {
    try {
      if (data.password === "") {
        delete data.password;
      }
      const response = await fetchWrapper.patch(`/sakha-user/${id}/`, data);
      // console.log(response, data);

      dispatch(editSakhaUser(data));
      setShowAdd(false);
      dispatch(setUiMessage(response.message));
    } catch (error) {
      console.log(error, "error");
      dispatch(setUiError(error.message));
    }
  };

export const activateKawaSakhaUser = (body) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(`/update-kawa-status/`, body);
    dispatch(activeSakhaUser(body));
    dispatch(setUiMessage(response.message));
  } catch (error) { }
};

export const removeSakhaUser = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/users/${id}/`);

    dispatch(deleteSakhaUser(id));
    dispatch(setUiMessage(response.message));
  } catch (error) { }
};
