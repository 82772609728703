/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import ReactTableRender from "../../../components/Table/ReactTableRender";
import { useTable } from "react-table";
import DefaultColumnFilter, {
  StyledFilterInput,
} from "../../../components/Table/Filters/DefaultColumnFilter";
import { PrashasakServices } from "../../../api/services/PrashasakServices";
import { useFilters, usePagination, useRowSelect } from "react-table";
import { useDispatch } from "react-redux";
import { handlesetActivePrashasak } from "../../../_redux/slices/prashasak";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { handleDecrementAdministratorStats } from "../../../_redux/slices/appStats";
import { useCallback } from "react";
import { debounce } from "../../../_helpers/methods";
import InputField from "../../../components/InputField";

const Prashasak = () => {
  const { pageNum } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const activePrashasak = useSelector(
    (state) => state?.prashasak?.activePrashasak
  );

  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [show, setShow] = useState(false);
  const [queryFilter, setQueryFilter] = useState({});

  useEffect(() => {
    setPageNo(pageNum);
  }, [pageNum]);

  const fetchProfiles = useCallback(async () => {
    setLoading(true);
    try {
      const response = await PrashasakServices.getProfiles({
        page: pageNo ?? 1,
        ...queryFilter,
      });
      setTotalCount(response.count);
      setResults(response.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [pageNo, queryFilter]);

  const handleDeleteProfile = useCallback(
    async (id) => {
      try {
        await PrashasakServices.deletePrashasak(id);
        dispatch(handleDecrementAdministratorStats());
        Swal.fire("Deleted!", "Elderly profile has been deleted.", "success");
        fetchProfiles();
      } catch (error) {
        console.log(error, "error");
      }
    },
    [dispatch, fetchProfiles]
  );

  useEffect(() => {
    history.push(`/settings/prashasak/list/${pageNo}`);
    try {
      fetchProfiles();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [fetchProfiles, history, pageNo]);

  const onActive = async (item) => {
    let activatedItem = {
      id: item.id,
      active: true,
    };
    await PrashasakServices.activatePrashasak(activatedItem);
    dispatch(handlesetActivePrashasak(item));
    setShow(null);
  };

  const handleDeletePrashasak = async (id) => {
    await PrashasakServices.deletePrashasak(id);
    fetchProfiles();
    setShow(null);
  };

  const ManualFilter = useCallback(({ column }) => {
    const handleOnChange = debounce((value) => {
      setQueryFilter((prev) => ({ ...prev, [column.id]: value }));
    }, 800);

    if (column?.filterType === "Select") {
      let options = column.filterOptions || {};
      return (
        <select
          style={{ width: "100%" }}
          onChange={(e) => {
            handleOnChange(e.target.value || undefined);
          }}
        >
          {Object.keys(options).map((key) => {
            return (
              <option key={key} value={key}>
                {options[key]}
              </option>
            );
          })}
        </select>
      );
    } else {
      if (column?.FilterLanguage === "nepali") {
        return (
          <InputField
            placeholder="खोज्नुहोस ..."
            getInputData={handleOnChange}
            className="filer-input"
          />
        );
      } else {
        return (
          <StyledFilterInput
            ref={inputRef}
            placeholder="खोज्नुहोस ..."
            onChange={(e) => {
              handleOnChange(e.target.value);
            }}
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "क्र. स.",
        accessor: "serial_number",
        disableFilters: true,
        width: "100px",
      },
      {
        Header: "पुरा नाम नेपालीमा",
        accessor: "fullname_np",
        Filter: ManualFilter,
        width: "200px",
        FilterLanguage: "nepali",
      },
      {
        Header: "Full name in English",
        accessor: "fullname_en",
        width: "200px",
        Filter: ManualFilter,
      },
      {
        Header: "पद नेपालीमा",
        accessor: "designation_np",
        width: "200px",
        disableFilters: true,
      },
      {
        Header: "Designation",
        accessor: "designation_en",
        disableFilters: true,
        width: "200px",
      },
      {
        Header: "रातो हस्ताक्षर",
        accessor: "redSignature",
        disableFilters: true,
        width: "110px",
      },
      {
        Header: "कालो हस्ताक्षर",
        accessor: "blackSignature",
        disableFilters: true,
        width: "110px",
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        width: "140px",
      },
      {
        Header: "कार्यहरु",
        accessor: "actions",
        disableFilters: true,
        width: "140px",
      },
    ],
    [ManualFilter]
  );

  const data = useMemo(() => {
    if (!results) return [];
    let count = 1;

    return results.map((result) => {
      const actions = (
        <span className="d-flex align-items-center">
          <FaEdit
            className="mr-3 text-primary cursor-pointer"
            onClick={() => history.push(`/settings/prashasak/new/${result.id}`)}
          />
          {result.id !== activePrashasak?.id && (
            <FaTrash
              className="mr-3 text-danger cursor-pointer"
              onClick={() =>
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then((response) => {
                  if (response.isConfirmed) {
                    handleDeleteProfile(result.id);
                  }
                })
              }
            />
          )}
        </span>
      );

      const redSignatureDiv = (
        <span>
          <img
            src={result.redSignature}
            alt="red signature"
            css={css`
              min-width: 0px;
              width: 110px;
            `}
          />
        </span>
      );

      const blackSignature = (
        <span>
          <img
            src={result.blackSignature}
            alt="black signature"
            css={css`
              min-width: 0px;
              width: 110px;
            `}
          />
        </span>
      );
      return {
        serial_number: (pageNo - 1) * 10 + count++,
        fullname_np: result.fullname_np,
        fullname_en: result.fullname_en,
        designation_np: result.designation_np,
        designation_en: result.designation_en,
        redSignature: redSignatureDiv,
        blackSignature: blackSignature,
        status: (
          <span className="d-flex align-items-center justify-content-center">
            {activePrashasak?.id === result.id ? (
              <Button variant="success">सक्रिय छ</Button>
            ) : (
              <Button
                variant="danger"
                onClick={() => setShow({ type: "activate", item: result })}
              >
                निस्क्रिय छ
              </Button>
            )}
          </span>
        ),
        actions: actions,
      };
    });
  }, [results, activePrashasak?.id, pageNo, history, handleDeleteProfile]);

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    pageCount,
    previousPage,
  } = useTable(
    {
      columns,
      data: data,
      manualPagination: true,
      pageCount: pageNo,
      defaultCanFilter: false,
      defaultColumn,
    },
    useFilters,
    usePagination,
    useRowSelect
  );

  const pageCountArray = useMemo(
    () =>
      Array.from({ length: Math.ceil(totalCount / pageSize) }, (v, i) => i + 1),
    [totalCount, pageSize]
  );

  const nextPage = (page) => {
    console.log("next page", page);
    setPageNo(page);
  };
  const gotoPage = (page) => {
    console.log("prev page", page);
    setPageNo(page);
  };

  return (
    <div>
      <div aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" aria-current="page">
            Home
          </li>
          <li class="breadcrumb-item" aria-current="page">
            सेटिंग्स
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            प्रशासकहरु
          </li>
        </ol>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>प्रशासकहरु</h2>

        <Button
          variant="success"
          onClick={() => history.push("/settings/prashasak/new")}
        >
          नयाँ प्रशासक
        </Button>
      </div>
      <Row>
        <Col>
          <ReactTableRender
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            totalCount={totalCount}
            gotoPage={gotoPage}
            previousPage={previousPage}
            pageCount={pageCount}
            pageCountArray={pageCountArray}
            pageNum={pageNo}
            showGoto={true}
            nextPage={nextPage}
            loading={loading}
            setPageSize={setPageSize}
          />
        </Col>
      </Row>

      <Modal show={Boolean(show)} onHide={() => setShow(null)} centered>
        <Modal.Header>
          <h3>प्रशासक</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="h5 font-weight-lighter">
            {show?.type === "activate"
              ? "के तपाइँ यो प्रशासकलाई सक्रिय गर्न चाहानुहुन्छ?"
              : "के तपाइँ यो प्रशासक हटाउन चाहानुहुन्छ?"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setShow(null);
            }}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button
            className="btn btn-primary"
            onClick={() => {
              if (show?.type === "activate") {
                onActive(show?.item);
              } else {
                handleDeletePrashasak(show?.item.id);
              }
            }}
          >
            सुनिश्चित गर्नुहोस्
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Prashasak;
