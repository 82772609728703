/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useState } from "react";
import { FaTrash, FaPlusSquare } from "react-icons/fa";
import InputField from "../../InputField";

const Table = ({ data, language, register }) => {
  const headings = data.data.headings;
  const headingLength = headings.length;

  const [noOfRows, setNoOfRows] = useState(Number(data.data.minRow) || 2);

  const addRow = () => {
    setNoOfRows(noOfRows + 1);
  };
  const removeRow = () => {
    setNoOfRows(noOfRows - 1);
  };

  return (
    <Fragment>
      <table className="table table-bordered table-striped">
        <tr>
          {headings.map((heading, idx) => (
            <th key={idx}>{heading.name}</th>
          ))}
          <th>Action</th>
        </tr>

        {Array.from({ length: noOfRows }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: headingLength }).map((_, fieldIndex) => (
              <Fragment>
                <td key={fieldIndex} className="p-0 m-0">
                  <InputField
                    className="table-input"
                    type={headings[fieldIndex].type}
                    language={language}
                    register={register}
                  />
                </td>
              </Fragment>
            ))}
            {rowIndex + 1 > (Number(data.data.minRow) || 2) ? (
              <td
                onClick={removeRow}
                css={css`
                  cursor: pointer;
                `}
              >
                <FaTrash color="red" size="32" />
              </td>
            ) : (
              <td></td>
            )}
          </tr>
        ))}
      </table>
      {Number(data.data.maxRow) > noOfRows && (
        <div
          className="d-flex justify-content-end"
          onClick={addRow}
          css={css`
            cursor: pointer;
          `}
        >
          <FaPlusSquare color="blue" size="24" />
        </div>
      )}
    </Fragment>
  );
};

export default Table;
