import { Fragment } from "react";
import InputField from "../components/InputField";

export const SelectCitizenshipIssuedDistrict = ({ language, register }) => (
  <Fragment>
    <InputField
      language={language}
      className="form-control formField"
      name="applicant[citizenship_issue_district]"
      id="applicant_citizenship_issue_district"
      type="text"
      list="district"
      register={register}
    />
    <datalist id="district">
      {language === "nepali" ? (
        <Fragment>
          {" "}
          <option value="">कृपया जिल्ला छान्नुहोस</option>
          <option value="ताप्लेजुङ">ताप्लेजुङ</option>
          <option value="पाँचथर">पाँचथर</option>
          <option value="ईलाम">ईलाम</option>
          <option value="झापा">झापा</option>
          <option value="मोरंग">मोरंग</option>
          <option value="सुनसरी">सुनसरी</option>
          <option value="धनकुटा">धनकुटा</option>
          <option value="तेहथुम">तेहथुम</option>
          <option value="संखुवासभा">संखुवासभा</option>
          <option value="भोजपुर">भोजपुर</option>
          <option value="सोलुखुम्बु">सोलुखुम्बु</option>
          <option value="ओखलढुंगा">ओखलढुंगा</option>
          <option value="खोटाङ">खोटाङ</option>
          <option value="उदयपुर">उदयपुर</option>
          <option value="सप्तरी">सप्तरी</option>
          <option value="सिराहा">सिराहा</option>
          <option value="धनुषा">धनुषा</option>
          <option value="महोत्तरी">महोत्तरी</option>
          <option value="सर्लाही">सर्लाही</option>
          <option value="सिन्धुली">सिन्धुली</option>
          <option value="रामेछाप">रामेछाप</option>
          <option value="दोलखा">दोलखा</option>
          <option value="सिन्धुपाल्चोक">सिन्धुपाल्चोक</option>
          <option value="काभ्रेपलान्चोक">काभ्रेपलान्चोक</option>
          <option value="ललितपुर">ललितपुर</option>
          <option value="भक्तपुर">भक्तपुर</option>
          <option value="काठमाण्डौ">काठमाण्डौ</option>
          <option value="नुवाकोट">नुवाकोट</option>
          <option value="रसुवा">रसुवा</option>
          <option value="धादिङ">धादिङ</option>
          <option value="मकवानपुर">मकवानपुर</option>
          <option value="रौतहट">रौतहट</option>
          <option value="वारा">वारा</option>
          <option value="पर्सा">पर्सा</option>
          <option value="चितवन">चितवन</option>
          <option value="गोरखा">गोरखा</option>
          <option value="लमजुङ">लमजुङ</option>
          <option value="तनहुँ">तनहुँ</option>
          <option value="स्याङजा">स्याङजा</option>
          <option value="कास्की">कास्की</option>
          <option value="मनाङ">मनाङ</option>
          <option value="मुस्ताङ">मुस्ताङ</option>
          <option value="म्याग्दी">म्याग्दी</option>
          <option value="पर्वत">पर्वत</option>
          <option value="वाग्लुङ">वाग्लुङ</option>
          <option value="गुल्मी">गुल्मी</option>
          <option value="पाल्पा">पाल्पा</option>
          <option value="रुपन्देही">रुपन्देही</option>
          <option value="कपिलबस्तु">कपिलबस्तु</option>
          <option value="अर्घाखाँची">अर्घाखाँची</option>
          <option value="प्यूठान">प्यूठान</option>
          <option value="रोल्पा">रोल्पा</option>
          <option value="रुकुम (पश्चिम भाग)">रुकुम (पश्चिम भाग)</option>
          <option value="रुकुम (पूर्वी भाग)">रुकुम (पूर्वी भाग)</option>
          <option value="सल्यान">सल्यान</option>
          <option value="दाङ">दाङ</option>
          <option value="बाँके">बाँके</option>
          <option value="बर्दिया">बर्दिया</option>
          <option value="सुर्खेत">सुर्खेत</option>
          <option value="दैलेख">दैलेख</option>
          <option value="जाजरकोट">जाजरकोट</option>
          <option value="डोल्पा">डोल्पा</option>
          <option value="जुम्ला">जुम्ला</option>
          <option value="कालिकोट">कालिकोट</option>
          <option value="मुगु">मुगु</option>
          <option value="हुम्ला">हुम्ला</option>
          <option value="बाजुरा">बाजुरा</option>
          <option value="बझाङ">बझाङ</option>
          <option value="अछाम">अछाम</option>
          <option value="डोटी">डोटी</option>
          <option value="कैलाली">कैलाली</option>
          <option value="कञ्चनपुर">कञ्चनपुर</option>
          <option value="डडेलधुरा">डडेलधुरा</option>
          <option value="बैतडी">बैतडी</option>
          <option value="दार्चुला">दार्चुला</option>
          <option value="नवलपरासी (बर्दघाट सुस्ता पूर्व)">
            नवलपरासी (बर्दघाट सुस्ता पूर्व)
          </option>
          <option value="नवलपरासी (बर्दघाट सुस्ता पश्चिम)">
            नवलपरासी (बर्दघाट सुस्ता पश्चिम)
          </option>
        </Fragment>
      ) : (
        <Fragment>
          <option value="">--Select District--</option>
          {/* %{--Mechi--}% */}
          <option value="Taplejung">Taplejung</option>
          <option value="Panchthar">Panchthar</option>
          <option value="Ilam">Ilam</option>
          <option value="Jhapa">Jhapa</option>
          {/* %{--Koshi--}% */}
          <option value="Morang">Morang</option>
          <option value="Sunsari">Sunsari</option>
          <option value="Dhankutta">Dhankutta</option>
          <option value="Sankhuwasabha">Sankhuwasabha</option>
          <option value="Bhojpur">Bhojpur</option>
          <option value="Terhathum">Terhathum</option>
          {/* %{--Sagarmatha--}% */}
          <option value="Okhaldunga">Okhaldunga</option>
          <option value="Khotang">Khotang</option>
          <option value="Solukhumbu">Solukhumbu</option>
          <option value="Udaypur">Udaypur</option>
          <option value="Saptari">Saptari</option>
          <option value="Siraha">Siraha</option>
          {/* %{--Janakpur--}% */}
          <option value="Dhanusa">Dhanusa</option>
          <option value="Mahottari">Mahottari</option>
          <option value="Sarlahi">Sarlahi</option>
          <option value="Sindhuli">Sindhuli</option>
          <option value="Ramechhap">Ramechhap</option>
          <option value="Dolkha">Dolkha</option>
          {/* %{--Bagmati--}% */}
          <option value="Sindhupalchauk">Sindhupalchauk</option>
          <option value="Kavreplanchauk">Kavreplanchauk</option>
          <option value="Lalitpur">Lalitpur</option>
          <option value="Bhaktapur">Bhaktapur</option>
          <option value="Kathmandu">Kathmandu</option>
          <option value="Nuwakot">Nuwakot</option>
          <option value="Rasuwa">Rasuwa</option>
          <option value="Dhading">Dhading</option>
          {/* %{--Narayani--}% */}
          <option value="Makwanpur">Makwanpur</option>
          <option value="Rauthat">Rauthat</option>
          <option value="Bara">Bara</option>
          <option value="Parsa">Parsa</option>
          <option value="Chitwan">Chitwan</option>
          {/* %{--Gandaki--}% */}
          <option value="Gorkha">Gorkha</option>
          <option value="Lamjung">Lamjung</option>
          <option value="Tanahun">Tanahun</option>
          <option value="Tanahun">Tanahun</option>
          <option value="Syangja">Syangja</option>
          <option value="Kaski">Kaski</option>
          <option value="Manag">Manag</option>
          {/* %{--Dhawalagiri--}% */}
          <option value="Mustang">Mustang</option>
          <option value="Parwat">Parwat</option>
          <option value="Myagdi">Myagdi</option>
          <option value="Myagdi">Myagdi</option>
          <option value="Baglung">Baglung</option>
          {/* %{--Lumbini--}% */}
          <option value="Gulmi">Gulmi</option>
          <option value="Palpa">Palpa</option>
          <option value="Nawalparasi">Nawalparasi</option>
          <option value="Rupandehi">Rupandehi</option>
          <option value="Arghakhanchi">Arghakhanchi</option>
          <option value="Kapilvastu">Kapilvastu</option>
          {/* %{--Rapti--}% */}
          <option value="Pyuthan">Pyuthan</option>
          <option value="Rolpa">Rolpa</option>
          <option value="Rukum">Rukum</option>
          <option value="Salyan">Salyan</option>
          <option value="Dang">Dang</option>
          {/* %{--Bheri--}% */}
          <option value="Bardiya">Bardiya</option>
          <option value="Surkhet">Surkhet</option>
          <option value="Dailekh">Dailekh</option>
          <option value="Banke">Banke</option>
          <option value="Jajarkot">Jajarkot</option>
          {/* %{--Karnali--}% */}
          <option value="Dolpa">Dolpa</option>
          <option value="Humla">Humla</option>
          <option value="Kalikot">Kalikot</option>
          <option value="Mugu">Mugu</option>
          <option value="Jumla">Jumla</option>
          {/* %{--Seti--}% */}
          <option value="Bajura">Bajura</option>
          <option value="Bajhang">Bajhang</option>
          <option value="Achham">Achham</option>
          <option value="Doti">Doti</option>
          <option value="Kailali">Kailali</option>
          {/* %{--Mahakali--}% */}
          <option value="Kanchanpur">Kanchanpur</option>
          <option value="Dadeldhura">Dadeldhura</option>
          <option value="Baitadi">Baitadi</option>
          <option value="Darchula">Darchula</option>
        </Fragment>
      )}
    </datalist>
  </Fragment>
);
