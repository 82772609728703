import React, { Fragment } from "react";
import { css } from "styled-components";
import Elements from "..";
import {
  ResizableInput,
  SelectCitizenshipIssuedDistrict,
} from "../../../../_elements";
import SelectDistrict from "../../../../_elements/SelectDistrict";
import SelectPalika from "../../../../_elements/SelectPalika";
import SelectProvince from "../../../../_elements/SelectProvince";

function OldPaperFormat({
  language,
  province,
  handleSelectProvince,
  district,
  handleSelectDistrict,
  palika,
  handleSelectPalika,
  printRef,
  data,
  register,
  setValue,
  control,
}) {
  return (
    <Fragment>
      <div>
        <div
          css={css`
            width: 100%;
            display: flex;
            .infoItem {
              display: flex;
              flex-direction: column;
              margin-right: 2rem;
              width: 25%;
              .align-left {
                text-align: left;
              }
            }
          `}
        >
          <div className="infoItem">
            <label>Name</label>
            <ResizableInput
              type="text"
              language={language}
              maxlength={50}
              className="form-control align-left border"
              placeholder="Name"
              isRequired={true}
            />
          </div>
          <div className="infoItem">
            <label>Citizenship Number</label>
            <ResizableInput
              type="text"
              language={language}
              maxlength={50}
              className=" align-left border"
              placeholder="Citizenship Number"
            />
          </div>
          <div className="infoItem">
            <label>Citizenship Issued District</label>
            <SelectCitizenshipIssuedDistrict language={language} />
          </div>
          <div className="infoItem">
            <label>Phone No.</label>
            <ResizableInput
              type="number"
              language={language}
              maxlength={10}
              className="form-control align-left border b-1"
              placeholder="Phone number"
            />
          </div>
        </div>
        <hr />
        <div>
          <h5>स्थायी ठेगाना</h5>
          <div
            css={css`
              width: 100%;
              display: flex;
              .infoItem {
                display: flex;
                flex-direction: column;
                margin-right: 2rem;
                width: 25%;
                .align-left {
                  text-align: left;
                }
              }
            `}
          >
            <div className="infoItem">
              <label>
                प्रदेश<span className="text-danger">*</span>
              </label>
              <SelectProvince
                province={province}
                handleSelectProvince={handleSelectProvince}
              />
            </div>
            <div className="infoItem">
              <label>
                जिल्ला<span className="text-danger">*</span>
              </label>
              <SelectDistrict
                province={province}
                district={district}
                handleSelectDistrict={handleSelectDistrict}
              />
            </div>
            <div className="infoItem">
              <label>
                स्थानीय तह<span className="text-danger">*</span>
              </label>
              <SelectPalika
                province={province}
                district={district}
                palika={palika}
                handleSelectPalika={handleSelectPalika}
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <hr
        css={css`
          border: 2px solid black;
        `}
      />
      <div className="page" ref={printRef}>
        <div className="content">
          <Elements
            language={language}
            elements={data.values}
            register={register}
            setValue={setValue}
            control={control}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default OldPaperFormat;
