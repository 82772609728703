import { apiKey } from "../constant";
import { jwtCheck } from "./jwt-check";

export function authHeader(isPublic) {
  // return authorization header with jwt token
  let Token = jwtCheck();
  let header = {
    apikey: apiKey,
    "Content-Type": "application/json",
  };
  if (Token && !isPublic) {
    header = { ...header, Authorization: `Bearer ${Token}` };
  }
  return header;
}
