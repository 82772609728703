import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FaEye, FaPrint, FaSave, FaTimesCircle } from "react-icons/fa";

const Step4 = () => {
  return (
    <>
      <Form className="wizard-content mt-2">
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className="form-group">
                  <label for="documents">नागरिकता प्रमाणपत्रको प्रतिलिपि</label>
                  <input
                    id=""
                    type="file"
                    className="form-control"
                    name="documents"
                    placeholder=""
                    autofocus
                    multiple
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="gallery-img">
                  <img
                    className="gallery-item"
                    src="https://demo.getstisla.com/assets/img/news/img03.jpg"
                    data-title="Image 11"
                    alt=""
                  />
                  <FaTimesCircle className="deleteIcon" />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Body>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label for="documents">नागरिकता प्रमाणपत्रको प्रतिलिपि</label>
                  <input
                    id=""
                    type="file"
                    className="form-control"
                    name="documents"
                    placeholder=""
                    autofocus
                  />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Form>
      <div className="d-flex justify-content-end w-100 mt-3">
        <Button className="btn btn-lg icon-right btn-success mr-2">
          <FaEye /> Preview
        </Button>
        <Button className="btn btn-lg icon-right btn-success mr-2">
          <FaSave /> Save
        </Button>
        <Button className="btn btn-lg icon-right btn-info">
          <FaPrint /> Save and Print
        </Button>
      </div>
    </>
  );
};

export default Step4;
