import { Fragment, useEffect } from "react";
import { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { fetchWrapper } from "../_helpers/fetchWrapper";

const Faq = () => {
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchWrapper.get("/faq/");
        setFaqList(response.data.results);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Fragment>
      <h2 className="main-title my-2">Frequently Asked Questions</h2>
      {/* <div class="accordion">
        <div class="accordion-item">
          <button id="accordion-button-1" aria-expanded="false">
            <span class="accordion-title">
              Why is the moon sometimes out during the day?
            </span>
            <span class="icon" aria-hidden="true"></span>
          </button>
          <div class="accordion-content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra
              suspendisse potenti.
            </p>
          </div>
        </div>
      </div> */}
      <Accordion>
        {faqList.map((faq) => (
          <Fragment>
            <Accordion.Toggle eventKey="0">
              <button
                id="accordion-button-1"
                aria-expanded="false"
                className="accordion-item"
              >
                <span class="accordion-title">{faq.question}</span>
                <span class="icon" aria-hidden="true"></span>
              </button>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <Card className="rounded">
                <Card.Body>{faq.answer}</Card.Body>
              </Card>
            </Accordion.Collapse>
          </Fragment>
        ))}
      </Accordion>
    </Fragment>
  );
};

export default Faq;
