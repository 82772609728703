import React from 'react';
import { useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { FaEdit, FaTrash, FaEye } from 'react-icons/fa';
import PaginationComp from '../../components/Pagination';
import { useHistory } from 'react-router-dom';

const DisabledWithoutId = () => {
	const history = useHistory();
	const [page, setPage] = useState(1);
	return (
		<div>
			<div aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item" aria-current="page">
						Home
					</li>

					<li class="breadcrumb-item active" aria-current="page">
						अपाङ्गता भएका परिचय पत्र नपाएको व्यक्तिको विवरणहरु
					</li>
				</ol>
			</div>
			<div className="mb-3 d-flex align-items-center justify-content-between">
				<h2 className="text-center">अपाङ्गता भएको परिचय पत्र नपाएको व्यक्तिको विवरणहरु</h2>
				<Button variant="success" onClick={() => history.push('/disabled/new')}>
					नयाँ फारम
				</Button>
			</div>
			<Row>
				<Col>
					<Table>
						<thead>
							<tr>
								<th style={{ width: '4%' }}>क्र. स.</th>
								<th>पुरा नाम नेपालीमा</th>
								<th>अपाङ्गताको प्रकार</th>
								<th style={{ width: '5%' }}>कार्ड नं</th>
								<th style={{ width: '5%' }}>लिङ्ग</th>
								<th style={{ width: '5%' }}>वार्ड</th>
								<th style={{ width: '15%' }}>जातीयता</th>
								<th style={{ width: '15%' }}>परिचय पत्र पाएको मिति (बि.स.)</th>
								<th style={{ width: '20%' }}>अपाङ्गताको प्रकार</th>
								<th style={{ width: '8%' }}>कार्यहरु</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td>लाल बहादुर शाह</td>
								<td>अति अशक्त अपाङ्गता</td>
								<td>१०२४</td>
								<td>पुरुष</td>
								<td>2</td>
								<td>मधेसी / ब्राह्मण / छेत्री / राजपुत</td>
								<td>2079-08-20</td>
								<td>मानसिक वा मनोसामाजिक अपाङ्गता</td>
								<td>
									<div className="d-flex justify-content-around align-items-center">
										<FaEye className="text-info" />
										<FaEdit className="text-primary" />
										<FaTrash className="text-danger" />
									</div>
								</td>
							</tr>
							<tr>
								<td>1</td>
								<td>लाल बहादुर शाह</td>
								<td>अति अशक्त अपाङ्गता</td>
								<td>१०२४</td>
								<td>पुरुष</td>
								<td>2</td>
								<td>मधेसी / ब्राह्मण / छेत्री / राजपुत</td>
								<td>2079-08-20</td>
								<td>मानसिक वा मनोसामाजिक अपाङ्गता</td>
								<td>
									<div className="d-flex justify-content-around align-items-center">
										<FaEye className="text-info" />
										<FaEdit className="text-primary" />
										<FaTrash className="text-danger" />
									</div>
								</td>
							</tr>
							<tr>
								<td>1</td>
								<td>लाल बहादुर शाह</td>
								<td>अति अशक्त अपाङ्गता</td>
								<td>१०२४</td>
								<td>पुरुष</td>
								<td>2</td>
								<td>मधेसी / ब्राह्मण / छेत्री / राजपुत</td>
								<td>2079-08-20</td>
								<td>मानसिक वा मनोसामाजिक अपाङ्गता</td>
								<td>
									<div className="d-flex justify-content-around align-items-center">
										<FaEye className="text-info" />
										<FaEdit className="text-primary" />
										<FaTrash className="text-danger" />
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
					<PaginationComp page={page} setPage={setPage} total={10} />
				</Col>
			</Row>
		</div>
	);
};

export default DisabledWithoutId;
