/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

function Photo({ data }) {
  return (
    <div
      css={css`
        width: 98px;
        height: 114px;
        text-align: center;
        border: 2px solid black;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        padding: 0.2rem;
      `}
    >
      {data.imgSrc ? (
        <img
          src={data.imgSrc}
          alt=""
          css={css`
            width: 100%;
            object-fit: contain;
          `}
        />
      ) : (
        "दुवै कान देखिने हाल खिचिएको २.५ X ३ से.मी. फोट"
      )}
    </div>
  );
}

export default Photo;
