import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { jwtCheck, setToken } from "../../_helpers/jwt-check";
import { setUiError, setUiMessage } from "./ui";
import { toast } from "react-toastify";

export const initialState = {
  loading: false,
  hasErrors: false,
  users: [],
  user: null,
  isLoggedIn: jwtCheck() ? true : false,
};

// A slice
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    updateUser: (state, action) => {
      let tempuser = {
        ...state.user,
        ...action.payload,
      };
      state.user = tempuser;
    },
    setUserLogin: (state, action) => {
      state.isLoggedIn = true;
      state.user = { ...state.user, ...action.payload };
    },
    // setUserLogout: (state, action) => initialState,
    setUserLogout: (state, action) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    addUser: (state, action) => {
      state.users.unshift(action.payload);
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getUsersFailure: (state) => {
      state.loading = false;
      //handling Errors
      state.hasErrors = true;
    },
  },
});

// Actions generated from the slice
const {
  setUser,
  setUserLogin,
  setUserLogout,
  addUser,
  startLoading,
  stopLoading,
  getUsersFailure,
  getUsersSuccess,
  updateUser,
} = usersSlice.actions;

// export user selector to get the slice in any component
export const usersSelector = (state) => state.users;
export const userSelector = (state) => state.users.user;

// export The reducer
const userReducer = usersSlice.reducer;

export default userReducer;

// Actions
export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await fetch("https://jsonplaceholder.typicode.com/users");
    const data = await response.json();
    dispatch(getUsersSuccess(data));
  } catch (error) {
    dispatch(getUsersFailure());
  }
};

export const createUser = (User) => async (dispatch) => {
  try {
    dispatch(addUser(User));
  } catch (error) {
    dispatch(getUsersFailure());
  }
};

export const loginUser = (credentials, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let response = await fetchWrapper.post("/auth/login/", credentials);
    let accessToken = {
      token: response.data.access,
    };
    let refreshToken = {
      token: response.data.refresh,
    };
    let role = response.data.role;
    setToken(accessToken, refreshToken, role);
    dispatch(stopLoading());
    dispatch(
      setUserLogin({
        id: response.data.id,
        role: role,
        email: response.data.email,
        fullname: response.data.fullname,
      })
    );
    history.push("/");
  } catch (err) {
    console.log(err, 'err')
    dispatch(stopLoading());
    if (err.code === 401) {
      toast.error('माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन।');
      return Promise.reject(err);
    }
    else if (err.code === 500) {
      toast.error('यो कार्य हाल सम्भव छैन। केहि समय पछि पुन: प्रयास गर्नुहोस्।');
      return Promise.reject(err);
    }
  }
};
export const loginUserWIthSSO = (user, history) => async (dispatch) => {
  try {
    dispatch(startLoading());
    let accessToken = {
      token: user.access,
    };
    let refreshToken = {
      token: user.refresh,
    };
    let role = user.role_en;
    //
    setToken(accessToken, refreshToken, role);
    dispatch(stopLoading());
    dispatch(setUserLogin(user));
    history.push("/");
  } catch (err) {
    dispatch(stopLoading());
    let errorMessage = err.error?.non_field_error || err.message;
    if (err.status === 401 || err.code === 401)
      dispatch(
        setUiError(["माफ गर्नुहोस ! तपाईले प्रविष्ट गर्नु भएको विवरण मिलेन |"])
      );
    else {
      dispatch(setUiError(errorMessage));
    }
  }
};

export const getUserData = () => async (dispatch) => {
  try {
    const user = await fetchWrapper.get(`/me/`);
    if (user.data) {
      dispatch(setUser(user.data));
    }
  } catch (err) {
    dispatch(setUserLogout());
  }
};

export const updateUserData = (userData) => async (dispatch) => {
  try {
    const user = await fetchWrapper.patch(`/me/`, userData);
    if (user.data) {
      dispatch(updateUser(user.data));
    }
    dispatch(setUiMessage(user.message));
  } catch (err) {
    if (err.status === 401 || err.code === 401) {
      dispatch(setUserLogout());
    } else {
      let errorMessage = err.error?.non_field_error || err.message;
      dispatch(setUiError(errorMessage));
    }
  }
};

export const logoutUser = () => async (dispatch) => {
  localStorage.removeItem("sv_user");
  localStorage.removeItem("sv_userRefresh");
  localStorage.removeItem("sv_userId");
  localStorage.removeItem("messagePermission");
  localStorage.setItem("showLoginPage", "true");
  dispatch(setUserLogout());
};
