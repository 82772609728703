import React, { Fragment, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { DisabledServices } from "../../api/services/DisabledServices";
import { ElderlyServices } from "../../api/services/ElderlyServices";
import { PrintImage } from "../../_elements/PrintImage";
import { nepaliNumberConverter } from "../../_helpers/methods";
import { Item, PreviewDetailContainer } from "../Preview/styles/StyledPreview";

function PratilipiPreview() {
  const { category, id } = useParams();
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const cardRef = useRef();

  useEffect(() => {
    const handleFetchUserProfile = async () => {
      try {
        setIsLoading(true);

        let result = [];
        if (category === "elderly") {
          result = await ElderlyServices.getElderlyPratilipiById(id);
        } else if (category === "disabled") {
          result = await DisabledServices.getDisabledPratilipiById(id);
        }
        console.log(result, "result");
        setUserProfile(result);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    handleFetchUserProfile();
  }, [category, id]);

  const handleCardPrint = useReactToPrint({
    content: () => {
      return cardRef.current;
    },
    onAfterPrint: () => {
      try {
        //Need to detect if the print is cancelled, if cancelled do not increment count
      } catch (err) {}
    },
  });

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <VscLoading className="spin" fontSize={43} />
        </div>
      ) : (
        <Fragment>
          <PreviewDetailContainer>
            <Item
              className="left"
              label="प्रतिलिपी"
              value={userProfile.oridinal}
            />
            <Item
              className="center"
              label="प्रतिलिपी मिति"
              value={nepaliNumberConverter(userProfile.newCardRecievedDate_bs)}
            />
            <Item
              className="right"
              label="प्रतिलिपी मिति (AD)"
              value={userProfile.newCardRecievedDate_ad}
            />
          </PreviewDetailContainer>

          <div>
            {(userProfile?.cardUrl_front || userProfile?.cardUrl_np) && (
              <div className="d-flex justify-content-end py-3">
                <Button onClick={handleCardPrint}>Print</Button>
              </div>
            )}

            <div ref={cardRef}>
              <Fragment>
                {category === "elderly" ? (
                  <Fragment>
                    <div className="page-break"></div>
                    {userProfile?.cardUrl_front ? (
                      <div>
                        <PrintImage
                          className="print-card"
                          src={userProfile?.cardUrl_front}
                          alt={userProfile?.name_en}
                        />
                      </div>
                    ) : (
                      <div className="text-center text-danger p-5">
                        Card not found!
                      </div>
                    )}

                    <div className="page-break mt-2"></div>
                    {userProfile?.cardUrl_back ? (
                      <div>
                        <PrintImage
                          className="print-card"
                          src={userProfile?.cardUrl_back}
                          alt={userProfile?.name_en}
                        />
                      </div>
                    ) : (
                      <div className="text-center text-danger p-5">
                        Card not found!
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="page-break"></div>
                    {userProfile?.cardUrl_np ? (
                      <div>
                        <PrintImage
                          className="print-card"
                          src={userProfile?.cardUrl_np}
                          alt={userProfile?.name_en}
                        />
                      </div>
                    ) : (
                      <div className="text-center text-danger p-5">
                        Card not found!
                      </div>
                    )}

                    <div className="page-break mt-2"></div>
                    {userProfile?.cardUrl_en ? (
                      <div>
                        <PrintImage
                          className="print-card"
                          src={userProfile?.cardUrl_en}
                          alt={userProfile?.name_en}
                        />
                      </div>
                    ) : (
                      <div className="text-center text-danger p-5">
                        Card not found!
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default PratilipiPreview;
