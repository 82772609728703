/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { connect } from "react-redux";
import { englishToNepaliNumber as np } from 'nepali-number';

import { MdLocationOn } from "react-icons/md";
import NagarpalikaDetailEditModal from "./NagarpalikaDetailEditModal";
import { HiOfficeBuilding } from "react-icons/hi";
import { useSelector } from "react-redux";

function NagarpalikaDetails({ language }) {
  const nagarpalika = useSelector((state) => state.nagarpalika?.nagarpalika);
  const [lang, setLang] = useState("np");
  const [show, setShow] = useState(false);
  const [selectedNagarpalika, setSelectedNagarpalika] = useState(null);
  const { sakhaStats, wardStats } = useSelector(state => state.appStats);


  const showEditModal = () => {
    setSelectedNagarpalika(nagarpalika);
    setShow(true);
  };

  useEffect(() => {
    if (language === "nepali") {
      setLang("np");
    } else {
      setLang("en");
    }
  }, [language]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={3}>
          <div className="icon-container text-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="icon">
                <div
                  css={css`
                    width: 100%;
                    max-width: 150px;
                    margin: 0 auto 1rem auto;
                  `}
                >
                  {nagarpalika?.main_logo ? (
                    <img
                      css={css`
                        width: 100%;
                      `}
                      src={nagarpalika["main_logo"]}
                      alt={nagarpalika[`name_${lang}`]}
                    />
                  ) : (
                    <HiOfficeBuilding fontSize="48" color="#6777ef" />
                  )}
                </div>
                <h4 className="card-text">{nagarpalika[`name_${lang}`]} </h4>
                <p className="card-text">
                  <MdLocationOn />
                  {nagarpalika[`address_${lang}`]}{" "}
                </p>
                <br />
                <p>{nagarpalika[`slogan_${lang}`]}</p>
                <p>वडा: {np(wardStats?.count || 0)}</p>
                <p>शाखा: {np(sakhaStats?.count || 0)}</p>
                <Button variant="link" onClick={() => showEditModal()}>
                  <FaEdit className="text-info mr-2" />
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <NagarpalikaDetailEditModal
        show={show}
        handleClose={handleClose}
        lang={lang}
        selectedNagarpalika={selectedNagarpalika}
      />
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.language,
});

export default connect(mapStateToProps)(NagarpalikaDetails);
