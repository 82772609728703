import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { VscLoading } from "react-icons/vsc";
import { toast } from "react-toastify";
import { ElderlyServices } from "../../../api/services/ElderlyServices";

export default function ElderlyUploader() {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //   const [headerRow, setHeaderRow] = useState();

  const handleClose = () => {
    setShow(false);
  };

  const handleUpload = async (e) => {
    try {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      //   formData.append("header", headerRow);
      await ElderlyServices.uploadElderlyProfile(formData);
      toast.success("Users loaded successfully!");
      setIsLoading(false);
      setShow(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };
  return (
    <div>
      <Button variant="primary" onClick={() => setShow(true)}>
        Upload
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>अपलोड</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="py-2">
            {isLoading ? (
              <div className="d-flex w-100 justify-content-center align-items-center">
                <VscLoading fontSize={43} className="spin" />
              </div>
            ) : (
              <>
                {/* <h6 className='mb-3'>Please open the excel file and enter the table header row number below</h6>

                                    <div className='mb-3'>
                                        <h6 className='mb-2'>Header Row number</h6>
                                        <input className='px-2 py-1' type="number" onChange={(e) => setHeaderRow(e.target.value)} placeholder="Table header is present row" />
                                    </div> */}

                <div>
                  <h6 className="mb-2">Upload xlsx file:</h6>
                  <input
                    type="file"
                    onChange={(e) => handleUpload(e)}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
