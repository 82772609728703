/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { yupResolver } from '@hookform/resolvers/yup';
//eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../_helpers/fetchWrapper';
import { changePasswordValidation } from '../_helpers/schema-validation';
import { useSelector } from 'react-redux';

export const eye = css`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
`;
const formLabel = css`
	margin: 0;
	float: none;
	width: 100%;
`;

export const formInput = css`
	display: block;
	width: 100% !important;
	float: none !important;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
	color: #495057 !important;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	outline: none;
	:focus {
		border-color: #80bdff;
		box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
	}
`;
function ChangePasswordModal({ show, handleCloseChangePasswordModal, showChangePasswordModal }) {
	const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { language } = useSelector((state) => state.language);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(changePasswordValidation),
	});

	const onSubmit = async (data) => {
		try {
			let body = {
				current_password: data.currentPassword,
				new_password: data.password,
			};
			setIsLoading(true);
			await fetchWrapper.post('/user/set_password/', body);
			toast.success('Password changed successfully!');
			setIsLoading(false);
			handleCloseChangePasswordModal();
		} catch (err) {
			if (err.error.new_password) {
				toast.error(err.error.new_password[0]);
			}
			if (err.error.current_password) {
				toast.error(err.error.current_password[0]);
			}
			setIsLoading(false);
		}
	};

	return (
		<Modal centered show={show} onHide={handleCloseChangePasswordModal}>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					{language === 'nepali' ? 'पासवर्ड परिवर्तन गर्नुहोस्' : 'Change Password'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					onSubmit={handleSubmit(onSubmit)}
					css={css`
						.col-md-4 {
							display: flex;
							justify-content: center;
							align-items: center;
						}
					`}
				>
					<Form.Group controlId="formBasicToken">
						<Row>
							<Col md="4">
								<Form.Label className="text-muted " css={formLabel}>
									{language === 'nepali' ? 'हालको पासवर्ड' : 'Current Password'}
								</Form.Label>
							</Col>
							<Col md="8">
								<div className="position-relative">
									<Form.Control
										css={formInput}
										type={showCurrentPassword ? 'text' : 'password'}
										name="currentPassword"
										{...register('currentPassword')}
										placeholder={language === 'nepali' ? 'हालको पासवर्ड' : 'Enter Current Password'}
									/>
									<div css={eye}>
										{showCurrentPassword ? (
											<AiFillEyeInvisible
												css={css`
													color: grey;
												`}
												onClick={() => setShowCurrentPassword(false)}
											/>
										) : (
											<AiFillEye
												css={css`
													color: grey;
												`}
												onClick={() => setShowCurrentPassword(true)}
											/>
										)}
									</div>
								</div>
								<Form.Text className="text-danger">
									{language === 'nepali' ? errors.currentPassword?.message.np : errors.currentPassword?.message.en}
								</Form.Text>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group controlId="formBasicPassword">
						<Row>
							<Col md="4">
								<Form.Label css={formLabel} className="text-muted " styles={{ wordBreak: 'normal' }}>
									{language === 'nepali' ? 'नयाँ पासवर्ड' : 'New Password'}
								</Form.Label>
							</Col>
							<Col md="8">
								<div className="position-relative">
									<Form.Control
										css={formInput}
										type={showPassword ? 'text' : 'password'}
										name="password"
										placeholder={language === 'nepali' ? 'नयाँ पासवर्ड' : 'Enter Password'}
										{...register('password')}
										autoComplete="new-password"
									/>
									<div css={eye}>
										{showPassword ? (
											<AiFillEyeInvisible
												css={css`
													color: grey;
												`}
												onClick={() => setShowPassword(false)}
											/>
										) : (
											<AiFillEye
												css={css`
													color: grey;
												`}
												onClick={() => setShowPassword(true)}
											/>
										)}
									</div>
								</div>
								<Form.Text className="text-danger">
									{language === 'nepali' ? errors.password?.message.np : errors.password?.message.en}
								</Form.Text>
							</Col>
						</Row>
					</Form.Group>
					<Form.Group controlId="formBasicConfirmPassword">
						<Row>
							<Col md="4">
								<Form.Label css={formLabel} className="text-muted " styles={{ wordBreak: 'normal' }}>
									{language === 'nepali' ? 'पुनः नयाँ पासवर्ड' : 'Confirm Password'}
								</Form.Label>
							</Col>
							<Col md="8">
								<div className="position-relative">
									<Form.Control
										css={formInput}
										type={showConfirmPassword ? 'text' : 'password'}
										name="confirmPassword"
										placeholder={language === 'nepali' ? 'पुनः नयाँ पासवर्ड' : 'Confirm Password'}
										{...register('confirmPassword')}
										autoComplete="new-password"
									/>
									<div css={eye}>
										{showConfirmPassword ? (
											<AiFillEyeInvisible
												css={css`
													color: grey;
												`}
												onClick={() => setShowConfirmPassword(false)}
											/>
										) : (
											<AiFillEye
												css={css`
													color: grey;
												`}
												onClick={() => setShowConfirmPassword(true)}
											/>
										)}
									</div>
								</div>
								<Form.Text className="text-danger">
									{language === 'nepali' ? errors.confirmPassword?.message.np : errors.confirmPassword?.message.en}
								</Form.Text>
							</Col>
						</Row>
					</Form.Group>
					<div className="d-flex justify-content-around">
						<Button disabled={isLoading} variant="primary" type="submit" size="lg">
							{isLoading ? <VscLoading className="spin" /> : language === 'nepali' ? 'परिवर्तन गर्नुहोस्' : 'Change'}
						</Button>
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant={'secondary'} onClick={handleCloseChangePasswordModal}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ChangePasswordModal;
