import styled from "@emotion/styled";

export const StyledSelect = styled.div`
  display: flex;
  padding: 0.4rem 0;
  .inputDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.3rem;
    .inputselect {
      height: fit-content;
    }
    .small {
      width: 40px;
    }
  }
`;
