import styled from "@emotion/styled";

export const PreviewMainContainer = styled.div`
    min-height: 100vh;

    .preview-card{
        background: white;
        padding-bottom: 20px;
        margin-bottom: 20px;
        box-shadow: 0 2px 3px 2px #d3d3d3;
    }
`;

export const PreviewHeader = styled.div`
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #a5a5a5;
`;

export const PreviewDetailContainer = styled.div`
    display: grid;
    border: 1px solid black;
    margin: 20px;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
`;


export const Item = ({label, value, children, className, title, isEmpty}) => {
    if(isEmpty){
        return (
            <StyledItem className={className}>
            </StyledItem>
        )
    }

    return (
        <StyledItem className={className}>
            {
                children ? 
                children : 
                <>
                {
                    title ? 
                    <span>{title}</span>
                    :
                    <><span className="label">{label}</span> : <span className="value">{value}</span></>
                }
                </>
            }
        </StyledItem>
    )
}

export const StyledItem = styled.div`
    padding: 10px;
    border: 1px solid black;
    font-size: 18px;

    &.left{
        grid-column: 1;
    }
    
    &.left-center{
        grid-column: 1;
    }

    &.center{
        grid-column: 1;
    }

   &.center-right{
        grid-column: 1;
    }

    &.left-right{
        grid-column:1;
    }

    &.right {
        grid-column: 1;
    }

    &.photo {
        grid-column: 1;
        grid-row: 4;
    }

    &.finger-print {
        grid-column: 1;
        grid-row: 10;
    }

    .value{
        font-weight: 700;
    }


    &.title {
        color: #333 !important;
        font-weight: 700;
        grid-column: 1;
    }

    @media screen and (min-width: 980px) {
        &.left{
            grid-column: 1 ;
        }

        &.left-center{
            grid-column: 1;
        }

        &.center{
            grid-column: 2 ;
        }

        &.center-right{
            grid-column: 2;
        }

        &.right {
            grid-column: 1 ;
        }

        &.left-right{
            grid-column:1;
        }

        &.photo {
            grid-column: 1;
            grid-row: 4;
        }

        &.finger-print {
            grid-column: 1;
            grid-row: 10;
        }
    }

    @media screen and (min-width: 1200px) {
        &.left{
            grid-column: 1;
        }

        &.left-center{
            grid-column: 1 / 3;
        }
        
        &.center{
            grid-column: 2;
        }

        &.center-right{
            grid-column: 2 /  4;
        }

        &.right {
            grid-column: 3;
        }

        &.left-right{
            grid-column:1 / 4;
        }

        &.photo {
            grid-column: 3;
            grid-row: 1 / span 4;
        }

        &.finger-print {
            grid-column: 3;
            grid-row: 5 / 10;
        }
    }

    @media print {
        &.left{
            grid-column: 1;
        }

        &.left-center{
            grid-column: 1 / 3;
        }
        
        &.center{
            grid-column: 2;
        }

        &.center-right{
            grid-column: 2 /  4;
        }

        &.right {
            grid-column: 3;
        }

        &.left-right{
            grid-column:1 / 4;
        }

        &.photo {
            grid-column: 3;
            grid-row: 1 / span 4;
        }

        &.finger-print {
            grid-column: 3;
            grid-row: 5 / 10;
        }
    }
`;
