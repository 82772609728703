import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import { NepaliDatePicker } from "nepali-datepicker-reactjs";
// import "nepali-datepicker-reactjs/dist/index.css";
import { Form } from "react-bootstrap";
import styled from "@emotion/styled";
import { BiCalendarAlt } from "react-icons/bi";
import { errorMessageChecker } from "../_helpers/methods";
import NepaliDatepicker from "../components/Form/Elements/DatePicker";

export default function DatePicker({
  register,
  onChange,
  value,
  elementId,
  setValue,
  clearErrors,
  mode,
  errors,
  validations,
}) {
  const [date, setDate] = useState(value);
  const onDatePickerChange = (value) => {
    setDate(value);
    setValue(elementId, value);
    clearErrors(elementId);
    if (onChange) {
      onChange(value);
    }
  };
  const onDatePickerChangeBS = (name, dateInMilli, bsDate, adDate) => {
    if (bsDate) {
      setDate(bsDate);
      setValue(elementId, bsDate);
      clearErrors(elementId);
      if (onChange) {
        onChange(bsDate, adDate);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setDate(value);
      setValue(elementId, value);
      clearErrors(elementId);
    }
  }, [clearErrors, elementId, setValue, value]);

  return (
    <StyledDatePicker>
      {mode === "AD" ? (
        <Form.Control
          type="date"
          id={elementId}
          value={date}
          onChange={(e) => onDatePickerChange(e.target.value)}
          className={`form-control
                        ${
                          errorMessageChecker(errors, elementId)
                            ? "is-invalid"
                            : ""
                        } 
                        `}
        />
      ) : (
        <div className="np_input_div">
          <NepaliDatepicker
            id="nepali-date"
            onChange={onDatePickerChangeBS}
            value={date}
          />
          <BiCalendarAlt className="icon" />
        </div>
      )}
      <input {...register(elementId, validations)} hidden readOnly />
    </StyledDatePicker>
  );
}

DatePicker.propTypes = {
  mode: PropTypes.oneOf("AD", "BS"),
};

const StyledDatePicker = styled.div`
  .form-control {
    background: #fff;
  }

  .np_input_div {
    position: relative;
    .icon {
      position: absolute;
      font-size: 16px;
      top: 50%;
      transform: translate(0, -50%);
      right: 10px;
      pointer-events: none;
    }
  }
`;
