import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row } from "react-bootstrap";
// import { useHistory } from 'react-router-dom';
import { FaEye, FaEdit } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { useFilters, usePagination, useRowSelect, useTable } from "react-table";
import { DisabledServices } from "../../api/services/DisabledServices";
import InputField from "../../components/InputField";
import DefaultColumnFilter, {
  StyledFilterInput,
} from "../../components/Table/Filters/DefaultColumnFilter";
import ReactTableRender from "../../components/Table/ReactTableRender";
import { debounce } from "../../_helpers/methods";

const Pratilipi = () => {
  const { pageNum } = useParams();
  const [pageNo, setPageNo] = useState(pageNum);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [queryFilter, setQueryFilter] = useState({});

  const history = useHistory();
  const inputRef = useRef(null);
  // const history = useHistory();

  const fetchPratilipis = useCallback(async () => {
    setLoading(true);
    try {
      const response = await DisabledServices.getDisabledPratilipis({
        page: pageNo ?? 1,
        ...queryFilter,
      });
      setTotalCount(response.count);
      setResults(response.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [pageNo, queryFilter]);

  const ManualFilter = useCallback(({ column }) => {
    const handleOnChange = debounce((value) => {
      setQueryFilter((prev) => ({ ...prev, [column.id]: value }));
    }, 800);

    if (column?.filterType === "Select") {
      let options = column.filterOptions || {};
      return (
        <select
          style={{ width: "100%" }}
          onChange={(e) => {
            handleOnChange(e.target.value || undefined);
          }}
        >
          {Object.keys(options).map((key) => {
            return (
              <option key={key} value={key}>
                {options[key]}
              </option>
            );
          })}
        </select>
      );
    } else {
      if (column?.FilterLanguage === "nepali") {
        return (
          <InputField
            placeholder="खोज्नुहोस ..."
            getInputData={handleOnChange}
            className="filer-input"
          />
        );
      } else {
        return (
          <StyledFilterInput
            ref={inputRef}
            placeholder="खोज्नुहोस ..."
            onChange={(e) => {
              handleOnChange(e.target.value);
            }}
          />
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "क्र. स.",
        accessor: "serial_number",
        disableFilters: true,
        width: "100px",
      },
      {
        Header: "व्यक्तिको नाम",
        accessor: "fullname",
        Filter: ManualFilter,
        width: "200px",
        FilterLanguage: "nepali",
      },
      {
        Header: "प्रतिलिपि मिति",
        accessor: "pratilipi_miti",
        width: "200px",
      },
      {
        Header: "प्रतिलिपि",
        accessor: "oridinal",
        width: "120px",
        disableFilters: true,
      },
      {
        Header: "कार्यहरु",
        accessor: "actions",
        disableFilters: true,
      },
    ],
    [ManualFilter]
  );

  const data = useMemo(() => {
    if (!results) return [];
    let count = 1;
    console.log(results, "results");
    return results.map((result) => {
      const actions = (
        <span className="d-flex align-items-center">
          <FaEye
            className="mr-3 text-info pointer"
            onClick={() => history.push(`/disabled/copy/preview/${result.id}`)}
          />
        </span>
      );

      return {
        serial_number: (pageNo - 1) * 10 + count++,
        fullname: result.original?.name_np,
        pratilipi_miti: result.newCardRecievedDate_bs,
        oridinal: result.oridinal,
        actions: actions,
      };
    });
  }, [history, pageNo, results]);

  useEffect(() => {
    history.push(`/disabled/copy/${pageNo}`);
    try {
      fetchPratilipis();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [fetchPratilipis, history, pageNo]);

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    pageCount,
    previousPage,
  } = useTable(
    {
      columns,
      data: data,
      manualPagination: true,
      pageCount: pageNo,
      defaultCanFilter: false,
      defaultColumn,
    },
    useFilters,
    usePagination,
    useRowSelect
  );

  const pageCountArray = useMemo(
    () =>
      Array.from({ length: Math.ceil(totalCount / pageSize) }, (v, i) => i + 1),
    [totalCount, pageSize]
  );

  const nextPage = (page) => {
    setPageNo(page);
  };
  const gotoPage = (page) => {
    setPageNo(page);
  };
  return (
    <div>
      <div aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" aria-current="page">
            Home
          </li>
          <li class="breadcrumb-item " aria-current="page">
            अपाङ्गता भएका व्यक्तिको विवरणहरु
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            प्रतिलिपीहरु
          </li>
        </ol>
      </div>
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <h2>प्रतिलिपीहरु</h2>
        {/* <Button variant="success" onClick={() => history.push('/disabled/new')}>
					नयाँ फारम
				</Button> */}
      </div>
      <Row>
        <Col>
          <ReactTableRender
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            totalCount={totalCount}
            gotoPage={gotoPage}
            previousPage={previousPage}
            pageCount={pageCount}
            pageCountArray={pageCountArray}
            pageNum={pageNo}
            showGoto={true}
            nextPage={nextPage}
            loading={loading}
            setPageSize={setPageSize}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Pratilipi;
