/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
  FaAddressCard,
  FaEdit,
  FaPlusCircle,
  FaTrashAlt,
} from "react-icons/fa";
import { Link, useParams, useHistory } from "react-router-dom";
import { fetchWards } from "../../../_redux/slices/ward";
import { connect } from "react-redux";
import AddWard from "../Ward/AddWard";
import BackButton from "../../../_elements/BackButton";
import { fetchWrapper } from "../../../_helpers/fetchWrapper";
import { VscLoading } from "react-icons/vsc";
import PaginationComp from "../../../components/Pagination";

const AllUsers = ({ fetchWards, wards, count }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [deleteWard, setDeleteWard] = useState(false);
  const { pageNum } = useParams();
  const [page, setPage] = useState(pageNum);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  useEffect(() => {
    const asyncFetchWard = async () => {
      setIsLoading(true);
      await fetchWards(page);
      setIsLoading(false);
    };
    asyncFetchWard();
  }, [fetchWards, page]);

  const handelWardDelete = async (wardId) => {
    let confirmation = window.confirm(
      "Are you sure you want to delete this ward ?"
    );
    if (confirmation) {
      setDeleteWard(wardId);
      await fetchWrapper.delete(`/ward/${wardId}/`);
      fetchWards(page);
      setDeleteWard(false);
    }
  };
  return (
    <Fragment>
      <BackButton />
      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <AddWard showAdd={showAdd} setShowAdd={setShowAdd} />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>वडा कार्यालयहरु</h2>
              {!showAdd && (
                <Link
                  to="/ward/create"
                  className="btn btn-primary"
                  variant="primary"
                >
                  <FaPlusCircle />
                  &nbsp; नयाँ वडा कार्यालय
                </Link>
              )}
            </Card.Header>
          </Card>
        </Col>
      </Row>
      <Row
        md={12}
        css={css`
          min-height: 400px;
        `}
      >
        {isLoading ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            `}
          >
            <VscLoading className="spin" fontSize={48} />
          </div>
        ) : (
          <Fragment>
            {wards.map((ward, index) => (
              <Col md={3}>
                <div className="icon-container text-center">
                  {deleteWard === ward.id && (
                    <div
                      css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: rgba(255, 255, 255, 0.5);
                      `}
                    >
                      <VscLoading className="spin" />
                    </div>
                  )}
                  <span className="settings-badge position-absolute">1</span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="icon">
                      <Link to={`/settings/warduser/${ward.id}`}>
                        <FaAddressCard size={48} />
                        <h6 className="card-text">
                          वडा {ward.ward_np || ward.ward_en}
                        </h6>
                      </Link>
                    </div>
                    <div>
                      <p>{ward.address_np || ward.address_en}</p>
                      <p>{ward.phone_np || ward.phone_en}</p>
                      <Link to={`/ward/update/${ward.id}`}>
                        <FaEdit className="text-info mr-2" />
                      </Link>
                      <FaTrashAlt
                        className="text-danger"
                        css={css`
                          cursor: pointer;
                        `}
                        onClick={() => handelWardDelete(ward.id)}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Fragment>
        )}
      </Row>
      <PaginationComp page={page} total={count} setPage={handleSetPage} />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  wards: state.wards.wards,
  count: state.wards.count,
});

const mapDispatchToProps = {
  fetchWards,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllUsers);
