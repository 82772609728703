import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const Step1 = () => {
  return (
    <Form className="wizard-content">
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={4}>
              <div className="form-group">
                <label for="first_name">नागरिकता नम्बर</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    aria-label=""
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      खोजी गर्नुहोस
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <div className="col-8">
              <div className="alert alert-danger alert-dismissible show fade mt-4">
                <div className="alert-body">
                  <button className="close" data-dismiss="alert">
                    <span>&times;</span>
                  </button>
                  सेवाग्राहिको विवरण भेटिएन्, कृपया तलका विवरण भर्नुहोस
                </div>
              </div>
            </div>
            {/* <Col md={8}>
              <div className="alert alert-success alert-dismissible show fade mt-4">
                <div className="alert-body">
                  <button className="close" data-dismiss="alert">
                    <span>&times;</span>
                  </button>
                  १२३४ नगरिकता नं रहेका सेवाग्राही शिशिर पाण्डेको विवरण तल
                  प्रविष्ट गरिएको छ । कृपया प्रमाणित गरेर अगाडी बढ्नुहोस ।
                </div>
              </div>
            </Col> */}
            <Col md={4}>
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck2"
                  />
                  <label className="form-check-label" for="defaultCheck2">
                    निवेदकको नागरिकता नखुलेको हकमा यहाँ Click गर्नुहोस
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row>
            <Col md={3}>
              <div className="form-group">
                <label for="first_name">निवेदकको पूरा नाम</label>
                <input
                  id="first_name"
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder=""
                  autofocus
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label for="first_name">नागरिकता नम्बर</label>
                <input
                  id="first_name"
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder=""
                  autofocus
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label for="first_name">नागरिकता जारी जिल्ला</label>
                <input
                  id="first_name"
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder=""
                  autofocus
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label for="first_name">निवेदकको फोन</label>
                <input
                  id="first_name"
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder=""
                  autofocus
                />
              </div>
            </Col>
          </Row>
          <h5 className="text-primary mb-2">स्थायी ठेगाना</h5>
          <Row>
            <Col md={4}>
              <div className="form-group">
                <label for="first_name">प्रदेश</label>
                <select type="text" className="form-control">
                  <option>Province no. 1</option>
                  <option>Province no. 2</option>
                  <option>Bagamati</option>
                  <option>Gandaki</option>
                  <option>Lumbini</option>
                  <option>Karnali</option>
                  <option>Sudurpaschim</option>
                </select>
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                <label for="district">जिल्ला</label>
                <select type="text" className="form-control">
                  <option>Kathmandu</option>
                  <option>Lalitpur</option>
                  <option>Bhaktapur</option>
                  <option>Makawanpur</option>
                  <option>Chitwan</option>
                  <option>Ramechhap</option>
                  <option>Sindhuli</option>
                </select>
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                <label for="first_name">स्थानीय तह</label>
                <select type="text" className="form-control">
                  <option>Kathmandu Metropolitan</option>
                  <option>Lalitpur Sub-Metropolitan</option>
                  <option>Bhaktapur Municipality</option>
                  <option>Makawanpur Sub-Metropolitan</option>
                  <option>Chitwan Sub-Metropolitan</option>
                </select>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default Step1;
