/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { Fragment } from "react";
import { Button, Card, Col, Row, Modal, Form } from "react-bootstrap";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import BackButton from "../../../_elements/BackButton";
import { useForm } from "react-hook-form";
import { updateUserData } from "../../../_redux/slices/users";
import { connect } from "react-redux";
import {
  englishToNepaliNumber as np,
  nepaliToEnglishNumber as en,
} from "nepali-number";

const Profile = ({ updateUserData }) => {
  const user = useSelector((state) => state.users.user);
  const [show, setShow] = React.useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      fullname_en: user?.fullname_en,
      fullname_np: user?.fullname_np,
      email: user?.email,
      phone_np: np(user?.phone_np),
      phone_en: en(user?.phone_en),
    },
  });
  const onEdit = (data) => {
    updateUserData(data);
    setShow(false);
  };

  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <BackButton />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Body
              css={css`
                padding: 2rem 0rem;
              `}
            >
              <Row>
                <Col
                  md="4"
                  sm="12"
                  css={css`
                    margin-bottom: 2rem;
                  `}
                >
                  <div
                    css={css`
                      max-width: 300px;
                      margin: auto;
                    `}
                  >
                    <img
                      src={user.img || "/assets/images/avatar.png"}
                      alt={user.username}
                      css={css`
                        max-width: 100%;
                      `}
                    />
                  </div>
                  <div></div>
                </Col>
                <Col
                  md="4"
                  sm="12"
                  css={css`
                    padding: 0 4rem;
                  `}
                >
                  <div>
                    <h2
                      css={css`
                        color: #000;
                      `}
                    >
                      {user?.fullname || user?.fullname_np}
                    </h2>
                    <p
                      css={css`
                        color: #6b6969;
                      `}
                    >
                      {user.username}
                    </p>
                    <p className="text-primary">{user.role_en}</p>
                  </div>
                  <div className="mt-2 d-flex ">
                    <p className="text-secondary">
                      <FaPhoneAlt
                        css={css`
                          margin-right: 0.2rem;
                        `}
                      />{" "}
                      {user?.phone || user?.phone_np}
                    </p>
                    {user.profile?.address && (
                      <p className="text-secondary ml-4">
                        <FaMapMarkerAlt
                          css={css`
                            margin-right: 0.2rem;
                          `}
                        />{" "}
                        {user.profile?.address}
                      </p>
                    )}
                  </div>
                </Col>
                <Col md={4}>
                  <div className="d-flex justify-content-end px-4">
                    <Button
                      variant="outline-primary"
                      onClick={() => setShow(true)}
                    >
                      Edit Profile
                    </Button>
                  </div>
                  <Modal show={show} onHide={() => setShow(false)} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-flex flex-column">
                        <Form.Group>
                          <Form.Label htmlFor="fullname_en">
                            Full Name(English):
                          </Form.Label>
                          <Form.Control
                            id="fullname_en"
                            type="text"
                            {...register("fullname_en")}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label htmlFor="fullname_np">
                            Full Name(Nepali):
                          </Form.Label>
                          <Form.Control
                            id="fullname_np"
                            type="text"
                            {...register("fullname_np")}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label htmlFor="email">Email</Form.Label>
                          <Form.Control
                            id="email"
                            type="email"
                            {...register("email")}
                          />
                        </Form.Group>
                        <Form.Group className="d-flex">
                          <Form.Label htmlFor="phone_np">
                            Phone(Nepali):
                          </Form.Label>
                          <Form.Control
                            id="phone_np"
                            type="text"
                            {...register("phone_np")}
                          />
                        </Form.Group>
                        <Form.Group className="d-flex">
                          <Form.Label htmlFor="phone_en">
                            Phone(English):
                          </Form.Label>
                          <Form.Control
                            id="phone_en"
                            type="text"
                            {...register("phone_en")}
                          />
                        </Form.Group>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="primary"
                          type="submit"
                          onClick={handleSubmit(onEdit)}
                        >
                          Update
                        </Button>
                        <Button
                          variant="secondary"
                          className="ml-2"
                          onClick={() => setShow(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default connect(null, { updateUserData })(Profile);
