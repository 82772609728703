import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { setUiMessage } from './ui';

export const initialState = {
	loading: false,
	hasErrors: false,
	documentsType: [],
	count: 0,
};

// A slice
const documentTypeSlice = createSlice({
	name: 'documentsType',
	initialState,
	reducers: {
		getDocumentType: (state, action) => {
			state.documentsType = action.payload.data.results;
			state.count = action.payload.data.count;
		},

		addDocumentType: (state, action) => {
			state.documentsType = action.payload;
			state.count = state.count + 1;
		},

		updateDocumentType: (state, action) => {
			const documentTypes = [...state.documentsType];

			const editedIndex = documentTypes.findIndex((doc) => doc.id === action.payload.id);

			if (editedIndex !== -1) {
				documentTypes[editedIndex] = action.payload;
			}
			state.documentsType = documentTypes;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
	},
});

// Actions generated from the slice
const { getDocumentType, addDocumentType, updateDocumentType, setLoading } =
	documentTypeSlice.actions;

// export user selector to get the slice in any component
export const documentTypeSelector = (state) => state.documentsType;

// export The reducer
const documentTypeReducer = documentTypeSlice.reducer;

export default documentTypeReducer;

// Actions
export const fetchDocumentType = (page) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const response = await fetchWrapper.get(`/template/document/?page=${page}`);
		dispatch(getDocumentType(response));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

// Actions
export const createDocumentType = (data, documents, setShowAdd) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const document = await fetchWrapper.post("/template/document/", data);
		const newDocumentTypeList = [document.data, ...documents];

		dispatch(addDocumentType(newDocumentTypeList));
		setShowAdd(false);
		dispatch(setLoading(false));

		dispatch(setUiMessage(document.message));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

// Actions
export const editDocumentType = (data, setShowAdd, setDocumentDetail) => async (dispatch) => {
	try {
		dispatch(setLoading(true));
		const document = await fetchWrapper.patch(`/template/document/${data.id}/`, data);

		dispatch(updateDocumentType(document.data));
		setShowAdd(prev => !prev);
		setDocumentDetail(null);
		dispatch(setUiMessage(document.message));
		dispatch(setLoading(false));
	} catch (error) {
		dispatch(setLoading(false));
	}
};

// Actions
export const deleteDocumentType = (id) => async (dispatch) => {
	const document = await fetchWrapper.delete(`/template/document/${id}/`);
	dispatch(setUiMessage(document.message));
};
