import React, { useMemo } from "react";
import { Pagination } from "react-bootstrap";
import { nepaliNumber } from "../_helpers/methods";

const PaginationComp = ({ total, page, setPage, perPage = 10 }) => {
  const totalCount = useMemo(
    () => Array.from({ length: Math.ceil(total / perPage) }, (v, i) => i + 1),
    [perPage, total]
  );
  return (
    <div className="pagination-wrapper">
      <Pagination>
        <Pagination.First
          disabled={page <= 5}
          onClick={() => (page > 1 ? setPage(page - 5) : null)}
        />
        <Pagination.Prev
          disabled={page <= 1}
          onClick={() => (page > 1 ? setPage(page - 1) : null)}
        />
        {page < 6 ? (
          totalCount
            .filter((count, index) => index < 5)
            // .filter((count, index) => index < 5)
            .map((count) => (
              <Pagination.Item
                active={count === parseInt(page)}
                key={count}
                onClick={() => setPage(count)}
              >
                {nepaliNumber(count)}
              </Pagination.Item>
            ))
        ) : (
          <>
            <Pagination.Item>...</Pagination.Item>
            <Pagination.Item onClick={() => setPage(page - 2)}>
              {page - 2}
            </Pagination.Item>
            <Pagination.Item onClick={() => setPage(page - 1)}>
              {page - 1}
            </Pagination.Item>

            <Pagination.Item active>{page}</Pagination.Item>

            <Pagination.Item onClick={() => setPage(page + 1)}>
              {page + 1}
            </Pagination.Item>

            <Pagination.Item
              onClick={() =>
                page < totalCount.length ? setPage(page + 2) : null
              }
            >
              {page + 2}
            </Pagination.Item>

            <Pagination.Item>...</Pagination.Item>
          </>
        )}

        <Pagination.Next
          disabled={page >= totalCount.length}
          onClick={() => (page <= totalCount.length ? setPage(page + 1) : null)}
        />
        <Pagination.Last
          disabled={page >= totalCount.length - 9}
          onClick={() => (page < totalCount.length ? setPage(page + 5) : null)}
        />
      </Pagination>
    </div>
  );
};

export default PaginationComp;
