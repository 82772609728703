import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { StyledRadioInput } from "../../../../styles/StyledComponents";

function RadioOptions({ defaultValue, options, register, elementId }) {
  return (
    <StyledRadioInput>
      {options?.map((option) => (
        <Fragment key={`${option.id}_${defaultValue}`}>
          <Form.Check
            key={option.id}
            id={option.id}
            value={option.value}
            type="radio"
            name={option.name}
            label={option.label}
            {...register(elementId)}
            defaultChecked={defaultValue?.toString() === option.value}
          />
        </Fragment>
      ))}
    </StyledRadioInput>
  );
}

export default RadioOptions;
