/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
// eslint-disable-next-line
import React, { useState, useRef, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";

import Elements from "./Elements";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { Button, Modal } from "react-bootstrap";
import PreviewModel from "./PreviewModel";
import { StyledFormPage } from "./styles";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

import "./index.css";
import { connect, useDispatch, useSelector } from "react-redux";

import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage } from "../../_redux/slices/ui";
import { languageSelector } from "../../_redux/slices/languages";
import { setConvertedDates } from "../../_redux/slices/formTemplates";
import { generatePreview } from "../../_helpers/methods";
import { incrementPaperCountHandler } from "../../_redux/slices/paper";
import { VscLoading } from "react-icons/vsc";
import { apiKey } from "../../constant";
import { StyledInput } from "../InputField/style/StyledIndex";
import OldPaperFormat from "./Elements/OldPaperFormat/OldPaperFormat";

function FormElements(props) {
  const templateWithPreviewData = props.data;
  const paperData = props.singleValue;
  const formInputValues = props.vals;
  const isPublic = props.isPublic;

  const { language } = useSelector(languageSelector);
  const dispatch = useDispatch();
  const params = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDocs, setShowDocs] = useState(false);
  const [isSaved, setIsSaved] = useState(() => {
    if (paperData?.issue_id) {
      return true;
    }
    return false;
  });
  const [issueId, setIssueId] = useState(() => {
    if (paperData?.issue_id) {
      return paperData?.issue_id;
    }
  });

  const [files, setFiles] = useState({});
  const [wardInfo, setWardInfo] = useState([]);
  const [province, setprovince] = useState(formInputValues?.province);
  const [district, setDistrict] = useState(formInputValues?.district);
  const [palika, setPalika] = useState(formInputValues?.palika);
  const [show, setShow] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [previewFormData, setPreviewFormData] = useState({});
  const user = useSelector((state) => state.users.user);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [wardAdmin, setWardAdmin] = useState(false);
  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleIsSaved = (value) => {
    setIsSaved(value);
  };
  const handleIssueId = (value) => {
    setIssueId(value);
  };

  const history = useHistory();

  const handleFileLoad = (title, file) => {
    let tempFiles = { ...files };
    tempFiles[title] = file;
    setFiles(tempFiles);
  };

  useEffect(() => {
    const getWardInfo = async () => {
      try {
        let response = await fetchWrapper.get("/public/all-ward/");
        setWardInfo(response.data);
      } catch (err) {}
    };
    if (isPublic) {
      getWardInfo();
    }
  }, [isPublic]);

  const onSubmit = (formData) => {
    let FORMDATA = {
      ...formInputValues,
      ...formData,
    };
    setPreviewFormData(FORMDATA);
    if (isPublic) {
      const requiredFiles = Object.values(files);
      if (getValues("ward") === "वडा छान्नु होस्") {
        toast.error("वडा छान्नु होस्");
        return null;
      }
      if (
        templateWithPreviewData.documents.length &&
        (requiredFiles.length === 0 || requiredFiles.includes(undefined))
      ) {
        toast.error("add the required Documents");
        return null;
      }
      handleTemplateSave(FORMDATA);
    } else {
      // if (!getValues('charge')) {
      // 	toast.error('add the required charge');
      // 	return null;
      // }
      // if (!getValues('invoice_detail.invoice_no')) {
      // 	toast.error('add the required invoice number');
      // 	return null;
      // }
      // console.log(data);
      setWardAdmin(formData?.ward_admin);
      if (templateWithPreviewData?.preview) {
        const tempPreviewData = generatePreview(
          templateWithPreviewData,
          FORMDATA,
          user
        );
        setPreviewData(tempPreviewData);
      }
      handleShow();
    }
  };
  const handleSelectProvince = (provinceVal) => {
    setprovince(provinceVal);
  };

  const handleSelectDistrict = (districtVal) => {
    setDistrict(districtVal);
  };

  const handleSelectPalika = (palikaVal) => {
    setPalika(palikaVal);
  };
  const [savedPaperId, setSavedPaperId] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current;
    },
    onAfterPrint: () => {
      try {
        setIsPrint(true);
        dispatch(incrementPaperCountHandler(params.paperId));
      } catch (err) {
        console.log(err, "Error after print");
      }
    },
  });
  useEffect(() => {
    if (isPrint && savedPaperId) {
      dispatch(incrementPaperCountHandler(savedPaperId));
      setIsPrint(false);
    }
  }, [isPrint, savedPaperId, dispatch]);

  const handleTemplateSave = async (
    val,
    preview,
    isFromSaveAndPrint = false
  ) => {
    // check preview from handleTemplateSave
    const body = {
      values: templateWithPreviewData,
      form_values: val,
      invoice_detail: val.invoice_detail,
    };
    if (val.charge) {
      body.charge = val.charge;
    }
    if (isFromSaveAndPrint) {
      body._save_and_print = true;
    }
    setIsSubmitting(true);
    // dispatch(startUiLoading());
    if (formInputValues) {
      delete body.applicant;
      return fetchWrapper
        .patch(`/paper/${params.paperId}/`, body)
        .then((res) => {
          dispatch(setUiMessage("paper updated!"));
          // dispatch(stopUiLoading());
          // history.push("/papers/verified")
          setIsSubmitting(false);
          return res;
        })
        .catch((err) => {
          setIsSubmitting(false);
          // dispatch(stopUiLoading());
          let errorMessage = err.error?.non_field_error || err.message;
          errorMessage && toast.error(errorMessage[0]);
          return false;
        });
    } else {
      if (isPublic) {
        let paperId;
        // body.values = data.values;
        body.template = params.id;
        //need to add template preview here
        let wardId = val.ward;
        body.ward = wardId;
        let selectedWard = wardInfo.filter((wardI) => wardI.id === +wardId)[0];
        if (templateWithPreviewData.category_title === "English Format") {
          body.form_values.ward_admin = `${selectedWard.fullname_en}_Ward Admin`;
        } else {
          body.form_values.ward_admin = `${selectedWard.fullname_np}_वडा अध्यक्ष`;
        }

        let createPaperResponse = await fetchWrapper
          .post(`/public/create-paper/`, body)
          .then((res) => {
            paperId = res.data.id;
            // dispatch(setUiMessage("paper saved!"));
            // dispatch(stopUiLoading());
            // history.push("/papers/verified")
            let token = res.data.token;
            setIsSubmitting(false);
            history.push(`/citizen-template/paper-submitted/${token}`);
            return res;
          })
          .catch((err) => {
            console.log(err, "err");
            // dispatch(stopUiLoading());
            let errorMessage = err.error?.non_field_error || err.message;
            errorMessage && toast.error(errorMessage[0]);
            setIsSubmitting(false);
            return false;
          });

        if (createPaperResponse && templateWithPreviewData.documents) {
          let formData = new FormData();
          let keysArray = Object.keys(files);

          for (let i = 0; i < keysArray.length; i++) {
            let documentTitle = keysArray[i];
            let document = files[documentTitle];
            if (document) {
              formData.append("paper", paperId);
              formData.append("title", documentTitle);
              formData.append("file", document);

              await fetch(
                process.env.REACT_APP_BACKEND_URL + "/public/upload-document/",
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    apikey: apiKey,
                  },
                  body: formData,
                }
              );
            }
          }
        }
        return createPaperResponse;
      }
      body.paper_status = "Approved";

      if (savedPaperId) {
        return fetchWrapper
          .patch(`/paper/${savedPaperId}/`, body)
          .then((res) => {
            dispatch(setUiMessage("paper updated!"));
            setIsSubmitting(false);
            return res;
          })
          .catch((err) => {
            let errorMessage = err.error?.non_field_error || err.message;
            errorMessage && toast.error(errorMessage[0]);
            setIsSubmitting(false);
            return false;
          });
      }
      return await fetchWrapper
        .post(`/template/${params.id}/paper/`, body)
        .then((res) => {
          dispatch(setUiMessage("paper saved!"));
          setSavedPaperId(res.data.id);
          setIsSubmitting(false);
          return res;
        })
        .catch((err) => {
          let errorMessage = err.error?.non_field_error || err.message;
          errorMessage && toast.error(errorMessage[0]);
          setIsSubmitting(false);
          return false;
        });
    }
  };

  useEffect(() => {
    setConvertedDates([]);
  }, []);

  return (
    <StyledFormPage>
      <div
        css={css`
          margin-bottom: 2rem;
        `}
      >
        <u className="h3"> {templateWithPreviewData.title} </u>
        {isPublic ? (
          <div>
            <div className="float-right">
              <div>
                <label
                  css={css`
                    font-weight: 500;
                  `}
                >
                  वडा छान्नु होस् :
                </label>
                <select
                  {...register("ward", { required: true })}
                  css={css`
                    width: fit-content;
                  `}
                  defaultValue={formInputValues && formInputValues.ward}
                >
                  <option>वडा छान्नु होस्</option>
                  {wardInfo?.map((ward) => (
                    <option value={`${ward.id}`} key={`${ward.id}`}>
                      {ward.name_np}
                    </option>
                  ))}
                </select>
              </div>
              {/* <p className="float-right text-danger">Please select ward</p> */}
            </div>
          </div>
        ) : (
          <Fragment>
            {templateWithPreviewData?.meta?.ward_kawa?.length > 0 ? (
              <div className="float-right">
                {templateWithPreviewData.category_title === "English Format"
                  ? "Act. Chairman"
                  : "वडा का.वा"}{" "}
                <span>:</span>{" "}
                {`${
                  templateWithPreviewData?.meta?.ward_kawa[0][
                    templateWithPreviewData.category_title === "English Format"
                      ? "name_en"
                      : "name_np"
                  ]
                }
                `}
                <input
                  {...register("ward_admin")}
                  value={`${
                    templateWithPreviewData?.meta?.ward_kawa[0][
                      templateWithPreviewData.category_title ===
                      "English Format"
                        ? "name_en"
                        : "name_np"
                    ]
                  }_${
                    templateWithPreviewData?.meta?.ward_kawa[0][
                      templateWithPreviewData.category_title ===
                      "English Format"
                        ? "post_en"
                        : "post_np"
                    ]
                  }`}
                  hidden
                />
              </div>
            ) : (
              templateWithPreviewData?.meta?.ward_admins && (
                <div className="float-right">
                  <label>
                    {templateWithPreviewData.category_title === "English Format"
                      ? "Ward Admins"
                      : "वडा अध्यक्ष"}
                  </label>
                  <select
                    {...register("ward_admin")}
                    css={css`
                      width: fit-content;
                    `}
                    defaultValue={formInputValues && formInputValues.ward_admin}
                  >
                    {templateWithPreviewData.meta.ward_admins.map(
                      (ward_admins) => (
                        <option
                          value={`${
                            ward_admins[
                              templateWithPreviewData.category_title ===
                              "English Format"
                                ? "name_en"
                                : "name_np"
                            ]
                          }_${
                            ward_admins[
                              templateWithPreviewData.category_title ===
                              "English Format"
                                ? "post_en"
                                : "post_np"
                            ]
                          }`}
                          key={`${ward_admins.name_np}_${ward_admins.post_np}`}
                        >
                          {
                            ward_admins[
                              templateWithPreviewData.category_title ===
                              "English Format"
                                ? "name_en"
                                : "name_np"
                            ]
                          }
                        </option>
                      )
                    )}
                  </select>
                </div>
              )
            )}
          </Fragment>
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isPublic ? null : (
          <div
            className="pt-2"
            css={css`
              width: 100%;
              display: flex;
              justify-content: space-between;
              border-top: 1px solid #333;
              label {
                width: max-content;
                margin-right: 0.5rem;
              }
            `}
          >
            <div>
              <label htmlFor="charge">
                {templateWithPreviewData?.category_title === "English Format"
                  ? "Charge :"
                  : "दस्तुर :"}
              </label>
              <StyledInput
                id="charge"
                type="text"
                defaultValue={paperData?.charge}
                language="nepali"
                {...register("charge", {
                  pattern: /([0-9०-९]+\.[0-9०-९]+)|([0-9०-९]+)$/,
                })}
              />
            </div>
            <div>
              <label htmlFor="invoiceNo">
                {templateWithPreviewData?.category_title === "English Format"
                  ? "Invoice no. :"
                  : "रसिद नं. :"}
              </label>
              <StyledInput
                id="charge"
                type="text"
                defaultValue={paperData?.invoice_detail?.invoice_no}
                {...register("invoice_detail.invoice_no", {
                  pattern: /[0-9०-९/-]+/,
                })}
              />
            </div>
          </div>
        )}
        <div>
          {!templateWithPreviewData?.config?.isNewFormat ? (
            <OldPaperFormat
              language={language}
              province={province}
              handleSelectProvince={handleSelectProvince}
              district={district}
              handleSelectDistrict={handleSelectDistrict}
              palika={palika}
              handleSelectPalika={handleSelectPalika}
              printRef={printRef}
              data={templateWithPreviewData}
              register={register}
              setValue={setValue}
              control={control}
            />
          ) : (
            <Elements
              language={language}
              elements={templateWithPreviewData.values}
              register={register}
              errors={errors}
              setValue={setValue}
              control={control}
              values={formInputValues}
              category={templateWithPreviewData.category_title}
              isPublic={isPublic}
            />
          )}
        </div>

        <div
          css={css`
            background: white;
            padding: 1rem;
            border-radius: 10px;
            margin-bottom: 2rem;
          `}
        >
          <h5
            css={css`
              margin-bottom: 0.5rem;
            `}
          >
            {isPublic && templateWithPreviewData.documents.length
              ? "आवश्यक कागजात अपलोड गर्नुहोस:"
              : "आवश्यक कागजात (कृपया कागजात अपलोड गर्नुहोस वा अगाडी बढ्नुहोस् |):"}
          </h5>
          <ul>
            {templateWithPreviewData.documents?.length > 0 &&
              templateWithPreviewData.documents.map((document) => {
                return (
                  <li key={document.id}>
                    {document.title}:{" "}
                    <input
                      type="file"
                      accept="image/*,.pdf"
                      onChange={(e) => {
                        setShowDocs(null);
                        e.target.files
                          ? handleFileLoad(document.title, e.target.files[0])
                          : handleFileLoad(document.title, null);
                      }}
                    />
                  </li>
                );
              })}
          </ul>
          {showDocs !== null && paperData?.documents?.length > 0 && (
            <div className="">
              {paperData.documents.map((document) => (
                <span key={document.id} className="text-primary pointer mr-4">
                  <u onClick={() => setShowDocs(document.file)}>
                    {document.title}
                  </u>
                </span>
              ))}
              <Modal show={showDocs} onHide={() => setShowDocs(false)}>
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[{ uri: showDocs }]}
                />
              </Modal>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between mb-3">
          {/* <FileInputModal files={data.files} setFiles={setFiles} /> */}
          {isPublic ? (
            <Button
              variant="primary"
              size="lg"
              disabled={isSubmitting}
              onClick={handleSubmit(onSubmit)}
            >
              {isSubmitting ? <VscLoading className="spin" /> : "Submit"}
            </Button>
          ) : (
            <Fragment>
              <Button
                variant="primary"
                size="lg"
                disabled={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? (
                  <VscLoading className="spin" />
                ) : (
                  "Preview & Print"
                )}
              </Button>
              {show && (
                <PreviewModel
                  handleClose={handleClose}
                  show={show}
                  innerRef={printRef}
                  isSaved={isSaved}
                  handleIsSaved={handleIsSaved}
                  issueId={issueId}
                  handleIssueId={handleIssueId}
                  paper={
                    <div className="page">
                      <div className="content">
                        <Elements
                          register={register}
                          language={language}
                          elements={templateWithPreviewData?.values}
                        />
                      </div>
                    </div>
                  }
                  files={files}
                  previewFormData={previewFormData}
                  previewData={previewData}
                  wardAdmin={wardAdmin}
                  paperExtra={props.paperExtra}
                  handlePrint={handlePrint}
                  handleTemplateSave={handleTemplateSave}
                  config={templateWithPreviewData.config}
                  meta={templateWithPreviewData.meta}
                  paperId={params.paperId}
                  isEnglish={
                    templateWithPreviewData.category_title === "English Format"
                  }
                  isCitizenTemplate={
                    params?.citizen && params?.citizen === "citizen"
                      ? true
                      : false
                  }
                  documents={paperData?.documents}
                />
              )}
            </Fragment>
          )}
        </div>
      </form>
    </StyledFormPage>
  );
}

export default connect(null, { setConvertedDates })(FormElements);
