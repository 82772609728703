/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

function Heading({ data }) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 1.5rem 0;
      `}
    >
      {data.logo ? (
        <div
          css={css`
            position: absolute;
            left: 0;
          `}
        >
          <img
            css={css`
              width: 100px;
            `}
            src={data.logo}
            alt="logo"  
          />
        </div>
      ) : null}
      <div
        css={css`
          width: 50%;
        `}
        dangerouslySetInnerHTML={{ __html: data.data }}
      />
    </div>
  );
}

export default Heading;
