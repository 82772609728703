/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useState } from "react";
import { jsx, css } from "@emotion/react";
import { useForm } from "react-hook-form";
import { connect, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Container, Row, Form, Col } from "react-bootstrap";

//
import { loginUser, loginUserWIthSSO } from "../../_redux/slices/users";

//
import { eye, formInput } from "../../layout/ChangePasswordModal";

//
import { logValidationSchema } from "../../_helpers/schema-validation";

//
import Loader from "../../_elements/Loader";
import Versioning from "../../components/Versioning";

//
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiLogIn } from "react-icons/bi";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

const ssoTitle = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO_TITLE;
const sso =
  process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true" ||
  process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === true;

const Login = ({ loginUser, loginUserWIthSSO }) => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(logValidationSchema),
  });
  const loading = useSelector((state) => state.users.loading);
  const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
  const location = useLocation();

  const token = new URLSearchParams(location.search).get("token");

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const getTokens = async () => {
      try {
        const response = await fetchWrapper.get(`/oauth/login/?token=${token}`);
        loginUserWIthSSO(response.data, history);
      } catch (error) {}
    };
    try {
      if (token) {
        getTokens();
      }
    } catch (error) {}
  }, [token, history, loginUserWIthSSO]);

  // const user = useSelector((state) => state.users.user);
  const onSubmit = async (data) => {
    // login api
    loginUser(data, history);
  };

  const handleSSO = () => {
    const redirectLink = document.createElement("a");
    let link = `${process.env.REACT_APP_SSO_URL}/o/consent/${process.env.REACT_APP_APPLICATION_ID}`;
    // redirectLink.target = "_blank";
    if (process.env.NODE_ENV === "development") {
      link += `?development=true`;
    }
    redirectLink.href = link;
    redirectLink.click();
  };

  // useEffect(() => {
  //   const showLoginPage = localStorage.getItem("showLoginPage");
  //   if (!showLoginPage) {
  //     localStorage.setItem("showLoginPage", "true");
  //     handleSSO();
  //   }
  // }, []);

  return (
    <section className="login section">
      <Container className="login-container">
        <Row className="mt-5 justify-content-center">
          {/* <Col
						sm="12"
						lg="8"
						css={css`
							display: flex;
							justify-content: center;
							align-items: center;
						`}
					>
						<div className="login-register w-100">
							<Card className="login-box">
								<Card.Body>
									<LoginHead nagarpalika={nagarpalika} />
									<h4 className="brand_red mb-3 text-center p-3">
										<strong>अनलाइन परिचय पत्र ब्यबस्थापन प्रणालीमा स्वागत छ!!</strong>
									</h4>
									<div className="row">
										<div
											className="col-12 text-center mb-3"
											css={css`
												color: white;
												transition: transform 200ms;
												:hover {
													transform: scale(1.05);
												}
											`}
										>
											<Link to="/citizens">
												<Button variant="success" className="btn-lg">
													सामाजिक सुरक्षा अनलाइन डाटा
												</Button>
											</Link>
										</div>
										<div
											className="col-12 text-center mb-3"
											css={css`
												color: white;
												transition: transform 200ms;
												:hover {
													transform: scale(1.05);
												}
											`}
										>
											<Button variant="secondary" className="btn-lg">
												<Link
													to="/status"
													css={css`
														color: white;
														:hover {
															color: white;
														}
													`}
												>
													सामाजिक सुरक्षा परिचय पत्र जांच
												</Link>
											</Button>
										</div>
									</div>
								</Card.Body>
							</Card>
						</div>
					</Col> */}

          <Col sm={12} lg="6">
            <div
              className="login-brand d-flex justify-content-center"
              style={{
                gap: "1rem",
              }}
            >
              {nagarpalika?.main_logo && (
                <div>
                  <img
                    className="loginBrand__logo"
                    src={nagarpalika?.main_logo}
                    alt={nagarpalika?.name_np}
                  />
                </div>
              )}

              <div>
                <h3 className="mb-2">परिचय पत्र ब्यबस्थापन प्रणाली</h3>

                {nagarpalika?.name_np && (
                  <h5 className="loginBrand__nagarpalika">
                    {nagarpalika?.name_np}
                  </h5>
                )}

                <p>प्रविधिमुखी सार्वजनिक सेवा प्रवाहको सुनिश्चितताको प्रयास</p>
              </div>

              {nagarpalika?.campaign_logo && (
                <div>
                  <img
                    className="loginCampaignBrand__logo"
                    src={nagarpalika?.campaign_logo}
                    alt={nagarpalika?.name_np}
                  />
                </div>
              )}
            </div>

            <div className="card card-primary">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="username">
                    <label className="label" htmlFor="email">
                      ईमेल
                    </label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      css={formInput}
                      className="username__input"
                      tabindex="1"
                      required
                      autofocus
                      {...register("email")}
                    />
                    <div className="invalid-feedback">
                      कृपया तपाईंको ईमेल भर्नुहोस्
                    </div>
                    <p className="text-small text-danger">
                      {errors.email?.message}
                    </p>
                  </div>

                  <div className="form-group">
                    <div className="d-flex w-100 justify-content-between">
                      <label htmlFor="password" className="label">
                        पासवर्ड
                      </label>
                      <div className="float-right">
                        <Link to="/forgot-password" className="text-small">
                          पासवर्ड भुल्नु भयो?
                        </Link>
                      </div>
                    </div>

                    <div className="position-relative">
                      <Form.Control
                        css={formInput}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        {...register("password")}
                        tabindex="2"
                      />
                      <div css={eye}>
                        {showPassword ? (
                          <AiFillEyeInvisible
                            css={css`
                              color: grey;
                            `}
                            onClick={() => setShowPassword(false)}
                          />
                        ) : (
                          <AiFillEye
                            css={css`
                              color: grey;
                            `}
                            onClick={() => setShowPassword(true)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="invalid-feedback">
                      कृपया तपाईको पासवर्ड भर्नुहोस्
                    </div>
                    <p className="text-small text-danger">
                      {errors.password?.message}
                    </p>
                  </div>
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        name="remember"
                        className="custom-control-input"
                        tabindex="3"
                        id="remember-me"
                      />
                      <label
                        className="custom-control-label text-muted"
                        htmlFor="remember-me"
                      >
                        मलाई सम्झनुहोस्
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <Button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block d-flex align-items-center justify-content-center"
                      tabindex="4"
                    >
                      <BiLogIn className="mr-2" />
                      लगइन
                    </Button>
                  </div>
                  {sso && (
                    <div className="form-group">
                      <Button
                        type="button"
                        className="btn btn-primary btn-lg btn-block"
                        onClick={handleSSO}
                        disabled={!sso}
                      >
                        {ssoTitle || "Login with SSO"}
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <div className="col-12 mt-5">
            <div className="simple-footer text-muted">
              Copyright &copy; {new Date().getFullYear()}{" "}
              <a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
                Ideabreed IT Solution Pvt. Ltd.
              </a>
              <Versioning color="#8a8080" />
            </div>
          </div>
        </Row>
      </Container>

      {loading && <Loader />}
    </section>
  );
};

export default connect(null, { loginUser, loginUserWIthSSO })(Login);
