/** @jsxRuntime classic */
/** @jsx jsx */
import { Button, Container, Row } from 'react-bootstrap';
import { jsx } from '@emotion/react';
import Loader from '../../_elements/Loader';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUiMessage, startUiLoading, stopUiLoading } from '../../_redux/slices/ui';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { useHistory } from 'react-router-dom';
import BackButton from '../../_elements/BackButton';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { formInput } from '../../layout/ChangePasswordModal';

function ForgotPassword(props) {
	const [email, setEmail] = useState('');
	const history = useHistory();
	const loading = useSelector((state) => state.users.loading);
	const [isLoading, setIsloading] = useState(false);
	const dispatch = useDispatch();

	const handleSubmit = () => {
		dispatch(startUiLoading);
		if (email) {
			setIsloading(true);
			fetchWrapper
				.post('/user/reset_password/', { email: email })
				.then((res) => {
					dispatch(setUiMessage('कृपया तपाइँको ईमेल हेर्नुहोस्'));
					setIsloading(false);
					history.push('/');
				})
				.catch((err) => {
					setIsloading(false);
					toast.error(err.error[0]);
				});
		}
		dispatch(stopUiLoading);
	};
	return (
		<section className="section">
			<Container>
				<Row className="mt-5">
					<div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
						<div className="login-brand">
							<img src="/assets/images/nep_logo.png" alt="logo" width="100" className="shadow-light rounded-circle" />
							<h3>परिचय पत्र ब्यबस्थापन प्रणाली</h3>
							<p>प्रविधिमुखी सार्वजनिक सेवा प्रवाहको सुनिश्चितताको प्रयास</p>
						</div>
						<div className="card card-primary">
							<div className="card-header">
								<h3 style={{ fontSize: '24px' }}>पासवर्ड रिसेट</h3>
							</div>
							<div className="card-body">
								<form className="needs-validation">
									<div className="form-group">
										<label for="email">ईमेल</label>
										<input
											id="email"
											type="username"
											css={formInput}
											name="email"
											tabindex="1"
											placeholder="तपाइँको ईमेल लेख्नुहोस्"
											required
											onChange={(e) => setEmail(e.target.value)}
											autofocus
										/>
										<div className="invalid-feedback">कृपया तपाईंको ईमेल भर्नुहोस्</div>
										<p className="text-small text-danger"></p>
									</div>

									<div className="form-group">
										<Button onClick={handleSubmit} className="btn btn-success btn-lg btn-block">
											{isLoading ? <VscLoading className="spin" /> : 'पासवर्ड रिसेट ईमेल पठाउनुहोस्'}
										</Button>
									</div>

									<BackButton />
								</form>
							</div>
						</div>
						<div className="text-muted simple-footer">
							Copyright &copy; {new Date().getFullYear()}{' '}
							<a href="https://ideabreed.net/" target="_blank" rel="noreferrer">
								Ideabreed IT Solution Pvt. Ltd.
							</a>
						</div>
					</div>
				</Row>
			</Container>
			{loading && <Loader />}
		</section>
	);
}

export default ForgotPassword;
