import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import InputField from "../../../../../InputField";
import "./DistrictModal.scss";


function DistrictModal({
  show,
  handleClose,
  districts,
  language,
  handleDistrictChangeByCode,
}) {
  districts = Object.values(districts);
  const [filteredDistrict, setFilteredDistrict] = useState(districts);

  const searchDistrictByName = (name) => {
    let tempFilteredDistrict = districts.filter((district) =>
      String(district[language])
        .toLocaleLowerCase()
        .includes(String(name).toLowerCase())
    );
    setFilteredDistrict(tempFilteredDistrict);
  };

  const searchDistrictByCode = (code) => {
    let tempFilteredDistrict = districts.filter((district) =>
      String(district.id).includes(String(code))
    );
    setFilteredDistrict(tempFilteredDistrict);
  };

  const handleDistrictSelect = (districtId) => {
    handleDistrictChangeByCode(districtId);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>जिल्ला खोज्नुहोस्</Modal.Header>
      <Modal.Body>
        <div className="modal_main">
          <div className="inputs_section">
            <div className="code_section">
              <div>Code: </div>
              <input
                type="number"
                className="code_input"
                onChange={(e) => {
                  searchDistrictByCode(e.target.value);
                }}
              />
            </div>
            <div className="description_section">
              <div>Description: </div>

              <InputField
                language={language}
                className={`description_input`}
                getInputData={(value) => searchDistrictByName(value)}
              />
            </div>
          </div>
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <td>Code</td>
                  <td>Description</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {filteredDistrict.map((district) => {
                  return (
                    <tr key={district.id}>
                      <td>{district.id}</td>
                      <td>{district[language]}</td>
                      <td>
                        <Button
                          onClick={() => handleDistrictSelect(district.id)}
                        >
                          छान्नु होस्{" "}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DistrictModal;
