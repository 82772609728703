export const apiKey = "3753bd03b970acfb13aba03a95e3728f71bf05be";
export const FILE_SIZES = {
    MAX_IMAGE_FILE_SIZE: 5 * 1024 * 1024,
}
export const colorClass = [
    "purple-shade",
    "yellow-shade",
    "red-shade",
    "orange-shade",
    "green-shade",
    "sky-shade",
];

export const DashBoardChartData = [
    {
        title: 'जेस्ठ नागरिक',
        id: 'elderly'
    },
    {
        title: 'अपाङ्गता',
        id: 'disabled'
    },
];

export const FINGER_PRINT_DATA = {
    1: 'कान्छी औंला (बायाँ हात)',
    2: 'साइली औंला (बायाँ हात)',
    3: 'माजी औंला (बायाँ हात)',
    4: 'चोर औंला (बायाँ हात)',
    5: 'बुढी औंला (बायाँ हात)',
    6: 'बुढी औंला (दायाँ हात)',
    7: 'चोर औंला (दायाँ हात)',
    8: 'माजी औंला (दायाँ हात)',
    9: 'साइली औंला (दायाँ हात)',
    10: 'कान्छी औंला (दायाँ हात)',
    11: 'कान्छी औंला (बायाँ खुट्टा)',
    12: 'साइली औंला (बायाँ खुट्टा)',
    13: 'माजी औंला (बायाँ खुट्टा)',
    14: 'चोर औंला (बायाँ खुट्टा)',
    15: 'बुढी औंला (बायाँ खुट्टा)',
    16: 'बुढी औंला (दायाँ खुट्टा)',
    17: 'चोर औंला (दायाँ खुट्टा)',
    18: 'माजी औंला (दायाँ खुट्टा)',
    19: 'साइली औंला (दायाँ खुट्टा)',
    20: 'कान्छी औंला (दायाँ खुट्टा)',
}