import React from "react";

function Checkbox({ text, register, validation, elementId, value, disabled }) {
  return (
    <div>
      <span className="mr-2">
        {text}
      </span>
      <input type="checkbox" {...register(elementId, validation)} defaultChecked={value} disabled={disabled} />
    </div>
  );
}

export default Checkbox;
