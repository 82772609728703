import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import { fetchWards } from "../../../_redux/slices/ward";
import { connect } from "react-redux";
import AddWard from "./AddWard";
import BackButton from "../../../_elements/BackButton";

const Ward = ({ fetchWards, wards }) => {
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    fetchWards();
  }, [fetchWards]);
  return (
    <Fragment>
      <BackButton />

      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <AddWard showAdd={showAdd} setShowAdd={setShowAdd} />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h2>वडा कार्यालयहरु</h2>
              {!showAdd && (
                <Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
                  <FaPlusCircle />
                  &nbsp; नयाँ वडा कार्यालय
                </Button>
              )}
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <tr>
                    <th className="text-center">वडा नम्बर</th>
                    <th className="text-center">फोन</th>
                    <th className="text-center">ठेगाना</th>
                    <th className="text-center">ईमेल</th>
                    <th className="text-center">अध्यक्ष</th>
                    <th className="text-center">कार्यहरू</th>
                  </tr>
                  {wards.map((ward, index) => (
                    <tr>
                      <td className="text-center">{ward.name}</td>
                      <td className="text-center">{ward.phone}</td>
                      <td className="text-center">{ward.address}</td>
                      <td className="text-center">{ward.email}</td>
                      <td className="text-center"></td>
                      <td className="text-center">
                        <Button variant="info" className="mr-1">
                          <FaEdit /> सम्पादन
                        </Button>
                        <Button variant="danger">
                          <FaTrash /> हटाउनु
                        </Button>
                      </td>
                    </tr>
                  ))}
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  wards: state.wards.wards,
});

const mapDispatchToProps = {
  fetchWards,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ward);
