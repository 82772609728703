/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useState } from "react";
import { useMemo } from "react";
import { Button } from "react-bootstrap";
import { StyledSignature } from "./_styles/SignatureStyle";
import SignaturePad from "react-signature-canvas";
import { ResizableInput } from "../../../_elements";

import { BiEraser } from "react-icons/bi";
import { MdDone } from "react-icons/md";

function Signature({ data, language }) {
  let sigPad = useMemo(() => ({}), []);
  const [showDoneBtn, setShowDoneBtn] = useState(true);
  const [trimmedDataURL, setTrimmedDataURL] = useState("");

  const clear = (e) => {
    e.preventDefault();
    // clear signature if not empty
    if (!sigPad.isEmpty()) {
      sigPad.clear();
      sigPad.on();
      setTrimmedDataURL("");
      setShowDoneBtn(true);
    }
  };

  const trim = (e) => {
    e.preventDefault();
    if (sigPad.isEmpty()) {
      alert("Signature cannot be blank!");
    } else {
      setShowDoneBtn(false);
      sigPad.off();
      setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    }
  };

  return (
    <StyledSignature>
      <div className="signaturePadContainer">
        <div className="signaturePad">
          <SignaturePad
            canvasProps={{ width: "230", height: "150", className: "sigPad" }}
            ref={(ref) => {
              sigPad = ref;
            }}
          />
          <input
            type="text"
            //   ref={
            //     elementRef &&
            //     elementRef({
            //       required: data.hasOwnProperty("required") ? data.required : true,
            //     })
            //   }
            onChange={setTrimmedDataURL}
            value={trimmedDataURL}
            className="hiddenInput"
          />
        </div>
        <div className="actionBtnsContainer">
          {trimmedDataURL && (
            <Button variant="secondary" onClick={clear}>
              <BiEraser fontSize="28" />
            </Button>
          )}

          {showDoneBtn && (
            <Button variant="primary" onClick={trim}>
              <MdDone fontSize="28" />
            </Button>
          )}
        </div>
      </div>

      <div className="textContainer">
        <div className="inputFields">
          <ResizableInput
            type="text"
            language={language}
            maxlength={50}
            className={""}
            placeholder={"Name"}
          />
        </div>
        <div className="inputFields">
          <ResizableInput
            type="text"
            language={language}
            maxlength={50}
            className={""}
            placeholder={"Post"}
          />
        </div>
      </div>
    </StyledSignature>
  );
}

export default Signature;
