import React, { useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import Layout from "../../../layout/Layout";
import BackButton from "../../../_elements/BackButton";
import AddFormalMunicipal from "./AddFormerMunicipal";

const FormerMunicipal = () => {
  const [showAdd, setShowAdd] = useState(false);
  return (
    <Layout>
      <Row>
        <Col>
          <BackButton />
        </Col>
      </Row>
      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <AddFormalMunicipal showAdd={showAdd} setShowAdd={setShowAdd} />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card>
            <Card.Header className="card-header-custom">
              <h3>साविकको गा.वि.स/नगरपालिका</h3>
              <Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
                <FaPlusCircle />
                &nbsp; नयाँ साविकको गा.वि.स/नगरपालिका
              </Button>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <tr>
                    <th>शीर्षक</th>
                    <th>साविकको वडा</th>
                    <th className="text-center">कार्यहरू</th>
                  </tr>
                  <tr>
                    <td>नयाँगाउँ</td>
                    <td>वडा नम्बर १</td>
                    <td className="text-center">
                      <Button variant="danger">
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default FormerMunicipal;
