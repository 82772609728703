/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { upperData, lowerData } from "./SidebarData";
import SidebarButtonRenderer from "./SidebarButtonRenderer";
import { languageSelector } from "../../_redux/slices/languages";

const Sidebar = () => {
  const nagarpalika = useSelector((state) => state.nagarpalika.nagarpalika);
  const nagarpalikaInfo = useSelector(
    (state) => state.users?.user?.profile?.nagarpalika_info
  );
  const user = useSelector((state) => state.users?.user);
  const { language } = useSelector(languageSelector);

  const role = user?.role || user?.role_en;
  const isSSO = process.env.REACT_APP_FEATURE_LOGIN_WITH_SSO === "true";

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-top">
        <a
          href={
            isSSO ? process.env.REACT_APP_SSO_URL + "/application/user" : "/"
          }
        >
          <img
            src={nagarpalika?.main_logo || "/assets/images/nep_logo.png"}
            className="logo"
            alt=""
          />
        </a>
        {nagarpalikaInfo ? (
          <div
            css={css`
              word-break: break-word;
            `}
          >
            <h5>
              {language === "english"
                ? nagarpalikaInfo.name_en
                : nagarpalikaInfo.name_np}
            </h5>
            <p
              css={css`
                font-size: 1.1rem;
              `}
            >
              {language === "nepali" ? (
                <Fragment>
                  {role === "ito_admin"
                    ? "नगर कार्यपालिकाको कार्यालय"
                    : user?.profile?.ward_info?.name_np
                    ? `${user?.profile?.ward_info?.name_np} नं. वडा कार्यालय`
                    : null}
                </Fragment>
              ) : (
                <Fragment>
                  {role === "ito_admin"
                    ? "Office Of the Municipal Executive"
                    : user?.profile?.ward_info?.name_en
                    ? `${
                        user?.profile?.ward_info?.name_en ?? ""
                      } No. Ward Office`
                    : null}
                </Fragment>
              )}
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {language === "english"
                ? nagarpalikaInfo.address_en
                : nagarpalikaInfo.address_np}
            </p>
          </div>
        ) : (
          <div
            css={css`
              word-break: break-word;
            `}
          >
            <h5>
              {language === "english"
                ? nagarpalika?.name_en
                : nagarpalika?.name_np}
            </h5>
            <p
              css={css`
                font-size: 1.1rem;
              `}
            >
              {language === "nepali" ? (
                <Fragment>
                  {role === "ito_admin"
                    ? "नगर कार्यपालिकाको कार्यालय"
                    : user?.profile?.ward_info?.name_np
                    ? `${user?.profile?.ward_info?.name_np} नं. वडा कार्यालय`
                    : null}
                </Fragment>
              ) : (
                <Fragment>
                  {role === "ito_admin"
                    ? "Office Of the Municipal Executive"
                    : user?.profile?.ward_info?.name_en
                    ? `Office of Ward No. ${user?.profile?.ward_info?.name_en}`
                    : null}
                </Fragment>
              )}
            </p>
            <p
              css={css`
                font-size: 0.9rem;
              `}
            >
              {language === "english"
                ? nagarpalika?.address_en
                : nagarpalika?.address_np}
            </p>
          </div>
        )}
      </div>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <h4
        css={css`
          text-align: center;
          padding: 0.5rem 0;
          color: black;
        `}
      >
        {language === "english"
          ? "Parichayapatra Management System"
          : "परिचय पत्र ब्यबस्थापन प्रणाली"}
      </h4>
      <hr
        css={css`
          margin: 0 auto;
          width: 90%;
          border-top-color: #dedede !important;
        `}
      />
      <div className="menus">
        {upperData && <SidebarButtonRenderer data={upperData} user={user} />}
        {/* {checkPermission(role, 'view:templates') && <PaperTemplateRenderer />} */}
        {lowerData && <SidebarButtonRenderer data={lowerData} user={user} />}
      </div>
    </div>
  );
};

export default Sidebar;
