import React from 'react';
import { useState } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { FaEdit, FaTrash, FaEye } from 'react-icons/fa';
import PaginationComp from '../../components/Pagination';
import { useHistory } from 'react-router-dom';

const OldAgeWithId = () => {
	const history = useHistory();
	const [page, setPage] = useState(1);
	return (
		<div>
			<div aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item" aria-current="page">
						Home
					</li>

					<li class="breadcrumb-item active" aria-current="page">
						परिचय पत्र पाएको जेष्ठ नागरिकको विवरणहरु
					</li>
				</ol>
			</div>
			<div className="mb-3 d-flex align-items-center justify-content-between">
				<h2>परिचय पत्र पाएको जेष्ठ नागरिकको विवरणहरु</h2>
				<Button variant="success" onClick={() => history.push('/oldage/new')}>
					नयाँ फारम
				</Button>
			</div>
			<Row>
				<Col>
					<Table>
						<thead>
							<tr>
								<th>क्र. स.</th>
								<th>फोटो</th>
								<th>पुरा नाम</th>
								<th>लिङ्ग</th>
								<th>कार्ड नं</th>
								<th>नागरिता न.</th>
								<th>जन्म मिति (B.S.)</th>
								<th>जारी मिति (B.S.)</th>
								<th className="text-center">कार्यहरु</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td></td>
								<td>लाल बहादुर शाह</td>
								<td>पुरुष</td>
								<td>१०२४</td>
								<td>१०२४</td>
								<td>2002-04-09</td>
								<td>2079-08-20</td>
								<td>
									<div className="d-flex justify-content-around align-items-center">
										<FaEye className="text-info" />
										<FaEdit className="text-primary" />
										<FaTrash className="text-danger" />
									</div>
								</td>
							</tr>
							<tr>
								<td>1</td>
								<td></td>
								<td>लाल बहादुर शाह</td>
								<td>पुरुष</td>
								<td>१०२४</td>
								<td>१०२४</td>
								<td>2002-04-09</td>
								<td>2079-08-20</td>
								<td>
									<div className="d-flex justify-content-around align-items-center">
										<FaEye className="text-info" />
										<FaEdit className="text-primary" />
										<FaTrash className="text-danger" />
									</div>
								</td>
							</tr>
							<tr>
								<td>1</td>
								<td></td>
								<td>लाल बहादुर शाह</td>
								<td>पुरुष</td>
								<td>१०२४</td>
								<td>१०२४</td>
								<td>2002-04-09</td>
								<td>2079-08-20</td>
								<td>
									<div className="d-flex justify-content-around align-items-center">
										<FaEye className="text-info" />
										<FaEdit className="text-primary" />
										<FaTrash className="text-danger" />
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
					<PaginationComp page={page} setPage={setPage} total={10} />
				</Col>
			</Row>
		</div>
	);
};

export default OldAgeWithId;
