import React from 'react';
import {
	// jsx,
	css,
} from '@emotion/react';
import { Fragment, useEffect, useState } from 'react';
import {
	Button,
	Card,
	Col,
	//  Form,
	Modal,
	Row,
	Table,
} from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { useForm } from 'react-hook-form';
import {
	FaCheckCircle,
	// FaPlusCircle,
	FaTrash,
} from 'react-icons/fa';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import BackButton from '../../../_elements/BackButton';
import { VscLoading } from 'react-icons/vsc';
import { connect, useSelector } from 'react-redux';
import {
	fetchDesignTemplate,
	deleteDesignTemplate,
	activateDesignTemplate,
	deactivateDesignTemplate,
} from '../../../_redux/slices/designTemplates';

const DesignTempate = ({
	fetchDesignTemplate,
	designTemplates,
	activateDesignTemplate,
	deactivateDesignTemplate,
	deleteDesignTemplate,
}) => {
	useEffect(() => {
		fetchDesignTemplate();
	}, [fetchDesignTemplate]);
	const [show, setShow] = useState(false);
	const onActive = (item, enable) => {
		if (enable) {
			activateDesignTemplate(item.id);
		} else {
			deactivateDesignTemplate(item.id);
		}
		setShow(null);
	};

	const handleDeleteDesignTemplate = (designTemplateId) => {
		deleteDesignTemplate(designTemplateId);
		setShow(null);
	};

	const loading = useSelector((state) => state.designTemplates.loading);
	return (
		<Fragment>
			<Row className="mt-2">
				<Col md={12}>
					<BackButton />
				</Col>
			</Row>
			{/* {showAdd && (
        <Card>
          <Card.Header className="card-header-custom d-block">
            <h3>नयाँ आर्थिक वर्ष</h3>
          </Card.Header>
          <Card.Body className="p-0 mt-4">
            <Row className="mt-2">
              <Col md={12}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex justify-content-between align-items-center p-3">
                    <div className="d-flex align-items-center">
                      <label className="mb-0">
                        वर्ष<span className="text-danger"></span>
                      </label>
                      <Form.Control
                        {...register("title")}
                        placeholder="Fiscal year title"
                      ></Form.Control>
                    </div>
                    <div>
                      <Form.Check
                        type="checkbox"
                        id="fiscalyear"
                        label="चालु आर्थिक वर्ष?"
                        {...register("is_enabled")}
                      />
                    </div>
                  </div>
                  <div className="p-3 d-flex justify-content-end">
                    <Button variant="success" type="submit">
                      सुरक्षित
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card> 
      )} */}

			<Row className="mt-2">
				<Col md={12}>
					<Card>
						<Card.Header className="card-header-custom">
							<h2>Template</h2>
							{/* <Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
                <FaPlusCircle />
                &nbsp; नयाँ आर्थिक वर्ष
              </Button> */}
						</Card.Header>

						<Card.Body className="p-0 mt-4">
							<div className="table-responsive">
								<Table striped hover responsive>
									<thead>
										<tr
											css={css`
												th {
													text-align: center;
												}
											`}
										>
											<th className="text-center">Title</th>
											<th className="text-center">Choice</th>
											<th className="text-center">Ward</th>
											<th className="text-center">Status</th>
											<th className="text-center">कार्यहरू</th>
										</tr>
									</thead>
									<tbody>
										{loading ? (
											<tr>
												<td></td>
												<td></td>
												<td className="text-right">
													<VscLoading className="spin" fontSize="48" />
												</td>
												<td></td>
												<td></td>
											</tr>
										) : designTemplates ? (
											designTemplates.map((item, index) => (
												<tr
													css={css`
														td {
															text-align: center;
														}
													`}
													key={item.id}
												>
													<td></td>
													<td className="d-flex justify-content-center align-items-center">{item.choices}</td>
													<td className="text-center">{item.ward}</td>
													<td className="text-center">
														<FaCheckCircle className={item.is_enabled === true ? `text-success ml-1` : `ml-1`} />
													</td>
													<td className="text-center">
														<Fragment>
															{item.is_enabled === true ? (
																<Button
																	variant="secondary"
																	onClick={() => setShow({ type: 'activate', item: item, enable: false })}
																>
																	<AiFillCloseCircle size={12} /> Deactivate
																</Button>
															) : (
																<Button
																	variant="primary"
																	onClick={() => setShow({ type: 'activate', item: item, enable: true })}
																>
																	<AiFillCheckCircle size={12} /> Activate
																</Button>
															)}
															<Button
																variant="danger"
																className="ml-2"
																onClick={() => setShow({ type: 'delete', item: item })}
															>
																<FaTrash size={12} /> हटाउनु
															</Button>
														</Fragment>
													</td>
												</tr>
											))
										) : (
											<tr
												css={css`
													th {
														text-align: center;
													}
												`}
											>
												<td></td>
												<td></td>
												<td className="text-center">Template पाईएन</td>
												<td></td>
											</tr>
										)}
									</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal show={Boolean(show)} onHide={() => setShow(null)} centered>
				<Modal.Header>
					<h3>Template</h3>
				</Modal.Header>
				<Modal.Body>
					<p className="h5 font-weight-lighter">
						{show?.type === 'activate'
							? 'के तपाइँ यो Template सक्रिय गर्न चाहानुहुन्छ?'
							: 'के तपाइँ यो Template हटाउन चाहानुहुन्छ?'}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						className="btn btn-secondary"
						onClick={() => {
							setShow(null);
						}}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button
						className="btn btn-primary"
						onClick={() => {
							if (show?.type === 'activate') {
								onActive(show?.item, show?.enable);
							} else {
								handleDeleteDesignTemplate(show?.item.id);
							}
						}}
					>
						सुनिश्चित गर्नुहोस्
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

const mapStateToProps = (state) => ({
	designTemplates: state.designTemplates.designTemplates,
});

const mapDispatchToProps = {
	fetchDesignTemplate,
	deleteDesignTemplate,
	activateDesignTemplate,
	deactivateDesignTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignTempate);
