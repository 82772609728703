/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

function Logo({ data }) {
  return (
    <div>
      <img
        css={css`
          width: 100px;
          object-fit: contain;
        `}
        src={data.data}
        alt="name"
      />
    </div>
  );
}

export default Logo;
