/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { ResizableInput } from "../../../_elements";

function Subject({ data, language }) {
  return (
    <div
      css={css`
        text-align: center;
        width: 100%;
      `}
    >
      Subject:{" "}
      <ResizableInput
        type="text"
        language={language}
        maxlength={50}
        className={""}
        placeholder={data.placeholder}
      />
    </div>
  );
}

export default Subject;
