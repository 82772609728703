import { createSlice } from "@reduxjs/toolkit";
import { PrashasakServices } from "../../api/services/PrashasakServices";

export const initialState = {
    activePrashasak: null,
    loading: false
};

// A slice
const prashasakSlice = createSlice({
    name: "Prashasak",
    initialState,
    reducers: {
        setActivePrashasak: (state, action) => {
            state.activePrashasak = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    }
})
const {
    setLoading,
    setActivePrashasak,
} = prashasakSlice.actions;

export const fiscalSelector = (state) => state.fiscalyear;

const prashasakReducer = prashasakSlice.reducer;

export default prashasakReducer;

export const fetchActivePrashasak = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const activePrashasak = await PrashasakServices.getActivePrashasak();

        if (activePrashasak) {
            dispatch(setActivePrashasak(activePrashasak));
        }
        dispatch(setLoading(false));
    } catch (error) {
        console.log(error, 'error')
        dispatch(setLoading(false));
    }
};

export const handlesetActivePrashasak = (prashasak) => async (dispatch) => {
    dispatch(setActivePrashasak(prashasak));
};
