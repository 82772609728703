/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import InputField from "../../components/InputField";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { toast } from "react-toastify";
import { VscLoading } from "react-icons/vsc";
import { nepaliNumberConverter } from "../../_helpers/methods";
import { setNagarpalika } from "../../_redux/slices/nagarpalika";
import { useDispatch } from "react-redux";

function NagarpalikaDetailEditModal({
  selectedNagarpalika,
  show,
  handleClose,
  lang,
}) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [mainLogoPreview, setMainLogoPreview] = useState("");
  const [mainLogo, setMainLogo] = useState("");
  const [campaignLogoPreview, setCampaignLogoPreview] = useState("");
  const [campaignLogo, setCampaignLogo] = useState("");
  const [uploadingLogo, setUploadingLogo] = useState("");
  const [isNagarpalikaUpdating, setIsNagarpalikaUpdating] = useState("");

  useEffect(() => {
    setMainLogoPreview(selectedNagarpalika?.main_logo);
  }, [selectedNagarpalika?.main_logo]);
  useEffect(() => {
    setCampaignLogoPreview(selectedNagarpalika?.campaign_logo);
  }, [selectedNagarpalika?.campaign_logo]);

  useEffect(() => {
    setValue("name_np", selectedNagarpalika?.name_np);
  }, [setValue, selectedNagarpalika?.name_np]);
  useEffect(() => {
    setValue("address_np", selectedNagarpalika?.address_np);
  }, [setValue, selectedNagarpalika?.address_np]);
  useEffect(() => {
    setValue("province_np", selectedNagarpalika?.province_np);
  }, [setValue, selectedNagarpalika?.province_np]);
  useEffect(() => {
    setValue("district_np", selectedNagarpalika?.district_np);
  }, [setValue, selectedNagarpalika?.district_np]);
  useEffect(() => {
    setValue("slogan_np", selectedNagarpalika?.slogan_np);
  }, [setValue, selectedNagarpalika?.slogan_np]);

  const onSubmit = async (data) => {
    try {
      setIsNagarpalikaUpdating(true);
      let body = {
        ...data,
        phone_np: nepaliNumberConverter(data.phone_en),
        main_logo: mainLogoPreview,
        campaign_logo: campaignLogoPreview,
      }
      const response = await fetchWrapper.patch(`/nagarpalika/`, body);
      dispatch(setNagarpalika(response.data));
      toast.success("Nagarpalika edited successfully!");
      setIsNagarpalikaUpdating(false);
      handleClose();
    } catch (err) {
      setIsNagarpalikaUpdating(false);
      toast.error("Something went wrong!");
    }
  };
  const handleLogoUpdate = async (id) => {
    let logo = null;
    const formData = new FormData();
    if (id === "main_logo") {
      logo = mainLogo;
    } else {
      logo = campaignLogo;
    }
    formData.append('file', logo);
    try {
      setUploadingLogo(id);
      let response = await fetchWrapper.post(
        `/file`,
        formData,
        true
      );
      if (id === "main_logo") {
        setMainLogoPreview(response.data);
        setMainLogo("");
      } else {
        setCampaignLogoPreview(response.data);
        setCampaignLogo("");
      }
      toast.success("Logo updated Successfully!");
      setUploadingLogo(null);
    } catch (err) {
      setUploadingLogo(null);
    }
  };
  const handleFileLoad = (id, file) => {
    const maxFileSize = 5 * 1024 * 1024;
    if (file) {
      if (file.size < maxFileSize) {
        if (id === "main_logo") {
          setMainLogo(file);
        } else {
          setCampaignLogo(file);
        }
      } else {
        toast.warn("Image Must be less than 5 mb in size!");
      }
    }
  };
  if (selectedNagarpalika) {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedNagarpalika[`name_${lang}`]}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isNagarpalikaUpdating && (
              <div
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  height: 100%;
                  background: rgba(255, 255, 255, 0.5);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 22;
                `}
              >
                <VscLoading className="spin" fontSize="48" />
              </div>
            )}
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Main Logo
              </Form.Label>
              <Col sm="8">
                {mainLogoPreview && (
                  <img
                    src={mainLogoPreview}
                    alt="/"
                    css={css`
                      width: 100px;
                    `}
                  />
                )}
                <div
                  css={css`
                    margin-top: 1rem;
                    display: flex;
                  `}
                >
                  <input
                    type="file"
                    onChange={(event) => {
                      handleFileLoad("main_logo", event.target.files[0]);
                    }}
                  />
                  <Button
                    disabled={!mainLogo}
                    type="button"
                    variant="secondary"
                    onClick={() => handleLogoUpdate("main_logo")}
                  >
                    {uploadingLogo === "main_logo" ? (
                      <VscLoading className="spin" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Campaign Logo
              </Form.Label>
              <Col sm="8">
                {campaignLogoPreview && (
                  <img
                    src={campaignLogoPreview}
                    alt="campaign"
                    css={css`
                      width: 100px;
                    `}
                  />
                )}
                <div
                  css={css`
                    margin-top: 1rem;
                    display: flex;
                  `}
                >
                  <input
                    type="file"
                    onChange={(event) => {
                      handleFileLoad("campaign_logo", event.target.files[0]);
                    }}
                  />
                  <Button
                    disabled={!campaignLogo}
                    type="button"
                    variant="secondary"
                    onClick={() => handleLogoUpdate("campaign_logo")}
                  >
                    {uploadingLogo === "campaign_logo" ? (
                      <VscLoading className="spin" />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </div>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Name(English)
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  defaultValue={selectedNagarpalika.name_en}
                  {...register("name_en")}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Name(Nepali)
              </Form.Label>
              <Col sm="8">
                <InputField
                  register={register}
                  elementId={"name_np"}
                  setValue={setValue}
                  language={"nepali"}
                  className={`form-control`}
                  css={css`
                    padding: 0.375rem 0.75rem !important;
                  `}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Address(English)
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("address_en")}
                  defaultValue={selectedNagarpalika.address_en}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Address(Nepali)
              </Form.Label>
              <Col sm="8">
                <InputField
                  register={register}
                  elementId={"address_np"}
                  setValue={setValue}
                  language={"nepali"}
                  className={`form-control`}
                  css={css`
                    padding: 0.375rem 0.75rem !important;
                  `}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Province(English)
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("province_en")}
                  defaultValue={selectedNagarpalika.province_en}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Province(Nepali)
              </Form.Label>
              <Col sm="8">
                <InputField
                  register={register}
                  elementId={"province_np"}
                  setValue={setValue}
                  language={"nepali"}
                  className={`form-control`}
                  css={css`
                    padding: 0.375rem 0.75rem !important;
                  `}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                District(English)
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("district_en")}
                  defaultValue={selectedNagarpalika.district_en}
                />
              </Col>
            </Form.Group>


            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                District(Nepali)
              </Form.Label>
              <Col sm="8">
                <InputField
                  register={register}
                  elementId={"district_np"}
                  setValue={setValue}
                  language={"nepali"}
                  className={`form-control`}
                  css={css`
                    padding: 0.375rem 0.75rem !important;
                  `}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="4">
                Email
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("email")}
                  defaultValue={selectedNagarpalika.email}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4">
                Phone no.
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("phone_en")}
                  defaultValue={selectedNagarpalika.phone_en}
                />
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4">
                Slogan.(English)
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  {...register("slogan_en")}
                  defaultValue={selectedNagarpalika.slogan_en}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="4">
                Slogan.(Nepali)
              </Form.Label>

              <Col sm="8">
                <InputField
                  register={register}
                  elementId={"slogan_np"}
                  setValue={setValue}
                  language={"nepali"}
                  className={`form-control`}
                  css={css`
                    padding: 0.375rem 0.75rem !important;
                  `}
                />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isNagarpalikaUpdating}
              variant="secondary"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              disabled={isNagarpalikaUpdating}
              variant="primary"
              type="submit"
            >
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
}

export default NagarpalikaDetailEditModal;
