/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Table,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { setPapersList } from "../../_redux/slices/paper";
import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  deletePaper,
  incrementPaperCountHandler,
} from "../../_redux/slices/paper";
import PaginationComp from "../../components/Pagination";
import { VscLoading } from "react-icons/vsc";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import moment from "moment";
import { generatePreview } from "../../_helpers/methods";
import PreviewModel from "../../components/Form/PreviewModel";
import { useReactToPrint } from "react-to-print";
import InputField from "../../components/InputField/index";
import { languageSelector } from "../../_redux/slices/languages";
import { BiPrinter } from "react-icons/bi";
import NepaliDate from "nepali-date-converter";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { toast } from "react-toastify";

const Verified = ({
  setPapersList,
  deletePaper,
  incrementPaperCountHandler,
}) => {
  let papers = useSelector((state) => state.papers.values);
  let count = useSelector((state) => state.papers.count);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState([null, false]);
  const [showPreview, setShowPreview] = useState(false);
  const [paperDetail, setPaperDetail] = useState(null);
  const [paper, setPaper] = useState(null);
  const [previewFormData, setPreviewFormData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const { language } = useSelector(languageSelector);
  const [searchText, setSearchText] = useState("");
  const { pageNum } = useParams();
  const history = useHistory();
  const [page, setPage] = useState(pageNum);
  const user = useSelector((state) => state.users.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const getPapersList = async () => {
      try {
        setIsLoading(true);
        await setPapersList(page, "Approved", searchText);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getPapersList();
  }, [page, setPapersList, searchText]);

  const handlePreviewPaper = async (paper) => {
    setPaperDetail(paper.values);
    setPaper(paper);
    const formData = paper.form_values;
    setPreviewFormData(formData);
    let templateWithPreviewData;
    if (paper.values?.preview) {
      templateWithPreviewData = paper.values;
    } else {
      const response = await fetchWrapper.get(`/template/${paper.template}/`);
      templateWithPreviewData = response.data;
    }
    const tempPreviewData = generatePreview(
      templateWithPreviewData,
      formData,
      user
    );
    setPreviewData(tempPreviewData);
    setShowPreview(true);
  };

  const handleDeletePaper = () => {
    deletePaper(show[0]);
    papers.filter((val) => val.id === show.id);
    setShow([null, false]);
  };
  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      return printRef.current;
    },
    onAfterPrint: async () => {
      try {
        //Need to detect if the print is cancelled, if cancelled do not increment count
        dispatch(incrementPaperCountHandler(paper.id));
      } catch (err) {
        console.log(err, "error after printing");
      }
    },
  });

  const handlePrintPreview = (id) => {
    const body = {
      _save_and_print: true,
    };
    return fetchWrapper
      .patch(`/paper/${paper.id}/`, body)
      .then((res) => {
        handlePrint();
      })
      .catch((err) => {
        // dispatch(stopUiLoading());
        let errorMessage = err.error?.non_field_error || err.message;
        errorMessage && toast.error(errorMessage[0]);
        return false;
      });
  };

  const handlePaperSearch = () => {
    setPapersList(page, "Approved", searchText);
  };

  console.log(paper?.form_values?.ward_admin);

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <Card
            css={css`
              min-height: 600px;
            `}
          >
            <Card.Header className="card-header-custom">
              <h2>सबै जारी भएका परिचय पत्र</h2>
              <div
                className="card-header-form"
                css={css`
                  min-width: 260px;
                `}
              >
                <div className="input-group">
                  <InputField
                    language={language}
                    className="form-control custom-search"
                    getInputData={(value) => {
                      if (value === "") {
                        return setPapersList(page, "Approved", null);
                      }
                      setSearchText(value);
                    }}
                    placeholder={
                      language === "english"
                        ? "Please enter name of applicant"
                        : "निवेदकको नाम टाईप गर्नुहोस"
                    }
                    type="text"
                  />

                  <div className="input-group-btn">
                    <button
                      className="btn btn-primary input-search-icon"
                      onClick={handlePaperSearch}
                    >
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr
                      css={css`
                        th {
                          text-align: left;
                        }
                      `}
                    >
                      <th>परिचय पत्रको प्रकार</th>
                      <th>EN Number</th>
                      <th>
                        <span
                          css={css`
                            display: block;
                          `}
                        >
                          निवेदकको नाम
                        </span>
                        <span
                          css={css`
                            font-size: 15px !important;
                          `}
                        >
                          निवेदकको नागरिकता
                        </span>
                      </th>
                      <th>
                        <span
                          css={css`
                            display: block;
                          `}
                        >
                          जारी गर्ने
                        </span>
                        <span
                          css={css`
                            font-size: 15px !important;
                          `}
                        >
                          जारी मिती
                        </span>
                      </th>
                      <th>वडा</th>
                      <th>
                        <span className="d-block">दस्तुर</span>
                        <span>रसिद नं.</span>
                      </th>

                      <th className="text-left">कार्य</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td />
                        <td />
                        <td />

                        <td
                          colSpan={4}
                          css={css`
                            display: flex;
                            justify-content: center;
                            align-items: left;
                            min-height: 60vh !important;
                          `}
                        >
                          <VscLoading className="spin" fontSize="42" />
                        </td>
                        <td />
                        <td />
                        <td />
                      </tr>
                    ) : papers?.length > 0 ? (
                      papers?.map((paper) => {
                        let applicantName;
                        if (language === "english") {
                          applicantName = paper.extra.fullname_en;
                        } else {
                          applicantName = paper.extra.fullname_np;
                        }

                        return (
                          <tr
                            key={paper.id}
                            css={css`
                              td {
                                text-align: left;
                              }
                            `}
                          >
                            <td>
                              <div className="d-flex">
                                <span
                                  css={css`
                                    display: inline-flex;
                                    padding: 0.2rem 0.5rem;
                                    background: #6777ef;
                                    color: white;
                                    border-radius: 5px;
                                    margin-right: 0.5rem;
                                    margin-left: 0.2rem;
                                    font-weight: bold;
                                    align-items: center;
                                    height: max-content;

                                    svg {
                                      margin-right: 0.1rem;
                                    }
                                  `}
                                >
                                  <BiPrinter fontSize="20" /> {paper.count}
                                </span>
                                {paper.extra.paper_title}
                              </div>
                            </td>
                            <td>{paper.issue_id}</td>
                            <td>
                              <span
                                css={css`
                                  display: block;
                                  min-height: 1.2rem;
                                `}
                              >
                                {applicantName || ""}
                              </span>
                              <span
                                css={css`
                                  font-size: 15px !important;
                                `}
                              >
                                {paper.form_values.citizenship_no
                                  ? paper.form_values.citizenship_no
                                  : null}
                              </span>
                            </td>
                            <td>
                              <span
                                css={css`
                                  display: block;
                                  min-height: 1.2rem;
                                `}
                              >
                                {language === "nepali"
                                  ? paper?.extra.created_by_name_np || "Guest"
                                  : paper?.extra.created_by_name_en || "Guest"}
                              </span>
                              <span
                                css={css`
                                  font-size: 15px !important;
                                `}
                              >
                                {language === "nepali"
                                  ? new NepaliDate(
                                      new Date(paper.created_at)
                                    ).format("YYYY/MM/DD", "np")
                                  : moment(paper.created_at).format(
                                      "YYYY/MM/DD"
                                    )}
                              </span>
                            </td>
                            <td>{paper.extra.ward}</td>
                            <td>
                              <span className="d-block">
                                Rs. {paper.extra.charge}
                              </span>
                              <span>{paper?.invoice_detail?.invoice_no}</span>
                            </td>

                            <td className="d-flex">
                              <Button
                                className="mr-1"
                                onClick={() => handlePreviewPaper(paper)}
                              >
                                <AiFillEye />
                              </Button>
                              <Link
                                to={`/paper-single/${paper?.id}`}
                                css={css`
                                  color: white;
                                  :hover {
                                    color: white;
                                  }
                                `}
                              >
                                <Button className="mr-1" variant="info">
                                  <FiEdit />
                                  {/* सम्पादन */}
                                </Button>
                              </Link>

                              <Button
                                variant="danger"
                                className=""
                                onClick={() => setShow([paper?.id, true])}
                              >
                                <AiFillDelete />
                                {/* हटाउनु */}
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td />
                        <td />
                        <td />

                        <td
                          colSpan={7}
                          css={css`
                            display: flex;
                            justify-content: left;
                            align-items: left;
                            min-height: 60vh !important;
                            font-size: 1.6rem;
                          `}
                        >
                          कुनै परिचय पत्र पाईएन
                        </td>
                        <td />
                        <td />
                        <td />
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <PaginationComp page={page} total={count} setPage={handleSetPage} />

      <Modal show={show[1]} onHide={() => setShow([null, false])}>
        <ModalBody>
          <p className="h5 font-weight-lighter">
            {" "}
            के तपाइँ यो कागज मेटाउन चाहानुहुन्छ?{" "}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-danger" onClick={handleDeletePaper}>
            सुनिश्चित गर्नुहोस्
          </Button>
        </ModalFooter>
      </Modal>
      {showPreview && (
        <PreviewModel
          handleClose={() => setShowPreview(false)}
          show={showPreview}
          previewData={previewData}
          previewFormData={previewFormData}
          innerRef={printRef}
          config={paperDetail.config}
          paperExtra={paper.extra}
          meta={paperDetail.meta}
          preview={true}
          handlePrint={handlePrintPreview}
          issueId={paper?.issue_id}
          documents={paper?.documents}
          wardAdmin={paper?.form_values?.ward_admin}
        />
      )}
    </Fragment>
  );
};

export default connect(null, {
  setPapersList,
  deletePaper,
  incrementPaperCountHandler,
})(Verified);
