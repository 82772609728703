/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";

//
import { VscLoading } from 'react-icons/vsc';

//
import { DisabledServices } from '../../api/services/DisabledServices';
import { ElderlyServices } from '../../api/services/ElderlyServices';

//
import FingerPrintSvg from '../../assets/fingerPrint.svg';

/**
 * 
 */
export default function FingerPrint() {
	const BASEURL =process.env.REACT_APP_BACKEND;
	const { category, id } = useParams();

	const [userProfile, setUserProfile] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isCollecting, setIsCollecting] =useState(false);

	useEffect(() => {
		const handleFetchUserProfile = async () => {
			try {
				let result = [];
				if (category === "elderly") {
					result = await ElderlyServices.getElderlyProfileById(id);
				}
				else if (category === "disabled") {
					result = await DisabledServices.getDisabledProfileById(id);
				}
				setUserProfile(result);
				setIsLoading(false);
			}
			catch (error) {
				setIsLoading(false);
			}
		}

		handleFetchUserProfile();
	}, [category, id]);

	if (isLoading) {
		return <div className="d-flex justify-content-center align-items-center" css={css`
		min-height: 60vh;`}>
			<VscLoading className='spin' size={'48'} />
		</div>
	}

	let profileCat = category === 'disabled' ? 'disabledProfile' : 'elderly';
   	const FingerPrintHref = `fingerprintprocessor://E&${id}&${userProfile?.name_en}&${BASEURL}&${profileCat}`;
   	const FingerLegPrintHref = `fingerprintprocessor://EL&${id}&${userProfile?.name_en}&${BASEURL}&${profileCat}`;
    
	const onCollecting = () => setIsCollecting(true);
	return (
		<div>
			<div aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item" aria-current="page">
						Home
					</li>
					<li class="breadcrumb-item" aria-current="page">
						परिचय पत्र
					</li>
					<li class="breadcrumb-item" aria-current="page">
					औंठाको छापको सङ्कलन
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						{userProfile?.name_np}
					</li>
				</ol>
			</div>

			<div>
				<div className='text-center h5 mt-5'>औंठाको छापको सङ्कलन</div>
				<div className='d-flex justify-content-center mb-5 mt-4'>
					<img src={FingerPrintSvg} css={css`
						width: 150px;
					`} />
				</div>

				{isCollecting ? 
				<div className='d-flex flex-column justify-content-center'>
					<p className="text-center font-weight-bold">फिंगरप्रिन्ट संकलनका लागि अर्को सफ्टवेयर खोलिएको छ । तपाईँले यो ट्याब बन्द गर्न सक्नुहुन्छ ।</p>
				</div>
				:
				<div className='d-flex justify-content-center'>

				<a className='btn btn-primary text-white mr-3' href={`${FingerPrintHref}`} onClick={onCollecting}>हातको औंला</a>
				<a className='btn btn-primary text-white mr-3' href={`${FingerLegPrintHref}`} onClick={onCollecting}>खुट्टाको औंला</a>

				<Link className='btn btn-outline-secondary' to={`/preview/${category}/${id}`}>
					दुवै नभएको</Link>
				
				</div>
				}

				
			</div>

		</div>
	)
}