/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Container, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { languageSelector, handleChangeLanguage } from '../../_redux/slices/languages';
import './CitizensHeaderStyles.scss';

function CitizensHeader() {
	const dispatch = useDispatch();
	const {
		language,
		//  fontType
	} = useSelector(languageSelector);
	return (
		<div className="citizenHeaderStyles">
			<div className="header">
				<Container fluid>
					<div className="header-inner">
						<div className="header-left">
							<img src="/assets/images/nep_logo.png" className="logo-sm" alt="" />
							<h5 className="text-white mb-0">परिचय पत्र ब्यबस्थापन प्रणाली</h5>
						</div>
						{/* <div className="header-right">
              <FaUser className="mx-2 text-white" />
              <div className="text-white">
                <p className="mb-0">संजिप ज्ञवाली</p>
              </div>
            </div> */}
						<div className="header-right">
							<h5>
								{/* {language === "english"
                  ? "English"
                  : language === "nepali" && fontType === "traditional"
                  ? "Traditional"
                  : fontType === "romanized"
                  ? " Romanized"
                  : fontType === "preeti"
                  ? "Preeti"
                  : fontType === "kantipur"
                  ? "Kantipur"
                  : "Psc Nepali"} */}
								{language === 'english' ? 'English' : 'नेपाली'}
							</h5>
							<Dropdown>
								<Dropdown.Toggle
									variant="primary"
									id="dropdown-language"
									css={css`
										&.dropdown-toggle {
											color: white !important;
										}
									`}
								></Dropdown.Toggle>
								<Dropdown.Menu
									css={css`
										transform: translate(-150px, 40px) !important;
									`}
								>
									<Dropdown.Item onClick={() => dispatch(handleChangeLanguage({ language: 'english' }))}>
										English
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() =>
											dispatch(
												handleChangeLanguage({
													language: 'nepali',
													fontType: 'traditional',
												})
											)
										}
									>
										नेपाली
									</Dropdown.Item>
									{/* <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        handleChangeLanguage({
                          language: "nepali",
                          fontType: "romanized",
                        })
                      )
                    }
                  >
                    Romanized
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        handleChangeLanguage({
                          language: "nepali",
                          fontType: "preeti",
                        })
                      )
                    }
                  >
                    Preeti
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        handleChangeLanguage({
                          language: "nepali",
                          fontType: "pcs_nepali",
                        })
                      )
                    }
                  >
                    Psc Nepali
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        handleChangeLanguage({
                          language: "nepali",
                          fontType: "kantipur",
                        })
                      )
                    }
                  >
                    Kantipur
                  </Dropdown.Item> */}
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
}

export default CitizensHeader;
