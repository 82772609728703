import NepaliDate from "nepali-date-converter";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VscLoading } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { DisabledServices } from "../../api/services/DisabledServices";
import { ElderlyServices } from "../../api/services/ElderlyServices";
import { PrashasakServices } from "../../api/services/PrashasakServices";
import FormInput from "../../components/Form/Elements/_new/FormInput";

export default function PratilipiForm() {
  const { id, type } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [prashasaks, setPrashasaks] = useState([]);

  const activePrashasak = useSelector(
    (state) => state.prashasak.activePrashasak
  );

  let today_bs = new NepaliDate().getBS();
  let today_ad = new NepaliDate().getAD();
  let todayBS = `${today_bs.year}-${(today_bs.month + 1)
    .toString()
    .padStart(2, 0)}-${today_bs.date.toString().padStart(2, 0)}`;
  let todayAD = `${today_ad.year}-${(today_ad.month + 1)
    .toString()
    .padStart(2, 0)}-${today_ad.date.toString().padStart(2, 0)}`;

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newCardRecievedDate_bs: todayBS,
      newCardRecievedDate_ad: todayAD,
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      let body = {
        ...data,
        original: id,
      };

      if (type === "disabled") {
        const result = await DisabledServices.createDisabledProfileCopy(body);
        setDefaultValues(result);
        history.push("/disabled/copy/1");
      } else if (type === "elderly") {
        const result = await ElderlyServices.createElderlyProfileCopy(body);
        setDefaultValues(result);
        history.push("/elderly/copy/1");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchDisabledUserProfile = async () => {
      const result = await DisabledServices.getDisabledProfileById(id);
      setDefaultValues(result);
    };
    const fetchElderlyUserProfile = async () => {
      const result = await ElderlyServices.getElderlyProfileById(id, false);
      setDefaultValues(result);
    };

    if (id) {
      if (type === "disabled") {
        fetchDisabledUserProfile();
      } else if (type === "elderly") {
        fetchElderlyUserProfile();
      }
    }
  }, [id, type]);

  useEffect(() => {
    const fetchPrashasaks = async () => {
      try {
        const response = await PrashasakServices.getProfiles({});
        let options = { null: "--छान्नु होस् " };
        response?.results?.forEach((user) => {
          options[user.id] = user.fullname_np;
        });
        setPrashasaks(options);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPrashasaks();
  }, []);

  const handleSetDateAD = (id, value, valueAd) => {
    setValue(id, valueAd.replaceAll("/", "-"));
  };

  return (
    <div>
      <div className="text-center h3 my-2">
        प्रतिलिपि-{defaultValues.name_np} ({defaultValues.name_en}){" "}
      </div>
      <div className="alert alert-success mb-3">प्रतिलिपि</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6} className="mb-4">
            <FormInput
              label="प्रमाणित गर्ने अधिकारी"
              id="originalAdministrator"
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              type="Select"
              errors={errors}
              options={prashasaks}
              validations={{
                isRequired: true,
              }}
              isDisabled={isLoading}
              defaultValue={defaultValues?.cardDetails?.administrator}
            />
          </Col>

          <Col md={6} className="mb-4">
            <FormInput
              label="प्रतिलिपि दिने अधिकारी"
              id="administrator"
              register={register}
              setValue={setValue}
              isDisabled={isLoading}
              clearErrors={clearErrors}
              type="Select"
              defaultValue={activePrashasak?.id}
              errors={errors}
              options={prashasaks}
              validations={{
                isRequired: true,
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormInput
              label="प्रतिलिपि मिति"
              id="newCardRecievedDate_bs"
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              type="Date"
              ADBSmode="BS"
              errors={errors}
              validations={{
                isRequired: true,
              }}
              onChange={(value, valueAd) =>
                handleSetDateAD("newCardRecievedDate_ad", value, valueAd)
              }
              isDisabled={isLoading}
            />
          </Col>
          <Col md={6}>
            <FormInput
              label="No. Of Copy English"
              id="oridinal"
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              type="Number"
              language="english"
              errors={errors}
              validations={{
                isRequired: true,
              }}
              placeholder="E.g: 1"
              isDisabled={isLoading}
            />
          </Col>
        </Row>

        <div className="d-flex justify-content-end mt-3">
          <Button type="submit" variant="primary" disabled={isLoading}>
            {isLoading ? (
              <VscLoading className="spin" />
            ) : (
              "प्रतिलिपि बनाउनुहोस"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}
