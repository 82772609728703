import {
  FaChartBar,
  FaCog,
  FaFile,
  FaHeadphones,
  FaHome,
  FaQuestionCircle,
  FaUser,
} from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { AiFillIdcard } from "react-icons/ai";
import { BsPencilSquare } from "react-icons/bs";

export const upperData = [
  {
    id: 1,
    title: "ड्यासबोर्ड",
    title_en: "Dashboard",
    icon: <FaHome />,
    path: "/",
  },
  // {
  // 	id: 3,
  // 	title: 'अनलाइन अनुरोध',
  // 	title_en: 'Online Request',
  // 	icon: <FaUser />,
  // 	badge: true,
  // 	path: '/verify/1',
  // 	rule: 'view:sifarisAnurodPranali',
  // },
  {
    id: "11",
    title: "अपाङ्गता परिचय पत्र",
    title_en: "अपाङ्गता परिचय पत्र",
    icon: <AiFillIdcard />,
    subNav: [
      {
        id: 1,
        title: "नयाँ",
        title_en: "New",
        icon: <BsPencilSquare />,
        path: "/disabled/new",
      },
      {
        id: 2,
        title: "तथ्यांक",
        title_en: "Tathyanka",
        path: "/disabled/list/1",
      },
      {
        id: 3,
        title: "प्रतिलिपि",
        title_en: "pratilipi",
        path: "/disabled/copy/1",
      },
    ],
  },
  {
    id: "12",
    title: "जेष्ठ नागरिक परिचय पत्र",
    title_en: "जेष्ठ नागरिक परिचय पत्र",
    icon: <AiFillIdcard />,
    subNav: [
      {
        id: 1,
        title: "नयाँ",
        title_en: "New",
        path: "/elderly/new",
      },
      {
        id: 2,
        title: "तथ्यांक",
        title_en: "Tathyanka",
        path: "/elderly/list/1",
      },
      {
        id: 3,
        title: "प्रतिलिपि",
        title_en: "pratilipi",
        path: "/elderly/copy/1",
      },
    ],
  },
];

export const lowerData = [
  {
    id: 7,
    title: "रिपोर्ट",
    title_en: "Report",
    icon: <FaChartBar />,
    path: "/reports",
    rule: "view:reports",
  },
  {
    id: 6,
    title: "सेटिंग्स",
    title_en: "Settings",
    icon: <FaCog />,
    path: "/settings",
    rule: "view:settings",
  },
  {
    id: 8,
    title: "नगरपालिकाको विवरण",
    title_en: "Nagarpalika details",
    icon: <ImOffice />,
    path: "/nagarpalika/1",
    rule: "view:nagarpalikaDetails",
  },
  // {
  // 	id: 9,
  // 	title: 'FAQ',
  // 	icon: <FaQuestionCircle />,
  // 	path: '/faq',
  // 	rule: 'view:faq',
  // },
  {
    id: 10,
    title: "सपोर्ट",
    title_en: "Support",
    icon: <FaHeadphones />,
    path: "/support",
    rule: "view:support",
  },
];
