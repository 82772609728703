import { fetchWrapper } from "../../_helpers/fetchWrapper";

const getWards = async (id) => {
    const response = await fetchWrapper.post(`/ward/${id}`);
    return response.results;
}

const createWardUser = async (data) => {
    const response = await fetchWrapper.post(`/users`, data);
    return response.data;
}

const updateWardUser = async(data, id) => {
    const response = await fetchWrapper.patch(`/users/${id}`, data);
    return response.data;
}

export const WardServices = {
    getWards,
    createWardUser,
    updateWardUser
}