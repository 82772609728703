import styled from "@emotion/styled";

export const PrintImage = styled.img`
    width: 100%;
    @media print {
        @page {
            // margin: 5mm 5mm 10mm 5mm;
            /* size: ${props => props.isCard ? "1560px 980px" : "A4 landscape"}; */
            size: 1560px 980px;
        }
    }
`;