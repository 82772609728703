/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CitizensHeader from './CitizensHeader';
import { Link } from 'react-router-dom';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { languageSelector } from '../../_redux/slices/languages';

const isValidEmail = (email) =>
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email
	);

const CitizensFormSubmitted = () => {
	const { token } = useParams();
	const [isSending, setIsSending] = useState(false);
	const { language } = useSelector(languageSelector);
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const handleSendEmail = async ({ email }) => {
		if (!isSending) {
			try {
				setIsSending(true);
				await fetchWrapper.post('/public/email-token/', {
					email: email,
					token: token,
				});
				setIsSending(false);
				toast.success('Mail sent successfully');
			} catch (err) {
				setIsSending(false);
				toast.error('something went wrong!');
			}
		}
	};
	const handleEmailValidation = (email) => {
		const isValid = isValidEmail(email);
		if (isValid) {
			return true;
		} else {
			return 'Email entered is Invalid';
		}
	};
	return (
		<div>
			<CitizensHeader />

			<div className="main-content">
				<Link to="/citizens">
					<Button>{language === 'english' ? 'Back' : 'पछाडी जानुहोस'}</Button>
				</Link>
				<div
					css={css`
						display: flex;
						justify-content: center;
					`}
				>
					<div
						css={css`
							background: white;
							padding: 2rem;
						`}
					>
						<h1
							className="mb-4"
							css={css`
								color: #6677ef;
							`}
						>
							{language === 'english' ? 'Form Submitted successfully !!' : 'फारम सफलतापूर्वक पेश गरियो !!'}
						</h1>
						{language === 'english'
							? 'Your Form has been submitted successfully, here is the token of your form, Keep it safe and use it to check the status of your Form.'
							: 'तपाईंको फारम सफलतापुर्वक पेश गरियो | आफ्नो निवेदनको स्थिती बुझ्न तल देखिएको टोकन सुरक्षित साथ राख्नुहोस् |'}

						<h2 className="text-center my-2">{token}</h2>
						<div>
							<div>
								{language === 'english'
									? 'Enter your email below and we will mail the token to you.'
									: 'प्राप्त टोकन इमेलमा पठाउन तल देखिएको फिल्डमा आफ्नो इमेल राखी Send बटनमा क्लिक गर्नुहोस् |'}
							</div>

							<form
								css={css`
									margin-top: 0.5rem;
									width: 400px;
									input {
										font-size: 1rem;
										padding: 0 0.5rem;
									}
								`}
								onSubmit={handleSubmit(handleSendEmail)}
							>
								<InputGroup className="mb-3">
									<FormControl
										aria-label="john.doe@gmail.com"
										placeholder="john.doe@gmail.com"
										aria-describedby="basic-addon2"
										{...register('email', {
											required: 'Email is required',
											validate: handleEmailValidation,
										})}
									/>
									<Button variant="primary" id="button-addon2" type="submit">
										{isSending ? <VscLoading className="spin" /> : 'Send'}
									</Button>
								</InputGroup>
								{errors.email && <p className="text-danger text-small">{errors.email.message}</p>}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CitizensFormSubmitted;
