import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import InputFields from "../../InputField";

const TextInput = ({ data, language, register }) => {
  const [results, setResults] = useState();

  useEffect(() => {
    if (data) {
      const keys = Object.keys(data.fields);
      const elements = keys.map((key) => document.getElementById(key));
      const result = elements.map((element) =>
        element
          ? ReactDOM.createPortal(
              <InputFields
                element={element}
                elementId={element.id}
                fields={data.fields}
                language={language}
                register={register}
              />,
              element
            )
          : ""
      );
      setResults(result);
    }
  }, [data, language, register]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: data.data }} />
      {results}
    </div>
  );
};

export default TextInput;
