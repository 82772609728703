/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
//eslint-disable-next-line no-unused-vars
import { Fragment, useState, useRef, useEffect } from "react";
import { Modal, Button, Tabs, Tab } from "react-bootstrap";
import { FaPrint } from "react-icons/fa";
import { AiOutlineFile, AiFillCloseCircle, AiFillEdit } from "react-icons/ai";
import { FiSave } from "react-icons/fi";
import { useSelector } from "react-redux";
// import NepaliDate from 'nepali-date-converter';
// import ContentEditable from "react-contenteditable";
import ContentEditable from "./ContentEditable";
import { normalizeHtml } from "../../_helpers/methods";

import { useHistory } from "react-router";
// import { Link } from 'react-router-dom';
import { nepaliNumberConverter } from "../../_helpers/methods";
// import QRCode from "qrcode.react";
import { VscLoading } from "react-icons/vsc";
import styled from "styled-components";
import { fetchActiveDesignTemplate } from "../../_redux/slices/designTemplates";
import { connect } from "react-redux";
import { generatePreview } from "../../_helpers/methods";

const PreviewModel = ({
  show,
  handleClose,
  paper,
  files = {},
  previewData,
  documents = [],
  previewFormData,
  handlePrint,
  innerRef,
  handleTemplateSave,
  config = {},
  preview,
  meta,
  isEnglish,
  paperExtra,
  isCitizenTemplate,
  paperId,
  isApproved,
  isSaved,
  handleIsSaved,
  issueId,
  handleIssueId,
  wardAdmin,
  fetchActiveDesignTemplate,
}) => {
  const user = useSelector((state) => state.users.user);
  const fiscal = useSelector((state) => state.fiscal);
  const activeDesignTemplate = useSelector(
    (state) => state.designTemplates.activeDesignTemplate
  );
  const history = useHistory();
  const [previewOuterHtml, setPreviewOuterHtml] = useState(
    normalizeHtml(previewData)
  );

  const [printSelect, setPrintSelect] = useState("preview");
  let wardAdminRole = "";
  let wardAdminName = "";

  useEffect(() => {
    fetchActiveDesignTemplate();
  }, [fetchActiveDesignTemplate]);

  const [designTemplate, setDesignTemplate] = useState({
    header: undefined,
    footer: undefined,
  });
  useEffect(() => {
    if (activeDesignTemplate?.length > 0) {
      let header = activeDesignTemplate?.find(
        (item) => item.choices === "header"
      );
      let footer = activeDesignTemplate?.find(
        (item) => item.choices === "footer"
      );
      let tempHeaderPreview = header
        ? generatePreview(
            { preview: header.content, meta: header.meta },
            header.meta,
            user
          )
        : undefined;
      let tempFooterPreview = footer
        ? generatePreview(
            { preview: footer.content, meta: footer.meta },
            footer.meta,
            user
          )
        : undefined;
      setDesignTemplate({
        header: tempHeaderPreview,
        footer: tempFooterPreview,
      });
    }
  }, [activeDesignTemplate, user]);
  // let ward = nagarpalika?.ward?.filter(
  //   (ward) => ward?.id === user?.profile?.ward
  // );
  const inputRef = useRef();
  // const handlePreviewEdit = (e) => {
  //   var targetNode = getSelectionStart();
  //   if (
  //     targetNode !== undefined &&
  //     targetNode.nodeType === 1 &&
  //     targetNode.nodeName === "SPAN"
  //   ) {
  //     var nodeHtmlString = targetNode.outerHTML;
  //     if (~nodeHtmlString.indexOf("nonEditable")) {
  //       e.preventDefault();
  //       e.stopPropagation();
  //     }
  //   }
  //   const data = getDifference(previewOuterHtml, e.target.innerHTML);
  //   console.log(data);
  // };

  // const getSelectionStart = () => {
  //   var node = document.getSelection().anchorNode;
  //   return node.nodeType === 3 ? node.parentNode : node;
  // };

  const [isSaveAndPrintLoading, setIsSaveAndPrintLoading] = useState(false);

  const handleSave = async () => {
    let response = await handleTemplateSave(previewFormData, previewOuterHtml);
    if (response) {
      history.push("/papers/verified/1");
      handleIsSaved(true);
    }
  };

  const handleSaveAndPrint = async () => {
    try {
      setIsSaveAndPrintLoading(true);
      let response = await handleTemplateSave(
        previewFormData,
        previewOuterHtml,
        true
      );
      if (response) {
        if (handleIsSaved) {
          handleIsSaved(true);
        }
        handleIssueId(response?.data?.issue_id);
        handlePrint();
      }
      setIsSaveAndPrintLoading(false);
    } catch (err) {
      console.log(err, "err ");
      setIsSaveAndPrintLoading(false);
    }
  };

  // For QR
  // let QRValue;
  // let applicantName;
  // if (isEnglish) {
  //   if (previewFormData.fullname_en) {
  //     applicantName = `${previewFormData.fullname_en.firstName} ${previewFormData.fullname_en.middleName} ${previewFormData.fullname_en.lastName} `;
  //   } else {
  //     applicantName = "";
  //   }
  //   QRValue = `
  //   Application Date: ${y}/${m}/${d},
  //     Invoice Token: ${previewFormData?.invoice_token || ""},
  //     Ward name: ${user.profile?.ward_info?.name_en || ""},
  //     Ward address: ${user.profile?.ward_info?.address_en || ""},
  //     Applicant Name:${applicantName}
  //   `;
  // } else {
  //   let applicantName;
  //   if (previewFormData.fullname_np) {
  //     applicantName = `${previewFormData.fullname_np.firstName} ${previewFormData.fullname_np.middleName} ${previewFormData.fullname_np.lastName} `;
  //   } else {
  //     applicantName = "";
  //   }
  //   QRValue = `
  //   जारी मिती: ${y}/${m}/${d},
  //   विधुतीय रशिद नं.: ${previewFormData?.invoice_token || ""},
  //   वडाको नाम :  ${user.profile?.ward_info?.name_np || ""},
  //   वडाको ठेगाना: ${user.profile?.ward_info?.address_np || ""},
  //   निवेदकको नाम: ${applicantName}
  //   `;
  // }
  if (wardAdmin) {
    let [AdminName, AdminRole] = wardAdmin.split("_");
    wardAdminRole = AdminRole;
    wardAdminName = AdminName;
  } else if (paperExtra) {
    if (paperExtra?.paper_category === "English Format") {
      wardAdminName =
        paperExtra.ward_kawa_fullname_en || paperExtra.ward_admin_fullname_en;
      wardAdminRole = paperExtra.ward_kawa_fullname_en
        ? "Act. Chairman"
        : "Ward Admin";
    } else {
      wardAdminName =
        paperExtra.ward_kawa_fullname_np || paperExtra.ward_admin_fullname_np;
      wardAdminRole = paperExtra.ward_kawa_fullname_np
        ? "वडा का.वा"
        : "वडा अध्यक्ष";
    }
  }

  return (
    <Modal
      id="preview"
      show={show}
      onHide={handleClose}
      dialogClassName="fullscreen-modal"
    >
      <Modal.Body className="position-relative">
        <Tabs
          defaultActiveKey="preview"
          className={`position-fixed top-0 left-0 border-0 flex-column bg-transparent p-0 d-flex justify-content-start `}
          style={{ gap: "1rem" }}
          onClick={(e) =>
            e.target.innerText === "Preview"
              ? setPrintSelect("preview")
              : e.target.innerText === "Documents" &&
                setPrintSelect("documents")
          }
        >
          <Tab
            eventKey="preview"
            title="Preview"
            disabled={
              documents?.length === 0 && Object.keys(files)?.length === 0
                ? true
                : false
            }
          >
            {previewData ? (
              <div
                className="page"
                ref={printSelect === "preview" ? innerRef : undefined}
                css={css``}
              >
                {config.hasHeader && user && user.profile && (
                  <Fragment>
                    {designTemplate.header ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: designTemplate.header,
                        }}
                      ></div>
                    ) : (
                      <div className="heading">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {user.profile.nagarpalika_info?.main_logo && (
                              <img
                                className="logo"
                                css={css`
                                  width: 150px;
                                `}
                                src={user.profile.nagarpalika_info?.main_logo}
                                alt="main_logo"
                              />
                            )}
                          </div>

                          <div className="title text-center">
                            <h5 className="nagarpalika_name">
                              {user.profile.nagarpalika_info?.name_np}
                            </h5>
                            <h5 className="nagarpalika_name">
                              {user.profile.nagarpalika_info?.name_en}
                            </h5>
                            <h3 className="woda_title">
                              {user.profile.ward_info?.name_np} नं वडा कार्यालय
                            </h3>
                            <h3 className="woda_title">
                              Office of Ward No.{" "}
                              {user.profile.ward_info?.name_en}
                            </h3>
                            <h5 className="ward_address">
                              {user.profile.ward_info?.address_np} (
                              {user.profile.ward_info?.address_en})
                            </h5>
                            <h5 className="ward_address">
                              {user.profile.nagarpalika_info?.address_np} (
                              {user.profile.nagarpalika_info?.address_en})
                            </h5>
                          </div>

                          {user.profile.nagarpalika_info?.campaign_logo ? (
                            <img
                              className="sidelogo"
                              css={css`
                                width: 150px;
                              `}
                              src={user.profile.nagarpalika_info?.campaign_logo}
                              alt="campaign_logo"
                            />
                          ) : (
                            <div style={{ width: "20%" }}></div>
                          )}
                        </div>
                        <div>
                          <div
                            className="
              header-secondary
              d-flex
              justify-content-between
              align-items-end
            "
                          >
                            <div>
                              <p className="mb-0">
                                प.स. (I.No.)-{" "}
                                {isEnglish
                                  ? fiscal?.activeFiscalYear?.title
                                  : nepaliNumberConverter(
                                      fiscal?.activeFiscalYear?.title
                                    )}
                              </p>
                              <p className="mb-0">चलानी नं. (Ref.No)</p>
                            </div>
                            {isEnglish ? (
                              previewFormData?.date_today_en ? (
                                <p className="mb-0">
                                  Date: {previewFormData?.date_today_en.year}/
                                  {previewFormData?.date_today_en.month}/
                                  {previewFormData?.date_today_en.day}
                                </p>
                              ) : null
                            ) : previewFormData?.date_today ? (
                              <p className="mb-0">
                                मिती: {previewFormData?.date_today.year}/
                                {previewFormData?.date_today.month}/
                                {previewFormData?.date_today.day}
                              </p>
                            ) : null}
                          </div>
                          <hr />{" "}
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}

                {issueId && (
                  <div
                    css={css`
                      position: absolute;
                      top: 28cm;
                      left: -1.7cm;
                      transform: rotate(90deg);
                      z-index: 5;
                      font-size: 1.2rem;
                    `}
                  >
                    {issueId}
                  </div>
                )}

                <table className="w-100 content">
                  {config.hasHeader && user && user.profile && (
                    <thead>
                      <tr>
                        <td>
                          <div className="header-space">&nbsp;</div>
                        </td>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <ContentEditable
                            id="preview"
                            autoComplete="off"
                            autoCorrect="off"
                            autocapiatlise="off"
                            spellCheck="false"
                            innerRef={inputRef}
                            html={
                              previewOuterHtml ? previewOuterHtml : previewData
                            }
                            disabled={preview ? true : false}
                            onChange={(e) => {
                              let tempValue = e.target.value;
                              setPreviewOuterHtml(tempValue);
                            }}
                            isEnglish={isEnglish}
                            tagName="div"
                          />
                        </div>
                        {config.hasHeader && (
                          <div className="col-12 d-flex justify-content-end">
                            <div className="text-right">
                              <div className="w-100 border-bottom border-dark border-dotted mb-2"></div>
                              <div>{wardAdminName}</div>
                              <div className="text-red">{wardAdminRole}</div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                  {config.hasFooter && user && user.profile && (
                    <tfoot>
                      <tr>
                        <td>
                          <div className="footer-space">&nbsp;</div>
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>

                {config.hasFooter &&
                user &&
                user.profile &&
                designTemplate.footer ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: designTemplate.footer }}
                  ></div>
                ) : (
                  <div className="page-footer flex-wrap pb-2">
                    <hr className="border-top border-dark w-100 d-block mx-4 mb-2" />
                    {}
                    <p>
                      {isEnglish ? "Email: " : "इमेल: "}
                      {meta?.ward_email
                        ? meta?.ward_email
                        : user?.profile?.ward_info?.email}
                    </p>
                    <p>
                      {isEnglish ? `ward secretary: ` : `वडा सचिब: `}
                      {isEnglish
                        ? meta?.ward_user[0]?.phone_en
                          ? meta?.ward_user[0]?.phone_en
                          : user?.profile?.ward_info?.ward_user_phone_en
                        : meta?.ward_user[0]?.phone_np
                        ? meta?.ward_user[0]?.phone_np
                        : user?.profile?.ward_info?.ward_user_phone_np}
                    </p>
                    <p>
                      {isEnglish ? `Ward Admin: ` : `वडा अध्यक्ष: `}
                      {isEnglish
                        ? meta?.ward_admins[0]?.phone_en
                          ? meta?.ward_admins[0]?.phone_en
                          : user?.profile?.ward_info?.ward_admin_phone_en
                        : meta?.ward_admins[0]?.phone_np
                        ? meta?.ward_admins[0]?.phone_np
                        : user?.profile?.ward_info?.ward_admin_phone_np}
                    </p>
                  </div>
                )}
              </div>
            ) : (
              paper
            )}
          </Tab>

          {(documents?.length !== 0 || Object.keys(files)?.length !== 0) && (
            <Tab eventKey="document" title="Documents">
              <div
                ref={printSelect === "documents" ? innerRef : undefined}
                className="page"
              >
                <div className="content">
                  {files &&
                    Object.values(files).map((file, i) => (
                      <div key={i}>
                        <div className="image-content">
                          <img
                            className="content-image"
                            src={URL.createObjectURL(file)}
                            alt="doc"
                          />
                        </div>
                      </div>
                    ))}
                  {documents?.length > 0 &&
                    documents?.map((document) => (
                      <div key={document.id}>
                        <h4 className="text-primary my-4 text-center">
                          {document.title}
                        </h4>
                        <div className="image-content">
                          <img
                            className="content-image"
                            src={document?.file}
                            alt="doc"
                          />
                        </div>
                      </div>
                    ))}
                  {/* {documents?.length === 0 && Object.keys(files)?.length === 0 && (
										<div style={{ height: '75vh' }} className="d-flex justify-content-center align-items-center">
											<h5 className="text-center text-danger">No documents to show</h5>
										</div>
									)} */}
                </div>
              </div>
            </Tab>
          )}
        </Tabs>
        <Actions>
          {preview ? (
            <Fragment>
              <Button
                variant="success"
                className="px-3 mx-2"
                onClick={handlePrint}
              >
                <FaPrint className="mr-2" />
                Print
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Fragment>
                {isCitizenTemplate && isApproved ? (
                  <Button
                    className="mx-2"
                    onClick={() => {
                      history.push(`/paper-single/${paperId}/citizen`);
                    }}
                  >
                    <AiFillEdit className="mr-2" />
                    Edit
                  </Button>
                ) : (
                  <Button
                    className="mx-2"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <AiFillEdit className="mr-2" />
                    Edit
                  </Button>
                )}
              </Fragment>

              <Fragment>
                {paperId ? (
                  <Fragment>
                    {isApproved ? (
                      <Button
                        variant="success"
                        className="px-3 mx-2"
                        onClick={handlePrint}
                      >
                        {isSaveAndPrintLoading ? (
                          <VscLoading className="spin" />
                        ) : (
                          <Fragment>
                            <FaPrint className="mr-2" />
                            Print
                          </Fragment>
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        className="px-3 mx-2"
                        onClick={handleSaveAndPrint}
                      >
                        <FaPrint className="mr-2" />
                        Update and Print
                      </Button>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <Button
                      variant="primary"
                      className="px-3 mx-2"
                      onClick={handleSave}
                    >
                      <FiSave className="mr-2" />
                      Save
                    </Button>
                    {isSaved ? (
                      <Button
                        variant="success"
                        className="px-3 mx-2"
                        onClick={handlePrint}
                      >
                        {isSaveAndPrintLoading ? (
                          <VscLoading className="spin" />
                        ) : (
                          <Fragment>
                            <FaPrint className="mr-2" />
                            Print
                          </Fragment>
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        className="px-3 mx-2"
                        onClick={handleSaveAndPrint}
                      >
                        <FaPrint className="mr-2" />
                        Save and Print
                      </Button>
                    )}
                  </Fragment>
                )}
              </Fragment>

              <Button
                variant="secondary"
                className="px-3 mx-2"
                onClick={() => {
                  history.push("/templates/1");
                }}
                disabled={isSaveAndPrintLoading}
              >
                <AiOutlineFile className="mr-2" />
                Create New
              </Button>
            </Fragment>
          )}
          <Button
            variant="secondary"
            className="px-3 mx-2"
            onClick={handleClose}
          >
            <AiFillCloseCircle className="mr-2" />
            Close
          </Button>
        </Actions>
      </Modal.Body>
    </Modal>
  );
};

export default connect(null, { fetchActiveDesignTemplate })(PreviewModel);

const Actions = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  /* transform: translateY(-50%); */
  width: max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  gap: 1rem;
  padding: 1.5rem;
  /* background-color: red; */
`;

/* 
                  Disabled for now
                  <div
                    css={css`
                      position: relative;
                    `}
                  >
                    <div
                      css={css`
                        position: absolute;
                        right: 0;
                        top: -13px;
                        width: 2.5cm;
                        height: 2.5cm;
                      `}
                    >
                      <QRCode value={QRValue} size={90} />
                    </div>
                  </div> */
