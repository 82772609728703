import styled from 'styled-components';
import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
// Define a default UI for filtering
const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  const lang = "nepali"
  const [value, setValue] = useState(filterValue);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value);
  }, 800);
  return (
    <StyledFilterInput
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={lang === 'ne' ? 'खोज्नुहोस' : 'Search...'}
    />
  );
};

export default DefaultColumnFilter;

export const StyledFilterInput = styled.input`
  width: 100%;
  padding: 0 5px;
`;
