/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useState, Fragment } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import { VscLoading } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../_helpers/fetchWrapper';
import BackButton from '../../_elements/BackButton';
import { useSelector } from 'react-redux';
import NepaliDate from 'nepali-date-converter';
import { languageSelector } from '../../_redux/slices/languages';

const SifarishVerification = () => {
	const [showStatus, setShowStatus] = useState(false);
	const [paper, setPaper] = useState(false);
	const [issueId, setIssueId] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isLoggedIn = useSelector((state) => state?.users?.isLoggedIn);
	const nagarpalika = useSelector((state) => state?.nagarpalika?.nagarpalika);
	const { language } = useSelector(languageSelector);
	const [isStatusCheckSuccess, setIsStatusCheckSuccess] = useState(false);

	const handleStatusCheck = async (e) => {
		try {
			setIsLoading(true);
			e.preventDefault();
			const response = await fetchWrapper.get(`/public/sifarish-verification?issue_id=${issueId}`);
			setShowStatus(!showStatus);
			if (response) {
				setPaper(response?.data);
				setIsStatusCheckSuccess(true);
			} else {
				toast.error('Paper not found');
				setIsStatusCheckSuccess(false);
			}
			setIsLoading(false);
		} catch (error) {
			setShowStatus(!showStatus);
			if (error?.error?.non_field_error) {
				toast.error(error.error.non_field_error[0]);
				setIsStatusCheckSuccess(false);
			}
			setIsLoading(false);
		}
	};
	let applicantName;
	if (language === 'english' && paper) {
		applicantName = paper?.fullname_en;
	} else {
		applicantName = paper?.fullname_np;
	}

	let wardAdminPhoneNo, wardUserPhoneNo;
	if (language === 'english' && paper) {
		let ward = nagarpalika?.ward?.filter((ward) => ward.id === paper?.ward);
		if (ward?.length > 0) {
			wardAdminPhoneNo = ward[0].ward_admin_phone_en;
			wardUserPhoneNo = ward[0].ward_user_phone_en;
		}
	} else {
		let ward = nagarpalika?.ward?.filter((ward) => ward.id === paper?.ward);
		if (ward?.length > 0) {
			wardAdminPhoneNo = ward[0].ward_admin_phone_np;
			wardUserPhoneNo = ward[0].ward_user_phone_np;
		}
	}
	return (
		<Fragment>
			<div
				className="login-register"
				css={css`
					min-height: 100vh;
					padding: 2% 5%;
				`}
			>
				{!isLoggedIn && (
					<div className="login-brand text-center mb-2">
						<img src="/assets/images/nep_logo.png" alt="logo" width="100" className="shadow-light rounded-circle" />
						<h3>परिचय पत्र ब्यबस्थापन प्रणाली</h3>
						<p>प्रविधिमुखी सार्वजनिक सेवा प्रवाहको सुनिश्चितताको प्रयास</p>{' '}
					</div>
				)}
				<div className="d-flex">
					<Card
						css={css`
							width: 460px;
							height: fit-content;
							margin: 1rem auto;
							box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
							border-radius: 5px;
							border-top: 2px solid #6777ef;

							@media screen and (max-width: 600px) {
								width: 100%;
							}
						`}
					>
						<Card.Header>
							<div className="text-center">
								<h2 className="text-primary mb-0">परिचय पत्र प्रमाणिकरण</h2>
							</div>
						</Card.Header>
						<Card.Body>
							<Form
								css={css`
									display: flex;
									flex-direction: column;
								`}
								onSubmit={(e) => handleStatusCheck(e)}
							>
								<div
									css={css`
										display: flex;
										justify-content: center;
										text-align: center;
									`}
								>
									कृपया परिचय पत्र E.N: राख्नुहोस ।
								</div>
								<div
									css={css`
										display: flex;
										justify-content: center;
										text-align: center;
									`}
								>
									<Form.Control
										css={css`
											width: 285px;
											font-size: 18px;
											margin: 0.5rem 0;
										`}
										type="text"
										placeholder="Enter request code"
										onChange={(e) => setIssueId(e.target.value)}
										required
									></Form.Control>
								</div>
								<div
									css={css`
										display: flex;
										justify-content: center;
										text-align: center;
									`}
								>
									<Button
										variant="success"
										css={css`
											width: 140px;
										`}
										className="mt-2"
										type="submit"
										disabled={isLoading}
									>
										{isLoading ? <VscLoading className="spin" /> : 'Submit'}
									</Button>
								</div>
								<BackButton />
							</Form>
						</Card.Body>
					</Card>
					{showStatus && (
						<Card
							css={css`
								width: 600px;
								margin: 1rem auto 2rem;
								border: #d9d9d9 2px solid;
							`}
						>
							<Card.Body
								css={css`
									padding: 2rem;
								`}
							>
								{isStatusCheckSuccess ? (
									<Fragment>
										<p
											className="text-center"
											css={css`
												font-size: 18px;
											`}
										>
											<strong>{nagarpalika?.name_np}</strong> वडा नं <strong>{paper?.ward}</strong> ले मिति{' '}
											<strong>
												{paper?.created_at && new NepaliDate(new Date(paper?.created_at)).format('YYYY/MM/DD', 'np')}
											</strong>{' '}
											का दिन <strong>{applicantName}</strong> ब्यक्ति/संस्थालाइ <strong>{paper?.paper_title}</strong>{' '}
											सम्बन्धी परिचय पत्र प्रदान गरेको छ।
										</p>
										<div
											css={css`
												width: 30%;
												margin: 1rem auto;
											`}
										>
											<img
												css={css`
													width: 100%;
												`}
												src={nagarpalika?.main_logo}
												alt={nagarpalika?.name_np}
											/>
										</div>
										<div className="text-center">
											<h5>{nagarpalika?.name_np}को कार्यालय</h5>
											<h6>{nagarpalika?.district_np}</h6>
											<p>{nagarpalika?.address_np}</p>
											<div className="mt-3" />
											<strong>थप जानकारीको लागि</strong>
											<p>
												वडा अध्यक्ष-{paper?.ward} : {paper?.ward_admin_phone_np}
											</p>
											<p>
												वडा सचिव-{paper?.ward} : {paper?.ward_user_phone_np}
											</p>
										</div>
									</Fragment>
								) : (
									<Fragment>
										<p
											className="text-center"
											css={css`
												font-size: 18px;
											`}
										>
											तपाईले खोज्नुभएको परिचय पत्र भेटिएन । कृपया थप जानकारीको लागि <strong>{nagarpalika?.name_np}</strong>
											मा सम्पर्क गर्नुहोला ।
										</p>
										<div
											css={css`
												width: 30%;
												margin: 1rem auto;
											`}
										>
											<img
												css={css`
													width: 100%;
												`}
												src={nagarpalika?.main_logo}
												alt={nagarpalika?.name_np}
											/>
										</div>
										<div className="text-center">
											<h5>{nagarpalika?.name_np}को कार्यालय</h5>
											<h6>{nagarpalika?.district_np}</h6>
											<p>{nagarpalika?.address_np}</p>
											<div className="mt-3" />
											<strong>सम्पर्कको लागि</strong>
											<p>
												नगरपालिकाको नम्बर: <strong>{nagarpalika?.phone_np}</strong>
											</p>
										</div>
									</Fragment>
								)}
							</Card.Body>
						</Card>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default SifarishVerification;
