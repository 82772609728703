import { createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { setUiMessage, setUiError } from "./ui";

export const initialState = {
  loading: false,
  hasErrors: false,
  wards: [],
  count: 0,
  wardUsers: [],
};

// A slice
const wardSlice = createSlice({
  name: "wards",
  initialState,
  reducers: {
    getWards: (state, action) => {
      state.wards = action.payload.data.results;
      state.count = action.payload.data.count;
    },
    getWardUsers: (state, action) => {
      state.wardUsers = action.payload;
    },
    createWardUser: (state, action) => {
      state.wardUsers.users.push(action.payload.data.users[0]);
    },
    editWardUser: (state, action) => {
      const editedUser = [...state.wardUsers];

      const editedIndex = editedUser.findIndex(
        (user) => user.id === action.payload.id
      );
      if (editedIndex !== -1) {
        editedUser[editedIndex] = action.payload;
      }
      state.wardUsers = editedUser;
    },
    activeWardUser: (state, action) => {
      const tempUser = [...state.wardUsers];
      const editedIndex = tempUser.findIndex(
        (user) => user.id === action.payload.profile_id
      );
      if (editedIndex !== -1) {
        if (action.payload.status === "active") {
          tempUser[editedIndex].is_active = true;
        } else {
          tempUser[editedIndex].is_active = false;
        }
      }
      state.wardUsers = tempUser;
    },
    deleteWardUser: (state, action) => {
      const tempUsers = [...state.wardUsers];
      const index = tempUsers.findIndex((user) => user.id === action.payload);
      tempUsers.splice(index, 1);
      state.wardUsers = tempUsers;
    },
  },
});

// Actions generated from the slice
const {
  getWards,
  getWardUsers,
  // createWardUser,
  activeWardUser,
  editWardUser,
  deleteWardUser,
} = wardSlice.actions;

// export user selector to get the slice in any component
export const wardSelector = (state) => state.wards;

// export The reducer
const wardReducer = wardSlice.reducer;

export default wardReducer;

// Actions
export const fetchWards =
  (page = 1) =>
    async (dispatch) => {
      try {
        const wards = await fetchWrapper.get(`/ward/?page=${page}`);
        console.log(wards, "wards");
        dispatch(getWards(wards));
      } catch (error) {
        console.log(error, "error");
      }
    };

export const fetchWardUsers = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.get(`/ward/${id}/users`);
    dispatch(getWardUsers(response.data));
  } catch (error) { }
};

export const fetchWardById = async (id) => {
  try {
    const response = await fetchWrapper.get(`/ward/${id}`);
    return response.data;
  } catch (error) { }
};

export const patchWardUser =
  (data, id, setShowAdd, showAdd) => async (dispatch) => {
    try {
      if (data.password === "") {
        delete data.password;
      }
      const response = await fetchWrapper.patch(`/ward-user/${id}/`, data);
      // console.log(response, data);

      dispatch(editWardUser(data));
      setShowAdd(false);
      dispatch(setUiMessage(response.message));
    } catch (error) {
      console.log(error, "error");
      dispatch(setUiError(error.message));
    }
  };

export const activateKawaWardUser = (body) => async (dispatch) => {
  try {
    const response = await fetchWrapper.post(`/update-kawa-status/`, body);
    dispatch(activeWardUser(body));
    dispatch(setUiMessage(response.message));
  } catch (error) { }
};

export const removeWardUser = (id) => async (dispatch) => {
  try {
    const response = await fetchWrapper.delete(`/users/${id}/`);

    dispatch(deleteWardUser(id));
    dispatch(setUiMessage(response.message));
  } catch (error) { }
};
