import "./sass/style.scss";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Templates from "./pages/Templates/Templates";
import TemplateSingle from "./pages/Templates/TemplateSingle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TemplateForm from "./pages/Templates/TemplateForm";
import TemplateOld from "./pages/Templates/TemplateOld";
import Verified from "./pages/Papers/Verified";
import Unverified from "./pages/Papers/Unverified";
import Users from "./pages/Users/Users";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import { PrivateRoute } from "./auth-route";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { clearUiMessage } from "./_redux/slices/ui";
import { getUserData } from "./_redux/slices/users";
import { fetchActiveFiscalYear } from "./_redux/slices/fiscalyear";
import Settings from "./pages/Settings/Settings";
import FiscalYear from "./pages/Settings/FiscalYear";
import Ward from "./pages/Settings/Ward/Ward";
import AllUsers from "./pages/Settings/Users/AllUsers";
import WardUser from "./pages/Settings/Users/WardUser";
import SifarishType from "./pages/Settings/SifarishType";
import DocumentsType from "./pages/Settings/Documents/DocumentsType";
import FormerMunicipal from "./pages/Settings/FormerMunicipal/FormerMunicipal";
import Applicant from "./pages/Settings/Applicant/Applicant";
import PaperSingle from "./pages/Templates/PaperSingle";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Report from "./pages/Report";
import AddWard from "./pages/Settings/Ward/AddWard";
import VerifyCitizenTemplate from "./pages/VerifyCitizenTemplate";
import CitizenTemplateSingle from "./pages/CitizenTemplateSingle";
import CitizensHome from "./pages/Citizens/CitizensHome";
import CitizensTemplate from "./pages/Citizens/CitizensTemplate";
import CitizensFormSubmitted from "./pages/Citizens/CitizensFormSubmitted";
import SifarishStatus from "./pages/Citizens/SifarishStatus";
import Faq from "./pages/Faq";
import Support from "./pages/Support";
import NagarpalikaDetails from "./pages/NagarpalikaDetails/NagarpalikaDetails";
import { fetchNagarpalika } from "./_redux/slices/nagarpalika";
import Profile from "./pages/Settings/Profile/Profile";
import SifarishVerification from "./pages/SifarishVerification/SifarishVerification";
import Layout from "./layout/Layout";
import DesignTempate from "./pages/Settings/DesignTemplate";
import Disabled from "./pages/Disabled";
import NewDisabled from "./pages/Disabled/NewDisabled";
import OldAge from "./pages/OldAge";
import NewOldAge from "./pages/OldAge/NewOldAge";
import DisabledWithId from "./pages/Disabled/DisabledWithId";
import OldAgeWithId from "./pages/OldAge/OldAgeWithId";
import DisabledWithoutId from "./pages/Disabled/DisabledWithoutId";
import OldAgeWithoutId from "./pages/OldAge/OldAgeWithoutId";
import Prashasak from "./pages/Settings/Prashasak";
import NewPrashasak from "./pages/Settings/Prashasak/NewPrashasak";
import DisabledPratilipi from "./pages/Disabled/Pratilipi";
import OldAgePratilipi from "./pages/OldAge/Pratilipi";
import { fetchActivePrashasak } from "./_redux/slices/prashasak";
import Preview from "./pages/Preview";
import { fetchAppStats } from "./_redux/slices/appStats";
import OverviewDashboard from "./pages/OverviewDashboard";
import PratilipiForm from "./pages/Disabled/PratilipiForm";
import PratilipiPreview from "./pages/Disabled/PratilipiPreview";
import FingerPrint from "./pages/Fingerprint";
import Sakhas from "./pages/Settings/Sakha/Sakhas";
import SakhaForm from "./pages/Settings/Sakha/Sakha/SakhaForm";
import SakhaUser from "./pages/Settings/Sakha/Sakha/SakhaUser";

function App({
  clearUiMessage,
  fetchNagarpalika,
  getUserData,
  fetchActiveFiscalYear,
  fetchActivePrashasak,
  fetchAppStats,
}) {
  const message = useSelector((state) => state.ui.message);
  const isLoggedIn = useSelector((state) => state.users.isLoggedIn);
  const user = useSelector((state) => state.users.user);

  useEffect(() => {
    fetchNagarpalika();
  }, [fetchNagarpalika]);

  useEffect(() => {
    if (isLoggedIn) {
      let filter = "";
      if (user?.role_en !== "admin" || user?.role_en !== "ito_admin") {
        if (user?.ward) {
          filter += `?ward=${user?.ward}`;
        }
      }
      fetchAppStats(filter);
    }
  }, [fetchAppStats, isLoggedIn, user]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserData();
      fetchActiveFiscalYear();
      fetchActivePrashasak();
    }
  }, [
    getUserData,
    fetchActiveFiscalYear,
    isLoggedIn,
    fetchActivePrashasak,
    fetchAppStats,
  ]);

  useEffect(() => {
    if (message) {
      if (message.error === false) {
        toast.success(message.message);
        clearUiMessage();
      } else if (message) {
        toast.error(message.message);
        clearUiMessage();
      } else {
        toast.success(message);
      }
    }
  }, [message, clearUiMessage]);

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/citizens" component={CitizensHome} />
        <Route exact path="/status" component={SifarishStatus} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/password/reset" component={ResetPassword} />

        <Route
          exact
          path="/citizen-template/paper-submitted/:token"
          component={CitizensFormSubmitted}
        />
        <Route
          exact
          path="/citizen-template/:id"
          component={CitizensTemplate}
        />

        <Route
          exact
          path="/public/sifarish/verification"
          component={SifarishVerification}
        />
        <Route
          path="/"
          render={({ match }) => <RootRoutes basePath={match.path} />}
        />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default connect(null, {
  clearUiMessage,
  getUserData,
  fetchNagarpalika,
  fetchActiveFiscalYear,
  fetchActivePrashasak,
  fetchAppStats,
})(App);

const RootRoutes = ({ basePath }) => (
  <Layout>
    {/* Samajik surakshya */}
    <PrivateRoute
      exact
      path="/disabled/list/:pageNum"
      component={Disabled}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "sakha_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/:type/pratilipi_form/:id"
      component={PratilipiForm}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "ward_user",
        "sakha_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/disabled-with-id"
      component={DisabledWithId}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/disabled-without-id"
      component={DisabledWithoutId}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/disabled/copy/:pageNum"
      component={DisabledPratilipi}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/:category/copy/preview/:id"
      component={PratilipiPreview}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path={["/disabled/new", "/disabled/edit/:id"]}
      component={NewDisabled}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />

    <PrivateRoute
      exact
      path="/elderly/list/:pageNum"
      component={OldAge}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/oldage-with-id"
      component={OldAgeWithId}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/oldage-without-id"
      component={OldAgeWithoutId}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/elderly/copy/:pageNum"
      component={OldAgePratilipi}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/elderly/new"
      component={NewOldAge}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />

    <PrivateRoute
      exact
      path="/elderly/edit/:id"
      component={NewOldAge}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />

    <PrivateRoute
      exact
      path="/preview/:category/:id"
      component={Preview}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />

    <PrivateRoute
      exact
      path="/fingerprint/:category/:id"
      component={FingerPrint}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />

    <PrivateRoute
      exact
      path="/settings"
      component={Settings}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/prashasak/list/:pageNum"
      component={Prashasak}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path={["/settings/prashasak/new/", "/settings/prashasak/new/:id"]}
      component={NewPrashasak}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    {/* Legacy */}
    <PrivateRoute
      exact
      path="/"
      component={OverviewDashboard}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/reports"
      component={Report}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />

    <PrivateRoute
      exact
      path="/settings/fiscalyear"
      component={FiscalYear}
      accessGrantedRoles={["admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/designtemplate"
      component={DesignTempate}
      accessGrantedRoles={["admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/ward"
      component={Ward}
      accessGrantedRoles={["admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/allusers/:pageNum"
      component={AllUsers}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/all-sakhas/:pageNum"
      component={Sakhas}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/sakha/form"
      component={SakhaForm}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/sakha/update/:id"
      component={SakhaForm}
      accessGrantedRoles={["admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/settings/sakhausers/:id"
      component={SakhaUser}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/warduser/:id"
      component={WardUser}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/profile"
      component={Profile}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
        "mayor",
        "upa_mayor",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/sifarishtype"
      component={SifarishType}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/settings/documentstype/:pageNum"
      component={DocumentsType}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/formermunicipal"
      component={FormerMunicipal}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/settings/applicants"
      component={Applicant}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/faq"
      component={Faq}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/support"
      component={Support}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/ward/create/"
      component={AddWard}
      accessGrantedRoles={["admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/ward/update/:id"
      component={AddWard}
      accessGrantedRoles={["admin", "ito_admin"]}
    />
    <PrivateRoute
      exact
      path="/nagarpalika/:pageNum"
      component={NagarpalikaDetails}
      accessGrantedRoles={["admin", "ito_admin"]}
    />
    <Route
      exact
      path="/sifarish/verification"
      component={SifarishVerification}
    />
    <PrivateRoute
      exact
      path="/users"
      component={Users}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/papers/unverified"
      component={Unverified}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/verify/:pageNum"
      component={VerifyCitizenTemplate}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/citizen/template/:id"
      component={CitizenTemplateSingle}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/papers/verified/:pageNum"
      component={Verified}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/template-single/:id"
      component={TemplateForm}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/template-single"
      component={TemplateSingle}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />

    <PrivateRoute
      exact
      path="/paper-single/:paperId"
      component={PaperSingle}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/paper-single/:paperId/:citizen"
      component={PaperSingle}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/templates/:pageNum"
      component={Templates}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
    <PrivateRoute
      exact
      path="/template-old"
      component={TemplateOld}
      accessGrantedRoles={[
        "admin",
        "ito_admin",
        "ward_admin",
        "sakha_user",
        "ward_user",
        "ward_paper_issuer",
      ]}
    />
  </Layout>
);
