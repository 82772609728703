/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState, Fragment } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VscLoading } from "react-icons/vsc";
import Elements from "../../..";
import { fetchWrapper } from "../../../../../../_helpers/fetchWrapper";
import getFormInputKey from "../../../../../../_helpers/getFormKey";
import { data } from "./data";

function SearchByNagritaModal({
  show,
  handleClose,
  values,
  nameElementId,
  language,
  setFormValue,
  setNameValue,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [searchedApplicants, setSearchedApplicants] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formData) => {
    const searchForApplicants = async () => {
      try {
        setIsLoading(true);
        let response = await fetchWrapper.get(
          `/applicant/?citizenship_number=${formData.nagriktaNum}${
            formData.district_en ? `&district=${formData.district_en}` : ""
          }`
        );
        setSearchedApplicants(response.data.results);
        setValue("permanent_country", "Nepal");
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    searchForApplicants();
  };

  const onClearClick = () => {
    setValue("nagriktaNum", null);
    setValue("district_np", null);
    handleClose();
  };

  const fillApplicantData = (data) => {
    try {
      Object.keys(data).forEach((key) => {
        setFormValue(getFormInputKey(key), data[key]);
      });
      if (nameElementId === "fullname_np") {
        setNameValue({
          firstName: data?.first_name_np ?? "",
          middleName: data?.middle_name_np ?? "",
          lastName: data?.last_name_np ?? "",
        });
      } else if (nameElementId === "fullname_en") {
        setNameValue({
          firstName: data?.first_name_en ?? "",
          middleName: data?.middle_name_en ?? "",
          lastName: data?.last_name_en ?? "",
        });
      }
      handleClose();
    } catch (err) {}
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>सदस्य खोज्नुहोस्</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          css={css`
            position: relative;
          `}
        >
          {isLoading ? (
            <div
              className="w-100 h-100 d-flex justify-content-center align-items-center"
              css={css`
                position: absolute;
                background-color: rgba(255, 255, 255, 0.8);
              `}
            >
              <VscLoading className="spin" fontSize="48" />
            </div>
          ) : null}

          <Elements
            language={language}
            elements={data}
            register={register}
            errors={errors}
            setValue={setValue}
          />
          <div className="d-flex justify-content-center">
            <Button className="mr-4" variant="secondary" onClick={onClearClick}>
              रद्द गर्नुहोस्
            </Button>
            <Button onClick={handleSubmit(onSubmit)}>खोज्नुहोस्</Button>
          </div>
        </form>

        {searchedApplicants && (
          <Fragment>
            <hr />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <td>नागरिकता नम्बर</td>
                  <td>नागरिकता जारी गर्ने जिल्ला </td>
                  <td>नाम</td>
                  <td>फोन नम्बर</td>
                  <td>कार्य</td>
                </tr>
              </thead>
              <tbody>
                {searchedApplicants.length ? (
                  searchedApplicants.map((applicant) => {
                    return (
                      <tr key={applicant.id}>
                        <td>{applicant.citizenship_number}</td>
                        <td>{applicant.citizenship_issue_district}</td>
                        <td>
                          {applicant.first_name_np} {applicant.middle_name_np}{" "}
                          {applicant.last_name_np}
                        </td>
                        <td>{applicant.phone}</td>
                        <td>
                          <Button onClick={() => fillApplicantData(applicant)}>
                            छान्नु होस्{" "}
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colspan="5" className="text-center">
                      Applicant not found
                    </td>
                  </tr>
                )}

                <tr></tr>
              </tbody>
            </Table>
          </Fragment>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default SearchByNagritaModal;
