import styled from '@emotion/styled';
import React from 'react';
import StatCard from '../_elements/StatCard';
import Chart from 'react-apexcharts';
import { Row, Col } from 'react-bootstrap';
import { GoBrowser } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { DashBoardChartData } from '../constant';

function OverviewDashboard() {
	const [chartType, setChartType] = React.useState('category');
	const dashboardStats = useSelector(state => state.appStats.dashboardStats);

	const options = React.useMemo(() => {
		let categories = [];
		if (chartType === 'category') {
			categories = DashBoardChartData.map(item => item.title);
		} else {
			categories = [];
		}
		return {
			chart: {
				type: 'bar',
			},
			xaxis: {
				categories: categories,
			},
		};
	}, [chartType]);

	const series = React.useMemo(() => {
		let data = [];
		if (chartType === 'category') {
			data = DashBoardChartData.map((item) => {
				if (!dashboardStats) {
					return '';
				}


				return dashboardStats[item.id]?.count ?? 0;
			})
		} else {
			data = [];
		}
		return [
			{
				name: 'जारी भएका परिचय पत्र',
				data: data,
			},
		];
	}, [chartType, dashboardStats]);

	return (
		<div>
			<Row className="mt-3 mb-2 px-3">
				<StyledChartsContainer className="col-9 shadow-md">
					<div className="bg-white p-4 rounded-5 w-100">
						<div>
							<button
								className={`chart-btn ${chartType === 'category' && 'text-primary'}`}
								onClick={() => setChartType('category')}
							>
								<h3>
									परिचय पत्रको प्रकार
								</h3>
							</button>
						</div>
						<div>
							<Chart options={options} series={series} type="bar" key={options} />
						</div>
					</div>
				</StyledChartsContainer>
				<Col md={3}>
					{
						(Object.keys(dashboardStats) ?? []).map(key => {
							return <div key={key}>
								<StatCard title={key} item={dashboardStats[key]} icon={<GoBrowser />} />
							</div>
						})
					}
				</Col>
			</Row>

		</div>
	);
}

export default OverviewDashboard;

const StyledChartsContainer = styled.div`
	height: max-content;
	.chart-btn {
		border: none;
		background: transparent;
		margin-inline: 1rem;
		&:focus,
		&:active,
		&:hover {
			outline: none;
		}
	}
	.count-badge {
		width: 3rem;
		aspect-ratio: 1 / 1;
		display: grid;
		place-content: center;
	}
`;
