import InputField from "../../InputField";

const Date = ({ data, language, register, setValue }) => {
  return (
    <div className="d-flex justify-content-end font-weight-bold px-2 py-3">
      मिती :
      <InputField
        type="date"
        language={language}
        className="dateInput "
        maxLength={14}
        register={register}
        setValue={setValue}
        placeholder="---- / -- / --"
      />
    </div>
  );
};

export default Date;
