import React from "react";
import { PALIKADATA } from "../_datas";

function Province({ register, elementId, province, handleProvinceChange }) {
  return (
    <div>
      <label>Province</label>
      <select
        value={province}
        {...register(elementId)}
        onChange={(e) => {
          handleProvinceChange(e.target.value);
          register(elementId).onChange(e);
        }}
      >
        <option key={1} value={""}>
          select province
        </option>
        {Object.keys(PALIKADATA).map((province) => (
          <option key={province} value={province}>
            {province}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Province;
