import styled from "@emotion/styled";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaCamera } from "react-icons/fa";
import uuid from "react-uuid";

// const constraints = {
//   video: {
//     width: 450,
//     height: 700,
//   },
// };

/**
 *
 */
export default function Camera({ handleFileUpload, defaultValue }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <StyledCamera>
      <Button onClick={handleShow}>
        <FaCamera className="mr-2" /> Camera
      </Button>
      {show && (
        <CameraModal
          show={show}
          handleClose={handleClose}
          defaultValue={defaultValue}
          handleFileUpload={handleFileUpload}
        />
      )}
    </StyledCamera>
  );
}

const CameraModal = ({ show, handleClose, defaultValue, handleFileUpload }) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const screenshotImageRef = useRef(null);
  const ImageContainerRef = useRef(null);
  const [stream, setStream] = useState(null);

  const [photo, setPhoto] = useState();
  const [videoInputOptions, setVideoInputOptions] = useState([]);

  useEffect(() => {
    if (
      defaultValue &&
      ImageContainerRef?.current &&
      screenshotImageRef?.current
    ) {
      ImageContainerRef.current.classList.remove("d-none");
      screenshotImageRef.current.src = defaultValue;
      setPhoto(defaultValue);
    }
  }, [defaultValue]);

  const handleCapture = () => {
    canvasRef.current.width = videoRef.current.videoWidth;
    canvasRef.current.height = videoRef.current.videoHeight;
    canvasRef.current.getContext("2d").drawImage(videoRef.current, 0, 0);

    const file = canvasRef.current.toDataURL("image/jpg");
    screenshotImageRef.current.src = file;
    ImageContainerRef.current.classList.remove("d-none");
    setPhoto(file);
  };

  useEffect(() => {
    const cameraLoad = async () => {
      // if (
      //   "mediaDevices" in navigator &&
      //   "getUserMedia" in navigator.mediaDevices
      // ) {
      //   await navigator.mediaDevices?.getUserMedia({ video: true });
      // }

      const devices = await navigator.mediaDevices?.enumerateDevices();
      const videoDevices = (devices || []).filter(
        (device) => device.kind === "videoinput"
      );
      const options = videoDevices.map((videoDevice) => {
        return { value: videoDevice.deviceId, label: videoDevice.label };
      });
      setVideoInputOptions(options);
    };
    cameraLoad();
  }, []);

  const startStream = async (event) => {
    const updatedConstraints = {
      video: true,
      deviceId: {
        exact: event.target.value,
      },
    };
    const stream = await navigator.mediaDevices.getUserMedia(
      updatedConstraints
    );
    setStream(stream);

    videoRef.current.srcObject = stream;
  };

  function handleCanPlay() {
    videoRef.current.play();
  }

  const handleSave = () => {
    canvasRef.current.toBlob((blob) => {
      let file = new File([blob], `${uuid()}-profile_Pic.jpg`, {
        type: "image/jpeg",
      });
      handleFileUpload(file);
      handleBeforClose();
    }, "image/jpeg");
  };

  const handleBeforClose = () => {
    if (stream) {
      const videoTrack = stream.getVideoTracks();
      console.log(videoTrack, stream);
      if (videoTrack.length > 0) {
        // stream.removeTrack(videoTrack[0]);
        videoTrack.forEach((track) => track.stop());
      }
    }
    handleClose();
  };

  return (
    <StyledModal size="xl" show={show} onHide={handleBeforClose}>
      <Modal.Header closeButton>
        <Modal.Title>Camera</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-around align-items-center mb-4">
            <div className="video_container">
              <video
                className="video"
                ref={videoRef}
                onCanPlay={handleCanPlay}
                autoplay
                playsInline
                muted
              >
                <FaCamera size={68} />
              </video>
              <canvas ref={canvasRef} class="d-none"></canvas>
            </div>

            <div ref={ImageContainerRef} className="d-none">
              <h3 className="text-center">Photo</h3>
              <img
                className="screenshot-image"
                ref={screenshotImageRef}
                alt=""
              />
            </div>
          </div>

          <div class="video-options">
            <select name="" id="" class="custom-select" onChange={startStream}>
              <option value="">Select camera</option>
              {videoInputOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="align-items-center d-flex justify-content-center">
        <Button variant="primary" onClick={handleCapture}>
          Capture
        </Button>

        {photo && (
          <Button
            className="ml-3"
            variant="outline-primary"
            onClick={handleSave}
          >
            Save
          </Button>
        )}
      </Modal.Footer>
    </StyledModal>
  );
};

const StyledCamera = styled.div`
  .screenshot-image {
    width: 150px;
    height: 90px;
    border-radius: 4px;
    border: 2px solid whitesmoke;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 5px;
    left: 10px;
    background: white;
  }
`;

const StyledModal = styled(Modal)`
  .video_container {
    max-width: 400px;
    height: 100%;
  }
  .screenshot-image {
    width: 400px;
  }
  .video {
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
`;
