import styled from "styled-components";
import { Card } from "react-bootstrap";

export const StyledStatusCard = styled(Card)`
  width: 600px;
  margin: 1rem auto;
  font-size: 1.2rem;
  .logo_div {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.4rem;
  }
  .status_span {
    font-size: 1.5rem;
    font-weight: 600;

    .Approved {
      color: #2f8a72;
    }
    .Pending {
      color: #6677ef;
    }
    .Rejected {
      color: #ad4848;
    }
  }
`;
