import styled from '@emotion/styled';
import React from 'react'
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { errorMessageChecker } from '../../../../_helpers/methods';
import { StyledInput } from '../../../InputField/style/StyledIndex';

function Password({
    register,
    elementId, className, value,
    setValue, validations, isDisabled, placeHolder, errors
}) {
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(prev => !prev);
    }
    return (
        <StyledPassword>
            <StyledInput
                id={elementId}
                {...register(elementId.split("'").join("&&&&"), validations)}
                type={show ? 'text' : 'password'}

                className={`${className} input ${validations?.isRequired ? "textbox_req" : ""
                    } ${errorMessageChecker(errors, elementId) ? "is-invalid" : ""} `}
                placeholder={placeHolder}
                autoComplete={"off"}
                disabled={isDisabled}
            />

            <div onClick={toggleShow} className="iconDiv">
                {
                    show ?
                        <FaEyeSlash size="16" className="icon" />
                        :
                        <FaEye size="16" className="icon" />
                }
            </div>


        </StyledPassword>
    )
}

export default Password;

const StyledPassword = styled.div`
    position: relative;

    .iconDiv{
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 14px;
    }

`;