/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { ad2bs } from "ad-bs-converter";
import moment from "moment";
import { getAge, nepaliNumberConverter } from "../../../_helpers/methods";

//
import { Item, PreviewDetailContainer } from "../styles/StyledPreview";
import { FINGER_PRINT_DATA } from "../../../constant";

/**
 *
 */
export default function ElderlyPreviewDetail({ userProfile, fingerPrints }) {
  let createdAt = ad2bs(moment(userProfile.createdAt).format("YYYY/MM/DD"));
  const hasFingerpintFeat = process.env.REACT_APP_HAS_FINGERPRINT_FEATURE;
  return (
    <PreviewDetailContainer>
      {/* 1st row */}
      <Item
        className="left"
        label="ज्येष्ठ नागरिक परिचयपत्र नं"
        value={userProfile.cardNo}
      />
      <Item className="center" label="पुरा नाम" value={userProfile.name_np} />
      <Item className="photo">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div
            css={css`
              width: 130px;
              height: 150px;
              border: 1px solid black;
              margin-bottom: 8px;
              border-radius: 14px;
              overflow: hidden;
            `}
          >
            <img
              src={userProfile?.photo}
              alt={userProfile.name_en}
              css={css`
                width: 100%;
                height: 100%;
                object-fit: cover;
              `}
            />
          </div>
        </div>
      </Item>
      {/* End of 1st Row */}

      {/* 2nd row */}
      <Item className="left" label="नागरिकता नम्बर" value={userProfile.id} />
      <Item
        className="center"
        label="पति/पली को नाम"
        value={userProfile.spouse_np}
      />
      {/* End of 2nd Row */}

      {/* 3rd row */}
      <Item className="left" label="लिङ्ग" value={userProfile.gender} />
      <Item
        className="center"
        label="रक्त समूह"
        value={userProfile.bloodGroup}
      />
      {/* End of 3rd Row */}

      {/* 4th row */}
      <Item
        className="left"
        label="उमेर"
        value={`${nepaliNumberConverter(
          getAge(userProfile.dateOfBirth_en)
        )} वर्ष`}
      />
      <Item
        className="center"
        label="सम्पर्क गर्नुपर्ने व्यक्तिको नाम"
        value={userProfile.contactPerson.name_np}
      />
      {/* End of 4th Row */}

      {/* 5th row */}
      <Item
        className="left"
        label="सम्पर्क गर्नुपर्ने व्यक्तिको सम्पर्क नं"
        value={nepaliNumberConverter(userProfile.contactPerson.phone)}
      />
      <Item className="center" label="रोगको नाम" value={userProfile.disease} />
      {hasFingerpintFeat && (
        <Item className="finger-print">
          {fingerPrints ? (
            <div className="d-flex justify-content-around align-items-center h-100">
              {fingerPrints?.map((finger) => {
                return (
                  <div className="text-center" key={finger.fingerNo}>
                    <div
                      css={css`
                        width: 130px;
                        height: 150px;
                        border: 1px solid black;
                        margin-bottom: 8px;
                        border-radius: 14px;
                        overflow: hidden;
                        text-align: center;
                      `}
                    >
                      {finger.image ? (
                        <img
                          src={finger.image}
                          alt="finger"
                          css={css`
                            width: 120px;
                            height: 120px;
                            object-fit: contain;
                          `}
                        />
                      ) : (
                        <p className="mt-4">Not found</p>
                      )}
                    </div>
                    <p>
                      {console.log(
                        FINGER_PRINT_DATA[finger.fingerNo],
                        "finger"
                      )}
                      {FINGER_PRINT_DATA[finger.fingerNo]}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <h5 className="font-weight-bold">औंलाको छाप उपलब्ध छैन।</h5>
            </div>
          )}
        </Item>
      )}

      {/* End of 5th Row */}

      {/* 6th row */}
      <Item
        className="left"
        label="सेवन गरिरहेको औषधिको नाम"
        value={userProfile.medication}
      />
      <Item
        className="center"
        label="कार्ड जारी गरिएको मिति"
        value={`${createdAt?.ne?.year}-${createdAt?.ne?.month}-${createdAt?.ne?.day}`}
      />
      {/* End of 6th Row */}

      {/* 7th row */}
      <Item
        className="left-center"
        label="उपलब्ध छुट तथा सुविधाहरु"
        value="सार्वजनिक सवारी भाडा र स्वास्थ्य सेवामा ५०% छुट"
      />
      {/* End of 7th Row */}

      {/* 8th row */}
      <Item
        className="left-center"
        label="हेरचाह गर्ने संस्थाको विवरण"
        value={userProfile.careHomeDetail_np}
      />
      {/* End of 8th Row */}

      {/* 9th row */}
      <Item
        className="left"
        label="संरक्षकको नाम"
        value={userProfile.guardian?.name_np}
      />
      <Item
        className="center"
        label="संरक्षकको ठेगाना"
        value={userProfile.guardian?.address}
      />
      {/* End of 9th Row */}

      {/* 9th row */}
      <Item
        className="left"
        label="सम्पर्क व्यक्तिको नाम"
        value={userProfile.contactPerson?.name_np}
      />
      <Item
        className="center"
        label="सम्पर्क व्यक्तिको ठेगाना"
        value={userProfile.contactPerson?.address}
      />
      <Item
        className="right"
        label="सम्पर्क व्यक्तिको सम्पर्क न."
        value={userProfile.contactPerson?.phone}
      />
      {/* End of 9th Row */}
    </PreviewDetailContainer>
  );
}
