import React from "react";
import { useLocation } from "react-router";
import { checkPermission } from "../../_helpers/roles/check-roles";
import SubMenu from "./SubMenu";

function SidebarButtonRenderer({ data, user }) {
  const location = useLocation();

  return data.map((item) => {
    if (!item.rule || checkPermission(user?.role_en, item.rule)) {
      return (
        <SubMenu item={item} key={item.id} currentPath={location.pathname} />
      );
    }
    return null;
  });
}

export default SidebarButtonRenderer;
