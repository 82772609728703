/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { FaEdit, FaPlusCircle, FaTrash } from "react-icons/fa";
import AddWardUser from "./AddWardUser";
import {
  fetchWardById,
  fetchWardUsers,
  removeWardUser,
} from "../../../_redux/slices/ward";
import BackButton from "../../../_elements/BackButton";
import { BsCheckCircle } from "react-icons/bs";
import { VscLoading } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { languageSelector } from "../../../_redux/slices/languages";
import { RiUserReceived2Fill } from "react-icons/ri";
import './styles/WardUser.scss';

const WardUser = ({ match }) => {
  const dispatch = useDispatch();

  const wardUsers = useSelector((state) => state.wards.wardUsers);
  const [showModal, setShowModal] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { language } = useSelector(languageSelector);
  const [ward, setWard] = useState({});

  const asyncFetchWardDetail = useCallback(async () => {
    setIsLoading(true);
    dispatch(fetchWardUsers(match.params.id));
    const response = await fetchWardById(match.params.id);
    setWard(response);
    setIsLoading(false);
  }, [dispatch, match.params.id]);

  useEffect(() => {
    asyncFetchWardDetail();
  }, [asyncFetchWardDetail]);

  function editUser(item) {
    let tempItem = {
      ...item,
      eventType: "edit",
    };
    setUserDetail(tempItem);
    setShowAdd(!showAdd);
  }

  return (
    <Fragment>
      <BackButton />
      {showAdd && (
        <Row className="mt-2">
          <Col md={12}>
            <AddWardUser
              showAdd={showAdd}
              setShowAdd={setShowAdd}
              userDetail={userDetail}
              setUserDetail={setUserDetail}
              id={match.params.id}
              asyncFetchWardDetail={asyncFetchWardDetail}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-2">
        <Col md={12}>
          <Card className="px-1">
            <Card.Header className="card-header-custom">
              <h2>वडा {ward.ward_np || ward.ward_en}</h2>
              <Button variant="primary" onClick={() => setShowAdd(!showAdd)}>
                <FaPlusCircle />
                &nbsp; नयाँ प्रयोगकर्ता
              </Button>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>नाम</th>
                      <th>ईमेल</th>
                      <th>फोन</th>
                      <th>पद</th>
                      <th className="text-center">कार्यहरू</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td />
                        <td />
                        <td
                          colSpan="5"
                          css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                          `}
                        >
                          <VscLoading className="spin" fontSize={48} />
                        </td>
                        <td />
                        <td />
                      </tr>
                    ) : (
                      wardUsers.map((wardUser) => (
                        <tr
                          key={wardUser.id}
                          className={`${wardUser.is_active ? "kawa_active" : ""
                            }`}
                        >
                          <td>
                            {wardUser.is_active ? (
                              <span className="kawa_indicator">
                                <RiUserReceived2Fill color="#6171e4" />
                                {language === "english"
                                  ? "Act. Chairman"
                                  : "का.वा"}
                              </span>
                            ) : null}
                            {wardUser.fullname_np || wardUser.fullname_en}
                          </td>
                          <td>{wardUser.email}</td>
                          <td>{wardUser.phone_np || wardUser.phone_en}</td>
                          <td>
                            {wardUser.designation_np || wardUser.designation_en}
                          </td>
                          <td className="text-center">
                            <Button
                              variant="info"
                              className="mr-1"
                              onClick={() => editUser(wardUser)}
                            >
                              <FaEdit />
                            </Button>
                            <Button
                              variant="danger"
                              className="mr-1"
                              onClick={() =>
                                setShowModal({
                                  type: "delete",
                                  body: {
                                    profile_id: wardUser.id,
                                  },
                                })
                              }
                            >
                              <FaTrash />
                            </Button>

                            {wardUser.is_active ? (
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  setShowModal({
                                    type: "kawa",
                                    body: {
                                      profile_id: wardUser.id,
                                      status: "inactive",
                                    },
                                  });
                                }}
                              >
                                <BsCheckCircle className="mr-1" />
                                {language === "english"
                                  ? "Deactivate Act. Chairman"
                                  : "का.वा निष्क्रिय पार्नुहोस्"}
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={Boolean(showModal)}
        onHide={() => setShowModal(null)}
        centered
      >
        <Modal.Header>
          <h3>{showModal?.type === "kawa" ? "का.वा अपडेट" : "User delete"}</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="h5 font-weight-lighter">
            {showModal?.type === "kawa"
              ? "के तपाइँ यो का.वा अपडेट गर्न चाहानुहुन्छ?"
              : "के तपाइँ यो user हटाउन चाहानुहुन्छ?"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              setShowModal(null);
            }}
          >
            रद्द गर्नुहोस्
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() => {
              console.log(showModal, "showModal");
              if (showModal?.type === "delete") {
                dispatch(removeWardUser(showModal.body.profile_id));
                setShowModal(null);
              } else if (showModal?.type === "kawa") {
                setShowModal(null);
              }
            }}
          >
            सुनिश्चित गर्नुहोस्
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default WardUser;
