import React, { useEffect, useState } from 'react'
import { useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { FILE_SIZES } from '../../../../constant';
import { fetchWrapper } from '../../../../_helpers/fetchWrapper'

function FileUpload({
    register,
    elementId, value,
    setValue, validations, isDisabled, subType, properties,clearErrors
}) {
    const [image, setImage] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [showModal, setShowModal] = useState(null);
    const imageRef = useRef(null);

    useEffect(() => {
        setImage(value);
    }, [value]);

    const handleFileLoad = async (event) => {
        let tempFile = event.target.files[0];
        if (tempFile) {
            if (tempFile.size > FILE_SIZES.MAX_IMAGE_FILE_SIZE) return toast.error('Image Must be less than 5 mb in size!');
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', tempFile);
            try {
                const response = await fetchWrapper.post('/file', formData, true);
                const result = response.data;
                setValue(elementId, result);
                setImage(result);
                setIsLoading(false);
                clearErrors(elementId);
            }
            catch (error) {
                setIsLoading(false);
            }
        }
    }

    const handleShowImageModal = () => {
        setShowModal(true);
    }
    const handleCloseImageModal = () => {
        setShowModal(false);
    }

    const handleRemoveImage = () => {
        setImage(null);
        imageRef.current.value = null;
        setShowModal(false);
        setValue(elementId, null);
    }

    return (
        <div>
            <div>
                <input type="file" onChange={handleFileLoad} disabled={isLoading || isDisabled} ref={imageRef} accept={subType} />
                <input hidden readOnly {...register(elementId, validations)} />
            </div>

            {
                image &&
                <div className="text-info cursor-pointer" onClick={handleShowImageModal}>
                    <FaEye className='mr-2' /> Image selected
                </div>
            }


            <Modal show={showModal} onHide={handleCloseImageModal} centered>
                <Modal.Header>
                    <Modal.Title>File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <img src={image} alt="fileimage" className='w-100 h-100' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleRemoveImage}>
                        Clear
                    </Button>
                    <Button variant="secondary" onClick={handleCloseImageModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FileUpload