import React, { useState } from 'react'
import InputField from '../../../InputField';
import uuid from 'react-uuid';
import { Button } from 'react-bootstrap';
import { errorMessageChecker } from '../../../../_helpers/methods';
import { useEffect } from 'react';

function List({ defaultValue, register, elementId, setValue, language, className, properties, errors }) {
    const [listItemIds, setListItemIds] = useState([uuid()]);

    useEffect(() => {
        if (defaultValue) {
            setListItemIds(defaultValue);
            setTimeout(() => {
                defaultValue.forEach(value => {
                    setValue(`${elementId}[${value}]`, value)
                })
            });
        }
    }, [defaultValue, elementId, setValue])

    const handleAddBtnClick = () => {
        setListItemIds(prev => [...prev, uuid()]);
    }

    const handleRemoveBtnClick = (id) => {
        setValue(`${elementId}[${id}]`, null);
        let tempListItemIds = [...listItemIds];
        tempListItemIds = tempListItemIds.filter(listId => listId !== id);
        setListItemIds(tempListItemIds);
    }
    return (
        <div>
            {
                listItemIds.map((id, index) => {
                    return (<div
                        className='d-flex mb-2'
                        key={id}
                    >
                        <InputField
                            register={register}
                            className={`${className} flex-grow form-control input ${properties?.isRequired ? "textbox_req" : ""
                                } ${errorMessageChecker(errors, elementId) ? "is-invalid" : ""} `}
                            elementId={`${elementId}[${id}]`}
                            setValue={setValue}
                            language={language}
                        />
                        <div className='d-flex mx-3'>
                            <Button className="mr-2" onClick={handleAddBtnClick}>+</Button>
                            {
                                index !== 0 &&
                                <Button variant='outline-danger' onClick={() => handleRemoveBtnClick(id)}>-</Button>
                            }
                        </div>
                    </div>
                    )
                })
            }
        </div>
    )
}

export default List