export const data = [
  {
    key: "FormGroup",
    id: "1",
    row: [
      [
        {
          key: "Text",
          language: "nepali",
          id: "nagriktaNum",
          label: "नागरिकता नं.",
          properties: {
            isRequired: true,
          },
        },
        {
          key: "District",
          id: "district_en",
          label: "जिल्ला",
          properties: {},
        },
      ],
    ],
  },
];
