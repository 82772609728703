export const BloodGroup = {
  "": "रक्त समूह छान्नुहोस्",
  "O+": "O+",
  "O-": "O-",
  "A+": "A+",
  "A-": "A+",
  "B+": "B+",
  "B-": "B+",
  "AB+": "AB+",
  "AB-": "AB-",
};

export const FamilyRelationship = {
  "": "नाता छान्नुहोस्",
  बुवा: "बुवा",
  आमा: "आमा",
  श्रीमान: "श्रीमान",
  श्रीमती: "श्रीमती",
  स्वयम: "स्वयम",
  छाेरा: "छाेरा",
  छोरी: "छोरी",
  दाई: "दाई",
  दिदि: "दिदि",
  भाई: "भाई",
  बहिनी: "बहिनी",
  बुहारी: "बुहारी",
  ज्वाई: "ज्वाई",
  देउरानी: "देउरानी",
  ससुरा: "ससुरा",
  नाति: "नाति",
  भतिजा: "भतिजा",
  सासु: "सासु",
  नातिनी: "नातिनी",
  अमजु: "अमजु",
  भाउजु: "भाउजु",
  फुपू: "फुपू",
  काका: "काका",
  जेठान: "जेठान",
  जेठानी: "जेठानी",
  "भाइ बुहारी": "भाइ बुहारी",
  "कान्छी आमा": "कान्छी आमा",
  "हजुर आमा": "हजुर आमा",
  "हजुर बुवा": "हजुर बुवा",
  "ठुली आमा": "ठुली आमा",
  "छाेरी ज्वाई": "छाेरी ज्वाई",
};

export const DisabilityType = {
  "": " छान्नुहोस्",
  "पूर्ण अशक्त अपाङ्गता": "पूर्ण अशक्त अपाङ्गता",
  "अति अशक्त अपाङ्गता": "अति अशक्त अपाङ्गता",
  "मध्यम अपाङ्गता ": "मध्यम अपाङ्गता",
  "सामान्य अपाङ्गता": "सामान्य अपाङ्गता",
};

export const DisabilityTypesObject = {
  "पूर्ण अशक्त अपाङ्गता": {
    title_np: "पूर्ण अशक्त अपाङ्गता",
    title_en: "Profound disability",
    code_np: "क",
    code_en: "Ka",
    color: "#ff0000",
  },
  "अति अशक्त अपाङ्गता": {
    title_np: "अति अशक्त अपाङ्गता",
    title_en: "Severe disability",
    code_np: "ख",
    code_en: "Kha",
    color: "#5760AB",
  },
  "मध्यम अपाङ्गता": {
    title_np: "मध्यम अपाङ्ग",
    title_en: "Moderate disability",
    code_np: "ग",
    code_en: "Ga",
    color: "#ffff00",
  },
  "सामान्य अपाङ्गता": {
    title_np: "सामान्य अपाङ्ग",
    title_en: "Mild disability",
    code_np: "घ",
    code_en: "Gha",
    color: "#ffffff",
  },
};

export const OrganDisablityType = {
  "": " छान्नुहोस्",
  "शारीरिक अपाङ्गता": "शारीरिक अपाङ्गता",
  "सुनाई सम्बन्धि अपाङ्गता": "सुनाई सम्बन्धि अपाङ्गता",
  "स्वर बोलाई सम्बन्धि अपाङ्गता": "स्वर बोलाई सम्बन्धि अपाङ्गता",
  "मानसिक वा मनोसामाजिक अपाङ्गता": "मानसिक वा मनोसामाजिक अपाङ्गता",
  "बौद्दिक अपाङ्गता": "बौद्दिक अपाङ्गता",
  "अतिज्म सम्बन्धि अपाङ्गता": "अतिज्म सम्बन्धि अपाङ्गता",
  "बहु अपाङ्गता": "बहु अपाङ्गता",
  हेमोफिलिया: "हेमोफिलिया",
  "श्रवन दृष्टिबिहीन अपाङ्गता": "श्रवन दृष्टिबिहीन अपाङ्गता",
  "दृष्टि सम्बन्धि अपाङ्गता": "दृष्टि सम्बन्धि अपाङ्गता",
};

export const ReasonOfDisability = {
  "": " छान्नुहोस्",
  जन्मजात: "जन्मजात",
  "रोगको दिर्घ असर": "रोगको दिर्घ असर",
  दुर्घटना: "दुर्घटना",
  अन्य: "अन्य",
  बंशानुगत: "बंशानुगत",
  "ससत्र द्वन्द": "ससत्र द्वन्द",
};

export const IssuedFrom = {
  "": " छान्नुहोस्",
  पालिका: "पालिका",
  जिल्ला: "जिल्ला",
  अन्य: "अन्य",
};

export const EducationQualification = {
  "": " छान्नुहोस्",
  "bachelor level": "bachelor level",
  "bachelor equivalent": "bachelor equivalent",
  degree: "degree",
  "secondary level": "secondary level",
  "primary level": "primary level",
  "pre primary": "pre primary",
  "read & write": "read & write",
  illiterate: "illiterate",
};

export const EthnicityData = {
  "": " छान्नुहोस्",
  "मधेसी / ब्राह्मण / छेत्री / राजपुत": "मधेसी / ब्राह्मण / छेत्री / राजपुत",
  "मधेशी / दलित": "मधेशी / दलित",
  "पहाडी / जनजाती / आदिवाशी": "पहाडी / जनजाती / आदिवाशी",
  "तराई / जनजाती / आदिवाशी": "तराई / जनजाती / आदिवाशी",
  "पहाडी / अन्य": "पहाडी / अन्य",
  "पहाडी / दलित": "पहाडी / दलित",
  "पहाडी / ब्राह्मण / छेत्री / ठकुरी / सन्यासी":
    "पहाडी / ब्राह्मण / छेत्री / ठकुरी / सन्यासी",
  "मधेशी / अन्य": "मधेशी / अन्य",
};

export const GenderData = {
  "": "लिङ्ग छान्नुहोस्",
  पुरुष: "पुरुष",
  महिला: "महिला",
  अन्य: "अन्य",
};
