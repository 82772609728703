import { toast } from "react-toastify";
import { fetchWrapper } from "../../_helpers/fetchWrapper";

const getSakhas = async (id) => {
    const response = await fetchWrapper.post(`/sakha/${id}`);
    return response.results;
}

const createSakhaUser = async (data) => {
    const response = await fetchWrapper.post(`/users`, data);
    return response.data;
}

const updateSakhaUser = async (data, id) => {
    const response = await fetchWrapper.patch(`/users/${id}`, data);
    return response.data;
}

export const SakhaServices = {
    getSakhas,
    createSakhaUser,
    updateSakhaUser
}