import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Alert, Button, Col, Form, FormControl, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VscLoading } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { DisabledServices } from "../../api/services/DisabledServices";
import { ADToBS } from "../../components/Form/Elements/DatePicker/AdToBsConverter/AdBsConverter";
import FormInput from "../../components/Form/Elements/_new/FormInput";
import { StyledFormRow, StyledLabel } from "../../styles/StyledComponents";
import {
  BloodGroup,
  DisabilityType,
  EducationQualification,
  EthnicityData,
  FamilyRelationship,
  IssuedFrom,
  OrganDisablityType,
  ReasonOfDisability,
} from "../../_datas/bloodGroup";
import Camera from "../../_elements/Camera";
import { fetchWrapper } from "../../_helpers/fetchWrapper";
import { fetchWards } from "../../_redux/slices/ward";

export default function DisabledForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const photoRef = useRef();
  const wards = useSelector((state) => state.wards.wards);

  useEffect(() => {
    dispatch(fetchWards());
  }, [dispatch]);
  const [ward, setWard] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [palika, setPalika] = useState("");
  const [temporaryProvince, setTemporaryProvince] = useState("");
  const [temporaryDistrict, setTemporaryDistrict] = useState("");
  const [temporaryPalika, setTemporaryPalika] = useState("");
  const [apiError, setApiError] = useState(null);
  const [applicantData, setApplicantData] = useState({});

  const [defaultValues, setDefaultValues] = useState({});

  const [formDateStates, setFormDateStates] = useState({
    birthAD: null,
    birthBS: null,
  });

  const handleSelectProvince = (provinceVal) => {
    setProvince(provinceVal);
  };

  const handleSelectDistrict = (districtVal) => {
    setDistrict(districtVal);
  };

  const handleSelectPalika = (palikaVal) => {
    setPalika(palikaVal);
  };

  const handleSelectTemporaryProvince = (TemporaryProvinceVal) => {
    setTemporaryProvince(TemporaryProvinceVal);
  };

  const handleSelectTemporaryDistrict = (temporaryDistrictVal) => {
    setTemporaryDistrict(temporaryDistrictVal);
  };

  const handleSelectTemporaryPalika = (temporaryPalikaVal) => {
    setTemporaryPalika(temporaryPalikaVal);
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    try {
      setIsLoading(true);
      formData.append("file", file);
      const response = await fetchWrapper.post("/file", formData, true);
      const result = response.data;
      setValue("photo", result);
      setIsLoading(false);
      setDefaultValues((prev) => ({ ...prev, photo: result }));
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // palikas handling
  useEffect(() => {
    setDistrict("");
  }, [province]);

  useEffect(() => {
    setPalika("");
  }, [district]);

  // temporaryPalikas handling
  useEffect(() => {
    setTemporaryDistrict("");
  }, [temporaryProvince]);

  useEffect(() => {
    setTemporaryPalika("");
  }, [temporaryDistrict]);

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    getValues,
    watch,
    formState: { errors },
  } = useForm({});

  const onSubmit = async (data) => {
    try {
      if (!data.photo) {
        photoRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        return setApiError("फोटो अनिवार्य छ!");
      }
      setIsLoading(true);
      let body = {
        ...data,
        ward: ward,
        fingerprint: {
          type: data.fingerprintType || "none",
        },
        cardDetails: {
          ...data.cardDetails,
          issued: hasReceivedDisabilityIdentityCard === "true" ? true : false,
        },
        identityDetails: {
          ...data.identityDetails,
        },
        helpMaterial: {
          required: hasHelpMaterial === "true" ? true : false,
          details:
            hasHelpMaterial === "true" ? data?.helpMaterial?.details : null,
        },
        canPerformDaily: data.canPerformDaily === "true" ? true : false,
        useHelpDevice: data.useHelpDevice === "true" ? true : false,
      };
      if (data.needHelpWith) {
        let needHelpWithTasks = Object.values(data.needHelpWith).filter(
          (val) => val
        );
        let doesnotNeedHelpWithTasks = Object.values(
          data.doesnotNeedHelpWith
        ).filter((val) => val);

        if (needHelpWithTasks.length > 0) {
          body.needHelpWith = needHelpWithTasks;
        } else {
          delete body.needHelpWith;
        }
        if (doesnotNeedHelpWithTasks.length > 0) {
          body.doesnotNeedHelpWith = doesnotNeedHelpWithTasks;
        } else {
          delete body.doesnotNeedHelpWith;
        }
      }

      if (
        !isPermanentTemporaryAddressSame ||
        isPermanentTemporaryAddressSame === "true"
      ) {
        body.temporaryAddress = {
          province: body.parmanentAddress.province,
          district: body.parmanentAddress.district,
          local: body.parmanentAddress.local,
          ward: body.parmanentAddress.ward,
          street: body.parmanentAddress.street,
          tole: body.parmanentAddress.tole,
          village_en: body.parmanentAddress.village_en,
          village_np: body.parmanentAddress.village_np,
        };
      }
      if (!(hasHelpMaterial === "true")) {
        delete body.helpMaterial;
      }
      if (
        hasReceivedDisabilityIdentityCard ||
        hasReceivedDisabilityIdentityCard === "false"
      ) {
        delete body.cardDetails.issuedFrom;
        delete body.cardDetails.cardNo;
        delete body.cardDetails.cardRecievedDate_bs;
        delete body.cardDetails.cardRecievedDate_ad;
      }

      if (!body.identityDetails.birthCertificateCopy) {
        delete body.identityDetails.birthCertificateCopy;
      }
      if (!body.identityDetails.citizenshipCopy) {
        delete body.identityDetails.citizenshipCopy;
      }

      delete body.applicant.name_np;
      delete body.fingerprintType;
      delete body.isPermanentTemporaryAddressSame;
      delete body.hasReceivedDisabilityIdentityCard;
      delete body.hasHelpMaterial;
      delete body.isApplicantGurdian;

      if (body.ethinicity === "null") {
        setApiError("Please select Ethnicity");
        setIsLoading(false);
        return;
      }
      await DisabledServices.createDisabledProfile(body, { isPublic: true });
      toast.success("Succesfully Applied!");
      history.goBack();
      setApiError(null);
      setIsLoading(false);
    } catch (error) {
      setApiError(error?.message);
      setIsLoading(false);
    }
  };

  const isPermanentTemporaryAddressSame = watch(
    "isPermanentTemporaryAddressSame"
  );

  const hasReceivedDisabilityIdentityCard = watch(
    "hasReceivedDisabilityIdentityCard"
  );

  const hasHelpMaterial = watch("hasHelpMaterial");

  const isApplicantGurdian = watch("isApplicantGurdian");

  const fingerprintType = watch("fingerprintType");

  const isMotherGurdian = watch("cardDetails.isMotherGurdian");
  const isFatherGurdian = watch("cardDetails.isFatherGurdian");

  useEffect(() => {
    let guardianName_np = getValues("guardian.name_np");
    let guardianName_en = getValues("guardian.name_en");
    let guardianRelation = getValues("guardian.relation");

    if (guardianRelation === "आमा" && isMotherGurdian) {
      setValue("cardDetails.motherName_np", guardianName_np);
      setValue("cardDetails.motherName_en", guardianName_en);
    }
  }, [getValues, isMotherGurdian, setValue]);

  useEffect(() => {
    let guardianName_np = getValues("guardian.name_np");
    let guardianName_en = getValues("guardian.name_en");
    let guardianRelation = getValues("guardian.relation");

    if (guardianRelation === "बुवा" && isFatherGurdian) {
      setValue("cardDetails.fatherName_np", guardianName_np);
      setValue("cardDetails.fatherName_en", guardianName_en);
    }
  }, [getValues, isFatherGurdian, setValue]);

  useEffect(() => {
    let guardianName_np = getValues("guardian.name_np");
    let guardianPhone = getValues("guardian.phone");
    let guardianRelation = getValues("guardian.relation");

    if (isApplicantGurdian) {
      setValue("applicant.fullname_np", guardianName_np);
      setValue("applicant.contact", guardianPhone);
      setValue("applicant.relation", guardianRelation);
      setApplicantData({
        relation: guardianRelation,
        fullname_np: guardianName_np,
        contact: guardianPhone,
      });
    }
  }, [getValues, isApplicantGurdian, setValue]);

  const handleSetDateAD = (id, value, valueAd) => {
    if (valueAd) {
      setFormDateStates((prev) => ({
        ...prev,
        [id]: valueAd.replaceAll("/", "-"),
      }));
    }
  };

  const handleSetDateBS = (id, value) => {
    const date = ADToBS(value.split("-").join("/"));

    setFormDateStates((prev) => ({
      ...prev,
      [id]: date,
    }));
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className="d-flex flex-column">
          <label htmlFor="from" className="w-100 mr-2 mb-0">
            वडा कार्यालय:{" "}
          </label>
          <FormControl
            as={"select"}
            onChange={(e) => setWard(e.target.value)}
            defaultValue={defaultValues.ward}
          >
            <option value="1">वडा कार्यालय छान्नुहोस्</option>
            {wards.map((w) => (
              <option value={w.id} key={w.id}>
                {w.ward_np || w.ward_en} न. वडा{" "}
              </option>
            ))}
          </FormControl>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-center">अपाङ्गता भएको व्यक्तिको विवरण</h2>

        <fieldset className="mt-3 p-3 border border-2 rounded" ref={photoRef}>
          <FormInput
            label="# फोटो"
            id="photo"
            register={register}
            setValue={setValue}
            isDisabled={isLoading}
            clearErrors={clearErrors}
            type="File"
            errors={errors}
            defaultValue={defaultValues.photo}
          />
          <Camera
            handleFileUpload={handleFileUpload}
            defaultValue={defaultValues.photo}
          />
        </fieldset>
        {/* <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel># छाप</StyledLabel>
          <div className="d-flex">
            <FormInput
              id="fingerprintType"
              register={register}
              setValue={setValue}
              isDisabled={isLoading}
              clearErrors={clearErrors}
              type="Radio"
              errors={errors}
              options={[
                {
                  id: "fingerprintType-हातको औंला",
                  label: "हातको औंला",
                  value: "hand",
                },
                {
                  id: "fingerprintType-खुट्टाको औंला",
                  label: "खुट्टाको औंला",
                  value: "leg",
                },
                {
                  id: "fingerprintType-दुवै नभएको",
                  label: "दुवै नभएको",
                  value: "none",
                },
              ]}
              defaultValue={defaultValues.fingerprint?.type}
            />
          </div>
          {fingerprintType !== "none" && (
            <div className="d-flex mt-2">
              <Form.Group>
                <Form.Label htmlFor="left">
                  <Button>बायाँ</Button>
                </Form.Label>
                <Form.Control type="file" id="left" hidden />
              </Form.Group>
              <Form.Group className="ml-3">
                <Form.Label htmlFor="left">
                  <Button>दायाँ</Button>
                </Form.Label>
                <Form.Control type="file" id="left" hidden />
              </Form.Group>
            </div>
          )}
        </fieldset> */}
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>१. नाम</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="पुरा नाम नेपालीमा"
                id="name_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.name_np}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Full name in English"
                id="name_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.name_en}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="लिङ्ग"
                id="gender"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Select"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.gender}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="जातीयता"
                id="ethinicity"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Select"
                errors={errors}
                options={EthnicityData}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.ethinicity}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="जन्म मिति (बि.स.)"
                id="dateOfBirth_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Date"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={formDateStates.birthBS}
                onChange={(value, valueAd) =>
                  handleSetDateAD("birthAD", value, valueAd)
                }
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Date of birth (A.D.)"
                id="dateOfBirth_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Date"
                ADBSmode="AD"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={formDateStates.birthAD}
                onChange={(value) => handleSetDateBS("birthBS", value)}
              />
            </Col>
          </StyledFormRow>
        </fieldset>

        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>२. स्थायी ठेगाना</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4} className="mb-4">
              <FormInput
                label="प्रदेश"
                id="parmanentAddress.province"
                register={register}
                type="Province"
                defaultValue={province}
                onChange={handleSelectProvince}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="जिल्ला"
                id="parmanentAddress.district"
                register={register}
                type="District"
                defaultValue={district}
                onChange={handleSelectDistrict}
                isDisabled={!province || isLoading}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                properties={{
                  province: province,
                }}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="पालिका"
                id="parmanentAddress.local"
                register={register}
                type="Palika"
                defaultValue={palika}
                onChange={handleSelectPalika}
                isDisabled={!district || isLoading}
                setValue={setValue}
                clearErrors={clearErrors}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                properties={{
                  province: province,
                  district: district,
                }}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="वडा न."
                id="parmanentAddress.ward"
                register={register}
                type="Number"
                setValue={setValue}
                isDisabled={isLoading}
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.parmanentAddress?.ward}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="टोल"
                id="parmanentAddress.tole"
                register={register}
                type="Text"
                errors={errors}
                setValue={setValue}
                isDisabled={isLoading}
                defaultValue={defaultValues.parmanentAddress?.tole}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Street"
                id="parmanentAddress.street"
                register={register}
                type="Text"
                language={"english"}
                setValue={setValue}
                isDisabled={isLoading}
                errors={errors}
                defaultValue={defaultValues.parmanentAddress?.street}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="गाउँ"
                id="parmanentAddress.village_np"
                register={register}
                type="Text"
                setValue={setValue}
                isDisabled={isLoading}
                errors={errors}
                defaultValue={defaultValues.parmanentAddress?.village_np}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Village"
                id="parmanentAddress.village_en"
                register={register}
                language="english"
                type="Text"
                setValue={setValue}
                isDisabled={isLoading}
                errors={errors}
                defaultValue={defaultValues.parmanentAddress?.village_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="फोन न."
                id="parmanentAddress.phone"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                language="english"
                type="Number"
                errors={errors}
                maxLength="10"
                // validations={{
                // 	isRequired: true
                // }}
                defaultValue={defaultValues.parmanentAddress?.phone}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>३. अस्थायी ठेगाना</StyledLabel>
          <StyledFormRow className="mb-2">
            <Col sm={12}>
              <Form.Label className="w-100">
                स्थायी ठेगाना र अस्थायी ठेगाना एउटै हो ?
              </Form.Label>

              <FormInput
                id="isPermanentTemporaryAddressSame"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Radio"
                errors={errors}
                options={[
                  {
                    id: "isPermanentTemporaryAddressSame-हो",
                    label: "हो",
                    value: "true",
                  },
                  {
                    id: "isPermanentTemporaryAddressSame-हैन",
                    label: "हैन",
                    value: "false",
                  },
                ]}
              />
            </Col>
          </StyledFormRow>
          {isPermanentTemporaryAddressSame === "false" && (
            <StyledFormRow>
              <Col md={6} lg={4} className="mb-4">
                <FormInput
                  label="प्रदेश"
                  id="temporaryAddress.province"
                  register={register}
                  type="Province"
                  defaultValue={temporaryProvince}
                  onChange={handleSelectTemporaryProvince}
                  errors={errors}
                  setValue={setValue}
                  isDisabled={isLoading}
                  clearErrors={clearErrors}
                  validations={{
                    isRequired: true,
                  }}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="जिल्ला"
                  id="temporaryAddress.district"
                  register={register}
                  type="District"
                  defaultValue={temporaryDistrict}
                  onChange={handleSelectTemporaryDistrict}
                  setValue={setValue}
                  clearErrors={clearErrors}
                  isDisabled={!temporaryProvince || isLoading}
                  errors={errors}
                  validations={{
                    isRequired: true,
                  }}
                  properties={{
                    province: temporaryProvince,
                  }}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="पालिका"
                  id="temporaryAddress.local"
                  register={register}
                  type="Palika"
                  setValue={setValue}
                  clearErrors={clearErrors}
                  defaultValue={temporaryPalika}
                  onChange={handleSelectTemporaryPalika}
                  isDisabled={!temporaryDistrict || isLoading}
                  errors={errors}
                  validations={{
                    isRequired: true,
                  }}
                  properties={{
                    province: temporaryProvince,
                    district: temporaryDistrict,
                  }}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="वडा न."
                  id="temporaryAddress.ward"
                  register={register}
                  type="Number"
                  setValue={setValue}
                  isDisabled={isLoading}
                  errors={errors}
                  validations={{
                    isRequired: true,
                  }}
                  defaultValue={defaultValues.temporaryAddress?.ward}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="टोल"
                  id="temporaryAddress.tole"
                  register={register}
                  type="Text"
                  errors={errors}
                  setValue={setValue}
                  isDisabled={isLoading}
                  defaultValue={defaultValues.temporaryAddress?.tole}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="Street"
                  id="temporaryAddress.street"
                  register={register}
                  type="Text"
                  language={"english"}
                  setValue={setValue}
                  isDisabled={isLoading}
                  errors={errors}
                  defaultValue={defaultValues.temporaryAddress?.street}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="गाउँ"
                  id="temporaryAddress.village_np"
                  register={register}
                  type="Text"
                  setValue={setValue}
                  isDisabled={isLoading}
                  errors={errors}
                  defaultValue={defaultValues.temporaryAddress?.village_np}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="Village"
                  id="temporaryAddress.village_en"
                  register={register}
                  language="english"
                  type="Text"
                  setValue={setValue}
                  isDisabled={isLoading}
                  errors={errors}
                  defaultValue={defaultValues.temporaryAddress?.village_en}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="Temporary Address"
                  id="parmanentAddress.address"
                  register={register}
                  language="english"
                  type="Text"
                  errors={errors}
                  setValue={setValue}
                  isDisabled={isLoading}
                  defaultValue={defaultValues.temporaryAddress?.address}
                />
              </Col>
              <Col md={6} lg={4}>
                <FormInput
                  label="फोन न."
                  id="temporaryAddress.phone"
                  register={register}
                  setValue={setValue}
                  isDisabled={isLoading}
                  language="english"
                  type="Number"
                  errors={errors}
                  maxLength="10"
                  defaultValue={defaultValues.temporaryAddress?.phone}
                />
              </Col>
            </StyledFormRow>
          )}
        </fieldset>

        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>४. परिवारको सदस्य वा संरक्षकको विवरण</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="संरक्षकको नाम"
                id="guardian.name_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.guardian?.name_np}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Guardian Name"
                id="guardian.name_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.guardian?.name_en}
              />
            </Col>
            <Col md={6} lg={4} className="mb-4">
              <FormInput
                label="नाता"
                id="guardian.relation"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Select"
                errors={errors}
                options={FamilyRelationship}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.guardian?.relation}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="टेलिफोन वा मोबाईल नं."
                id="guardian.phone"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                language="english"
                type="Number"
                errors={errors}
                maxLength="10"
                defaultValue={defaultValues.guardian?.phone}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>
            ५. असक्तताको गम्भीरताका आधारमा अपाङ्गताको वर्गीकरण
          </StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4} className="mb-4">
              <FormInput
                label="अपाङ्गताको प्रकार"
                id="disabilityType"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Select"
                errors={errors}
                options={DisabilityType}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.disabilityType}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="रक्त समूह"
                id="bloodGroup"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Select"
                errors={errors}
                options={BloodGroup}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.bloodGroup}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>
            ६. शारीरिक अङ्ग वा प्रणालीमा भएको समस्या तथा कठिनाइको आधारमा
          </StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="अपाङ्गताको प्रकार"
                id="organDisabilityType"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Select"
                errors={errors}
                options={OrganDisablityType}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.organDisabilityType}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>७. अपाङ्गताको कारण</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="अपाङ्गताको कारण"
                id="reasonOfDisability"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Select"
                errors={errors}
                options={ReasonOfDisability}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.reasonOfDisability}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>८. अपाङ्गता परिचय पत्र पाएको</StyledLabel>
          <StyledFormRow className="mb-2">
            <Col sm={12}>
              <FormInput
                label="अपाङ्गता परिचय पत्र"
                id="hasReceivedDisabilityIdentityCard"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Radio"
                errors={errors}
                options={[
                  {
                    id: "hasReceivedDisabilityIdentityCard-पाएको",
                    label: "पाएको",
                    value: "true",
                  },
                  {
                    id: "hasReceivedDisabilityIdentityCard-नपाएको",
                    label: "नपाएको",
                    value: "false",
                  },
                ]}
              />
            </Col>
          </StyledFormRow>
          <StyledFormRow>
            {hasReceivedDisabilityIdentityCard === "true" && (
              <>
                <Col md={6} lg={4}>
                  <FormInput
                    label="कहाँ बाट"
                    id="cardDetails.issuedFrom"
                    register={register}
                    setValue={setValue}
                    isDisabled={isLoading}
                    clearErrors={clearErrors}
                    type="Select"
                    errors={errors}
                    options={IssuedFrom}
                    validations={{
                      isRequired: true,
                    }}
                  />
                </Col>
                <Col md={6} lg={4}>
                  <FormInput
                    label="कार्ड नं"
                    id="cardDetails.cardNo"
                    register={register}
                    setValue={setValue}
                    isDisabled={isLoading}
                    type="Number"
                    language="english"
                    errors={errors}
                    validations={{
                      isRequired: true,
                    }}
                    defaultValue={defaultValues.cardDetails?.cardNo}
                  />
                </Col>
                <Col md={6} lg={4}>
                  <FormInput
                    label="परिचय पत्र पाएको मिति (बि.स.)"
                    id="cardDetails.cardRecievedDate_bs"
                    register={register}
                    setValue={setValue}
                    isDisabled={isLoading}
                    clearErrors={clearErrors}
                    type="Date"
                    errors={errors}
                    validations={{
                      isRequired: true,
                    }}
                    defaultValue={formDateStates.cardReceivedBS}
                    onChange={(value, valueAd) =>
                      handleSetDateAD("cardReceivedAD", value, valueAd)
                    }
                  />
                </Col>
                <Col md={6} lg={4}>
                  <FormInput
                    label="Card received date (A.D.)"
                    id="cardDetails.cardRecievedDate_ad"
                    register={register}
                    setValue={setValue}
                    isDisabled={isLoading}
                    clearErrors={clearErrors}
                    type="Date"
                    ADBSmode="AD"
                    errors={errors}
                    validations={{
                      isRequired: true,
                    }}
                    defaultValue={formDateStates.cardReceivedAD}
                    onChange={(value) =>
                      handleSetDateBS("cardReceivedBS", value)
                    }
                  />
                </Col>
              </>
            )}
          </StyledFormRow>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                id="cardDetails.isFatherGurdian"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Checkbox"
                errors={errors}
                properties={{
                  text: "संरक्षक र बुवा एउटै हुन् ",
                }}
                defaultValue={defaultValues.isFatherGurdian}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                id="cardDetails.isMotherGurdian"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Checkbox"
                errors={errors}
                properties={{
                  text: "संरक्षक र आमा एउटै हुन्",
                }}
                defaultValue={defaultValues.isMotherGurdian}
              />
            </Col>
          </StyledFormRow>

          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="बुवाको नाम"
                id="cardDetails.fatherName_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.cardDetails?.fatherName_np}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Father's name"
                id="cardDetails.fatherName_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.cardDetails?.fatherName_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="हजुरबुवाको नाम"
                id="cardDetails.grandFatherName_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.cardDetails?.grandFatherName_np}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Grand-father's name"
                id="cardDetails.grandFatherName_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.cardDetails?.grandFatherName_en}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="आमाको नाम"
                id="cardDetails.motherName_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.cardDetails?.motherName_np}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="Mother's name"
                id="cardDetails.motherName_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.cardDetails?.motherName_np}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>९. परिचय खुलाउने विवरण</StyledLabel>
          <StyledFormRow>
            <Col md={6} lg={4}>
              <FormInput
                label="जन्म दर्ता नं."
                id="identityDetails.birthCertificate"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.identityDetails?.birthCertificate}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="जन्म दर्ता पाएको स्थान"
                id="identityDetails.birthCertificateIssuedFrom"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={
                  defaultValues.identityDetails?.birthCertificateIssuedFrom
                }
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="जन्म दर्ता पाएको मिति (बि.स.)"
                id="identityDetails.birthCertificateIssuedDate_bs"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Date"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={formDateStates.birthIssuedBS}
                onChange={(value, valueAd) =>
                  handleSetDateAD("birthIssuedAD", value, valueAd)
                }
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Born registration date (A.D.)"
                id="identityDetails.birthCertificateIssuedDate_ad"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Date"
                ADBSmode="AD"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={formDateStates.birthIssuedAD}
                onChange={(value) => handleSetDateBS("birthIssuedBS", value)}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="नागरिकता नं."
                id="identityDetails.citizenshipNo"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                // validations={{
                //   isRequired: true,
                // }}
                defaultValue={defaultValues.identityDetails?.citizenshipNo}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="नागरिकता पाएको स्थान"
                id="identityDetails.citizenshipIssuedFrom"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                // validations={{
                //   isRequired: true,
                // }}
                defaultValue={
                  defaultValues.identityDetails?.citizenshipIssuedFrom
                }
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="नागरिकता पाएको मिति (बि.स.)"
                id="identityDetails.citizenshipIssuedDate_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Date"
                errors={errors}
                defaultValue={formDateStates.citizenIssuedBS}
                onChange={(value, valueAd) =>
                  handleSetDateAD("citizenIssuedAD", value, valueAd)
                }
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="Citizenship Reg. Date (A.D.)"
                id="identityDetails.citizenshipIssuedDate_en"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Date"
                ADBSmode="AD"
                errors={errors}
                defaultValue={formDateStates.citizenIssuedAD}
                onChange={(value) => handleSetDateBS("citizenIssuedBS", value)}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="नागरिकताको फोटोकपी"
                id="identityDetails.citizenshipCopy"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="File"
                errors={errors}
                defaultValue={defaultValues.identityDetails?.citizenshipCopy}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="जन्मदर्ताको फोटोकपी"
                id="identityDetails.birthCertificateCopy"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="File"
                errors={errors}
                defaultValue={
                  defaultValues.identityDetails?.birthCertificateCopy
                }
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>
            १०.सहयोग सामाग्री प्रयोग गर्नुपर्ने आबश्यकता
          </StyledLabel>

          <StyledFormRow className="mb-2">
            <Col sm={12}>
              <FormInput
                id="hasHelpMaterial"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Radio"
                errors={errors}
                options={[
                  {
                    id: "hasHelpMaterial-भएको",
                    label: "भएको",
                    value: "true",
                  },
                  {
                    id: "hasHelpMaterial-नभएको",
                    label: "नभएको",
                    value: "false",
                  },
                ]}
              />
            </Col>
            {hasHelpMaterial === "true" && (
              <Col sm={12}>
                <FormInput
                  label="सामाग्री विवरण"
                  id="helpMaterial.details"
                  register={register}
                  setValue={setValue}
                  isDisabled={isLoading}
                  type="Text"
                  errors={errors}
                  validations={{
                    isRequired: true,
                  }}
                />
              </Col>
            )}
          </StyledFormRow>
        </fieldset>

        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>११. पछिल्लो सैक्षिक योग्यता</StyledLabel>
          <Col sm={12}>
            <FormInput
              id="educationQualification"
              register={register}
              setValue={setValue}
              isDisabled={isLoading}
              clearErrors={clearErrors}
              type="Select"
              errors={errors}
              options={EducationQualification}
              validations={{
                isRequired: true,
              }}
              defaultValue={defaultValues.educationQualification}
            />
          </Col>
        </fieldset>

        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>१२.</StyledLabel>
          <StyledFormRow className="mb-2">
            <Col ld={6}>
              <FormInput
                label="दैनिक क्रियाकलाप गर्न
							"
                id="canPerformDaily"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Radio"
                errors={errors}
                options={[
                  {
                    id: "canPerformDaily-सक्ने",
                    label: "सक्ने",
                    value: "true",
                  },
                  {
                    id: "canPerformDaily-नसक्ने",
                    label: "नसक्ने",
                    value: "false",
                  },
                ]}
                defaultValue={defaultValues.canPerformDaily ?? false}
              />
            </Col>

            <Col ld={6}>
              <FormInput
                label="साहायक सामाग्री प्रयोग गर्ने
							"
                id="useHelpDevice"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Radio"
                errors={errors}
                options={[
                  {
                    id: "useHelpDevice-गरेको",
                    label: "गरेको",
                    value: "true",
                  },
                  {
                    id: "useHelpDevice-नगरेको",
                    label: "नगरेको",
                    value: "false",
                  },
                ]}
                defaultValue={defaultValues.useHelpDevice ?? false}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>
            १३. सहायक सामग्री प्रयोग गर्ने गरेको भए सामाग्रीको नाम
          </StyledLabel>

          <StyledFormRow className="mb-2">
            <Col md={6} lg={4}>
              <FormInput
                label="सामाग्रीको नाम"
                id="helpDeviceName"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                defaultValue={defaultValues.helpDeviceName}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>
            १४. अन्य व्यक्तिको सहयोग लिनु पर्ने भए त्यस्तो सहयोग लिनु पर्ने काम
          </StyledLabel>

          <StyledFormRow className="mb-2">
            <Col>
              <FormInput
                label="कामको नाम"
                id="needHelpWith"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="List"
                language="english"
                errors={errors}
                defaultValue={defaultValues.needHelpWith}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>
            १५. अन्य व्यक्तिको सहयोग बिना गर्न सक्ने दैनिक कार्य
          </StyledLabel>

          <StyledFormRow className="mb-2">
            <Col>
              <FormInput
                label="कामको नाम"
                id="doesnotNeedHelpWith"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="List"
                language="english"
                errors={errors}
                defaultValue={defaultValues.doesnotNeedHelpWith}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>
            १६. कुनै तालिम प्राप्त गरेको भए मुख्य तालिमको नाम
          </StyledLabel>

          <StyledFormRow className="mb-2">
            <Col>
              <FormInput
                id="mainQualification"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                defaultValue={defaultValues.mainQualification}
              />
            </Col>
          </StyledFormRow>
        </fieldset>
        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>१७. हालको पेसा</StyledLabel>
          <StyledFormRow className="mb-2">
            <Col>
              <FormInput
                id="occupation"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                language="english"
                errors={errors}
                defaultValue={defaultValues.occupation}
              />
            </Col>
          </StyledFormRow>
        </fieldset>

        <fieldset className="mt-3 p-3 border border-2 rounded">
          <StyledLabel>१८. विवरण उपलब्ध गराउने</StyledLabel>
          <StyledFormRow>
            <Col sm="12" className="d-flex">
              <FormInput
                id="isApplicantGurdian"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Checkbox"
                errors={errors}
                properties={{
                  text: "संरक्षक र निवेदक एउटै हुन्",
                }}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="निवेदकको पुरा नाम"
                id="applicant.fullname_np"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                defaultValue={applicantData?.fullname_np}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>

            <Col md={6} lg={4} className="mb-4">
              <FormInput
                label="नाता"
                id="applicant.relation"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                defaultValue={applicantData?.relation}
                type="Select"
                errors={errors}
                options={FamilyRelationship}
                validations={{
                  isRequired: true,
                }}
              />
            </Col>

            <Col md={6} lg={4}>
              <FormInput
                label="सम्पर्क नं."
                id="applicant.contact"
                register={register}
                defaultValue={applicantData?.contact}
                setValue={setValue}
                isDisabled={isLoading}
                language="english"
                type="Number"
                errors={errors}
                maxLength="10"
                validations={{
                  isRequired: true,
                }}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="ठेगाना"
                id="applicant.address"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.applicant?.address}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="नागरिकता नं."
                id="applicant.citizenship"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.applicant?.citizenship}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="नागरिकता पाएको मिति (बि.स.)"
                id="applicant.issuedDate"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                clearErrors={clearErrors}
                type="Date"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.applicant?.issuedDate}
              />
            </Col>
            <Col md={6} lg={4}>
              <FormInput
                label="नागरिकता पाएको स्थान"
                id="applicant.issuedFrom"
                register={register}
                setValue={setValue}
                isDisabled={isLoading}
                type="Text"
                errors={errors}
                validations={{
                  isRequired: true,
                }}
                defaultValue={defaultValues.applicant?.issuedFrom}
              />
            </Col>
          </StyledFormRow>
        </fieldset>

        {apiError && (
          <Row>
            <Alert variant="danger">{apiError}</Alert>
          </Row>
        )}

        <Row className="mt-4">
          <Col lg={6}>
            <Button variant={"success"} type="submit" disabled={isLoading}>
              {isLoading ? <VscLoading className="spin" /> : "पेश गर्नुहोस"}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
}
