/** @jsxRuntime classic */
/** @jsx jsx */
import {
  css,
  jsx,
  // , css
} from "@emotion/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import {
  createDocumentType,
  editDocumentType,
} from "../../../_redux/slices/documents";
import { useForm } from "react-hook-form";
import InputField from "../../../components/InputField";
import { useSelector } from "react-redux";
import { languageSelector } from "../../../_redux/slices/languages";
import { InputFieldStyleOverwrite } from "../Ward/AddWard";
import { yupResolver } from "@hookform/resolvers/yup";
import { documentTypeValidation } from "../../../_helpers/schema-validation";
import { VscLoading } from "react-icons/vsc";

const AddDocumentType = ({ showAdd, setShowAdd, documentDetail, setDocumentDetail }) => {
  const dispatch = useDispatch();

  const { language } = useSelector(languageSelector);
  const loading = useSelector((state) => state.documents.loading);
  const documents = useSelector((state) => state.documents);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(documentTypeValidation),
  });

  const onSubmit = (data) => {
    if (documentDetail) {
      dispatch(
        editDocumentType(
          { ...data, id: documentDetail.id },
          setShowAdd,
          setDocumentDetail
        )
      )
      setShowAdd(false);
    }
    else {
      dispatch(createDocumentType(data, documents?.documentsType, setShowAdd));
    }
  };

  useEffect(() => {
    if (documentDetail) {
      setValue("title", documentDetail.title);
      setValue("type", documentDetail.type);
    } else {
      setValue("title", "");
      setValue("type", null);
    }
  }, [setValue, documentDetail]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Header className="card-header-custom">
          <h3>
            {documentDetail?.eventType === "edit"
              ? "आवश्यक कागजात सम्पादन गर्नुहोस्"
              : " नयाँ आवश्यक कागजात"}
          </h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formBasicEmail" className="d-flex">
                <Form.Label className="mb-0">शीर्षक</Form.Label>

                <div className="w-100">
                  <InputField
                    css={css`
                      font-size: 16px !important;
                    `}
                    type="textarea"
                    className={`textbox_req`}
                    language={language}
                    classNameName={`input`}
                    elementId={"title"}
                    placeholder={"Enter Title"}
                    register={register}
                    isRequired={true}
                    setValue={setValue}
                    autoComplete={"nope"}
                  />

                  <Form.Text className="text-danger">
                    {errors.title?.message}
                  </Form.Text>
                </div>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="mb-0">प्रकार</Form.Label>
                <Form.Control
                  css={InputFieldStyleOverwrite}
                  as="select"
                  placeholder="Enter type"
                  name="type"
                  {...register("type")}
                >
                  <option value="personal">Personal</option>
                  <option value="sifarish">Sifarish</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>

        <Card.Footer className="d-flex justify-content-end align-items-center">
          <Button variant="secondary" className="mr-2" onClick={() => setShowAdd(!showAdd)} disabled={loading}>
            रद्द गर्नुहोस्
          </Button>
          <Button type="submit" variant="success" disabled={loading}>
            {loading ? <VscLoading className="spin" /> : ' पेश गर्नुहोस्'}
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default AddDocumentType;
