/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { Fragment } from 'react';
import { useRef } from "react";
import { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { BiRefresh } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import { PrintImage } from "../../../_elements/PrintImage";

/**
 * 
 */
export default function DisabledParichayaPatra({ userProfile }) {
    const history = useHistory();
    const cardRef = useRef();

    const [showPreview, setShowPreview] = useState(false);
    const handleClose = () => {
        setShowPreview(false);
    }


    const handleCardPrint = useReactToPrint({
        content: () => {
            return cardRef.current;
        },
        onAfterPrint: () => {
            try {
                //Need to detect if the print is cancelled, if cancelled do not increment count
            } catch (err) { }
        },
    });
    return <Container fluid>
        {
            userProfile?.cardDetails?.cardUrl_np === "processing" ?
                <Row>
                    <div className="p-4 text-center w-100 border my-3">
                        <h2 className="mb-2">
                            कार्ड बनाउने प्रक्रियामा छ।
                        </h2>
                        <h4>
                            कृपया पछि हेर्नुहोस्।
                        </h4>

                        <div className='mt-4 d-flex justify-content-center align-items-center'>
                            <button className='btn btn-primary' onClick={() => {
                                window.location.reload();
                            }}>
                                <BiRefresh fontSize={'24px'} />
                            </button>
                        </div>
                    </div>
                </Row>
                :
                <Fragment>
                    <Row>
                        <Col css={css`
                display: flex;
                justify-content: center;
                padding: 20px 0;
            `}>
                            <img css={css`width: 80%;`} src={userProfile?.cardDetails?.cardUrl_np} alt={userProfile?.name_en} />
                        </Col>

                        <Col css={css`
                display: flex;
                justify-content: center;
                padding: 20px 0;
            `}>
                            <img css={css`width: 80%;`} src={userProfile?.cardDetails?.cardUrl_en} alt={userProfile?.name_en} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                        </Col>
                        <Col>
                            <div className="d-flex justify-content-end mb-4">
                                <Button variant="primary" className="mr-2"
                                    onClick={() => {
                                        history.push(`/disabled/edit/${userProfile?.id}`)
                                    }}>
                                    सम्पादन
                                </Button>
                                <Button variant="info" className="mr-2" onClick={() => setShowPreview(true)}>
                                    Print Test
                                </Button>
                                <Button variant="primary" onClick={() => setShowPreview(true)}>Print</Button>
                            </div>
                            <Container>
                                <Row>
                                    <Col lg={6} className="bg-info text-white p-2">
                                        <h4 className="mb-3">Prints</h4>
                                        <h5>0</h5>
                                    </Col>
                                    <Col lg={6} className="bg-warning  p-2">
                                        <h4 className="mb-3">Test Prints</h4>
                                        <h5>0</h5>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Fragment>
        }



        <Modal size="xl" centered show={showPreview} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{userProfile.name_np} ({userProfile.name_en}) परिचयपत्र</Modal.Title>
            </Modal.Header>
            <div className='d-flex mt-2 justify-content-end mx-4'>
                <Button onClick={handleCardPrint}>
                    Print
                </Button>
            </div>

            <Modal.Body ref={cardRef}>
                <div className='page-break'></div>
                {
                    userProfile?.cardDetails?.cardUrl_np ?
                        <div>
                            <PrintImage className="print-card" src={userProfile?.cardDetails?.cardUrl_np} alt={userProfile?.name_en} />
                        </div>
                        :
                        <div className='text-center text-danger p-5'>
                            Card not found!
                        </div>

                }

                <div className='page-break mt-2'></div>
                {
                    userProfile?.cardDetails?.cardUrl_en ?
                        <div>
                            <PrintImage className="print-card" src={userProfile?.cardDetails?.cardUrl_en} alt={userProfile?.name_en} />
                        </div>
                        :
                        <div className='text-center text-danger p-5'>
                            Card not found!
                        </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    </Container>
}