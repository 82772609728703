export const PALIKADATA = {
  "प्रदेश नम्बर १": {
    ताप्लेजुङ: [
      {
        "क्र.स.": 1,
        "स्थानीय तहको नाम": "फुङलिङ नगरपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "phunglingmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 2,
        "स्थानीय तहको नाम": "आठराई त्रिवेणी गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "aathraitribenimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 3,
        "स्थानीय तहको नाम": "सिदिङ्वा गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sidingbamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 4,
        "स्थानीय तहको नाम": "फक्ताङलुङ गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "phaktanglungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 5,
        "स्थानीय तहको नाम": "मिक्वाखोला गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "mikwakholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 6,
        "स्थानीय तहको नाम": "मेरिङदेन गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "meringdenmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 7,
        "स्थानीय तहको नाम": "मैवाखोला गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "maiwakholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 8,
        "स्थानीय तहको नाम": "पाथीभरा याङवरक गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "yangwarakmuntaplejung.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 9,
        "स्थानीय तहको नाम": "सिरीजङ्घा गाउँपालिका",
        जिल्ला: "ताप्लेजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sirijanghamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    पाँचथर: [
      {
        "क्र.स.": 10,
        "स्थानीय तहको नाम": "फिदिम नगरपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "phidimmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 11,
        "स्थानीय तहको नाम": "फालेलुङ गाउँपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "phalelungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 12,
        "स्थानीय तहको नाम": "फाल्गुनन्द गाउँपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "phalgunandamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 13,
        "स्थानीय तहको नाम": "हिलिहाङ गाउँपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "hilihangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 14,
        "स्थानीय तहको नाम": "कुम्मायक गाउँपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kummayakmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 15,
        "स्थानीय तहको नाम": "मिक्लाजुङ गाउँपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "miklajungmunpanchthar.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 16,
        "स्थानीय तहको नाम": "तुम्बेवा गाउँपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "tumwewamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 17,
        "स्थानीय तहको नाम": "याङवरक गाउँपालिका",
        जिल्ला: "पाँचथर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "yangwarakmunpanchthar.gov.np",
        कैफियत: "तयार",
      },
    ],
    ईलाम: [
      {
        "क्र.स.": 18,
        "स्थानीय तहको नाम": "ईलाम नगरपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "ilammun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 19,
        "स्थानीय तहको नाम": "देउमाई नगरपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "deumaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 20,
        "स्थानीय तहको नाम": "माई नगरपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "maimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 21,
        "स्थानीय तहको नाम": "सूर्योदय नगरपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "suryodayamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 22,
        "स्थानीय तहको नाम": "फाकफोकथुम गाउँपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "phakphokthummun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 23,
        "स्थानीय तहको नाम": "चुलाचुली गाउँपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "chulachulimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 24,
        "स्थानीय तहको नाम": "माईजोगमाई गाउँपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "maijogmaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 25,
        "स्थानीय तहको नाम": "माङसेबुङ गाउँपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "mansebungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 26,
        "स्थानीय तहको नाम": "रोङ गाउँपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "rongmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 27,
        "स्थानीय तहको नाम": "सन्दकपुर गाउँपालिका",
        जिल्ला: "ईलाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sandakpurmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    झापा: [
      {
        "क्र.स.": 28,
        "स्थानीय तहको नाम": "मेचीनगर नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "mechinagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 29,
        "स्थानीय तहको नाम": "दमक नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "damakmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 30,
        "स्थानीय तहको नाम": "कन्काई नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kankaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 31,
        "स्थानीय तहको नाम": "भद्रपुर नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "bhadrapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 32,
        "स्थानीय तहको नाम": "अर्जुनधारा नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "arjundharamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 33,
        "स्थानीय तहको नाम": "शिवशताक्षी नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "shivasatakshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 34,
        "स्थानीय तहको नाम": "गौरादह नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "gauradahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 35,
        "स्थानीय तहको नाम": "विर्तामोड नगरपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "birtamodmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 36,
        "स्थानीय तहको नाम": "कमल गाउँपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kamalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 37,
        "स्थानीय तहको नाम": "गौरीगंज गाउँपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "gaurigunjmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 38,
        "स्थानीय तहको नाम": "बाह्रदशी गाउँपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "bahradashimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 39,
        "स्थानीय तहको नाम": "झापा गाउँपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "jhapamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 40,
        "स्थानीय तहको नाम": "बुद्धशान्ति गाउँपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "buddhashantimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 41,
        "स्थानीय तहको नाम": "हल्दिवारी गाउँपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "haldibarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 42,
        "स्थानीय तहको नाम": "कचनकवल गाउँपालिका",
        जिल्ला: "झापा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kachankawalmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    मोरंग: [
      {
        "क्र.स.": 43,
        "स्थानीय तहको नाम": "विराटनगर महानगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "महानगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "biratnagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 44,
        "स्थानीय तहको नाम": "बेलवारी नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "belbarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 45,
        "स्थानीय तहको नाम": "लेटाङ नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "letangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 46,
        "स्थानीय तहको नाम": "पथरी शनिश्चरे नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "patharishanishcharemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 47,
        "स्थानीय तहको नाम": "रंगेली नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "rangelimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 48,
        "स्थानीय तहको नाम": "रतुवामाई नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "ratuwamaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 49,
        "स्थानीय तहको नाम": "सुनवर्षि नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sunawarshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 50,
        "स्थानीय तहको नाम": "उर्लावारी नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "urlabarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 51,
        "स्थानीय तहको नाम": "सुन्दरहरैचा नगरपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sundarharaichamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 52,
        "स्थानीय तहको नाम": "बुढीगंगा गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "budhigangamunmorang.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 53,
        "स्थानीय तहको नाम": "धनपालथान गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "dhanapalthanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 54,
        "स्थानीय तहको नाम": "ग्रामथान गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "gramthanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 55,
        "स्थानीय तहको नाम": "जहदा गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "jahadamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 56,
        "स्थानीय तहको नाम": "कानेपोखरी गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kanepokharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 57,
        "स्थानीय तहको नाम": "कटहरी गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kataharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 58,
        "स्थानीय तहको नाम": "केरावारी गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kerabarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 59,
        "स्थानीय तहको नाम": "मिक्लाजुङ गाउँपालिका",
        जिल्ला: "मोरंग",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "miklajungmunmorang.gov.np",
        कैफियत: "तयार",
      },
    ],
    सुनसरी: [
      {
        "क्र.स.": 60,
        "स्थानीय तहको नाम": "ईटहरी उपमहानगरपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "itaharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 61,
        "स्थानीय तहको नाम": "धरान उपमहानगरपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "dharan.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 62,
        "स्थानीय तहको नाम": "ईनरुवा नगरपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "inaruwamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 63,
        "स्थानीय तहको नाम": "दुहवी नगरपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "duhabimun.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 64,
        "स्थानीय तहको नाम": "रामधुनी नगरपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "ramdhunimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 65,
        "स्थानीय तहको नाम": "बराहक्षेत्र नगरपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "barahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 66,
        "स्थानीय तहको नाम": "देवानगञ्ज गाउँपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "dewanganjmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 67,
        "स्थानीय तहको नाम": "कोशी गाउँपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "koshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 68,
        "स्थानीय तहको नाम": "गढी गाउँपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "gadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 69,
        "स्थानीय तहको नाम": "बर्जु गाउँपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "barjumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 70,
        "स्थानीय तहको नाम": "भोक्राहा नरसिंह गाउँपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "bhokrahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 71,
        "स्थानीय तहको नाम": "हरिनगर गाउँपालिका",
        जिल्ला: "सुनसरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "harinagaramun.gov.np",
        कैफियत: "तयार",
      },
    ],
    धनकुटा: [
      {
        "क्र.स.": 72,
        "स्थानीय तहको नाम": "पाख्रिबास नगरपालिका",
        जिल्ला: "धनकुटा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "pakhribasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 73,
        "स्थानीय तहको नाम": "धनकुटा नगरपालिका",
        जिल्ला: "धनकुटा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "dhankutamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 74,
        "स्थानीय तहको नाम": "महालक्ष्मी नगरपालिका",
        जिल्ला: "धनकुटा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "mahalaxmimundhankuta.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 75,
        "स्थानीय तहको नाम": "साँगुरीगढी गाउँपालिका",
        जिल्ला: "धनकुटा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sangurigadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 76,
        "स्थानीय तहको नाम": "सहिदभूमि गाउँपालिका",
        जिल्ला: "धनकुटा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "shahidbhumimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 77,
        "स्थानीय तहको नाम": "छथर जोरपाटी गाउँपालिका",
        जिल्ला: "धनकुटा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "chhatharjorpatimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 78,
        "स्थानीय तहको नाम": "चौविसे गाउँपालिका",
        जिल्ला: "धनकुटा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "choubisemun.gov.np",
        कैफियत: "तयार",
      },
    ],
    तेहथुम: [
      {
        "क्र.स.": 79,
        "स्थानीय तहको नाम": "म्याङलुङ नगरपालिका",
        जिल्ला: "तेहथुम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "myanglungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 80,
        "स्थानीय तहको नाम": "लालीगुराँस नगरपालिका",
        जिल्ला: "तेहथुम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "laliguransmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 81,
        "स्थानीय तहको नाम": "आठराई गाउँपालिका",
        जिल्ला: "तेहथुम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "aathraimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 82,
        "स्थानीय तहको नाम": "छथर गाउँपालिका",
        जिल्ला: "तेहथुम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "chhatharmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 83,
        "स्थानीय तहको नाम": "फेदाप गाउँपालिका",
        जिल्ला: "तेहथुम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "phedapmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 84,
        "स्थानीय तहको नाम": "मेन्छयायेम गाउँपालिका",
        जिल्ला: "तेहथुम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "menchhayayemmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    संखुवासभा: [
      {
        "क्र.स.": 85,
        "स्थानीय तहको नाम": "चैनपुर नगरपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "chainpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 86,
        "स्थानीय तहको नाम": "धर्मदेवी नगरपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "dharmadevimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 87,
        "स्थानीय तहको नाम": "खाँदवारी नगरपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "khandbarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 88,
        "स्थानीय तहको नाम": "मादी नगरपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "madimunsankhuwasabha.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 89,
        "स्थानीय तहको नाम": "पाँचखपन नगरपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "panchkhapanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 90,
        "स्थानीय तहको नाम": "भोटखोला गाउँपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "bhotkholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 91,
        "स्थानीय तहको नाम": "चिचिला गाउँपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "chichilamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 92,
        "स्थानीय तहको नाम": "मकालु गाउँपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "makalumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 93,
        "स्थानीय तहको नाम": "सभापोखरी गाउँपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "savapokharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 94,
        "स्थानीय तहको नाम": "सिलीचोङ गाउँपालिका",
        जिल्ला: "संखुवासभा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "silichongmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    भोजपुर: [
      {
        "क्र.स.": 95,
        "स्थानीय तहको नाम": "भोजपुर नगरपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "bhojpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 96,
        "स्थानीय तहको नाम": "षडानन्द नगरपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "shadanandamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 97,
        "स्थानीय तहको नाम": "टेम्केमैयुङ गाउँपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "tyamkemaiyummun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 98,
        "स्थानीय तहको नाम": "रामप्रसाद राई गाउँपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "ramprasadraimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 99,
        "स्थानीय तहको नाम": "अरुण गाउँपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "arunmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 100,
        "स्थानीय तहको नाम": "पौवादुङमा गाउँपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "pauwadungmamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 101,
        "स्थानीय तहको नाम": "साल्पासिलिछो गाउँपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "salpasilichhomun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 102,
        "स्थानीय तहको नाम": "आमचोक गाउँपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "aamchowkmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 103,
        "स्थानीय तहको नाम": "हतुवागढी गाउँपालिका",
        जिल्ला: "भोजपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "hatuwagadhimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    सोलुखुम्बु: [
      {
        "क्र.स.": 104,
        "स्थानीय तहको नाम": "सोलुदुधकुण्ड नगरपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "solududhkundamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 105,
        "स्थानीय तहको नाम": "माप्य दुधकोशी गाउँपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "dudhkoshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 106,
        "स्थानीय तहको नाम": "खुम्वु पासाङल्हमु गाउँपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "khumbupasanglhamumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 107,
        "स्थानीय तहको नाम": "थुलुङ दुधकोशी गाउँपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "thulungdudhkoshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 108,
        "स्थानीय तहको नाम": "नेचासल्यान गाउँपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "nechasalyanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 109,
        "स्थानीय तहको नाम": "माहाकुलुङ गाउँपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "mahakulungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 110,
        "स्थानीय तहको नाम": "लिखु पिके गाउँपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "likhupikemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 111,
        "स्थानीय तहको नाम": "सोताङ गाउँपालिका",
        जिल्ला: "सोलुखुम्बु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sotangmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    ओखलढुंगा: [
      {
        "क्र.स.": 112,
        "स्थानीय तहको नाम": "सिद्दिचरण नगरपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "siddhicharanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 113,
        "स्थानीय तहको नाम": "खिजिदेम्बा गाउँपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "khijidembamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 114,
        "स्थानीय तहको नाम": "चम्पादेवी गाउँपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "champadevimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 115,
        "स्थानीय तहको नाम": "चिशंखुगढी गाउँपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "chishankhugadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 116,
        "स्थानीय तहको नाम": "मानेभञ्याङ गाउँपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "manebhanjyangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 117,
        "स्थानीय तहको नाम": "मोलुङ गाउँपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "molungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 118,
        "स्थानीय तहको नाम": "लिखु गाउँपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "likhumunokhaldhunga.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 119,
        "स्थानीय तहको नाम": "सुनकोशी गाउँपालिका",
        जिल्ला: "ओखलढुंगा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sunkoshimunokhaldhunga.gov.np",
        कैफियत: "तयार",
      },
    ],
    खोटाङ: [
      {
        "क्र.स.": 120,
        "स्थानीय तहको नाम": "हलेसी तुवाचुङ नगरपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "halesituwachungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 121,
        "स्थानीय तहको नाम": "दिक्तेल रुपाकोट मझुवागढी नगरपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "rupakotmajhuwagadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 122,
        "स्थानीय तहको नाम": "ऐसेलुखर्क गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "aiselukharkamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 123,
        "स्थानीय तहको नाम": "रावा बेसी गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "rawabesimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 124,
        "स्थानीय तहको नाम": "जन्तेढुंगा गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "jantedhungamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 125,
        "स्थानीय तहको नाम": "खोटेहाङ गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "khotehangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 126,
        "स्थानीय तहको नाम": "केपिलासगढी गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "kepilasgadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 127,
        "स्थानीय तहको नाम": "दिप्रुङ चुइचुम्मा गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "diprungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 128,
        "स्थानीय तहको नाम": "साकेला गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sakelamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 129,
        "स्थानीय तहको नाम": "वराहपोखरी गाउँपालिका",
        जिल्ला: "खोटाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "barahapokharimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    उदयपुर: [
      {
        "क्र.स.": 130,
        "स्थानीय तहको नाम": "कटारी नगरपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "katarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 131,
        "स्थानीय तहको नाम": "चौदण्डीगढी नगरपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "chaudandigadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 132,
        "स्थानीय तहको नाम": "त्रियुगा नगरपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "triyugamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 133,
        "स्थानीय तहको नाम": "वेलका नगरपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "belakamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 134,
        "स्थानीय तहको नाम": "उदयपुरगढी गाउँपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "udayapurgadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 135,
        "स्थानीय तहको नाम": "ताप्ली गाउँपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "taplimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 136,
        "स्थानीय तहको नाम": "रौतामाई गाउँपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "rautamaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 137,
        "स्थानीय तहको नाम": "लिम्चुङ्बुङ गाउँपालिका",
        जिल्ला: "उदयपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर १",
        वेवसाईट: "sunkoshimunudayapur.gov.np",
        कैफियत: "तयार",
      },
    ],
  },
  "प्रदेश नम्बर २": {
    सप्तरी: [
      {
        "क्र.स.": 138,
        "स्थानीय तहको नाम": "राजविराज नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "rajbirajmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 139,
        "स्थानीय तहको नाम": "कञ्चनरुप नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kanchanrupmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 140,
        "स्थानीय तहको नाम": "डाक्नेश्वरी नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "dakneshworimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 141,
        "स्थानीय तहको नाम": "बोदेबरसाईन नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bodebarsainmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 142,
        "स्थानीय तहको नाम": "खडक नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "khadakmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 143,
        "स्थानीय तहको नाम": "शम्भुनाथ नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "shambhunathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 144,
        "स्थानीय तहको नाम": "सुरुङ्‍गा नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "surungamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 145,
        "स्थानीय तहको नाम": "हनुमाननगर कङ्‌कालिनी नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "hanumannagarkankalinimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 146,
        "स्थानीय तहको नाम": "सप्तकोशी नगरपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "saptakoshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 147,
        "स्थानीय तहको नाम": "अग्निसाइर कृष्णासरवन गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "agnisairkrishnasawaranmun.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 148,
        "स्थानीय तहको नाम": "छिन्नमस्ता गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "chhinnamastamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 149,
        "स्थानीय तहको नाम": "महादेवा गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "mahadevamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 150,
        "स्थानीय तहको नाम": "तिरहुत गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "tirahutmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 151,
        "स्थानीय तहको नाम": "तिलाठी कोईलाडी गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "tilathikoiladimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 152,
        "स्थानीय तहको नाम": "रुपनी गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "rupanimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 153,
        "स्थानीय तहको नाम": "राजगढ गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "rajgadhmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 154,
        "स्थानीय तहको नाम": "बिष्णुपुर गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bishnupurmunsaptari.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 155,
        "स्थानीय तहको नाम": "बलान-बिहुल गाउँपालिका",
        जिल्ला: "सप्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "balanbihulmun.gov.np",
        कैफियत: "तयाार",
      },
    ],
    सिराहा: [
      {
        "क्र.स.": 156,
        "स्थानीय तहको नाम": "लहान नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "lahanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 157,
        "स्थानीय तहको नाम": "धनगढीमाई नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "dhangadhimaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 158,
        "स्थानीय तहको नाम": "सिरहा नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "sirahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 159,
        "स्थानीय तहको नाम": "गोलबजार नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "golbazarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 160,
        "स्थानीय तहको नाम": "मिर्चैयाँ नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "mirchaiyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 161,
        "स्थानीय तहको नाम": "कल्याणपुर नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kalyanpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 162,
        "स्थानीय तहको नाम": "कर्जन्हा नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "karjanhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 163,
        "स्थानीय तहको नाम": "सुखीपुर नगरपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "sukhipurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 164,
        "स्थानीय तहको नाम": "भगवानपुर गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bhagwanpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 165,
        "स्थानीय तहको नाम": "औरही गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "aurahimunsiraha.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 166,
        "स्थानीय तहको नाम": "विष्णुपुर गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bishnupurmunsiraha.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 167,
        "स्थानीय तहको नाम": "बरियारपट्टी गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bariyapattimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 168,
        "स्थानीय तहको नाम": "लक्ष्मीपुर पतारी गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "laxmipurpatarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 169,
        "स्थानीय तहको नाम": "नरहा गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "narahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 170,
        "स्थानीय तहको नाम": "सखुवानान्कारकट्टी गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "sakhuwanankarkattimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 171,
        "स्थानीय तहको नाम": "अर्नमा गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "anarmamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 172,
        "स्थानीय तहको नाम": "नवराजपुर गाउँपालिका",
        जिल्ला: "सिराहा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "nawarajpurmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    धनुषा: [
      {
        "क्र.स.": 173,
        "स्थानीय तहको नाम": "जनकपुरधाम उपमहानगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "janakpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 174,
        "स्थानीय तहको नाम": "क्षिरेश्वरनाथ नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kshireshwornathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 175,
        "स्थानीय तहको नाम": "गणेशमान चारनाथ नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "ganeshmancharnathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 176,
        "स्थानीय तहको नाम": "धनुषाधाम नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "dhanushadhammun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 177,
        "स्थानीय तहको नाम": "नगराइन नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "nagrainmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 178,
        "स्थानीय तहको नाम": "विदेह नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bidehamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 179,
        "स्थानीय तहको नाम": "मिथिला नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "mithilamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 180,
        "स्थानीय तहको नाम": "शहीदनगर नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "shahidnagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 181,
        "स्थानीय तहको नाम": "सबैला नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "sabailamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 182,
        "स्थानीय तहको नाम": "कमला नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kamalamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 183,
        "स्थानीय तहको नाम": "मिथिला बिहारी नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "mithilabiharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 184,
        "स्थानीय तहको नाम": "हंसपुर नगरपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "hansapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 185,
        "स्थानीय तहको नाम": "जनकनन्दिनी गाउँपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "janaknandinimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 186,
        "स्थानीय तहको नाम": "बटेश्वर गाउँपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bateshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 187,
        "स्थानीय तहको नाम": "मुखियापट्टी मुसहरमिया गाउँपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "mukhiyapattimusaharmiyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 188,
        "स्थानीय तहको नाम": "लक्ष्मीनिया गाउँपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "laxminiyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 189,
        "स्थानीय तहको नाम": "औरही गाउँपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "aurahimundhanusha.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 190,
        "स्थानीय तहको नाम": "धनौजी गाउँपालिका",
        जिल्ला: "धनुषा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "dhanaujimun.gov.np",
        कैफियत: "तयाार",
      },
    ],
    महोत्तरी: [
      {
        "क्र.स.": 191,
        "स्थानीय तहको नाम": "जलेश्वर नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "jaleshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 192,
        "स्थानीय तहको नाम": "बर्दिबास नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bardibasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 193,
        "स्थानीय तहको नाम": "गौशाला नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "gaushalamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 194,
        "स्थानीय तहको नाम": "लोहरपट्टी नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "loharpattimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 195,
        "स्थानीय तहको नाम": "रामगोपालपुर नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "ramgopalpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 196,
        "स्थानीय तहको नाम": "मनरा शिसवा नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "manrashiswamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 197,
        "स्थानीय तहको नाम": "मटिहानी नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "matihanimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 198,
        "स्थानीय तहको नाम": "भँगाहा नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bhagahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 199,
        "स्थानीय तहको नाम": "बलवा नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "balwamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 200,
        "स्थानीय तहको नाम": "औरही नगरपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "aurahimunmahottari.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 201,
        "स्थानीय तहको नाम": "एकडारा गाउँपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "ekdaramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 202,
        "स्थानीय तहको नाम": "सोनमा गाउँपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "sonmamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 203,
        "स्थानीय तहको नाम": "साम्सी गाउँपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "samsimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 204,
        "स्थानीय तहको नाम": "महोत्तरी गाउँपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "mahottarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 205,
        "स्थानीय तहको नाम": "पिपरा गाउँपालिका",
        जिल्ला: "महोत्तरी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "pipramun.gov.np",
        कैफियत: "तयार",
      },
    ],
    सर्लाही: [
      {
        "क्र.स.": 206,
        "स्थानीय तहको नाम": "ईश्वरपुर नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "ishworpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 207,
        "स्थानीय तहको नाम": "मलंगवा नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "malangwamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 208,
        "स्थानीय तहको नाम": "लालबन्दी नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "lalbandimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 209,
        "स्थानीय तहको नाम": "हरिपुर नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "haripurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 210,
        "स्थानीय तहको नाम": "हरिपुर्वा नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "haripurwamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 211,
        "स्थानीय तहको नाम": "हरिवन नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "harionmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 212,
        "स्थानीय तहको नाम": "बरहथवा नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "barhathwamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 213,
        "स्थानीय तहको नाम": "बलरा नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "balramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 214,
        "स्थानीय तहको नाम": "गोडैटा नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "godaitamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 215,
        "स्थानीय तहको नाम": "बागमती नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bagmatimunsarlahi.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 216,
        "स्थानीय तहको नाम": "कविलासी नगरपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kawilasimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 217,
        "स्थानीय तहको नाम": "चक्रघट्टा गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "chakraghattamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 218,
        "स्थानीय तहको नाम": "चन्द्रनगर गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "chandranagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 219,
        "स्थानीय तहको नाम": "धनकौल गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "dhankaulmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 220,
        "स्थानीय तहको नाम": "ब्रह्मपुरी गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "brahmapurimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 221,
        "स्थानीय तहको नाम": "रामनगर गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "ramnagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 222,
        "स्थानीय तहको नाम": "विष्णु गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bishnumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 223,
        "स्थानीय तहको नाम": "कौडेना गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kaudenamun.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 224,
        "स्थानीय तहको नाम": "पर्सा गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "parsamun.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 225,
        "स्थानीय तहको नाम": "बसबरीया गाउँपालिका",
        जिल्ला: "सर्लाही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "basbariyamun.gov.np",
        कैफियत: "तयाार",
      },
    ],
    रौतहट: [
      {
        "क्र.स.": 338,
        "स्थानीय तहको नाम": "चन्द्रपुर नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "chandrapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 339,
        "स्थानीय तहको नाम": "गरुडा नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "garudamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 340,
        "स्थानीय तहको नाम": "गौर नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "gaurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 341,
        "स्थानीय तहको नाम": "बौधीमाई नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "baudhimaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 342,
        "स्थानीय तहको नाम": "बृन्दावन नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "brindawanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 343,
        "स्थानीय तहको नाम": "देवाही गोनाही नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "dewahigonahimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 344,
        "स्थानीय तहको नाम": "गढीमाई नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "gadhimaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 345,
        "स्थानीय तहको नाम": "गुजरा नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "gujaramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 346,
        "स्थानीय तहको नाम": "कटहरिया नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "katahariyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 347,
        "स्थानीय तहको नाम": "माधव नारायण नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "madhavnarayanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 348,
        "स्थानीय तहको नाम": "मौलापुर नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "maulapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 349,
        "स्थानीय तहको नाम": "फतुवाबिजयपुर नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "phatuwabijaypurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 350,
        "स्थानीय तहको नाम": "ईशनाथ नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "ishnathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 351,
        "स्थानीय तहको नाम": "परोहा नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "parohamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 352,
        "स्थानीय तहको नाम": "राजपुर नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "rajpurmunrautahat.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 353,
        "स्थानीय तहको नाम": "राजदेवी नगरपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "rajdevimun.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 354,
        "स्थानीय तहको नाम": "दुर्गा भगवती गाउँपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "durgabhagawatimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 355,
        "स्थानीय तहको नाम": "यमुनामाई गाउँपालिका",
        जिल्ला: "रौतहट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "yamunamaimun.gov.np",
        कैफियत: "तयाार",
      },
    ],
    वारा: [
      {
        "क्र.स.": 356,
        "स्थानीय तहको नाम": "कलैया उपमहानगरपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kalaiyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 357,
        "स्थानीय तहको नाम": "जीतपुर सिमरा उपमहानगरपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "jeetpursimaramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 358,
        "स्थानीय तहको नाम": "कोल्हवी नगरपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kolhabimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 359,
        "स्थानीय तहको नाम": "निजगढ नगरपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "nijgadhmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 360,
        "स्थानीय तहको नाम": "महागढीमाई नगरपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "mahagadimaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 361,
        "स्थानीय तहको नाम": "सिम्रौनगढ नगरपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "simraungadhmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 362,
        "स्थानीय तहको नाम": "पचरौता नगरपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "pachrautamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 363,
        "स्थानीय तहको नाम": "आदर्श कोटवाल गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "aadarshakotwalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 364,
        "स्थानीय तहको नाम": "करैयामाई गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "karaiyamaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 365,
        "स्थानीय तहको नाम": "देवताल गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "devtalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 366,
        "स्थानीय तहको नाम": "परवानीपुर गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "parwanipurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 367,
        "स्थानीय तहको नाम": "प्रसौनी गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "prasaunimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 368,
        "स्थानीय तहको नाम": "फेटा गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "phetamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 369,
        "स्थानीय तहको नाम": "बारागढीगाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "baragadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 370,
        "स्थानीय तहको नाम": "सुवर्ण गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "suwarnamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 371,
        "स्थानीय तहको नाम": "विश्रामपुर गाउँपालिका",
        जिल्ला: "वारा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bishrampurmun.gov.np",
        कैफियत: "तयाार",
      },
    ],
    पर्सा: [
      {
        "क्र.स.": 372,
        "स्थानीय तहको नाम": "बिरगंज महानगरपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "महानगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "birgunjmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 373,
        "स्थानीय तहको नाम": "पोखरिया नगरपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "pokhariyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 374,
        "स्थानीय तहको नाम": "बहुदरमाई नगरपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bahudarmaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 375,
        "स्थानीय तहको नाम": "पर्सागढी नगरपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "parsagadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 376,
        "स्थानीय तहको नाम": "ठोरी गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "thorimun.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 377,
        "स्थानीय तहको नाम": "जगरनाथपुर गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "jagarnathpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 378,
        "स्थानीय तहको नाम": "धोबीनी गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "dhobinimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 379,
        "स्थानीय तहको नाम": "छिपहरमाई गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "chhipaharmaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 380,
        "स्थानीय तहको नाम": "पकाहा मैनपुर गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "pakahamainpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 381,
        "स्थानीय तहको नाम": "बिन्दबासिनी गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "bindabasinimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 382,
        "स्थानीय तहको नाम": "सखुवा प्रसौनी गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "sakhuwaprasaunimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 383,
        "स्थानीय तहको नाम": "पटेर्वा सुगौली गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "paterwasugaulimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 384,
        "स्थानीय तहको नाम": "कालिकामाई गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "kalikamaimun.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 385,
        "स्थानीय तहको नाम": "जिरा भवानी गाउँपालिका",
        जिल्ला: "पर्सा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "प्रदेश नम्बर २",
        वेवसाईट: "jirabhawanimun.gov.np",
        कैफियत: "तयाार",
      },
    ],
  },
  "बागमती प्रदेश": {
    सिन्धुली: [
      {
        "क्र.स.": 226,
        "स्थानीय तहको नाम": "कमलामाई नगरपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kamalamaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 227,
        "स्थानीय तहको नाम": "दुधौली नगरपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "dudhaulimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 228,
        "स्थानीय तहको नाम": "गोलन्जर गाउँपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "golanjormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 229,
        "स्थानीय तहको नाम": "घ्याङलेख गाउँपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "ghyanglekhmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 230,
        "स्थानीय तहको नाम": "तीनपाटन गाउँपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tinpatanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 231,
        "स्थानीय तहको नाम": "फिक्कल गाउँपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "phikkalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 232,
        "स्थानीय तहको नाम": "मरिण गाउँपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "marinmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 233,
        "स्थानीय तहको नाम": "सुनकोशी गाउँपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "sunkoshimunsindhuli.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 234,
        "स्थानीय तहको नाम": "हरिहरपुरगढी गाउँपालिका",
        जिल्ला: "सिन्धुली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "hariharpurgadhimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    रामेछाप: [
      {
        "क्र.स.": 235,
        "स्थानीय तहको नाम": "मन्थली नगरपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "manthalimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 236,
        "स्थानीय तहको नाम": "रामेछाप नगरपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "ramechhapmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 237,
        "स्थानीय तहको नाम": "उमाकुण्ड गाउँपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "umakundamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 238,
        "स्थानीय तहको नाम": "खाँडादेवी गाउँपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "khandadevimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 239,
        "स्थानीय तहको नाम": "गोकुलगङ्गा गाउँपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "gokulgangamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 240,
        "स्थानीय तहको नाम": "दोरम्बा शैंलुङ गाउँपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "dorambamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 241,
        "स्थानीय तहको नाम": "लिखु तामाकोशी गाउँपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "likhumunramechhap.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 242,
        "स्थानीय तहको नाम": "सुनापती गाउँपालिका",
        जिल्ला: "रामेछाप",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "sunapatimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    दोलखा: [
      {
        "क्र.स.": 243,
        "स्थानीय तहको नाम": "जिरी नगरपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "jirimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 244,
        "स्थानीय तहको नाम": "भिमेश्वर नगरपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bhimeshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 245,
        "स्थानीय तहको नाम": "कालिन्चोक गाउँपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kalinchowkmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 246,
        "स्थानीय तहको नाम": "गौरीशङ्कर गाउँपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "gaurishankarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 247,
        "स्थानीय तहको नाम": "तामाकोशी गाउँपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tamakoshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 248,
        "स्थानीय तहको नाम": "मेलुङ्ग गाउँपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "melungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 249,
        "स्थानीय तहको नाम": "विगु गाउँपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bigumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 250,
        "स्थानीय तहको नाम": "वैतेश्वर गाउँपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "baiteshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 251,
        "स्थानीय तहको नाम": "शैलुङ्ग गाउँपालिका",
        जिल्ला: "दोलखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "shailungmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    सिन्धुपाल्चोक: [
      {
        "क्र.स.": 252,
        "स्थानीय तहको नाम": "चौतारा साँगाचोकगढी नगरपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "chautarasangachowkgadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 253,
        "स्थानीय तहको नाम": "बाह्रविसे नगरपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bahrabisemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 254,
        "स्थानीय तहको नाम": "मेलम्ची नगरपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "melamchimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 255,
        "स्थानीय तहको नाम": "ईन्द्रावती गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "indrawatimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 256,
        "स्थानीय तहको नाम": "जुगल गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "jugalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 257,
        "स्थानीय तहको नाम": "पाँचपोखरी थाङपाल गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "panchpokharithangpalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 258,
        "स्थानीय तहको नाम": "बलेफी गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "balephimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 259,
        "स्थानीय तहको नाम": "भोटेकोशी गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bhotekoshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 260,
        "स्थानीय तहको नाम": "लिसङ्खु पाखर गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "lisankhupakharmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 261,
        "स्थानीय तहको नाम": "सुनकोशी गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "sunkoshimunsindhupalchowk.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 262,
        "स्थानीय तहको नाम": "हेलम्बु गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "helambumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 263,
        "स्थानीय तहको नाम": "त्रिपुरासुन्दरी गाउँपालिका",
        जिल्ला: "सिन्धुपाल्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tripurasundarimunsindhupalchowk.gov.np",
        कैफियत: "तयार",
      },
    ],
    काभ्रेपलान्चोक: [
      {
        "क्र.स.": 264,
        "स्थानीय तहको नाम": "धुलिखेल नगरपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "dhulikhelmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 265,
        "स्थानीय तहको नाम": "बनेपा नगरपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "banepamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 266,
        "स्थानीय तहको नाम": "पनौती नगरपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "panautimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 267,
        "स्थानीय तहको नाम": "पाँचखाल नगरपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "panchkhalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 268,
        "स्थानीय तहको नाम": "नमोबुद्ध नगरपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "namobuddhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 269,
        "स्थानीय तहको नाम": "मण्डनदेउपुर नगरपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "mandandeupurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 270,
        "स्थानीय तहको नाम": "खानीखोला गाउँपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "khanikholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 271,
        "स्थानीय तहको नाम": "चौंरीदेउराली गाउँपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "chaurideuralimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 272,
        "स्थानीय तहको नाम": "तेमाल गाउँपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "temalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 273,
        "स्थानीय तहको नाम": "बेथानचोक गाउँपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bethanchowkmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 274,
        "स्थानीय तहको नाम": "भुम्लु गाउँपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bhumlumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 275,
        "स्थानीय तहको नाम": "महाभारत गाँउपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "mahabharatmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 276,
        "स्थानीय तहको नाम": "रोशी गाउँपालिका",
        जिल्ला: "काभ्रेपलान्चोक",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "roshimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    ललितपुर: [
      {
        "क्र.स.": 277,
        "स्थानीय तहको नाम": "ललितपुर महानगरपालिका",
        जिल्ला: "ललितपुर",
        "स्थानीय तहको प्रकार": "महानगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "lalitpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 278,
        "स्थानीय तहको नाम": "गोदावरी नगरपालिका",
        जिल्ला: "ललितपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "godawarimunlalitpur.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 279,
        "स्थानीय तहको नाम": "महालक्ष्मी नगरपालिका",
        जिल्ला: "ललितपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "mahalaxmimunlalitpur.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 280,
        "स्थानीय तहको नाम": "कोन्ज्योसोम गाउँपालिका",
        जिल्ला: "ललितपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "konjyosommun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 281,
        "स्थानीय तहको नाम": "बागमती गाउँपालिका",
        जिल्ला: "ललितपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bagmatimunlalitpur.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 282,
        "स्थानीय तहको नाम": "महाङ्काल गाउँपालिका",
        जिल्ला: "ललितपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "mahankalmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    भक्तपुर: [
      {
        "क्र.स.": 283,
        "स्थानीय तहको नाम": "चाँगुनारायण नगरपालिका",
        जिल्ला: "भक्तपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "changunarayanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 284,
        "स्थानीय तहको नाम": "भक्तपुर नगरपालिका",
        जिल्ला: "भक्तपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bhaktapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 285,
        "स्थानीय तहको नाम": "मध्यपुर थिमी नगरपालिका",
        जिल्ला: "भक्तपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "madhyapurthimimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 286,
        "स्थानीय तहको नाम": "सूर्यविनायक नगरपालिका",
        जिल्ला: "भक्तपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "suryabinayakmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    काठमाण्डौ: [
      {
        "क्र.स.": 287,
        "स्थानीय तहको नाम": "काठमाण्डौं महानगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "महानगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kathmandu.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 288,
        "स्थानीय तहको नाम": "कागेश्वरी मनोहरा नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kageshworimanoharamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 289,
        "स्थानीय तहको नाम": "कीर्तिपुर नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kirtipurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 290,
        "स्थानीय तहको नाम": "गोकर्णेश्वर नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "gokarneshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 291,
        "स्थानीय तहको नाम": "चन्द्रागिरी नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "chandragirimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 292,
        "स्थानीय तहको नाम": "टोखा नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tokhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 293,
        "स्थानीय तहको नाम": "तारकेश्वर नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tarakeshwormunkathmandu.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 294,
        "स्थानीय तहको नाम": "दक्षिणकाली नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "dakshinkalimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 295,
        "स्थानीय तहको नाम": "नागार्जुन नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "nagarjunmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 296,
        "स्थानीय तहको नाम": "बुढानिलकण्ठ नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "budhanilkanthamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 297,
        "स्थानीय तहको नाम": "शङ्खरापुर नगरपालिका",
        जिल्ला: "काठमाण्डौ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "shankharapurmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    नुवाकोट: [
      {
        "क्र.स.": 298,
        "स्थानीय तहको नाम": "विदुर नगरपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bidurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 299,
        "स्थानीय तहको नाम": "बेलकोटगढी नगरपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "belkotgadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 300,
        "स्थानीय तहको नाम": "ककनी गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kakanimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 301,
        "स्थानीय तहको नाम": "किस्पाङ गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kispangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 302,
        "स्थानीय तहको नाम": "तादी गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tadimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 303,
        "स्थानीय तहको नाम": "तारकेश्वर गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tarakeshwormunnuwakot.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 304,
        "स्थानीय तहको नाम": "दुप्चेश्वर गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "dupcheshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 305,
        "स्थानीय तहको नाम": "पञ्चकन्या गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "panchakanyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 306,
        "स्थानीय तहको नाम": "लिखु गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "likhumunnuwakot.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 307,
        "स्थानीय तहको नाम": "म्यगङ गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "meghangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 308,
        "स्थानीय तहको नाम": "शिवपुरी गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "shivapurimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 309,
        "स्थानीय तहको नाम": "सुर्यगढी गाउँपालिका",
        जिल्ला: "नुवाकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "suryagadhimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    रसुवा: [
      {
        "क्र.स.": 310,
        "स्थानीय तहको नाम": "उत्तरगया गाउँपालिका",
        जिल्ला: "रसुवा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "uttargayamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 311,
        "स्थानीय तहको नाम": "कालिका गाउँपालिका",
        जिल्ला: "रसुवा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kalikamunrasuwa.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 312,
        "स्थानीय तहको नाम": "गोसाईकुण्ड गाउँपालिका",
        जिल्ला: "रसुवा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "gosaikundamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 313,
        "स्थानीय तहको नाम": "नौकुण्ड गाउँपालिका",
        जिल्ला: "रसुवा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "naukundamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 314,
        "स्थानीय तहको नाम": "आमाछोदिङमो गाउँपालिका",
        जिल्ला: "रसुवा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "parbatikundamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    धादिङ: [
      {
        "क्र.स.": 315,
        "स्थानीय तहको नाम": "धुनीबेंशी नगरपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "dhunibeshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 316,
        "स्थानीय तहको नाम": "निलकण्ठ नगरपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "neelakanthamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 317,
        "स्थानीय तहको नाम": "खनियाबास गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "khaniyabasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 318,
        "स्थानीय तहको नाम": "गजुरी गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "gajurimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 319,
        "स्थानीय तहको नाम": "गल्छी गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "galchhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 320,
        "स्थानीय तहको नाम": "गङ्गाजमुना गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "gangajamunamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 321,
        "स्थानीय तहको नाम": "ज्वालामूखी गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "jwalamukhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 322,
        "स्थानीय तहको नाम": "थाक्रे गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "thakremun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 323,
        "स्थानीय तहको नाम": "नेत्रावती डबजोङ गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "netrawatimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 324,
        "स्थानीय तहको नाम": "बेनीघाट रोराङ्ग गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "benighatrorangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 325,
        "स्थानीय तहको नाम": "रुवी भ्याली गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "rubivalleymun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 326,
        "स्थानीय तहको नाम": "सिद्धलेक गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "siddhalekmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 327,
        "स्थानीय तहको नाम": "त्रिपुरासुन्दरी गाउँपालिका",
        जिल्ला: "धादिङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "tripurasundarimundhading.gov.np",
        कैफियत: "तयार",
      },
    ],
    मकवानपुर: [
      {
        "क्र.स.": 328,
        "स्थानीय तहको नाम": "हेटौडा उपमहानगरपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "hetaudamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 329,
        "स्थानीय तहको नाम": "थाहा नगरपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "thahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 330,
        "स्थानीय तहको नाम": "इन्द्रसरोबर गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "indrasarowarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 331,
        "स्थानीय तहको नाम": "कैलाश गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kailashmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 332,
        "स्थानीय तहको नाम": "बकैया गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bakaiyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 333,
        "स्थानीय तहको नाम": "बाग्मति गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bagmatimunmakawanpur.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 334,
        "स्थानीय तहको नाम": "भिमफेदी गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bhimphedimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 335,
        "स्थानीय तहको नाम": "मकवानपुरगढी गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "makawanpurgadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 336,
        "स्थानीय तहको नाम": "मनहरी गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "manaharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 337,
        "स्थानीय तहको नाम": "राक्सिराङ्ग गाउँपालिका",
        जिल्ला: "मकवानपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "raksirangmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    चितवन: [
      {
        "क्र.स.": 386,
        "स्थानीय तहको नाम": "भरतपुर महानगरपालिका",
        जिल्ला: "चितवन",
        "स्थानीय तहको प्रकार": "महानगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "bharatpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 387,
        "स्थानीय तहको नाम": "कालिका नगरपालिका",
        जिल्ला: "चितवन",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "kalikamunchitwan.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 388,
        "स्थानीय तहको नाम": "खैरहनी नगरपालिका",
        जिल्ला: "चितवन",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "khairhanimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 389,
        "स्थानीय तहको नाम": "माडी नगरपालिका",
        जिल्ला: "चितवन",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "madimunchitwan.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 390,
        "स्थानीय तहको नाम": "रत्ननगर नगरपालिका",
        जिल्ला: "चितवन",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "ratnanagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 391,
        "स्थानीय तहको नाम": "राप्ती नगरपालिका",
        जिल्ला: "चितवन",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "raptimunchitwan.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 392,
        "स्थानीय तहको नाम": "इच्छाकामना गाउँपालिका",
        जिल्ला: "चितवन",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "बागमती प्रदेश",
        वेवसाईट: "ichchhakamanamun.gov.np",
        कैफियत: "तयार",
      },
    ],
  },
  "गण्डकी प्रदेश": {
    गोरखा: [
      {
        "क्र.स.": 393,
        "स्थानीय तहको नाम": "गोरखा नगरपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "gorkhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 394,
        "स्थानीय तहको नाम": "पालुङटार नगरपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "palungtarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 395,
        "स्थानीय तहको नाम": "बारपाक सुलिकोट गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "barpaksulikotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 396,
        "स्थानीय तहको नाम": "सिरानचोक गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "siranchowkmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 397,
        "स्थानीय तहको नाम": "अजिरकोट गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "ajirkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 398,
        "स्थानीय तहको नाम": "आरूघाट गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "aarughatmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 399,
        "स्थानीय तहको नाम": "गण्डकी गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "gandakimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 400,
        "स्थानीय तहको नाम": "चुमनुव्री गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "chumanuwrimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 401,
        "स्थानीय तहको नाम": "धार्चे गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "dharchemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 402,
        "स्थानीय तहको नाम": "भिमसेनथापा गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bhimsenmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 403,
        "स्थानीय तहको नाम": "शहिद लखन गाउँपालिका",
        जिल्ला: "गोरखा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "shahidlakhanmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    लमजुङ: [
      {
        "क्र.स.": 404,
        "स्थानीय तहको नाम": "बेसीशहर नगरपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "besishaharmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 405,
        "स्थानीय तहको नाम": "मध्यनेपाल नगरपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "madhyanepalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 406,
        "स्थानीय तहको नाम": "रार्इनास नगरपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "rainasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 407,
        "स्थानीय तहको नाम": "सुन्दरबजार नगरपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "sundarbazarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 408,
        "स्थानीय तहको नाम": "क्व्होलासोथार गाउँपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "kwholasotharmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 409,
        "स्थानीय तहको नाम": "दूधपोखरी गाउँपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "dudhpokharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 410,
        "स्थानीय तहको नाम": "दोर्दी गाउँपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "dordimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 411,
        "स्थानीय तहको नाम": "मर्स्याङदी गाउँपालिका",
        जिल्ला: "लमजुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "marsyangdimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    तनहुँ: [
      {
        "क्र.स.": 412,
        "स्थानीय तहको नाम": "भानु नगरपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bhanumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 413,
        "स्थानीय तहको नाम": "भिमाद नगरपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bhimadmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 414,
        "स्थानीय तहको नाम": "व्यास नगरपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "vyasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 415,
        "स्थानीय तहको नाम": "शुक्लागण्डकी नगरपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "shuklagandakimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 416,
        "स्थानीय तहको नाम": "आँबुखैरेनी गाउँपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "aanbookhairenimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 417,
        "स्थानीय तहको नाम": "ऋषिङ्ग गाउँपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "rishingmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 418,
        "स्थानीय तहको नाम": "घिरिङ गाउँपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "ghiringmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 419,
        "स्थानीय तहको नाम": "देवघाट गाउँपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "devghatmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 420,
        "स्थानीय तहको नाम": "म्याग्दे गाउँपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "myagdemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 421,
        "स्थानीय तहको नाम": "वन्दिपुर गाउँपालिका",
        जिल्ला: "तनहुँ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bandipurmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    स्याङजा: [
      {
        "क्र.स.": 422,
        "स्थानीय तहको नाम": "गल्याङ नगरपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "galyangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 423,
        "स्थानीय तहको नाम": "चापाकोट नगरपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "chapakotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 424,
        "स्थानीय तहको नाम": "पुतलीबजार नगरपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "putalibazarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 425,
        "स्थानीय तहको नाम": "भीरकोट नगरपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bheerkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 426,
        "स्थानीय तहको नाम": "वालिङ नगरपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "walingmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 427,
        "स्थानीय तहको नाम": "अर्जुनचौपारी गाउँपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "arjunchauparimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 428,
        "स्थानीय तहको नाम": "आँधिखोला गाउँपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "aandhikholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 429,
        "स्थानीय तहको नाम": "कालीगण्डकी गाउँपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "kaligandakimunsyangja.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 430,
        "स्थानीय तहको नाम": "फेदीखोला गाउँपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "phedikholamun.gov.np",
        कैफियत: "-",
      },
      {
        "क्र.स.": 431,
        "स्थानीय तहको नाम": "बिरुवा गाउँपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "biruwamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 432,
        "स्थानीय तहको नाम": "हरिनास गाउँपालिका",
        जिल्ला: "स्याङजा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "harinasmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    कास्की: [
      {
        "क्र.स.": 433,
        "स्थानीय तहको नाम": "पोखरा महानगरपालिका",
        जिल्ला: "कास्की",
        "स्थानीय तहको प्रकार": "महानगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "pokharamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 434,
        "स्थानीय तहको नाम": "अन्नपूर्ण गाउँपालिका",
        जिल्ला: "कास्की",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "annapurnamunkaski.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 435,
        "स्थानीय तहको नाम": "माछापुच्छ्रे गाउँपालिका",
        जिल्ला: "कास्की",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "machhapuchhremun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 436,
        "स्थानीय तहको नाम": "मादी गाउँपालिका",
        जिल्ला: "कास्की",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "madimunkaski.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 437,
        "स्थानीय तहको नाम": "रूपा गाउँपालिका",
        जिल्ला: "कास्की",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "rupamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    मनाङ: [
      {
        "क्र.स.": 438,
        "स्थानीय तहको नाम": "चामे गाउँपालिका",
        जिल्ला: "मनाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "chamemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 439,
        "स्थानीय तहको नाम": "नार्पा भूमि गाउँपालिका",
        जिल्ला: "मनाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "narpabhumimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 440,
        "स्थानीय तहको नाम": "नासोँ गाउँपालिका",
        जिल्ला: "मनाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "nasonmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 441,
        "स्थानीय तहको नाम": "मनाङ ङिस्याङ गाउँपालिका",
        जिल्ला: "मनाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "manangngisyangmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    मुस्ताङ: [
      {
        "क्र.स.": 442,
        "स्थानीय तहको नाम": "घरपझोङ गाउँपालिका",
        जिल्ला: "मुस्ताङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "gharapjhongmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 443,
        "स्थानीय तहको नाम": "थासाङ गाउँपालिका",
        जिल्ला: "मुस्ताङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "thasangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 444,
        "स्थानीय तहको नाम": "लो-घेकर दामोदरकुण्ड गाउँपालिका",
        जिल्ला: "मुस्ताङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "dalomemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 445,
        "स्थानीय तहको नाम": "लोमन्थाङ गाउँपालिका",
        जिल्ला: "मुस्ताङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "lomanthangmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 446,
        "स्थानीय तहको नाम": "वारागुङ मुक्तिक्षेत्र गाउँपालिका",
        जिल्ला: "मुस्ताङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bahragaumuktichhetramun.gov.np",
        कैफियत: "तयार",
      },
    ],
    म्याग्दी: [
      {
        "क्र.स.": 447,
        "स्थानीय तहको नाम": "बेनी नगरपालिका",
        जिल्ला: "म्याग्दी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "benimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 448,
        "स्थानीय तहको नाम": "अन्नपुर्ण गाउँपालिका",
        जिल्ला: "म्याग्दी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "annapurnamunmyagdi.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 449,
        "स्थानीय तहको नाम": "धवलागिरी गाउँपालिका",
        जिल्ला: "म्याग्दी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "dhawalagirimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 450,
        "स्थानीय तहको नाम": "मंगला गाउँपालिका",
        जिल्ला: "म्याग्दी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "mangalamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 451,
        "स्थानीय तहको नाम": "मालिका गाउँपालिका",
        जिल्ला: "म्याग्दी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "malikamunmyagdi.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 452,
        "स्थानीय तहको नाम": "रघुगंगा गाउँपालिका",
        जिल्ला: "म्याग्दी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "raghugangamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    पर्वत: [
      {
        "क्र.स.": 453,
        "स्थानीय तहको नाम": "कुश्मा नगरपालिका",
        जिल्ला: "पर्वत",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "kushmamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 454,
        "स्थानीय तहको नाम": "फलेवास नगरपालिका",
        जिल्ला: "पर्वत",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "phalewasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 455,
        "स्थानीय तहको नाम": "जलजला गाउँपालिका",
        जिल्ला: "पर्वत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "jaljalamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 456,
        "स्थानीय तहको नाम": "पैयूं गाउँपालिका",
        जिल्ला: "पर्वत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "paiyunmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 457,
        "स्थानीय तहको नाम": "महाशिला गाउँपालिका",
        जिल्ला: "पर्वत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "mahashilamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 458,
        "स्थानीय तहको नाम": "मोदी गाउँपालिका",
        जिल्ला: "पर्वत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "modimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 459,
        "स्थानीय तहको नाम": "विहादी गाउँपालिका",
        जिल्ला: "पर्वत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bihadimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    वाग्लुङ: [
      {
        "क्र.स.": 460,
        "स्थानीय तहको नाम": "बागलुङ नगरपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "baglungmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 461,
        "स्थानीय तहको नाम": "गल्कोट नगरपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "galkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 462,
        "स्थानीय तहको नाम": "जैमूनी नगरपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "jaiminimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 463,
        "स्थानीय तहको नाम": "ढोरपाटन नगरपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "dhorpatanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 464,
        "स्थानीय तहको नाम": "वरेङ गाउँपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "barengmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 465,
        "स्थानीय तहको नाम": "काठेखोला गाउँपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "kathekholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 466,
        "स्थानीय तहको नाम": "तमानखोला गाउँपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "tamankholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 467,
        "स्थानीय तहको नाम": "ताराखोला गाउँपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "tarakholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 468,
        "स्थानीय तहको नाम": "निसीखोला गाउँपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "nisikholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 469,
        "स्थानीय तहको नाम": "वडिगाड गाउँपालिका",
        जिल्ला: "वाग्लुङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "badigadmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    "नवलपरासी (बर्दघाट सुस्ता पूर्व)": [
      {
        "क्र.स.": 739,
        "स्थानीय तहको नाम": "कावासोती नगरपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "kawasotimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 740,
        "स्थानीय तहको नाम": "गैडाकोट नगरपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "gaindakotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 741,
        "स्थानीय तहको नाम": "देवचुली नगरपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "devchulimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 742,
        "स्थानीय तहको नाम": "मध्यविन्दु नगरपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "madhyabindumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 743,
        "स्थानीय तहको नाम": "बौदीकाली गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bungdikalimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 744,
        "स्थानीय तहको नाम": "बुलिङटार गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "bulingtarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 745,
        "स्थानीय तहको नाम": "विनयी त्रिवेणी गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "binayitribenimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 746,
        "स्थानीय तहको नाम": "हुप्सेकोट गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पूर्व)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "गण्डकी प्रदेश",
        वेवसाईट: "hupsekotmun.gov.np",
        कैफियत: "तयार",
      },
    ],
  },
  "लुम्बिनी प्रदेश": {
    गुल्मी: [
      {
        "क्र.स.": 470,
        "स्थानीय तहको नाम": "मुसिकोट नगरपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "musikotmungulmi.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 471,
        "स्थानीय तहको नाम": "रेसुङ्गा नगरपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "resungamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 472,
        "स्थानीय तहको नाम": "ईस्मा गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "ismamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 473,
        "स्थानीय तहको नाम": "कालीगण्डकी गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "kaligandakimungulmi.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 474,
        "स्थानीय तहको नाम": "गुल्मी दरबार गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "gulmidarbarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 475,
        "स्थानीय तहको नाम": "सत्यवती गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "satyawatimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 476,
        "स्थानीय तहको नाम": "चन्द्रकोट गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "chandrakotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 477,
        "स्थानीय तहको नाम": "रुरुक्षेत्र गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rurumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 478,
        "स्थानीय तहको नाम": "छत्रकोट गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "chhatrakotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 479,
        "स्थानीय तहको नाम": "धुर्कोट गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "dhurkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 480,
        "स्थानीय तहको नाम": "मदाने गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "madanemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 481,
        "स्थानीय तहको नाम": "मालिका गाउँपालिका",
        जिल्ला: "गुल्मी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "malikamungulmi.gov.np",
        कैफियत: "तयार",
      },
    ],
    पाल्पा: [
      {
        "क्र.स.": 482,
        "स्थानीय तहको नाम": "रामपुर नगरपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rampurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 483,
        "स्थानीय तहको नाम": "तानसेन नगरपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "tansenmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 484,
        "स्थानीय तहको नाम": "निस्दी गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "nisdimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 485,
        "स्थानीय तहको नाम": "पूर्वखोला गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "purbakholamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 486,
        "स्थानीय तहको नाम": "रम्भा गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rambhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 487,
        "स्थानीय तहको नाम": "माथागढी गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "mathagadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 488,
        "स्थानीय तहको नाम": "तिनाउ गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "tinaumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 489,
        "स्थानीय तहको नाम": "बगनासकाली गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "baganaskalimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 490,
        "स्थानीय तहको नाम": "रिब्दिकोट गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "ribdikotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 491,
        "स्थानीय तहको नाम": "रैनादेवी छहरा गाउँपालिका",
        जिल्ला: "पाल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rainadevichhaharamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    रुपन्देही: [
      {
        "क्र.स.": 492,
        "स्थानीय तहको नाम": "बुटवल उपमहानगरपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "butwalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 493,
        "स्थानीय तहको नाम": "देवदह नगरपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "devdahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 494,
        "स्थानीय तहको नाम": "लुम्बिनी सांस्कृतिक नगरपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "lumbinisanskritikmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 495,
        "स्थानीय तहको नाम": "सैनामैना नगरपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sainamainamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 496,
        "स्थानीय तहको नाम": "सिद्धार्थनगर नगरपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "siddharthanagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 497,
        "स्थानीय तहको नाम": "तिलोत्तमा नगरपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "tilottamamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 498,
        "स्थानीय तहको नाम": "गैडहवा गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "gaidahawamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 499,
        "स्थानीय तहको नाम": "कन्चन गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "kanchanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 500,
        "स्थानीय तहको नाम": "कोटहीमाई गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "kotahimaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 501,
        "स्थानीय तहको नाम": "मर्चवारी गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "marchawarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 502,
        "स्थानीय तहको नाम": "मायादेवी गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "mayadevimunrupandehi.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 503,
        "स्थानीय तहको नाम": "ओमसतिया गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "omsatiyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 504,
        "स्थानीय तहको नाम": "रोहिणी गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rohinimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 505,
        "स्थानीय तहको नाम": "सम्मरीमाई गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sammarimaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 506,
        "स्थानीय तहको नाम": "सियारी गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "siyarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 507,
        "स्थानीय तहको नाम": "शुद्धोधन गाउँपालिका",
        जिल्ला: "रुपन्देही",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "shuddhodhanmunrupandehi.gov.np",
        कैफियत: "तयार",
      },
    ],
    कपिलबस्तु: [
      {
        "क्र.स.": 508,
        "स्थानीय तहको नाम": "कपिलवस्तु नगरपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "kapilvastumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 509,
        "स्थानीय तहको नाम": "बुद्धभुमी नगरपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "buddhabhumimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 510,
        "स्थानीय तहको नाम": "शिवराज नगरपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "shivrajmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 511,
        "स्थानीय तहको नाम": "महाराजगंज नगरपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "maharajgunjmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 512,
        "स्थानीय तहको नाम": "कृष्णनगर नगरपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "krishnanagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 513,
        "स्थानीय तहको नाम": "बाणगंगा नगरपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "bangangamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 514,
        "स्थानीय तहको नाम": "मायादेवी गाउँपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "mayadevimunkapilvastu.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 515,
        "स्थानीय तहको नाम": "यसोधरा गाउँपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "yasodharamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 516,
        "स्थानीय तहको नाम": "सुद्धोधन गाउँपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "shuddhodhanmunkapilvastu.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 517,
        "स्थानीय तहको नाम": "विजयनगर गाउँपालिका",
        जिल्ला: "कपिलबस्तु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "bijaynagarmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    अर्घाखाँची: [
      {
        "क्र.स.": 518,
        "स्थानीय तहको नाम": "सन्धिखर्क नगरपालिका",
        जिल्ला: "अर्घाखाँची",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sandhikharkamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 519,
        "स्थानीय तहको नाम": "शितगंगा नगरपालिका",
        जिल्ला: "अर्घाखाँची",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "shitagangamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 520,
        "स्थानीय तहको नाम": "भूमिकास्थान नगरपालिका",
        जिल्ला: "अर्घाखाँची",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "bhumikasthanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 521,
        "स्थानीय तहको नाम": "छत्रदेव गाउँपालिका",
        जिल्ला: "अर्घाखाँची",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "chhatradevmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 522,
        "स्थानीय तहको नाम": "पाणिनी गाउँपालिका",
        जिल्ला: "अर्घाखाँची",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "paninimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 523,
        "स्थानीय तहको नाम": "मालारानी गाउँपालिका",
        जिल्ला: "अर्घाखाँची",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "malaranimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    प्यूठान: [
      {
        "क्र.स.": 524,
        "स्थानीय तहको नाम": "प्यूठान नगरपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "pyuthanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 525,
        "स्थानीय तहको नाम": "स्वर्गद्वारी नगरपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "swargadwarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 526,
        "स्थानीय तहको नाम": "गौमुखी गाउँपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "gaumukhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 527,
        "स्थानीय तहको नाम": "माण्डवी गाउँपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "mandavimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 528,
        "स्थानीय तहको नाम": "सरुमारानी गाउँपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sarumaranimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 529,
        "स्थानीय तहको नाम": "मल्लरानी गाउँपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "mallaranimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 530,
        "स्थानीय तहको नाम": "नौवहिनी गाउँपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "naubahinimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 531,
        "स्थानीय तहको नाम": "झिमरुक गाउँपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "jhimrukmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 532,
        "स्थानीय तहको नाम": "ऐरावती गाउँपालिका",
        जिल्ला: "प्यूठान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "airawatimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    रोल्पा: [
      {
        "क्र.स.": 533,
        "स्थानीय तहको नाम": "रोल्पा नगरपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rolpamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 534,
        "स्थानीय तहको नाम": "त्रिवेणी गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "trivenimunrolpa.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 535,
        "स्थानीय तहको नाम": "परिवर्तन गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "duikholimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 536,
        "स्थानीय तहको नाम": "माडी गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "madimunrolpa.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 537,
        "स्थानीय तहको नाम": "रुन्टीगढी गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "runtigadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 538,
        "स्थानीय तहको नाम": "लुङग्री गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "lungrimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 539,
        "स्थानीय तहको नाम": "गंगादेव गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sukidahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 540,
        "स्थानीय तहको नाम": "सुनछहरी गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sunchhaharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 541,
        "स्थानीय तहको नाम": "सुनिल स्मृति गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sunilsmritimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 542,
        "स्थानीय तहको नाम": "थवाङ गाउँपालिका",
        जिल्ला: "रोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "thabangmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    "रुकुम (पूर्वी भाग)": [
      {
        "क्र.स.": 546,
        "स्थानीय तहको नाम": "पुथा उत्तरगंगा गाउँपालिका",
        जिल्ला: "रुकुम (पूर्वी भाग)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "puthauttargangamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 547,
        "स्थानीय तहको नाम": "भूमे गाउँपालिका",
        जिल्ला: "रुकुम (पूर्वी भाग)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "bhumemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 548,
        "स्थानीय तहको नाम": "सिस्ने गाउँपालिका",
        जिल्ला: "रुकुम (पूर्वी भाग)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sisnemun.gov.np",
        कैफियत: "तयार",
      },
    ],
    दाङ: [
      {
        "क्र.स.": 562,
        "स्थानीय तहको नाम": "तुल्सीपुर उपमहानगरपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "tulsipurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 563,
        "स्थानीय तहको नाम": "घोराही उपमहानगरपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "ghorahimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 564,
        "स्थानीय तहको नाम": "लमही नगरपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "lamahimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 565,
        "स्थानीय तहको नाम": "बंगलाचुली गाउँपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "bangalachulimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 566,
        "स्थानीय तहको नाम": "दंगीशरण गाउँपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "dangisharanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 567,
        "स्थानीय तहको नाम": "गढवा गाउँपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "gadhawamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 568,
        "स्थानीय तहको नाम": "राजपुर गाउँपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rajpurmundang.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 569,
        "स्थानीय तहको नाम": "राप्ती गाउँपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "raptimundang.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 570,
        "स्थानीय तहको नाम": "शान्तिनगर गाउँपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "shantinagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 571,
        "स्थानीय तहको नाम": "बबई गाउँपालिका",
        जिल्ला: "दाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "babaimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    बाँके: [
      {
        "क्र.स.": 572,
        "स्थानीय तहको नाम": "नेपालगंज उपमहानगरपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "nepalgunjmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 573,
        "स्थानीय तहको नाम": "कोहलपुर नगरपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "kohalpurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 574,
        "स्थानीय तहको नाम": "नरैनापुर गाउँपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "narainapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 575,
        "स्थानीय तहको नाम": "राप्ती सोनारी गाउँपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "raptisonarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 576,
        "स्थानीय तहको नाम": "बैजनाथ गाउँपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "baijanathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 577,
        "स्थानीय तहको नाम": "खजुरा गाउँपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "khajuramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 578,
        "स्थानीय तहको नाम": "डुडुवा गाउँपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "duduwamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 579,
        "स्थानीय तहको नाम": "जानकी गाउँपालिका",
        जिल्ला: "बाँके",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "janakimunbanke.gov.np",
        कैफियत: "तयार",
      },
    ],
    बर्दिया: [
      {
        "क्र.स.": 580,
        "स्थानीय तहको नाम": "गुलरिया नगरपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "gulariyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 581,
        "स्थानीय तहको नाम": "मधुवन नगरपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "madhuwanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 582,
        "स्थानीय तहको नाम": "राजापुर नगरपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "rajapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 583,
        "स्थानीय तहको नाम": "ठाकुरबाबा नगरपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "thakurbabamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 584,
        "स्थानीय तहको नाम": "बाँसगढी नगरपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "bansgadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 585,
        "स्थानीय तहको नाम": "बारबर्दिया नगरपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "barbardiyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 586,
        "स्थानीय तहको नाम": "बढैयाताल गाउँपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "badhaiyatalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 587,
        "स्थानीय तहको नाम": "गेरुवा गाउँपालिका",
        जिल्ला: "बर्दिया",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "geruwamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    "नवलपरासी (बर्दघाट सुस्ता पश्चिम)": [
      {
        "क्र.स.": 747,
        "स्थानीय तहको नाम": "बर्दघाट नगरपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "bardghatmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 748,
        "स्थानीय तहको नाम": "रामग्राम नगरपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "ramgrammun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 749,
        "स्थानीय तहको नाम": "सुनवल नगरपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sunwalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 750,
        "स्थानीय तहको नाम": "सुस्ता गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sustamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 751,
        "स्थानीय तहको नाम": "पाल्हीनन्दन गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "palhinandanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 752,
        "स्थानीय तहको नाम": "प्रतापपुर गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "pratappurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 753,
        "स्थानीय तहको नाम": "सरावल गाउँपालिका",
        जिल्ला: "नवलपरासी (बर्दघाट सुस्ता पश्चिम)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "लुम्बिनी प्रदेश",
        वेवसाईट: "sarawalmun.gov.np",
        कैफियत: "तयार",
      },
    ],
  },
  "कर्णाली प्रदेश": {
    "रुकुम (पश्चिम भाग)": [
      {
        "क्र.स.": 543,
        "स्थानीय तहको नाम": "मुसिकोट नगरपालिका",
        जिल्ला: "रुकुम (पश्चिम भाग)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "musikotmunrukum.gov.np",
        कैफियत: "तयाार",
      },
      {
        "क्र.स.": 544,
        "स्थानीय तहको नाम": "चौरजहारी नगरपालिका",
        जिल्ला: "रुकुम (पश्चिम भाग)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chaurjaharimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 545,
        "स्थानीय तहको नाम": "आठबिसकोट नगरपालिका",
        जिल्ला: "रुकुम (पश्चिम भाग)",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "aathbiskotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 549,
        "स्थानीय तहको नाम": "बाँफिकोट गाउँपालिका",
        जिल्ला: "रुकुम (पश्चिम भाग)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "banphikotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 550,
        "स्थानीय तहको नाम": "त्रिवेणी गाउँपालिका",
        जिल्ला: "रुकुम (पश्चिम भाग)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "trivenimunrukum.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 551,
        "स्थानीय तहको नाम": "सानी भेरी गाउँपालिका",
        जिल्ला: "रुकुम (पश्चिम भाग)",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "sanibherimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    सल्यान: [
      {
        "क्र.स.": 552,
        "स्थानीय तहको नाम": "शारदा नगरपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "shaaradamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 553,
        "स्थानीय तहको नाम": "बागचौर नगरपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "bagchaurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 554,
        "स्थानीय तहको नाम": "बनगाड कुपिण्डे नगरपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "bangadkupindemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 555,
        "स्थानीय तहको नाम": "कालिमाटी गाउँपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kalimatimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 556,
        "स्थानीय तहको नाम": "त्रिवेणी गाउँपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "trivenimunsalyan.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 557,
        "स्थानीय तहको नाम": "कपुरकोट गाउँपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kapurkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 558,
        "स्थानीय तहको नाम": "छत्रेश्वरी गाउँपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chhatreshworimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 559,
        "स्थानीय तहको नाम": "सिद्ध कुमाख गाउँपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "siddhakumakhmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 560,
        "स्थानीय तहको नाम": "कुमाख गाउँपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kumakhmalikamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 561,
        "स्थानीय तहको नाम": "दार्मा गाउँपालिका",
        जिल्ला: "सल्यान",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "darmamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    सुर्खेत: [
      {
        "क्र.स.": 588,
        "स्थानीय तहको नाम": "बीरेन्द्रनगर नगरपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "birendranagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 589,
        "स्थानीय तहको नाम": "भेरीगंगा नगरपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "bherigangamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 590,
        "स्थानीय तहको नाम": "गुर्भाकोट नगरपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "gurbhakotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 591,
        "स्थानीय तहको नाम": "पञ्चपुरी नगरपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "panchapurimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 592,
        "स्थानीय तहको नाम": "लेकवेशी नगरपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "lekbeshimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 593,
        "स्थानीय तहको नाम": "चौकुने गाउँपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chaukunemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 594,
        "स्थानीय तहको नाम": "बराहताल गाउँपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "barahatalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 595,
        "स्थानीय तहको नाम": "चिङ्गाड गाउँपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chingadmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 596,
        "स्थानीय तहको नाम": "सिम्ता गाउँपालिका",
        जिल्ला: "सुर्खेत",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "simtamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    दैलेख: [
      {
        "क्र.स.": 597,
        "स्थानीय तहको नाम": "नारायण नगरपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "narayanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 598,
        "स्थानीय तहको नाम": "दुल्लु नगरपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "dullumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 599,
        "स्थानीय तहको नाम": "चामुण्डा विन्द्रासैनी नगरपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chamundabindrasainimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 600,
        "स्थानीय तहको नाम": "आठबीस नगरपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "aathabismun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 601,
        "स्थानीय तहको नाम": "भगवतीमाई गाउँपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "bhagawatimaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 602,
        "स्थानीय तहको नाम": "गुराँस गाउँपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "guransmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 603,
        "स्थानीय तहको नाम": "डुंगेश्वर गाउँपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "dungeshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 604,
        "स्थानीय तहको नाम": "नौमुले गाउँपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "naumulemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 605,
        "स्थानीय तहको नाम": "महावु गाउँपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "mahabumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 606,
        "स्थानीय तहको नाम": "भैरवी गाउँपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "bhairabimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 607,
        "स्थानीय तहको नाम": "ठाँटीकाँध गाउँपालिका",
        जिल्ला: "दैलेख",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "thantikandhmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    जाजरकोट: [
      {
        "क्र.स.": 608,
        "स्थानीय तहको नाम": "भेरी नगरपालिका",
        जिल्ला: "जाजरकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "bherimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 609,
        "स्थानीय तहको नाम": "छेडागाड नगरपालिका",
        जिल्ला: "जाजरकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chhedagadmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 610,
        "स्थानीय तहको नाम": "नलगाड नगरपालिका",
        जिल्ला: "जाजरकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "tribeninalgaadmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 611,
        "स्थानीय तहको नाम": "बारेकोट गाउँपालिका",
        जिल्ला: "जाजरकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "barekotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 612,
        "स्थानीय तहको नाम": "कुसे गाउँपालिका",
        जिल्ला: "जाजरकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kushemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 613,
        "स्थानीय तहको नाम": "जुनीचाँदे गाउँपालिका",
        जिल्ला: "जाजरकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "junichaandemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 614,
        "स्थानीय तहको नाम": "शिवालय गाउँपालिका",
        जिल्ला: "जाजरकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "shibalayamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    डोल्पा: [
      {
        "क्र.स.": 615,
        "स्थानीय तहको नाम": "ठूली भेरी नगरपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "thulibherimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 616,
        "स्थानीय तहको नाम": "त्रिपुरासुन्दरी नगरपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "tripurasundarimundolpa.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 617,
        "स्थानीय तहको नाम": "डोल्पो बुद्ध गाउँपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "dolpobuddhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 618,
        "स्थानीय तहको नाम": "शे फोक्सुन्डो गाउँपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "shephoksundomun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 619,
        "स्थानीय तहको नाम": "जगदुल्ला गाउँपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "jagdullamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 620,
        "स्थानीय तहको नाम": "मुड्केचुला गाउँपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "mudkechulamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 621,
        "स्थानीय तहको नाम": "काईके गाउँपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kaikemun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 622,
        "स्थानीय तहको नाम": "छार्का ताङसोङ गाउँपालिका",
        जिल्ला: "डोल्पा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chharkatangsongmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    जुम्ला: [
      {
        "क्र.स.": 623,
        "स्थानीय तहको नाम": "चन्दननाथ नगरपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chandannathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 624,
        "स्थानीय तहको नाम": "कनकासुन्दरी गाउँपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kankasundarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 625,
        "स्थानीय तहको नाम": "सिंजा गाउँपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "sinjamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 626,
        "स्थानीय तहको नाम": "हिमा गाउँपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "himamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 627,
        "स्थानीय तहको नाम": "तिला गाउँपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "tilamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 628,
        "स्थानीय तहको नाम": "गुठिचौर गाउँपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "guthichaurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 629,
        "स्थानीय तहको नाम": "तातोपानी गाउँपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "tatopanimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 630,
        "स्थानीय तहको नाम": "पातारासी गाउँपालिका",
        जिल्ला: "जुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "patarasimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    कालिकोट: [
      {
        "क्र.स.": 631,
        "स्थानीय तहको नाम": "खाँडाचक्र नगरपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "khandachakramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 632,
        "स्थानीय तहको नाम": "रास्कोट नगरपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "raskotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 633,
        "स्थानीय तहको नाम": "तिलागुफा नगरपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "tilagufamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 634,
        "स्थानीय तहको नाम": "पचालझरना गाउँपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "pachaljharanamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 635,
        "स्थानीय तहको नाम": "सान्नी त्रिवेणी गाउँपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "sannitrivenimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 636,
        "स्थानीय तहको नाम": "नरहरिनाथ गाउँपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "narharinathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 637,
        "स्थानीय तहको नाम": "शुभ कालीका गाउँपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kalikamunkalikot.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 638,
        "स्थानीय तहको नाम": "महावै गाउँपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "mahawaimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 639,
        "स्थानीय तहको नाम": "पलाता गाउँपालिका",
        जिल्ला: "कालिकोट",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "palatamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    मुगु: [
      {
        "क्र.स.": 640,
        "स्थानीय तहको नाम": "छायाँनाथ रारा नगरपालिका",
        जिल्ला: "मुगु",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chhayanathraramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 641,
        "स्थानीय तहको नाम": "मुगुम कार्मारोंग गाउँपालिका",
        जिल्ला: "मुगु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "mugumkarmarongmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 642,
        "स्थानीय तहको नाम": "सोरु गाउँपालिका",
        जिल्ला: "मुगु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "sorumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 643,
        "स्थानीय तहको नाम": "खत्याड गाउँपालिका",
        जिल्ला: "मुगु",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "khatyadmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    हुम्ला: [
      {
        "क्र.स.": 644,
        "स्थानीय तहको नाम": "सिमकोट गाउँपालिका",
        जिल्ला: "हुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "simkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 645,
        "स्थानीय तहको नाम": "नाम्खा गाउँपालिका",
        जिल्ला: "हुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "namkhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 646,
        "स्थानीय तहको नाम": "खार्पुनाथ गाउँपालिका",
        जिल्ला: "हुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "kharpunathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 647,
        "स्थानीय तहको नाम": "सर्केगाड गाउँपालिका",
        जिल्ला: "हुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "sarkegadmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 648,
        "स्थानीय तहको नाम": "चंखेली गाउँपालिका",
        जिल्ला: "हुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "chankhelimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 649,
        "स्थानीय तहको नाम": "अदानचुली गाउँपालिका",
        जिल्ला: "हुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "adanchulimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 650,
        "स्थानीय तहको नाम": "ताँजाकोट गाउँपालिका",
        जिल्ला: "हुम्ला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "कर्णाली प्रदेश",
        वेवसाईट: "tajakotmun.gov.np",
        कैफियत: "तयार",
      },
    ],
  },
  "सुदूरपश्चिम प्रदेश": {
    बाजुरा: [
      {
        "क्र.स.": 651,
        "स्थानीय तहको नाम": "बडीमालिका नगरपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "badimalikamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 652,
        "स्थानीय तहको नाम": "त्रिवेणी नगरपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "trivenimunbajura.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 653,
        "स्थानीय तहको नाम": "बुढीगंगा नगरपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "budhigangamunbajura.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 654,
        "स्थानीय तहको नाम": "बुढीनन्दा नगरपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "budhinandamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 655,
        "स्थानीय तहको नाम": "गौमुल गाउँपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "gaumulmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 656,
        "स्थानीय तहको नाम": "जगन्‍नाथ गाउँपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "jagganathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 657,
        "स्थानीय तहको नाम": "स्वामीकार्तिक खापर गाउँपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "swamikartikmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 658,
        "स्थानीय तहको नाम": "खप्तड छेडेदह गाउँपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "chhededahamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 659,
        "स्थानीय तहको नाम": "हिमाली गाउँपालिका",
        जिल्ला: "बाजुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "himalimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    बझाङ: [
      {
        "क्र.स.": 660,
        "स्थानीय तहको नाम": "जयपृथ्वी नगरपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "jayaprithvimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 661,
        "स्थानीय तहको नाम": "बुंगल नगरपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bungalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 662,
        "स्थानीय तहको नाम": "तलकोट गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "talkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 663,
        "स्थानीय तहको नाम": "मष्टा गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "mastamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 664,
        "स्थानीय तहको नाम": "खप्तडछान्ना गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "khaptadchhannamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 665,
        "स्थानीय तहको नाम": "थलारा गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "thalaramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 666,
        "स्थानीय तहको नाम": "वित्थडचिर गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bitthadchirmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 667,
        "स्थानीय तहको नाम": "सूर्मा गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "surmamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 668,
        "स्थानीय तहको नाम": "छबिसपाथिभेरा गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "chhabispathiveramun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 669,
        "स्थानीय तहको नाम": "दुर्गाथली गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "durgathalimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 670,
        "स्थानीय तहको नाम": "केदारस्युँ गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "kedarasyumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 671,
        "स्थानीय तहको नाम": "साइपाल गाउँपालिका",
        जिल्ला: "बझाङ",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "kandamun.gov.np",
        कैफियत: "तयार",
      },
    ],
    अछाम: [
      {
        "क्र.स.": 672,
        "स्थानीय तहको नाम": "मंगलसेन नगरपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "mangalsenmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 673,
        "स्थानीय तहको नाम": "कमलबजार नगरपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "kamalbazarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 674,
        "स्थानीय तहको नाम": "साँफेबगर नगरपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "sanfebagarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 675,
        "स्थानीय तहको नाम": "पन्चदेवल विनायक नगरपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "panchadewalbinayakmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 676,
        "स्थानीय तहको नाम": "चौरपाटी गाउँपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "chaurpatimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 677,
        "स्थानीय तहको नाम": "मेल्लेख गाउँपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "mellekhmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 678,
        "स्थानीय तहको नाम": "बान्निगढी जयगढ गाउँपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bannigadhijaygadhmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 679,
        "स्थानीय तहको नाम": "रामारोशन गाउँपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "ramaroshanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 680,
        "स्थानीय तहको नाम": "ढकारी गाउँपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "dhakarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 681,
        "स्थानीय तहको नाम": "तुर्माखाँद गाउँपालिका",
        जिल्ला: "अछाम",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "turmakhadmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    डोटी: [
      {
        "क्र.स.": 682,
        "स्थानीय तहको नाम": "दिपायल सिलगढी नगरपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "dipayalsilgadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 683,
        "स्थानीय तहको नाम": "शिखर नगरपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "shikharmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 684,
        "स्थानीय तहको नाम": "पूर्वीचौकी गाउँपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "purbichaukimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 685,
        "स्थानीय तहको नाम": "बडीकेदार गाउँपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "badikedarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 686,
        "स्थानीय तहको नाम": "जोरायल गाउँपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "jorayalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 687,
        "स्थानीय तहको नाम": "सायल गाउँपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "sayalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 688,
        "स्थानीय तहको नाम": "आदर्श गाउँपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "aadarshamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 689,
        "स्थानीय तहको नाम": "के.आई.सिं. गाउँपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "kisinghmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 690,
        "स्थानीय तहको नाम": "बोगटान फुड्सिल गाउँपालिका",
        जिल्ला: "डोटी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bogatanmun.gov.np",
        कैफियत: "तयार",
      },
    ],
    कैलाली: [
      {
        "क्र.स.": 691,
        "स्थानीय तहको नाम": "धनगढी उपमहानगरपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "उपमहानगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "dhangadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 692,
        "स्थानीय तहको नाम": "टिकापुर नगरपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "tikapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 693,
        "स्थानीय तहको नाम": "घोडाघोडी नगरपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "ghodaghodimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 694,
        "स्थानीय तहको नाम": "लम्कीचुहा नगरपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "lamkichuhamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 695,
        "स्थानीय तहको नाम": "भजनी नगरपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bhajanimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 696,
        "स्थानीय तहको नाम": "गोदावरी नगरपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "godawarimunkailali.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 697,
        "स्थानीय तहको नाम": "गौरीगंगा नगरपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "gaurigangamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 698,
        "स्थानीय तहको नाम": "जानकी गाउँपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "janakimunkailali.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 699,
        "स्थानीय तहको नाम": "बर्दगोरिया गाउँपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bardgoriyamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 700,
        "स्थानीय तहको नाम": "मोहन्याल गाउँपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "mohanyalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 701,
        "स्थानीय तहको नाम": "कैलारी गाउँपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "kailarimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 702,
        "स्थानीय तहको नाम": "जोशीपुर गाउँपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "joshipurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 703,
        "स्थानीय तहको नाम": "चुरे गाउँपालिका",
        जिल्ला: "कैलाली",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "churemun.gov.np",
        कैफियत: "तयार",
      },
    ],
    कञ्चनपुर: [
      {
        "क्र.स.": 704,
        "स्थानीय तहको नाम": "भीमदत्त नगरपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bheemdattamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 705,
        "स्थानीय तहको नाम": "पुर्नवास नगरपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "punarbasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 706,
        "स्थानीय तहको नाम": "वेदकोट नगरपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bedkotmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 707,
        "स्थानीय तहको नाम": "दोधारा चादँनी नगरपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "dodharachandanimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 708,
        "स्थानीय तहको नाम": "शुक्लाफाँटा नगरपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "shuklaphantamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 709,
        "स्थानीय तहको नाम": "बेलौरी नगरपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "belaurimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 710,
        "स्थानीय तहको नाम": "कृष्णपुर नगरपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "krishnapurmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 711,
        "स्थानीय तहको नाम": "बेलडाडी गाउँपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "beldandimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 712,
        "स्थानीय तहको नाम": "लालझाडी गाउँपालिका",
        जिल्ला: "कञ्चनपुर",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "laljhadimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    डडेलधुरा: [
      {
        "क्र.स.": 713,
        "स्थानीय तहको नाम": "अमरगढी नगरपालिका",
        जिल्ला: "डडेलधुरा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "amargadhimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 714,
        "स्थानीय तहको नाम": "परशुराम नगरपालिका",
        जिल्ला: "डडेलधुरा",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "parshurammun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 715,
        "स्थानीय तहको नाम": "आलिताल गाउँपालिका",
        जिल्ला: "डडेलधुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "aalitalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 716,
        "स्थानीय तहको नाम": "भागेश्वर गाउँपालिका",
        जिल्ला: "डडेलधुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "bhageshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 717,
        "स्थानीय तहको नाम": "नवदुर्गा गाउँपालिका",
        जिल्ला: "डडेलधुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "navadurgamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 718,
        "स्थानीय तहको नाम": "अजयमेरु गाउँपालिका",
        जिल्ला: "डडेलधुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "ajayamerumun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 719,
        "स्थानीय तहको नाम": "गन्यापधुरा गाउँपालिका",
        जिल्ला: "डडेलधुरा",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "ganyapadhuramun.gov.np",
        कैफियत: "तयार",
      },
    ],
    बैतडी: [
      {
        "क्र.स.": 720,
        "स्थानीय तहको नाम": "दशरथचन्द नगरपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "dasharathchandmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 721,
        "स्थानीय तहको नाम": "पाटन नगरपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "patanmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 722,
        "स्थानीय तहको नाम": "मेलौली नगरपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "melaulimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 723,
        "स्थानीय तहको नाम": "पुर्चौडी नगरपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "purchaudimun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 724,
        "स्थानीय तहको नाम": "सुर्नया गाउँपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "sunaryamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 725,
        "स्थानीय तहको नाम": "सिगास गाउँपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "sigasmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 726,
        "स्थानीय तहको नाम": "शिवनाथ गाउँपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "shivanathmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 727,
        "स्थानीय तहको नाम": "पञ्चेश्वर गाउँपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "pancheshwormun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 728,
        "स्थानीय तहको नाम": "दोगडाकेदार गाउँपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "dogdakedarmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 729,
        "स्थानीय तहको नाम": "डीलासैनी गाउँपालिका",
        जिल्ला: "बैतडी",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "dilasainimun.gov.np",
        कैफियत: "तयार",
      },
    ],
    दार्चुला: [
      {
        "क्र.स.": 730,
        "स्थानीय तहको नाम": "महाकाली नगरपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "mahakalimundarchula.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 731,
        "स्थानीय तहको नाम": "शैल्यशिखर नगरपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "नगरपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "shailyashikharmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 732,
        "स्थानीय तहको नाम": "मालिकार्जुन गाउँपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "malikarjunmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 733,
        "स्थानीय तहको नाम": "अपिहिमाल गाउँपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "apihimalmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 734,
        "स्थानीय तहको नाम": "दुहुँ गाउँपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "duhunmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 735,
        "स्थानीय तहको नाम": "नौगाड गाउँपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "naugadmun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 736,
        "स्थानीय तहको नाम": "मार्मा गाउँपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "marmamun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 737,
        "स्थानीय तहको नाम": "लेकम गाउँपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "lekammun.gov.np",
        कैफियत: "तयार",
      },
      {
        "क्र.स.": 738,
        "स्थानीय तहको नाम": "ब्याँस गाउँपालिका",
        जिल्ला: "दार्चुला",
        "स्थानीय तहको प्रकार": "गाउँपालिका",
        प्रदेश: "सुदूरपश्चिम प्रदेश",
        वेवसाईट: "vyansmun.gov.np",
        कैफियत: "तयार",
      },
    ],
  },
};
