import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { errorMessageChecker } from "../../../../_helpers/methods";

function Select({
  options,
  register,
  elementId,
  setValue,
  clearErrors,
  properties = {},
  value,
  errors,
  validations
}) {
  const [selectValue, setSelectValue] = useState();
  useEffect(() => {
    if (value) {
      setSelectValue(value)
    }
  }, [value])

  const handleSelectChange = (e) => {
    if (e.target.value) {
      clearErrors(elementId)
      setValue(elementId, e.target.value);
      setSelectValue(e.target.value)
    }
  }
  return (
    <div className="inputDiv">
      <select
        className={`form-control
          ${errorMessageChecker(errors, `${elementId}`) ? "is-invalid" : ""} 
          `}
        value={selectValue}
        onChange={handleSelectChange}
      >
        {Object.keys(options).map((key) => {
          return (
            <option key={key} value={key}>
              {options[key]}
            </option>
          );
        })}
      </select>

      <input
        {...register(elementId, validations)}
        hidden readOnly />
    </div>
  );
}

Select.defaultProps = {
  label: "Gender",
  options: {
    null: "---लिङ्ग छान्नुहोस्---",
    'पुरुष': "पुरुष",
    'महिला': "महिला",
    'अन्य': "अन्य",
  },
};

export default (Select);
