import rules from './roles-rules';

export const checkPermission = (role, action, data) => {
	const permissions = rules[role];
	if (!permissions) {
		// role is not present in the rules
		return false;
	}

	const staticPermissions = permissions.static;

	if (staticPermissions && staticPermissions.includes(action)) {
		// static rule not provided for action
		return true;
	}

	const dynamicPermissions = permissions.dynamic;
	if (dynamicPermissions) {
		const permissionCondition = dynamicPermissions[action];
		if (!permissionCondition) {
			// dynamic rule not provided for action
			return false;
		}

		return permissionCondition(data);
	}
	return false;
};

export const Permission = (props) =>
	checkPermission(props.role, props.perform, props.data) ? props.yes() : props.no();

Permission.defaultProps = {
	yes: () => null,
	no: () => null,
};
