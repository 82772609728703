/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { VscLoading } from "react-icons/vsc";
import { nepaliToEnglishNumber } from "nepali-number";
import { useSelector } from "react-redux";
import BackButton from "../../../../_elements/BackButton";
import { fetchWrapper } from "../../../../_helpers/fetchWrapper";
import { nepaliNumberConverter } from "../../../../_helpers/methods";
import { handleIncrementWardStats } from "../../../../_redux/slices/appStats";
import { setUiError } from "../../../../_redux/slices/formTemplates";
import { setUiMessage } from "../../../../_redux/slices/ui";
import FormInput from "../../../../components/Form/Elements/_new/FormInput";

export const InputFieldStyleOverwrite = css`
  border: 1px solid #ccc !important;
  border-radius: 0.25rem;
  width: 164px !important;
  height: calc(1.5em + 0.75rem + 2px);
  font: 14px Roboto !important;
  color: #333 !important;
  padding: 3px 4px !important;
  background-color: #fff !important;

  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;
const roles = [
	{
		role: "sakha_user",
		title: "शाखा प्रयोगकर्ता विवरण",
	}
];
const SakhaForm = () => {
	const { id } = useParams();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		mode: "onBlur",
		shouldUnregister: true,

		// resolver: yupResolver(wardValidationSchema),
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const nagarpalika = useSelector((state) => state.nagarpalika?.nagarpalika);

	const [defaultVal, setDefaultVal] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [apiErrors, setApiErrors] = useState(null);

	useEffect(() => {
		if (id) {
			const getSakha = async () => {
				try {
					const res = await fetchWrapper.get(`/sakha/${id}/`);
					setDefaultVal(res.data);
				} catch (err) { }
			};
			getSakha();
		}
	}, [id]);

	useEffect(() => {
		setValue("sakha_np", defaultVal?.sakha_np);
	}, [setValue, defaultVal?.sakha_np]);

	useEffect(() => {
		setValue("sakha_en", defaultVal?.sakha_en);
	}, [setValue, defaultVal?.sakha_en]);

	useEffect(() => {
		setValue("phone_np", defaultVal?.phone_np);
	}, [setValue, defaultVal?.phone_np]);

	useEffect(() => {
		setValue("phone_en", defaultVal?.phone_en);
	}, [setValue, defaultVal?.phone_en]);

	useEffect(() => {
		setValue("email", defaultVal?.email);
	}, [setValue, defaultVal?.email]);

	const onSubmit = async (data) => {
		try {
			setIsLoading(true);
			let body = {
				...data,
				nagarpalika: nagarpalika?.id,
				phone_np: nepaliNumberConverter(data.phone_en),
			};
			if (data && !id) {
				body.sakha_user = {
					...data.sakha_user,
					username: data.sakha_user.email,
					phone_np: nepaliNumberConverter(data.sakha_user.phone_en),
				};
				await fetchWrapper
					.post("/sakha/", body)
					.then((res) => {
						dispatch(setUiMessage("नया शाखा कार्यालय थपियो "));
						history.push("/settings/all-sakhas/1");
					})
					.catch(function (err) {
						setApiErrors(err.message);
					})
				dispatch(handleIncrementWardStats());
			} else if (data && id) {
				await fetchWrapper
					.patch(`/sakha/${id}/`, body)
					.then((res) => {
						dispatch(setUiMessage("शाखा कार्यालयको जानकारी परिबर्तन गरियो ।"));
						history.push("/settings/all-sakhas/1");
					})
					.catch((err) => {
						err.error?.non_field_error &&
							dispatch(setUiMessage(err.error?.non_field_error[0]));
					});
			}
			setIsLoading(false);
		} catch (err) {
			setUiError(err);
		}
	};

	return (
		<Fragment>
			<BackButton />
			<h1 className="text-center mb-3 text-blue">नयाँ शाखा कार्यालय</h1>

			<Form onSubmit={handleSubmit(onSubmit)}>
				<Card>
					<Card.Header className="card-header-custom">
						<h2>शाखा कार्यालयको विवरण</h2>
					</Card.Header>
					<Card.Body>
						<Row>
							<Col md={6} lg={4} className="mb-3">
								<FormInput
									label="शाखाको नाम"
									id="sakha_np"
									register={register}
									setValue={setValue}
									isDisabled={isLoading}
									type="Text"
									language={"nepali"}
									errors={errors}
									validations={{
										isRequired: true,
									}}
								/>
							</Col>

							<Col md={6} lg={4} className="mb-3">
								<FormInput
									label="Sakha name"
									id="sakha_en"
									register={register}
									setValue={setValue}
									isDisabled={isLoading}
									type="Text"
									language={"english"}
									errors={errors}
									validations={{
										isRequired: true,
									}}
								/>
							</Col>

							<Col md={6} lg={4} className="mb-3">
								<FormInput
									label="Email"
									id="email"
									register={register}
									setValue={setValue}
									isDisabled={isLoading}
									language="english"
									type="Text"
									subType="email"
									errors={errors}
									validations={{
										isRequired: true,
									}}
								/>
							</Col>
							<Col md={6} lg={4} className="mb-3">
								<FormInput
									label="Phone No."
									id="phone_en"
									register={register}
									setValue={setValue}
									isDisabled={isLoading}
									language="english"
									type="Number"
									errors={errors}
									maxLength="10"
									validations={{
										isRequired: true,
									}}
								/>
							</Col>
						</Row>
					</Card.Body>
				</Card>

				{!id && (
					<>
						{roles.map((role, index) => {
							return (
								<Card key={index}>
									<Card.Header className="card-header-custom">
										<h2>{role.title}</h2>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col md={6} lg={4} className="mb-3">
												<FormInput
													label="पूरा नाम"
													id={`${role.role}.fullname_np`}
													register={register}
													setValue={setValue}
													isDisabled={isLoading}
													type="Text"
													errors={errors}
													validations={{
														isRequired: true,
													}}
													placeholder={"पूरा नाम भर्नुहोस्"}
												/>
											</Col>
											<Col md={6} lg={4} className="mb-3">
												<FormInput
													label="Full name"
													id={`${role.role}.fullname_en`}
													register={register}
													setValue={setValue}
													isDisabled={isLoading}
													type="Text"
													language="english"
													errors={errors}
													validations={{
														isRequired: true,
													}}
													placeholder={"Enter full name"}
												/>
											</Col>
											<Col md={6} lg={4} className="mb-3">
												<FormInput
													label="Phone No"
													id={`${role.role}.phone_en`}
													register={register}
													setValue={setValue}
													isDisabled={isLoading}
													language="english"
													type="Number"
													errors={errors}
													maxLength="10"
													placeholder={"Enter Phone no."}
													validations={{
														isRequired: true,
													}}
												/>
											</Col>
											<Col md={6} lg={4} className="mb-3">
												<FormInput
													label="Email"
													id={`${role.role}.email`}
													register={register}
													setValue={setValue}
													isDisabled={isLoading}
													language="english"
													type="Text"
													subType="email"
													errors={errors}
													validations={{
														isRequired: true,
													}}
													placeholder={"Enter full name"}
												/>
											</Col>
											<Col md={6} lg={4} className="mb-3">
												<FormInput
													label="Password"
													id={`${role.role}.password`}
													register={register}
													setValue={setValue}
													isDisabled={isLoading}
													type="Password"
													errors={errors}
													validations={{
														isRequired: true,
													}}
													placeholder="Enter Password"
												/>
											</Col>
											<Col md={6} lg={4} className="mb-3">
												<FormInput
													label="पद"
													id={`${role.role}.designation_np`}
													register={register}
													setValue={setValue}
													isDisabled={isLoading}
													type="Text"
													errors={errors}
													validations={{
														isRequired: true,
													}}
													placeholder={"पद भर्नुहोस्"}
												/>
											</Col>
											<Col md={6} lg={4} className="mb-3">
												<FormInput
													label="Designation"
													id={`${role.role}.designation_en`}
													register={register}
													setValue={setValue}
													isDisabled={isLoading}
													language="english"
													type="Text"
													errors={errors}
													validations={{
														isRequired: true,
													}}
													placeholder="Enter Designation"
												/>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							);
						})}
					</>
				)}
				{apiErrors && <Alert variant="danger">{apiErrors}</Alert>}
				<Card.Footer className="d-flex justify-content-end align-items-center">
					<Button
						variant="secondary"
						className="mr-2"
						onClick={() => history.goBack()}
						disabled={isLoading}
					>
						रद्द गर्नुहोस्
					</Button>
					<Button type="submit" variant="success" disabled={isLoading}>
						{isLoading ? (
							<VscLoading className="spin" />
						) : (
							<Fragment>{id ? "अपडेट" : "सुरक्षित"} गर्नुहोस्</Fragment>
						)}
					</Button>
				</Card.Footer>
			</Form>
		</Fragment>
	);
};

export default SakhaForm;
