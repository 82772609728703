import styled from "@emotion/styled";

export const StyledSignature = styled.div`
  width: 230px;
  display: flex;
  flex-direction: column;
  .textContainer {
    padding-top: 1rem;
  }
  .signaturePadContainer {
    position: relative;
    display: flex;
    height: 150px;
    width: 230px;

    .signaturePad {
      height: 150px;
      width: 230px;
      border: none;
      border-bottom: 3px solid black;
    }
    :hover .actionBtnsContainer {
      opacity: 1;
    }
    :hover .signaturePad {
      border: 3px solid black;
    }
  }
  .hiddenInput {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }
  .inputFields {
    > span {
      text-align: center;
      width: 100%;
      margin-bottom: 0.2rem;
    }
  }
  .actionBtnsContainer {
    opacity: 0;
    transition: opacity 150ms;
    z-index: 2;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    button {
      margin-bottom: 0.5rem;
      margin-left: 0.2rem;
    }
  }
`;
