export const districts = {
  0: {
    nepali: "",
    english: "",
    id: "0",
  },
  1: {
    nepali: "ताप्लेजुङ",
    english: "Taplejung",
    id: "1",
  },
  2: {
    nepali: "पाँचथर",
    english: "Panchthar",
    id: "2",
  },
  3: {
    nepali: "ईलाम",
    english: "Ilam",
    id: "3",
  },
  4: {
    nepali: "झापा",
    english: "Jhapa",
    id: "4",
  },
  5: {
    nepali: "संखुवासभा",
    english: "Sankhuwasabha",
    id: "5",
  },
  6: {
    nepali: "तेह्रथुम",
    english: "Terhathum",
    id: "6",
  },
  7: {
    nepali: "भोजपुर",
    english: "Bhojpur",
    id: "7",
  },
  8: {
    nepali: "धनकुटा",
    english: "Dhankuta",
    id: "8",
  },
  9: {
    nepali: "मोरङ",
    english: "Morang",
    id: "9",
  },
  10: {
    nepali: "सुनसरी",
    english: "Sunsari",
    id: "10",
  },
  11: {
    nepali: "सोलुखुम्बु",
    english: "Solukhumbu",
    id: "11",
  },
  12: {
    nepali: "खोटाङ",
    english: "Khotang",
    id: "12",
  },
  13: {
    nepali: "ओखलढुङ्गा",
    english: "Okhaldhunga",
    id: "13",
  },
  14: {
    nepali: "उदयपुर",
    english: "Udaypur",
    id: "14",
  },
  15: {
    nepali: "सप्तरी",
    english: "Saptari",
    id: "15",
  },
  16: {
    nepali: "सिराहा",
    english: "Siraha",
    id: "16",
  },
  17: {
    nepali: "दोलखा",
    english: "Dolakha",
    id: "17",
  },
  18: {
    nepali: "रामेछाप",
    english: "Ramechap",
    id: "18",
  },
  19: {
    nepali: "सिन्धुली",
    english: "Sindhuli",
    id: "19",
  },
  20: {
    nepali: "धनुषा",
    english: "Dhanusa",
    id: "20",
  },
  21: {
    nepali: "महोत्तरी",
    english: "Mahottari",
    id: "21",
  },
  22: {
    nepali: "सर्लाही",
    english: "Sarlahi",
    id: "22",
  },
  23: {
    nepali: "रसुवा",
    english: "Rasuwa",
    id: "23",
  },
  24: {
    nepali: "धादिङ",
    english: "Dhading",
    id: "24",
  },
  25: {
    nepali: "नुवाकोट",
    english: "Nuwakot",
    id: "25",
  },
  26: {
    nepali: "काठमाण्डौ",
    english: "Kathmandu",
    id: "26",
  },
  27: {
    nepali: "भक्तपुर",
    english: "Bhaktapur",
    id: "27",
  },
  28: {
    nepali: "ललितपुर",
    english: "Lalitpur",
    id: "28",
  },
  29: {
    nepali: "काभ्रेपलान्चोक",
    english: "Kavre palanchok",
    id: "29",
  },
  30: {
    nepali: "सिन्धुपाल्चोक",
    english: "Sindhupalchowk",
    id: "30",
  },
  31: {
    nepali: "मकवानपुर",
    english: "Makwanpur",
    id: "31",
  },
  32: {
    nepali: "रौतहट",
    english: "Rautahat",
    id: "32",
  },
  33: {
    nepali: "बारा",
    english: "Bara",
    id: "33",
  },
  34: {
    nepali: "पर्सा",
    english: "Parsa",
    id: "34",
  },
  35: {
    nepali: "चितवन",
    english: "Chitwan",
    id: "35",
  },
  36: {
    nepali: "गोरखा",
    english: "Gorkha",
    id: "36",
  },
  37: {
    nepali: "मनाङ्ग",
    english: "Manang",
    id: "37",
  },
  38: {
    nepali: "लम्ँजुङ",
    english: "Lamjung",
    id: "38",
  },
  39: {
    nepali: "कास्की",
    english: "Kaski",
    id: "39",
  },
  40: {
    nepali: "तनहुँ",
    english: "Tanahu",
    id: "40",
  },
  41: {
    nepali: "स्याङ्जा",
    english: "Syangja",
    id: "41",
  },
  42: {
    nepali: "गुल्मी",
    english: "Gulmi",
    id: "42",
  },
  43: {
    nepali: "पाल्पा",
    english: "Palpa",
    id: "43",
  },
  44: {
    nepali: "अर्घाखाची",
    english: "Arghakhachi",
    id: "44",
  },
  45: {
    nepali: "नवलपरासी",
    english: "Nawalparasi",
    id: "45",
  },
  46: {
    nepali: "रुपन्देही",
    english: "Rupandehi",
    id: "46",
  },
  47: {
    nepali: "कपिलवस्तु",
    english: "Kapilbastu",
    id: "47",
  },
  48: {
    nepali: "मुस्ताङ्ग",
    english: "Mustang",
    id: "48",
  },
  49: {
    nepali: "म्याग्दी",
    english: "Myagdi",
    id: "49",
  },
  50: {
    nepali: "बाग्लुङ",
    english: "Baglung",
    id: "50",
  },
  51: {
    nepali: "पर्वत",
    english: "Parbat",
    id: "51",
  },
  52: {
    nepali: "रुकुम",
    english: "Rukum",
    id: "52",
  },
  53: {
    nepali: "रोल्पा",
    english: "Rolpa",
    id: "53",
  },
  54: {
    nepali: "प्युठान",
    english: "Pyuthan",
    id: "54",
  },
  55: {
    nepali: "सल्यान",
    english: "Salyan",
    id: "55",
  },
  56: {
    nepali: "दाङ",
    english: "Dang",
    id: "56",
  },
  57: {
    nepali: "डोल्पा",
    english: "Dolpa",
    id: "57",
  },
  58: {
    nepali: "मुगु",
    english: "Mugu",
    id: "58",
  },
  59: {
    nepali: "जुम्ला",
    english: "Jumla",
    id: "59",
  },
  60: {
    nepali: "कालिकोट",
    english: "Kalikot",
    id: "60",
  },
  61: {
    nepali: "हुम्ला",
    english: "Humla",
    id: "61",
  },
  62: {
    nepali: "जाजरकोट",
    english: "Jajarkot",
    id: "62",
  },
  63: {
    nepali: "दैलेख",
    english: "Dailekh",
    id: "63",
  },
  64: {
    nepali: "सुर्खेत",
    english: "Surkhet",
    id: "64",
  },
  65: {
    nepali: "बाँके",
    english: "Banke",
    id: "65",
  },
  66: {
    nepali: "बर्दिया",
    english: "Bardiya",
    id: "66",
  },
  67: {
    nepali: "बाजुरा",
    english: "Bajura",
    id: "67",
  },
  68: {
    nepali: "अछाम",
    english: "Achham",
    id: "68",
  },
  69: {
    nepali: "बझाङ",
    english: "Bajhang",
    id: "69",
  },
  70: {
    nepali: "डोटी",
    english: "Doti",
    id: "70",
  },
  71: {
    nepali: "कैलाली",
    english: "Kailali",
    id: "71",
  },
  72: {
    nepali: "दार्चुला",
    english: "Darchula",
    id: "72",
  },
  73: {
    nepali: "बैतडी",
    english: "Baitadi",
    id: "73",
  },
  74: {
    nepali: "डडेलधुरा",
    english: "Dadeldhura",
    id: "74",
  },
  75: {
    nepali: "कन्चनपुर",
    english: "Kanchanpur",
    id: "75",
  },
  76: {
    nepali: "नवलपरासी ( बर्दघाट सुस्ता पुर्व )",
    english: "Nawalparasi ( Bardaghat Susta East)",
    id: "76",
  },
  77: {
    nepali: "रुकुम (पुर्व भाग)",
    english: "Rukum (East)",
    id: "77",
  },
};
