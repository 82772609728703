/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import {
  Fragment,
  useEffect,
  //  useRef,
  useState,
} from "react";
import {
  Button,
  // Button,
  Card,
  Col,
  Row,
  Table,
} from "react-bootstrap";
import {
  FaSearch,
  FaEye,
  // ,FaPrint
} from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchWrapper } from "../_helpers/fetchWrapper";
import PaginationComp from "../components/Pagination";
import { useParams, useHistory } from "react-router-dom";
import { VscLoading } from "react-icons/vsc";
import moment from "moment";
import {
  // generatePreview,
  // nepaliNumber,
  nepaliNumberConverter,
} from "../_helpers/methods";
import { useSelector } from "react-redux";
import { languageSelector } from "../_redux/slices/languages";
import InputField from "../components/InputField";
import { DisabledServices } from "../api/services/DisabledServices";
import { ElderlyServices } from "../api/services/ElderlyServices";
// import PreviewModel from "../components/Form/PreviewModel";
// import Elements from "../components/Form/Elements";
// import { useReactToPrint } from "react-to-print";

const VerifyUser = () => {
  const [isLoading, setIsLoading] = useState();
  const history = useHistory();
  const user = useSelector(state => state.users?.user);
  const { pageNum } = useParams();
  const [page, setPage] = useState(pageNum);
  const [papers, setPapers] = useState([]);
  const [searchToken, setSearchToken] = useState("");
  const [token, setToken] = useState("");
  const { language } = useSelector(languageSelector);
  console.log(papers, 'papers')
  const role = user.role_en;

  const [activeTab, setActiveTab] = useState('disabled');


  useEffect(() => {
    const getPapersList = async () => {
      try {
        setIsLoading(true);

        let wardFilter = {};
        if (role !== "admin") {
          wardFilter = {
            ward: user.ward
          }
        }

        if (activeTab === 'disabled') {
          let response = await DisabledServices.getDisabledProfiles({
            page: page ?? 1,
            isPublic: true,
            ...wardFilter
          });
          setPapers(response.results);
        }
        else if (activeTab === "elderly") {
          let response = await ElderlyServices.getElderlyProfiles({
            page: page ?? 1,
            isPublic: true,
            ...wardFilter
          });
          setPapers(response.results);
        }

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    getPapersList();
  }, [page, setPapers, searchToken, role, user.ward, activeTab]);

  const handleSetPage = (page) => {
    setPage(page);
    history.push(`${page}`);
  };

  const handleTokenChange = (value) => {
    setToken(value);
    if (value === "") {
      setSearchToken("");
    }
  };

  // const [show, setShow] = useState(false);
  // const [data, setData] = useState(null);
  // const [paper, setPaper] = useState(null);
  // const [previewData, setPreviewData] = useState();
  // const [previewFormData, setPreviewFormData] = useState();

  // const printRef = useRef("");

  // const handleClose = () => {
  //   setShow(false);
  // };

  // const handlePreview = () => {
  //   setPreviewFormData(paper.form_values);
  //   if (data.preview) {
  //     const tempPreviewData = generatePreview(data, paper.form_values);
  //     setPreviewData(tempPreviewData);
  //   }
  //   setShow(true);
  // };

  // const handlePrint = useReactToPrint({
  //   content: () => {
  //     return printRef.current;
  //   },
  // });

  // const handlePrintClick = async (paper) => {
  //   try {
  //     console.log(paper, 'paper')
  //     setPaper(paper);
  //     let response = await fetchWrapper.get(`/template/${paper.template}/`);
  //     setShow(true);
  //     setData(response.data);
  //     handlePreview();
  //   } catch (err) {
  //     console.log("error");
  //   }
  // };

  return (
    <Fragment>
      <Row className="mt-2">
        <Col md={12}>
          <Card
            css={css`
              min-height: 600px;
            `}
          >
            <Card.Header className="card-header-custom">
              <h2>परिचय पत्र अनुरोध प्रमाणिकरण </h2>
              <div
                className="card-header-form  citzen-token-search"
                css={css`
                  min-width: 260px;
                `}
              >
                <form>
                  <div className="input-group">
                    <InputField
                      css={css`
                        padding: 0 1rem !important;
                        font-size: 1rem !important;
                      `}
                      className="form-control custom-search"
                      placeholder={
                        language === "english"
                          ? "Enter token"
                          : "कोड टाईप गर्नुहोस"
                      }
                      language={language}
                      value={token}
                      getInputData={(value) => {
                        handleTokenChange(value);
                      }}
                    />
                    <div className="input-group-btn">
                      <button
                        className="btn btn-primary input-search-icon"
                        onClick={(e) => {
                          e.preventDefault();
                          setSearchToken(token);
                        }}
                      >
                        <FaSearch />
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div>
                <Button variant={activeTab === "disabled" ? "primary" : "primary-outline"} onClick={() => setActiveTab("disabled")}>अपाङ्गता</Button>
                <Button variant={activeTab === "elderly" ? "primary" : "primary-outline"} onClick={() => setActiveTab("elderly")}>जेष्ठ नागरिक</Button>
              </div>
            </Card.Header>

            <Card.Body className="p-0">
              <div className="table-responsive">
                <Table striped hover responsive>
                  <thead>
                    <tr
                      css={css`
                        th {
                          text-align: left;
                        }
                      `}
                    >
                      <th>
                        <span
                          css={css`
                            display: block;
                          `}
                        >
                          निवेदकको नाम
                        </span>
                        <span
                          css={css`
                            font-size: 12px !important;
                          `}
                        >
                          निवेदकको नागरिकता
                        </span>
                      </th>

                      <th>परिचय पत्रको प्रकार</th>

                      <th>कोड</th>

                      <th>
                        <span>जारी मिती</span>
                      </th>
                      <th>Status</th>
                      <th className="text-left">कार्य</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td
                          css={css`
                            display: flex;
                            justify-content: left;
                            align-items: left;
                            min-height: 60vh !important;
                          `}
                          className=""
                          colSpan="6"
                        >
                          <VscLoading className="spin" fontSize="42" />
                        </td>
                      </tr>
                    ) : papers?.results?.length === 0 ? (
                      <tr>
                        <td
                          colSpan="6"
                          css={css`
                            text-align: left !important;
                            padding: 3rem;
                            font-size: 1.2rem;
                          `}
                        >
                          कुनै परिचय पत्र पाईएन
                        </td>
                      </tr>
                    ) : (
                      papers?.map((paper) => {
                        let applicantName;
                        if (language === "english") {
                          applicantName = paper.extra.fullname_en;
                        } else {
                          applicantName = paper.name_np;
                        }
                        console.log(applicantName, 'applicantName')
                        return (
                          <tr
                            key={paper.id}
                            css={css`
                              td {
                                text-align: left;
                              }
                            `}
                          >
                            <td>
                              {applicantName || ""}
                            </td>
                            <td>{paper.id}</td>
                            <td>
                              <span
                                css={css`
                                  display: block;
                                `}
                              >
                              </span>
                              <span
                                css={css`
                                  font-size: 15px !important;
                                `}
                              >

                              </span>
                            </td>
                            <td>
                              <span>

                              </span>
                            </td>
                            {/* <td>
                              {language === "nepali" ? (
                                <Fragment>
                                  रु {nepaliNumber(paper?.extra?.charge)}
                                </Fragment>
                              ) : (
                                <Fragment>Rs. {paper?.extra?.charge}</Fragment>
                              )}
                            </td> */}
                            <td>Pending</td>
                            <td className="text-left">
                              <Link to={`/citizen/template/${paper?.id}`}>
                                <FaEye className="text-success" />
                              </Link>
                              {/* <Button>
                                <FaPrint
                                  onClick={() => handlePrintClick(paper)}
                                />
                              </Button> */}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <PaginationComp
        page={page}
        total={papers?.count}
        setPage={handleSetPage}
      />
    </Fragment >
  );
};

export default connect(null, {})(VerifyUser);
